import { createStyles, makeStyles, Theme } from '@material-ui/core';
import { blueGrey } from '@material-ui/core/colors';

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        cardHeader: {
            fontWeight: 600,
            fontSize: 16,
            color: '#020202',
        },
        line: {
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            flexGrow: 1,
        },
        listItem: {
            display: 'flex',
            padding: theme.spacing(0, 1),
            alignItems: 'center',
            height: '40px',
            '&:hover': {
                backgroundColor: blueGrey[100],
                cursor: 'pointer',
                borderRadius: theme.spacing(1),
            },
        },
        listItemInactive: {
            display: 'flex',
            padding: theme.spacing(0, 1),
            alignItems: 'center',
            height: '40px',
        },
        checkBoxRed: {
            color: 'red',
        },
    })
);