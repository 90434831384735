import { Typography, TypographyVariant, useTheme } from '@material-ui/core';
import { useStyles } from './style'
import { Property } from 'csstype';
import React from 'react';
import formatter from '../../formater';

interface IValueColorTextProps {
    value: number;
    type: 'percentage' | 'currency';
    variant?: TypographyVariant;
    align?: 'right' | 'left' | Property.JustifyContent;
    textColor?: string;
    style?: React.CSSProperties;
    sameColor?: boolean;
}

const ValueColorText: React.FC<IValueColorTextProps> = ({
    value,
    type,
    variant,
    align,
    children,
    style,
    textColor,
    sameColor,
}) => {
    const classes = useStyles();

    const getValueString = (val: number) => {
        switch (type) {
            case 'currency':
                return `${formatter.format(val)}`;
            case 'percentage':
                return `${val.toFixed(2).replace('.', ',')} %`;
        }
    };

    const theme = useTheme();

    const getFlexAlignment = (): Property.JustifyContent => {
        switch (align) {
            case 'right':
                return 'flex-end';
            case 'left':
                return 'flex-start';
            case 'center':
            default:
                return align as Property.JustifyContent;
        }
    };

    const color = () => {
        if (value === 0) {
            return 'black';
        }
        return value > 0 ? theme.palette.secondary.main : theme.palette.error.main;
    };

    return (
        <div className={classes.line} style={{ justifyContent: getFlexAlignment() }}>
            <Typography variant={variant} style={{ color: color(), marginRight: theme.spacing(1), ...style }}>
                {getValueString(value)}
            </Typography>
            <Typography style={{ color: sameColor ? color() : textColor ? textColor : undefined, ...style }}>
                {children}
            </Typography>
        </div>
    );
};
export default ValueColorText;