import {
    Chip,
    CircularProgress,
    createStyles,
    makeStyles,
    Theme,
    Typography,
    Select,
    MenuItem,
} from '@material-ui/core';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import api from '../../../apis/dreamrichapi';
import { ChangeCustomerUserInfo } from '../../../redux/actions/planningActions';
import { IStore } from '../../../redux/types/store';
import { validateCPF } from '../../../utils/validateCPF';
import { GreyedTextInput } from '../../global/inputs/GreyedInput';
import CPFMaskedInput from '../../global/masks/cpfMaskedInput';
import { IPlannerPageProps } from '../types';
import { GenerateFakeID } from '../../../utils/generateFakeID';
import paises from '../../../utils/ddis.json';
import { useStyles } from './style';

const CreatePlanner: React.FC<IPlannerPageProps> = ({ changeTitle }) => {
    const classes = useStyles();

    const { plannerUsername } = useSelector((store: IStore) => ({
        plannerUsername: store.loggedUser.currentUser['cognito:username'],
    }));
    const history = useHistory();

    const emailRe = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@(([[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    const dispatch = useDispatch();
    const [nickname, setNickname] = React.useState('');
    const [nicknameErr, setNicknameErr] = React.useState(false);
    const [username, setUsername] = React.useState('');
    const [email, setEmail] = React.useState('');
    const [emailErr, setEmailErr] = React.useState(false);
    const [nationality, setNationality] = React.useState<string | null>('Brasil');
    const [postErr, setPostErr] = React.useState('');
    const [loading, setLoading] = React.useState(false);
    const [cpf, setCpf] = React.useState('');
    const [cpfErr, setCpfErr] = React.useState(false);
    const [rne, setRNE] = React.useState<string>('');
    const [rneErr, setRNEErr] = React.useState<boolean>(false);

    React.useEffect(() => {
        changeTitle('Cadastro para login');
    }, []);

    React.useEffect(() => {
        if (emailErr || cpfErr) {
            setPostErr('Favor preencher os campos corretamente');
        }
        if (!emailErr && !cpfErr) {
            setPostErr('');
        }
    }, [emailErr, cpfErr]);

    React.useEffect(() => {
        if (nationality !== 'Brasil') {
            setCpfErr(false);
        }
    }, [nationality]);

    const checkEmail = () => {
        let valid = false;
        if (emailRe.test(email)) {
            valid = true;
        }

        setEmailErr(!valid);
        return valid;
    };

    const handleSubmit = (event: React.FormEvent) => {
        event.preventDefault();
        setLoading(true);

        if (emailErr || cpfErr) {
            return;
        }

        if (nationality === 'Brasil' && !cpf.trim()) {
            setCpfErr(true);
            setLoading(false);
            return;
        } else if (nationality !== 'Brasil' && checkRNE()) {
            setLoading(false);
            return;
        }

        let unmaskedCPF = '';
        if (nationality === 'Brasil') {
            unmaskedCPF = cpf.replace(/\./g, '').replace('-', '');
        } else {
            unmaskedCPF = rne.replace(/\./g, '').replace('-', '');
        }

        api.post('/createplanner', {
            email: email.trim(),
            nickname: nickname.trim(),
            user: unmaskedCPF,
            plannerUser: plannerUsername,
            nationality: nationality,
        })
            .then(res => {
                dispatch(ChangeCustomerUserInfo({ email, nickname, userID: res.data, user: unmaskedCPF }));
                setLoading(false);
                history.push('/clientinfo');
            })
            .catch(err => {
                setLoading(false);
                if (err.response) {
                    setPostErr(err.response.data);
                }
            });
    };

    const checkCpf = () => {
        const unmaskedCPF = cpf.replace(/\./g, '').replace('-', '');
        if (unmaskedCPF) {
            const validCPF = validateCPF(unmaskedCPF);
            setCpfErr(!validCPF);
            if (!validCPF) {
                setCpfErr(true);
                return;
            }
        }
    };

    const checkRNE = () => {
        let condition = rne?.trim() === '' || rne === null;
        setRNEErr(condition);
        return condition;
    };

    const buttonDisabled = () => {
        if (emailErr || (cpfErr && nationality === 'Brasil')) {
            return true;
        }
        if (!emailErr && !cpfErr) {
            return false;
        }
        return false;
    };

    return (
        <div className={classes.root}>
            <div className={classes.topInfo}>
                <Typography className={classes.header} align="center">
                    Como o(a) cliente irá fazer login?
                </Typography>
                <div className={classes.subHeader}>
                    <Typography variant="body2" align="center">
                        O cliente receberá uma senha temporária no email
                    </Typography>
                    <Typography variant="body2" align="center">
                        Verifique se o email está correto
                    </Typography>
                </div>
            </div>
            <form onSubmit={event => handleSubmit(event)}>
                <div className={classes.form}>
                    <div>
                        <Typography className="formItem mb-2" variant="body2">Como o(a) Assessor(a) prefere ser chamado(a)</Typography>
                        <GreyedTextInput
                            required
                            variant="outlined"
                            fullWidth
                            size="small"
                            error={nicknameErr}
                            placeholder="Nome da pessoa"
                            id="client-name-input"
                            helperText="Pode ser um apelido"
                            value={nickname}
                            onChange={event => setNickname(event.target.value)}
                            onBlur={() => {
                                if (!nickname) {
                                    setNicknameErr(true);
                                }
                            }}
                        />
                    </div>
                    <div>
                        <Typography className="formItem mb-2">Qual a nacionalidade do Assessor?</Typography>
                        <Select
                            labelId="nacionalidade"
                            required={true}
                            id="nacionalidade"
                            value={nationality}
                            name="select-nacionlidade"
                            style={{ minWidth: 250 }}
                            defaultValue="Brasil"
                            fullWidth
                            variant="outlined"
                            onChange={(event: React.ChangeEvent<{ value: unknown }>) => {
                                setNationality(event.target.value as string);
                            }}
                        >
                            {paises.map(data => (
                                <MenuItem value={data.country}>{data.country}</MenuItem>
                            ))}
                        </Select>
                    </div>
                    {nationality === 'Brasil' && (
                        <div>
                            <Typography className="formItem mb-2" variant="body2">Entre com o CPF do Assessor.</Typography>
                            <GreyedTextInput
                                error={cpfErr}
                                helperText={cpfErr ? 'Favor inserir um cpf válido' : ''}
                                variant="outlined"
                                size="small"
                                InputProps={{
                                    inputComponent: CPFMaskedInput as any,
                                }}
                                value={cpf}
                                onBlur={checkCpf}
                                onChange={event => setCpf(event.target.value)}
                                required={true}
                            />
                        </div>
                    )}
                    {nationality !== 'Brasil' && (
                        <div>
                            <Typography className="formItem mb-2" variant="body2">
                                Entre com o Registro Nacional de Estrangeiro (RNE) do Assessor.
                            </Typography>
                            <GreyedTextInput
                                variant="outlined"
                                size="small"
                                value={rne}
                                helperText={rneErr ? 'Inserir um valor para o RNE do cliente' : ''}
                                onBlur={checkRNE}
                                onChange={event => setRNE(event.target.value)}
                                required={true}
                            />
                        </div>
                    )}
                    <div>
                        <Typography className="formItem mb-2" variant="body2">E-mail</Typography>
                        <GreyedTextInput
                            required
                            variant="outlined"
                            size="small"
                            fullWidth
                            error={emailErr}
                            id="client-email"
                            placeholder="emaildoassessor@email.com.br"
                            helperText="E-mail mais utilizado pelo Assessor"
                            value={email}
                            onChange={event => setEmail(event.target.value)}
                            onBlur={checkEmail}
                        />
                    </div>
                    <Typography color="error" align="center">
                        {postErr}
                    </Typography>
                    <div className={classes.sendControl}>
                        {loading ? <CircularProgress size={20} /> : null}
                        <button
                            type="submit"
                            disabled={buttonDisabled()}
                            className={classes.submitButton}
                            style={{ float: 'right' }}
                        >
                            <Chip
                                className={buttonDisabled() ? classes.chipDisabled : classes.chip}
                                size="medium"
                                clickable={!buttonDisabled()}
                                label={'Finalizar cadastro'}
                            />
                        </button>
                    </div>
                </div>
            </form>
        </div>
    );
};

export default CreatePlanner;
