import { createStyles, makeStyles, Theme } from '@material-ui/core';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import api from '../../../../apis/dreamrichapi';
import { ChangeCustomerIncoming } from '../../../../redux/actions/planningActions';
import { IStore } from '../../../../redux/types/store';
import { IPlannerPageProps } from '../../types';
import { Incoming } from '../incoming/incoming';
import { IIncoming } from '../types';
import { useStyles } from './style';

const Income: React.FC<IPlannerPageProps> = ({ changeTitle }) => {
    const classes = useStyles();
    const history = useHistory();
    // store vars
    const { userID, income, maritalStatus, currToolbar } = useSelector((store: IStore) => ({
        userID: store.planning.customerUserInfo.userID,
        income: store.planning.customerFormValues.incoming,
        currToolbar: store.planning.toolbar.current,
        maritalStatus: store.planning.customerFormValues.customerInfo.personalInfo.personalInfo.maritalStatus,
    }));
    const hasPartner = maritalStatus === 'married';
    if (!userID) {
        history.push('/');
    }
    // step control
    const [totalIncome, setTotalIncome] = React.useState({ ...income, userID });
    const [currentStep, setStep] = React.useState(1);

    const incomingStorage = JSON.parse(localStorage.getItem('storageCustomer'+userID))?.incoming;

    React.useEffect(() => {
        if (!currToolbar || currentStep === currToolbar) return;
        setStep(currToolbar);
    }, [currToolbar]);

    const nextStep = () => {
        if (currentStep < totalSteps) {
            setStep(currentStep + 1);
        }
    };
    const previousStep = () => {
        setStep(currentStep - 1);
    };
    // send control
    const [send, setSend] = React.useState(false);
    // risk control to send just after finish
    const [ready, setReady] = React.useState(false);
    const finish = () => {
        setReady(true);
        setSend(true);
    };

    const dispatch = useDispatch();
    const [errLabel, setErrLabel] = React.useState('');

    const totalSteps = hasPartner ? 2 : 1;
    const percentage = (currentStep / totalSteps) * 100;

    const [personalIncome, setPersonalIncome] = React.useState<IIncoming>(incomingStorage.personalIncome||totalIncome.personalIncome);

    const [partnerIncome, setPartnerIncome] = React.useState<IIncoming>(incomingStorage.partnerIncome||totalIncome.partnerIncome);

    React.useEffect(() => {
        if (send && totalIncome?.ready) {
            api.post('/incoming', totalIncome)
                .then(() => {
                    setErrLabel('');
                    dispatch(ChangeCustomerIncoming(totalIncome));
                    history.push('/planner');
                })
                .catch(err => {
                    if (err.response) {
                        setErrLabel(`Erro salvando informações de renda: ${err.response.data}`);
                    } else {
                        setErrLabel(`Erro interno do sistema: ${err}`);
                    }
                });
            setSend(false);
        }
    }, [send, history, totalIncome]);

    const Steps = hasPartner
        ? [
              <Incoming
                  key="personal_income_input"
                  defaultIncome={personalIncome}
                  setIncome={setPersonalIncome}
                  errLabel={errLabel}
                  nextStep={nextStep}
                  previousStep={history.goBack}
              />,
              <Incoming
                  key="partner_income_input"
                  defaultIncome={partnerIncome}
                  setIncome={setPartnerIncome}
                  errLabel={errLabel}
                  nextStep={finish}
                  previousStep={previousStep}
                  partner={true}
              />,
          ]
        : [
              <Incoming
                  defaultIncome={personalIncome}
                  setIncome={setPersonalIncome}
                  errLabel={errLabel}
                  nextStep={finish}
                  previousStep={history.goBack}
              />,
          ];

    React.useEffect(() => {
        if (personalIncome) {
            setTotalIncome(previousIncome => ({ ...previousIncome!, personalIncome, ready }));
        }
        if (partnerIncome) {
            setTotalIncome(previousIncome => ({ ...previousIncome!, partnerIncome, ready }));
        }
    }, [personalIncome, partnerIncome, ready]);

    React.useEffect(() => {
        changeTitle(`Renda`, currentStep, totalSteps);
    }, [currentStep, totalSteps]);

    return <div className={classes.content}>{Steps[currentStep - 1]}</div>;
};
export default Income;
