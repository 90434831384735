import React from 'react';
import { Typography } from '@material-ui/core';

const PercentageAdornmentGainInfo: React.FC = () => (
    <Typography style={{ marginRight: 4 }} variant="body2">
        %
    </Typography>
);

export default PercentageAdornmentGainInfo;
