import { createStyles, makeStyles, Theme } from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        content: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            alignItems: 'center',
            marginBottom: theme.spacing(1),
        },
        headerContainer: {
            margin: theme.spacing(4, 0),
            width: '80%',
        },
        header: {
            fontWeight: 600,
        },
        inputLabel: {
            padding: '1em 0 0.5em',
        },
        form: {
            width: '90%',
        },
        chooseButtons: {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-start',
            alignItems: 'right',
        },
        heirsAmount: {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'right',
            maxWidth: '50%',
        },
        formControllers: {
            paddingTop: theme.spacing(2),
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            alignContent: 'flex-end',
            marginBottom: '25px'
        },
        chipButton: {
            backgroundColor: 'transparent',
            border: 'none',
            marginBottom: '15px'
        
        },
        chip: {
            color: '#FFFFFF',
            backgroundColor: theme.palette.primary.dark,
        },
        subheader: {
            marginTop: theme.spacing(2),
        },
        outButton: {
            backgroundColor: 'white',
            color: '#bdbdbd',
        },
        heirs: {
            display: 'flex',
        },
        heirsName: {
            flexBasis: '60%',
            flex: '1 0',
        },
        heirsAge: {
            marginLeft: theme.spacing(2),
            flex: '0 1',
            flexBasis: '20%',
        },
        heirsInput: {
            height: 56,
        },
    })
);
