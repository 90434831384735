import { Divider, Paper, Typography } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { IStore } from '../../../../../redux/types/store';
import formatter from '../../../../global/formater';
import CurrentLiabilities from '../../currentLiabilities';
import CurrentPatrimony from '../../currentPatrimony';
import Earnings from '../../earnings/desktop/earnings';

import Expenses from '../../expenses/desktop/expenses';
import ExpensesMobile from '../../expenses/mobile/expensesMobile'

import { ICurrentState } from '../../types';

import { useStyles } from './style';
interface ICurrentStateProps {
    currentState: ICurrentState;
}

const CurrentStateMobile: React.FC<ICurrentStateProps> = ({ currentState }) => {
    const classes = useStyles();

    const { financing } = useSelector((store: IStore) => ({
        financing: store.planning.customerFormValues.financing.financing,
    }));

    const [netWorth, setNetWorth] = useState([]);

    const totalNetWorth = (netWorth[0] || 0) - (netWorth[1] || 0);

    return (

        <div className='mt-5'>
            <h4> Sua Situação Atual</h4>
            <div className={classes.innerPadding}>
                <Earnings
                    monthlyIncome={currentState.monthlyIncoming}
                    profits={currentState.profits}
                    bonuses={currentState.bonuses}
                />
                <Divider className={classes.spacing} />
                <ExpensesMobile expenses={currentState.fixedCost} left={currentState.left} />
            </div>
            <Divider className={classes.spacing} />
            <div className={classes.innerPadding}>
                <CurrentPatrimony netWorth={netWorth} setNetWorth={setNetWorth} consortia={currentState.consortia} patrimony={currentState.patrimony} />
                {financing.length !== 0
                    ? <CurrentLiabilities netWorth={netWorth} setNetWorth={setNetWorth} />
                    : null
                }
                <Paper elevation={3}>
                    <div className={classes.totalLabel}>
                        <div style={{ margin: 'auto' }}>
                            <Typography style={{ fontSize: '16px', lineHeight: '20px' }}>Patrimônio líquido</Typography>
                            <Typography style={{ fontSize: '10px', lineHeight: '10px' }} variant="overline">
                                Ativos menos passivos
                            </Typography>
                            <Typography variant="h4" style={{ color: totalNetWorth > 0 ? '#03a9f4' : 'red' }}>
                                {formatter.format(totalNetWorth)}
                            </Typography>
                        </div>
                    </div>
                </Paper>
            </div>
        </div>

    );
};

export default CurrentStateMobile;