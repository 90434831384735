import { createStyles, makeStyles, Paper, Theme, Typography } from '@material-ui/core';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ShowYourNumber } from '../../../../../redux/actions/storageRedux';
import { IStore } from '../../../../../redux/types/store';
import formatter from '../../../../global/formater';
import ScratchOff from '../../../../global/scretcher/scretcher';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        yourNumber: {
            color: theme.palette.secondary.light,
        },
        blurText: {
            color: 'transparent',
            textShadow: '0 0 7px rgba(3, 169, 244,0.8)',
            cursor: 'pointer',
            '&:hover': {
                textShadow: '0 0 5px rgba(3, 169, 244,0.8)',
            },
        },
        groupTotal: {
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between',
            padding: theme.spacing(2),
            marginTop: theme.spacing(2),
        },
    })
);

interface IYourNumberProps {
    yourNumber: number;
    userID: string;
    isPlanner: boolean;
}

const YourNumber: React.FC<IYourNumberProps> = ({ yourNumber, userID, isPlanner }) => {
    const classes = useStyles();

    const showNumber = useSelector((store: IStore) => store.storage.yourNumber[userID] || false);
    const dispatch = useDispatch();

    const NumberTypography = () => (
        <Typography className={showNumber ? classes.yourNumber : classes.blurText} variant="h4">
            {formatter.format(yourNumber)}
        </Typography>
    );

    const action = () => dispatch(ShowYourNumber(userID, true));
    const hide = () => (isPlanner ? dispatch(ShowYourNumber(userID, false)) : () => {});

    return (
        <Paper className={classes.groupTotal}>
            <div>
                <Typography style={{ userSelect: 'none', fontSize: 16, lineHeight: '20px' }}>
                    Portanto seu número é:
                </Typography>
                <Typography variant="overline" style={{ lineHeight: 1, userSelect: 'none' }}>
                    Objetivos mais IF
                </Typography>
            </div>
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <ScratchOff hide={hide} saveShow={action} show={showNumber}>
                    <Typography className={classes.yourNumber} variant="h4">
                        {formatter.format(yourNumber)}
                    </Typography>
                </ScratchOff>
                <span style={{ userSelect: 'none' }}>clique e arraste para revelar</span>
            </div>
        </Paper>
    );
};

export default YourNumber;
