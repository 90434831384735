import React, { useState } from "react"
import { useLocation } from "react-router"
import api from "../apis/dreamrichapi";
import { useHistory } from 'react-router';

export const TermPage = () => {

    const id = useLocation().pathname;

    const history = useHistory();

    let idArray = id.split('/')
    let userIDToSend = idArray[idArray.length - 1]

    const [term, setTerm] = useState(false)
    const [policy, setPolicy] = useState(false)

    const handleChange = (e: any) => {
        if (e.target.checked && e.target.id == 'termos') setTerm(true)
        else if (!e.target.checked && e.target.id == 'termos') setTerm(false)
        else if (e.target.checked && e.target.id == 'politicas') setPolicy(true)
        else setPolicy(false)
    }

    const acceptTerm = () => {
        api.post<string>('/customer/term', userIDToSend ).catch(
            e => {
                if (e.response) {
                    console.log("userID: ", userIDToSend);
                }
            }
        );
        history.push('/');
    }

    return (
        <section className="container d-flex flex-column justify-content-center align-items-center pt-5 mb-5 pb-5">
            <h2 className="text-center text-dark mb-3 display-4">Termos de uso e políticas de privacidade</h2>
            <img className="img-fluid mb-5" src="https://cakeinvest-front-dev.s3.amazonaws.com/Logos/cake-invest-PNG+1.svg" alt="logo-cake" />

            <div className="d-flex flex-column align-items-center justify-content-center w-100 pb-5">

                <div className="mt-3 w-100">
                    <iframe className="w-100" src="https://cakeinvest-frontend.s3.amazonaws.com/termos-de-uso.pdf#toolbar=0" height="500px">
                    </iframe>
                </div>

                <div className="mt-5 d-flex gap-2 justify-content-start fs-4 w-100">
                    <input className="pointer w-20" onChange={handleChange} type="checkbox" name="termos" id="termos" />
                    <label className="pointer " htmlFor="termos">Declaro que li e estou de acordo com os termos de uso.</label>
                </div>
                <div className="mt-1 d-flex gap-2 justify-content-start fs-4 w-100">
                    <input className="pointer w-20" onChange={handleChange} type="checkbox" name="politicas" id="politicas" />
                    <label className="pointer " htmlFor="politicas">Declaro que li e estou de acordo com as políticas de privacidade.</label>
                </div>

                <div className="mt-5 d-flex gap-2 justify-content-start fs-4 w-100">
                        {/* @ts-ignore */}
                    <button onClick={() => acceptTerm()} className="btn btn-outline-primary fs-4" disabled={!term || !policy}>CONTINUAR</button>
                </div>
            </div>
        </section>
    )
}
