import moment from 'moment';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import api from '../../../../apis/dreamrichapi';
import { ChangeCustomerGoal } from '../../../../redux/actions/planningActions';
import { IStore } from '../../../../redux/types/store';
import { IPlannerPageProps } from '../../types';
import FIExpectation from '../fiExpectation/fiExpectation';
import TableGoal from '../tableGoal/tableGoal';
import { IFinancialIndependence, IGoal, IGoalValues } from '../types';
import {useStyles} from './style'

const Goals: React.FC<IPlannerPageProps> = ({ changeTitle }) => {
    // store vars
    const { userID, goalStored, birthdate, currToolbar } = useSelector((store: IStore) => ({
        userID: store.planning.customerUserInfo.userID,
        goalStored: store.planning.customerFormValues.goal,
        birthdate: store.planning.customerFormValues.customerInfo.personalInfo.personalInfo.dateOfBirth,
        currToolbar: store.planning.toolbar.current,
    }));
    const history = useHistory();

    const goalLocalStorage = JSON.parse(localStorage.getItem('storageCustomer'+userID))?.goal;

    if (!userID) {
        history.push('/');
    }

    const classes = useStyles();
    // step control
    const [currentStep, setStep] = React.useState(1);
    React.useEffect(() => {
        if (!currToolbar || currentStep === currToolbar) return;
        setStep(currToolbar);
    }, [currToolbar]);
    const nextStep = () => {
        if (currentStep < totalSteps) {
            setStep(currentStep + 1);
        }
    };

    // send control
    const [send, setSend] = React.useState(false);
    const finish = () => {
        setReady(true);
        // finish dipares send effect bellow
        setSend(true);
    };

    const [goal, setGoal] = React.useState<IGoal>(goalStored);
    const [errLabel, setErrLabel] = React.useState('');
    // goal control to send just after finish
    const [ready, setReady] = React.useState(false);
    const [loading, setLoading] = React.useState(false);
    const dispatch = useDispatch();

    React.useEffect(() => {
        // When clientInfo changes it doesn't necessarily is ready to be sent
        // send state is enabled when a finish function is called
        // verifyClientInfo checks for required fields before sending it through api then we just send data when all required fields where filled
        // this prevines to send twice
        // remove ready field before sending it isn't a problem to send this to server
        // but I rather not
        if (send && goal?.ready) {
            setLoading(true);
            const goalBody: IGoal = { ...goal, userID };
            delete goalBody.ready;
            api.post('/goal', goalBody)
                .then(() => {
                    setLoading(false);
                    setErrLabel('');
                    dispatch(ChangeCustomerGoal(goal));
                    history.push('/planner');
                })
                .catch(err => {
                    setLoading(false);
                    if (err.response) {
                        setErrLabel(`Erro salvando informações de independência financeira: ${err.response.data}`);
                    } else {
                        setErrLabel(`Erro interno do sistema: ${err}`);
                    }
                });
            setSend(false);
        }
    }, [send, history, goal, dispatch, userID]);

    const previousStep = () => {
        if (currentStep > 0) {
            setStep(currentStep - 1);
        }
    };

    const goBack = () => {
        history.push('/planner');
    };

    const totalSteps = 2;
    const currentAge = moment(new Date()).diff(moment(birthdate, 'DD/MM/YYYY'), 'years');

    const [goalsValues, setGoalsValues] = React.useState<IGoalValues[]>(goalLocalStorage.goals || goalStored.goals);
    const [financialIndependence, setFinancialIndependence] = React.useState<IFinancialIndependence>(
        goalLocalStorage.financialIndependence || goalStored.financialIndependence
    );
    const Steps = [
        <TableGoal
            previousStep={goBack}
            defaultGoalValues={goalsValues}
            setGoalValues={setGoalsValues}
            nextStep={nextStep}
        />,
        <FIExpectation
            previousStep={previousStep}
            defaultFI={financialIndependence}
            setFinancialIndependence={setFinancialIndependence}
            nextStep={finish}
            errLabel={errLabel}
            loading={loading}
            currentAge={currentAge}
        />,
    ];

    React.useEffect(() => {
        // this effects handles the returns from steps components, everytime each returns it saves to a new state on goal
        // if is the last step, set ready to true
        // makes sure child component just update state at nextbutton
        let newGoal: IGoal = { ...goal! };
        if (financialIndependence) {
            newGoal = { ...newGoal!, financialIndependence };
        }
        if (goalsValues) {
            newGoal = { ...newGoal!, goals: goalsValues };
        }
        if (goalsValues && financialIndependence) {
            newGoal = { ...newGoal!, ready };
        }

        setGoal(newGoal);
    }, [goalsValues, financialIndependence, ready]);

    React.useEffect(() => {
        changeTitle(`Objetivos do cliente`, currentStep, totalSteps);
    }, [currentStep, totalSteps]);
    return (
        <div className={classes.content}>
            {/* <LinearProgress variant="determinate" value={percentage} color="primary" /> */}
            {Steps[currentStep - 1]}
        </div>
    );
};
export default Goals;
