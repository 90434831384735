import { grey } from '@material-ui/core/colors';
import { makeStyles, Theme, createStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            marginBottom: theme.spacing(2),
        },
        content: {
            display: 'flex',
            marginTop: theme.spacing(1),
            padding: theme.spacing(2),
        },
        free: {
            backgroundColor: theme.palette.primary.dark,
            flexBasis: '30%',
            margin: `-${theme.spacing(2)}px`,
        },
        freeLabel: {
            color: theme.palette.success.main,
        },
        whiteLabel: {
            color: 'white',
            flexGrow: 1,
        },
        child: {},
        childWide: {},
        freeContent: {
            display: 'flex',
            height: '100%',
            flexDirection: 'column',
            alignItems: 'center',
            alignContent: 'center',
        },
        divider: {
            borderLeft: `1px solid ${grey[400]}`,
            margin: `6px ${theme.spacing(2)}px`,
        },
        grow: {
            flexGrow: 1,
        },
    })
);