import { IFinancialHealth } from '../../components/customerPages/financialHealth/types';
import { IFinancialIndependence } from '../../components/customerPages/financialIndependence/types';
import { IFlow, IProjection } from '../../components/customerPages/flow/types';
import { IHomework, IMonthlyObject } from '../../components/customerPages/homework/types';
import { IIncomingDestination } from '../../components/customerPages/incomingDestination/types';
import { IInvestmentPortfolio } from '../../components/customerPages/investments/types';
import { IRiskManagement } from '../../components/customerPages/riskManagement/types';
import { IFinancialAssets } from '../../components/planningForm/patrimony/types';
import { ReduxAction } from './reduxAction';

export const setFinancialHealth = 'UPDATE_FINANCIAL_HEALTH';
export const ChangeFinancialHealth = (financialHealth: IFinancialHealth): ReduxAction<IFinancialHealth> => ({
    type: setFinancialHealth,
    payload: financialHealth,
});

export const setIncomingDestination = 'UPDATE_INCOMING_DESTINATION';
export const ChangeIncomingDestination = (
    incomingDestination: IIncomingDestination
): ReduxAction<IIncomingDestination> => ({
    type: setIncomingDestination,
    payload: incomingDestination,
});

export const setFinancialIndependence = 'UPDATE_FINANCIAL_INDEPENDENCE';
export const ChangeFinancialIndependence = (
    financialIndependence: IFinancialIndependence
): ReduxAction<IFinancialIndependence> => ({
    type: setFinancialIndependence,
    payload: financialIndependence,
});

export const setFinancialIndependenceChart = 'UPDATE_FINANCIAL_INDEPENDENCE_CHART';
export const ChangeFinancialIndependenceChart = (fiChart: IProjection): ReduxAction<IProjection> => ({
    type: setFinancialIndependenceChart,
    payload: fiChart,
});

export const setRiskManagement = 'UPDATE_RISK_MANAGEMENT';
export const ChangeRiskManagement = (riskManagement: IRiskManagement): ReduxAction<IRiskManagement> => ({
    type: setRiskManagement,
    payload: riskManagement,
});

export const setRentability = 'UPDATE_RENTABILITY';
export const ChangeRentability = (rentability: number): ReduxAction<number> => ({
    type: setRentability,
    payload: rentability,
});

export const setSuccessionWithdraw = 'UPDATE_SUCCESSION_WITHDRAW';
export const ChangeSuccessionWithdraw = (withdraw: number): ReduxAction<number> => ({
    type: setSuccessionWithdraw,
    payload: withdraw,
});

export const setFlow = 'UPDATE_FLOW';
export const ChangeFlow = (flow: IFlow): ReduxAction<IFlow> => ({
    type: setFlow,
    payload: flow,
});

export const setFlowFinancialHealth = 'UPDATE_FLOWFINANCIALHEALTH';
export const ChangeFlowFinancialHealth = (financialHealth: IFinancialHealth): ReduxAction<IFinancialHealth> => ({
    type: setFlowFinancialHealth,
    payload: financialHealth,
});

export const setHomework = 'UPDATE_HOMEWORK';
export const ChangeHomework = (homework: IHomework): ReduxAction<IHomework> => ({
    type: setHomework,
    payload: homework,
});

export const setImplementations = 'UPDATE_IMPLEMENTATIONS';
export const ChangeImplementations = (implementation: IMonthlyObject[][]) => ({
    type: setImplementations,
    payload: implementation,
});

export const setInvestmentPortfolio = 'UPDATE_INVESTMENT_PORTFOLIO';
export const ChangeInvestmentPortfolio = (
    investmentPortfolio: IInvestmentPortfolio
): ReduxAction<IInvestmentPortfolio> => ({
    type: setInvestmentPortfolio,
    payload: investmentPortfolio,
});

export const reset = 'RESET';
export const Reset = (): ReduxAction<never> => ({
    type: reset,
});

export const setHwPortfolioMap = 'SET_HW_PORTFOLIO_MAP';
export const SetHomeworkPortfolioMap = (
    current: boolean,
    classLabel: string,
    portfolioEntry: IFinancialAssets[]
): ReduxAction<{ current: boolean; classLabel: string; portfolioEntry: IFinancialAssets[] }> => ({
    type: setHwPortfolioMap,
    payload: {
        current,
        classLabel,
        portfolioEntry,
    },
});

export const updateHomeworkYear = 'SET_HW_SELECTED_YEAR';
export const UpdateHomeworkYear = (year: number): ReduxAction<number> => ({
    type: updateHomeworkYear,
    payload: year,
});

export const resetCustomer = 'reset_customer';
export const ResetCustomer = (): ReduxAction<never> => ({
    type: resetCustomer,
});

export const copyInvestiments = 'COPY_INVESTIMENTS';
export const CopyInvestiments = (): ReduxAction<never> => ({
    type: copyInvestiments,
});

export const changeHomeworkMonth = 'CHANGE_HW_MONTH';
export const ChangeHomeworkMonth = (month: number): ReduxAction<number> => ({
    type: changeHomeworkMonth,
    payload: month,
});
