import { makeStyles, Theme, createStyles } from '@material-ui/core';
export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        negativeRoot: {
            backgroundColor: theme.palette.primary.dark,
        },
        cardHeader: {
            fontSize: 16,
            color: '#020202',
        },
        cardSubtitle: {
            fontSize: 12,
            color: '#020202',
            lineHeight: 1.5,
        },
        cardContent: {
            display: 'flex',
            height: '100%',
            justifyContent: 'space-between',
            flexDirection: 'column',
            padding: `${theme.spacing(2)}px ${theme.spacing(2)}px`,
        },
    })
);
