import { createStyles, makeStyles, Theme } from '@material-ui/core'

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            background: theme.palette.primary.dark,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            color: '#FFFFFF',
            '@media (max-width: 768px)': {
                minHeight: '0 !important',
            },
        },
        rootSuggestion: {
            background: theme.palette.primary.dark,
            backgroundImage: 'url(https://cakeinvest-front-dev.s3.amazonaws.com/icons/bgSugestions.png)',
            backgroundSize: 'cover',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            color: '#FFFFFF',
            '@media (max-width: 768px)': {
                minHeight: '0 !important',
            }
        },
        rootProject: {
            background: theme.palette.primary.dark,
            backgroundImage: 'url(https://cakeinvest-front-dev.s3.amazonaws.com/icons/bgProject.png)',
            backgroundSize: 'cover',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            color: '#FFFFFF',
            '@media (max-width: 768px)': {
                minHeight: '0 !important',
            }
        },
        disabled: {
            background: theme.palette.primary.dark,
            display: 'flex',
            flexDirection: 'column',
            pointerEvents: 'none',
            opacity: '0.4',
            justifyContent: 'space-between',
            color: '#FFFFFF',
            '@media (max-width: 768px)': {
                minHeight: '0 !important',
            },
        },
        negative: {
            background: theme.palette.background.default,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            '@media (max-width: 768px)': {
                minHeight: '0 !important',
            },
        },
        cardContent: {
            paddingBottom: 0,
        },
        grow: {
            flexGrow: 1,
        },
        modal: {
            top: '50%',
            margin: 'auto',
        },
        cardHeader: {
            fontSize: 22,
            fontWeight: 600,
        },
        cardBody: {
            fontSize: 22,
            paddingTop: theme.spacing(1),
        },
        cardTypeLabel: {
            paddingLeft: theme.spacing(0.3),
            fontSize: 10,
            textTransform: 'uppercase',
            marginBottom: -6,
        },
        cardWhite: {
            color: '#FFFFFF',
            display: 'flex',
            justifyContent: 'center',
            text: 'center',
            backgroundColor: '#034663',
            borderRadius: '20px',
            padding: '0px 48px 0px 48px',
            '& .MuiButton-root.Mui-disabled': {
                color: '#FFFFFF'
            },
        },

        cardBlue: {
            color: '#034663',
            display: 'flex',
            justifyContent: 'center',
            text: 'center',
            backgroundColor: '#FFFFFF',
            borderRadius: '20px',
            padding: '0px 48px 0px 48px',
            '& .MuiButton-root.Mui-disabled': {
                color: '#034663'
            },
        },
        cardActions: {
            display: 'flex',
            justifyContent: 'center',
            padding: '32px 16px 16px 16px !important',
        },
        iconFont: {
            fontSize: '5em',
        },
        check: {
            display: 'flex',
            justifyContent: 'end',

        },
        '& .MuiButton-root': {
            color: '#FFFFFF',
        },
        icon: {
            top: 0,
            left: '50%',
            transform: 'translateX(-50%)'
        },

        tooltip: {
            cursor: 'pointer',
            display: 'block',
            position: 'relative',
            width: '100px',
        },
        'div.tooltip span': {
            position: 'absolute',
            textAlign: 'center',
            visibility: 'hidden',
            backgroundColor: '#000',
            color: '#000',
            padding: '5px 10px',
        },
        'div.tooltip span:after': {
            content: '',
            position: 'absolute',
            bottom: '100%',
            left: '50%',
            marginLeft: '-8px',
            width: 0,
            height: 0,
            borderBottom: '8px solid #000',
            borderRight: '8px solid transparent',
            borderLeft: '8px solid transparent',
        },
        'div:hover.tooltip span': {
            visibility: 'visible',
            opacity: '0.9',
            top: '30px',
            left: '0',
            zIndex: 999,
        },
        GridIcons: {
            display: 'flex',
            justifyContent: 'center',
        }
    })
);
