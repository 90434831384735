import { createStyles, makeStyles, Theme } from '@material-ui/core';
import { grey, red } from '@material-ui/core/colors';


export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        header: {
            fontWeight: 600,
        },
    })
);