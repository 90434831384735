import React from 'react';
import { Bar, BarChart, CartesianGrid, Legend, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import CustomTooltip from '../../global/charts/customTooltip/customTooltip';
import { IIncomingDestination } from './types';

interface ExpensesChartProps { expenses: IIncomingDestination; }

const ExpensesChart: React.FC<ExpensesChartProps> = ({ expenses }) => {
    const [data, setData] = React.useState([{ name: '', valor: 0 }]);

    React.useEffect(() => {
        setData(
            [
                { name: 'Custo Fixo', valor: expenses.expenses },
                { name: 'Financiamentos', valor: expenses.financing },
                { name: 'Proteção', valor: expenses.protection },
                { name: 'Independência Financeira', valor: expenses.financialIndependence },
                { name: 'Previdência', valor: expenses.pension },
                { name: 'Objetivos', valor: expenses.goals },
                { name: 'Automóveis', valor: expenses.cars },
                { name: 'Viagens', valor: expenses.trips },
            ]
                .filter(item => item.valor)
                .sort((a, b) => b.valor - a.valor)
        );
    }, [expenses, setData]);

    return (
        <ResponsiveContainer width={'100%'} height={350}>
            <BarChart margin={{ left: 50 }} data={data} layout="vertical">
                <defs>
                    <linearGradient id="colorUv" x1="0%" y1="0%" x2="100%" y2="0%">
                        <stop offset="0%" stopColor="#26999c" />
                        <stop offset="63%" stopColor="#065875" />
                        <stop offset="100%" stopColor="#023c58" />
                    </linearGradient>
                </defs>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis type="number" tickFormatter={tick => tick.toLocaleString()} />
                <YAxis type="category" dataKey="name" />
                <Tooltip content={<CustomTooltip />} />
                <Legend />
                <Bar dataKey="valor" barSize={20} fill="url(#colorUv)" />
            </BarChart>
        </ResponsiveContainer>
    );
};
export default ExpensesChart;
