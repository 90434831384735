import { Button, Chip, createStyles, makeStyles, Theme } from '@material-ui/core';
import { grey } from '@material-ui/core/colors';
import React from 'react';
import TableCost from '../../../global/inputs/tableCost/tableCost';
import CurrencyInput from '../../../global/masks/currencyMaskedInput';
import { ITransportValues, NewTransportValues } from '../types';
import { useStyles } from './style';

interface ITransportCostProps {
    defaultTransportValues?: ITransportValues;
    setTransportValues: React.Dispatch<React.SetStateAction<ITransportValues>>;
    nextStep: () => void;
    previousStep: () => void;
}

const TransportCost: React.FC<ITransportCostProps> = ({
    setTransportValues,
    nextStep,
    defaultTransportValues,
    previousStep,
}) => {
    const classes = useStyles();
    const [transportCosts, setTransportCosts] = React.useState(NewTransportValues(defaultTransportValues));

    const costsLabel = Object.values(transportCosts).map((cost): [string, number] => [cost.label, cost.value]);

    const setTableValues = (values: Map<string, number>) => {
        const hc = { ...transportCosts };
        values.forEach((val, key) => {
            // forEach value find it in TransportCost by its label
            const objectKeyForLabel = Object.entries(hc).filter(([_, hcval]) => hcval.label === key)![0]![0];
            // if there's a key for that label, then modify its value
            if (objectKeyForLabel) {
                const keyEntry = objectKeyForLabel as keyof ITransportValues;
                hc[keyEntry] = {
                    label: hc[keyEntry].label,
                    value: val,
                };
            }
        });
        setTransportCosts(hc);
    };
    const [btnDisabled, setBtnDisabled] = React.useState(false);

    const onSumbit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        const transportValue: ITransportValues = {
            fuel: transportCosts.fuel.value,
            maintenance: transportCosts.maintenance.value,
            sharedTransport: transportCosts.sharedTransport.value,
            ipva: transportCosts.ipva.value,
            insurance: transportCosts.insurance.value,
            uber: transportCosts.uber.value,
            other: transportCosts.other.value,
        };
        setTransportValues(transportValue);
        nextStep();
        setBtnDisabled(true);
    };
    return (
        <div className={classes.root}>
            <form onSubmit={onSumbit}>
                <div className={classes.content}>
                    <TableCost
                        risk=''
                        className={classes.table}
                        showTotal
                        header="Custos de Transporte"
                        rows={costsLabel}
                        tableValues={setTableValues}
                        inputComponent={CurrencyInput as any}
                    ></TableCost>

                    <div className={classes.formControllers}>
                        {/* @ts-ignore */}
                        <Button
                            variant="contained"
                            className={classes.outButton}
                            size="medium"
                            color="default"
                            disableElevation
                            onClick={previousStep}
                        >
                            Voltar
                        </Button>
                        <button type="submit" className={classes.chipButton} disabled={btnDisabled}>
                            <Chip className={classes.chip} color="primary" size="medium" label="Próximo" clickable />
                        </button>
                    </div>
                </div>
            </form>
        </div>
    );
};
export default TransportCost;
