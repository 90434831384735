import { Button, Chip, createStyles, makeStyles, Theme } from '@material-ui/core';
import { grey } from '@material-ui/core/colors';
import React from 'react';
import TableCost from '../../../global/inputs/tableCost/tableCost';
import CurrencyInput from '../../../global/masks/currencyMaskedInput';
import { IHabitationalValues, NewHabitationalCost } from '../types';
import { useStyles } from './style';

interface IHabitationalCostProps {
    defaultHabitationalValues?: IHabitationalValues;
    setHabitationalValues: React.Dispatch<React.SetStateAction<IHabitationalValues>>;
    nextStep: () => void;
    previousStep: () => void;
}

const HabitationalCost: React.FC<IHabitationalCostProps> = ({
    setHabitationalValues,
    nextStep,
    defaultHabitationalValues,
    previousStep,
}) => {
    const classes = useStyles();
    const [habitationalCosts, setHabitationalCosts] = React.useState(NewHabitationalCost(defaultHabitationalValues));

    const costsLabel = Object.values(habitationalCosts).map((cost): [string, number] => [cost.label, cost.value]);

    const setTableValues = (values: Map<string, number>) => {
        const hc = { ...habitationalCosts };
        values.forEach((val, key) => {
            const objectKeyForLabel = Object.entries(hc).filter(
                ([_, hcval]) => hcval.label === key
            )![0]![0] as keyof IHabitationalValues;
            if (objectKeyForLabel) {
                hc[objectKeyForLabel] = {
                    label: hc[objectKeyForLabel].label,
                    value: val,
                };
            }
        });
        setHabitationalCosts(hc);
    };
    const [btnDisabled, setBtnDisabled] = React.useState(false);

    const onSumbit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        const habitationalValue: IHabitationalValues = {
            condoFee: habitationalCosts.condoFee.value,
            rent: habitationalCosts.rent.value,
            domesticWorkers: habitationalCosts.domesticWorkers.value,
            eletricityBill: habitationalCosts.eletricityBill.value,
            communicationBill: habitationalCosts.communicationBill.value,
            gasBill: habitationalCosts.gasBill.value,
            waterBill: habitationalCosts.waterBill.value,
            iptu: habitationalCosts.iptu.value,
            houseMaintenance: habitationalCosts.houseMaintenance.value,
            houseInsurance: habitationalCosts.houseInsurance.value,
            other: habitationalCosts.other.value,
        };
        setHabitationalValues(habitationalValue);
        nextStep();
        setBtnDisabled(true);
    };
    return (
        <div className={classes.content} style={{ display: 'flex', justifyContent: 'center' }}>
            <form onSubmit={onSumbit}>
                <div className={classes.content}>
                    <TableCost
                        className={classes.table}
                        showTotal
                        risk=''
                        header="Custos com moradia"
                        rows={costsLabel}
                        tableValues={setTableValues}
                        inputComponent={CurrencyInput as any}
                    ></TableCost>

                    <div className={classes.formControllers}>
                        {/* @ts-ignore */}
                        <Button
                            variant="contained"
                            className={classes.outButton}
                            size="medium"
                            color="default"
                            disableElevation
                            onClick={previousStep}
                        >
                            Sair
                        </Button>
                        <button disabled={btnDisabled} type="submit" className={classes.chipButton}>
                            <Chip className={classes.chip} color="primary" size="medium" label="Próximo" clickable />
                        </button>
                    </div>
                </div>
            </form>
        </div>
    );
};
export default HabitationalCost;
