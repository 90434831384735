import { ITableEntry } from '../../global/inputs/tableCost/tableCost';

export interface IHabitationalValues {
    condoFee: number;
    rent: number;
    domesticWorkers: number;
    eletricityBill: number;
    communicationBill: number;
    iptu: number;
    gasBill: number;
    waterBill: number;
    houseMaintenance: number;
    houseInsurance: number;
    other: number;
}

export const NewHabitationalCost = (habitationalValues?: IHabitationalValues): ITableEntry<IHabitationalValues> => ({
    condoFee: {
        value: habitationalValues?.condoFee || 0,
        label: 'CONDOMÍNIO',
    },
    rent: {
        value: habitationalValues?.rent || 0,
        label: 'ALUGUEL',
    },
    iptu: {
        value: habitationalValues?.iptu || 0,
        label: 'IPTU',
    },
    domesticWorkers: {
        value: habitationalValues?.domesticWorkers || 0,
        label: 'FUNCIONÁRIOS DOMÉSTICOS',
    },
    eletricityBill: {
        value: habitationalValues?.eletricityBill || 0,
        label: 'ENERGIA ELÉTRICA',
    },
    communicationBill: {
        value: habitationalValues?.communicationBill || 0,
        label: 'CELULAR / TV / INTERNET',
    },
    gasBill: {
        value: habitationalValues?.gasBill || 0,
        label: 'GÁS',
    },
    waterBill: {
        value: habitationalValues?.waterBill || 0,
        label: 'ÁGUA',
    },
    houseMaintenance: {
        value: habitationalValues?.houseMaintenance || 0,
        label: 'MANUTENÇÃO',
    },
    houseInsurance: {
        value: habitationalValues?.houseInsurance || 0,
        label: 'SEGURO DE CASA',
    },
    other: {
        value: habitationalValues?.other || 0,
        label: 'OUTROS',
    },
});
export interface IFeedingValues {
    superMarket: number;
    lunch: number;
    supplement: number;
    breadWine: number;
}
export const NewFeedingCost = (feedingValues?: IFeedingValues): ITableEntry<IFeedingValues> => ({
    superMarket: {
        label: 'SUPERMERCADO',
        value: feedingValues?.superMarket || 0,
    },
    lunch: {
        label: 'REFEIÇÕES',
        value: feedingValues?.lunch || 0,
    },
    supplement: {
        label: 'SUPLEMENTOS',
        value: feedingValues?.supplement || 0,
    },
    breadWine: {
        label: 'PÃES E VINHOS',
        value: feedingValues?.breadWine || 0,
    },
});

export interface ICareValues {
    clinical: number;
    perfume: number;
    gym: number;
    other: number;
}

export const NewCareCost = (careValues?: ICareValues): ITableEntry<ICareValues> => ({
    clinical: {
        label: 'CLÍNICA / SALÃO',
        value: careValues?.clinical || 0,
    },
    perfume: {
        label: 'COSMÉTICOS / HIGIENE',
        value: careValues?.perfume || 0,
    },
    gym: {
        label: 'ACADEMIA / PERSONAL TRAINER',
        value: careValues?.gym || 0,
    },
    other: {
        label: 'OUTROS',
        value: careValues?.other || 0,
    },
});
export interface IPersonalValues {
    feeding: IFeedingValues;
    care: ICareValues;
}

export interface IRecreationValues {
    diversion: number;
    streaming: number;
    hobbies: number;
    club: number;
    other: number;
}

export const NewRecreationCost = (recreationValues?: IRecreationValues): ITableEntry<IRecreationValues> => ({
    streaming: {
        label: 'SPOTIFY / NETFLIX ...',
        value: recreationValues?.streaming || 0,
    },
    diversion: {
        label: 'FINAL DE SEMANA',
        value: recreationValues?.diversion || 0,
    },
    hobbies: {
        label: 'HOBBIES',
        value: recreationValues?.hobbies || 0,
    },
    club: {
        label: 'CLUBE',
        value: recreationValues?.club || 0,
    },
    other: {
        label: 'OUTROS',
        value: recreationValues?.other || 0,
    },
});

export interface IHealthValues {
    insurancePlan: number;
    medicalExpenses: number;
    medicament: number;
    other: number;
}

export const NewHealthCost = (healthValues?: IHealthValues): ITableEntry<IHealthValues> => ({
    insurancePlan: {
        label: 'PLANO DE SAÚDE',
        value: healthValues?.insurancePlan || 0,
    },
    medicalExpenses: {
        label: 'CONSULTAS E EXAMES',
        value: healthValues?.medicalExpenses || 0,
    },
    medicament: {
        label: 'REMÉDIOS',
        value: healthValues?.medicament || 0,
    },
    other: {
        label: 'OUTROS',
        value: healthValues?.other || 0,
    },
});

export interface ITransportValues {
    fuel: number;
    maintenance: number;
    sharedTransport: number;
    ipva: number;
    insurance: number;
    other: number;
    uber: number;
}

export const NewTransportValues = (transportValues?: ITransportValues): ITableEntry<ITransportValues> => ({
    fuel: {
        label: 'COMBUSTÍVEL',
        value: transportValues?.fuel || 0,
    },
    maintenance: {
        label: 'MANUTENÇÃO / LIMPEZA',
        value: transportValues?.maintenance || 0,
    },
    sharedTransport: {
        label: 'TÁXI / ÔNIBUS / METRÔ',
        value: transportValues?.sharedTransport || 0,
    },
    ipva: {
        label: 'IPVA (mensalizado)',
        value: transportValues?.ipva || 0,
    },
    insurance: {
        label: 'SEGURO (mensalizado)',
        value: transportValues?.insurance || 0,
    },
    uber: {
        label: 'UBER',
        value: transportValues?.uber || 0,
    },
    other: {
        label: 'OUTROS',
        value: transportValues?.other || 0,
    },
});

export interface IEducationalValues {
    school: number;
    books: number;
    other: number;
    sports: number;
}

export const NewEducationValues = (educationValues?: IEducationalValues): ITableEntry<IEducationalValues> => ({
    school: {
        label: 'ESCOLAS / CURSOS',
        value: educationValues?.school || 0,
    },
    books: {
        label: 'LIVROS / JORNAL / REVISTAS',
        value: educationValues?.books || 0,
    },
    sports: {
        label: 'ESPORTES',
        value: educationValues?.books || 0,
    },
    other: {
        label: 'OUTROS',
        value: educationValues?.other || 0,
    },
});

export interface IWellbeingValues {
    recreation: IRecreationValues;
    health: IHealthValues;
}

export interface IOtherValues {
    tithe: number;
    pet: number;
    other: number;
    alimony: number;
    classCouncil: number;
}

export const NewOtherValues = (otherValues?: IOtherValues): ITableEntry<IOtherValues> => ({
    tithe: {
        label: 'DÍZIMO',
        value: otherValues?.tithe || 0,
    },
    pet: {
        label: 'PET',
        value: otherValues?.pet || 0,
    },
    alimony: {
        label: 'PENSÃO',
        value: otherValues?.alimony || 0,
    },
    classCouncil: {
        label: 'CONSELHO DE CLASSE',
        value: otherValues?.classCouncil || 0,
    },
    other: {
        label: 'OUTROS',
        value: otherValues?.other || 0,
    },
});

const fixedCostOptions = () => {
    // instantiate the costs as if it was for tables and extract all
    // info about label/value from its table entry

    const fixedCostInit = [
        NewHabitationalCost,
        NewFeedingCost,
        NewCareCost,
        NewRecreationCost,
        NewHealthCost,
        NewTransportValues,
        NewEducationValues,
        NewOtherValues,
    ];

    let hasOther = false;
    const initVal: { value: string; label: string }[] = [];
    const options = fixedCostInit.reduce((acc, init) => {
        const costEntry = init();
        const entries = Object.entries(costEntry).filter(([key]) => key !== 'other');

        return [
            ...acc,
            ...entries.map(([key, value]) => ({
                value: value.label,
                label: value.label,
            })),
        ];
    }, initVal);

    return [...options.sort((a, b) => a.label.localeCompare(b.label)), { value: 'OUTROS', label: 'OUTROS' }];
};

export const costSelectOptions = fixedCostOptions();

export interface ICostChange {
    name: string;
    value: number;
    date: Date;
}

export interface IFixedCost {
    userID?: string;
    habitational: IHabitationalValues;
    feeding: IFeedingValues;
    care: ICareValues;
    recreation: IRecreationValues;
    health: IHealthValues;
    other: IOtherValues;
    education: IEducationalValues;
    transport: ITransportValues;
    costChange: ICostChange[];
    ready?: boolean;
    total?: number;
}
