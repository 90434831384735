import { createStyles, makeStyles, Theme } from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        header: {
            marginTop: theme.spacing(4),
        },
        content: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            alignItems: 'center',
        },
        outButton: {
            backgroundColor: 'white',
            color: '#bdbdbd',
        },
        inputLabel: {
            padding: '1em 0 0.5em',
        },
        form: {
            width: '70%',
            margin: theme.spacing(2, 0),
        },
        grow: {
            flexGrow: 1,
            flexBasis: '100%',
        },
        break: {
            flexBasis: '100%',
            height: 0,
        },
        spacing: {
            padding: '0.5em',
            width: 0,
        },
        address: {
            display: 'flex',
            flexWrap: 'wrap',
            justifyContent: 'flex-start',
            alignItems: 'space-around',
        },
        formControllers: {
            marginTop: theme.spacing(2),
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            alignContent: 'flex-end',
        },
        chipButton: {
            backgroundColor: 'transparent',
            border: 'none',
        },
        chip: {
            color: '#fafafa',
            backgroundColor: theme.palette.primary.dark,
        },
    })
);