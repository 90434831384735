import { createStyles, makeStyles, Theme } from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        subHeading: {
            color: '#2C2F31',
            fontWeight: 600,
            marginBottom: theme.spacing(2),
        },
        group: {
            backgroundColor: '#F8F8F8',
            width: '100%',
            borderRadius: theme.spacing(1),
            marginBottom: theme.spacing(2),
        },
        innerPadding: {
            width: '100%',
            padding: theme.spacing(2),
        },
        summary: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            height: '100%',
        },
        summaryHeader: {
            fontWeight: 600,
        },
        summaryValue: {
            fontSize: 24,
        },
        grow: {
            flexBasis: '20%',
        },
    })
);