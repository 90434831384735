import { createStyles, makeStyles, Theme } from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        formControl: {
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'baseline',
            marginRight: theme.spacing(3),
        },
        select: {
            minWidth: 300,
            flexGrow: 1,
        },
    })
);