export const validateCPF = (cpf: string) => {
    let sum = 0;
    let equalDigits = 1;
    let numbers = '';
    let result = -1;
    if (cpf.length < 11) {
        return false;
    }
    for (let i = 0; i < cpf.length - 1; i++) {
        if (cpf.charAt(i) !== cpf.charAt(i + 1)) {
            equalDigits = 0;
            break;
        }
    }
    if (!equalDigits) {
        numbers = cpf.substring(0, 9);
        const digits = cpf.substring(9);
        for (let i = 10; i > 1; i--) {
            sum += parseInt(numbers.charAt(10 - i), 10) * i;
        }
        result = sum % 11 < 2 ? 0 : 11 - (sum % 11);
        if (result.toString() !== digits.charAt(0)) {
            return false;
        }
        numbers = cpf.substring(0, 10);
        sum = 0;
        for (let i = 11; i > 1; i--) {
            sum += parseInt(numbers.charAt(11 - i), 10) * i;
        }
        result = sum % 11 < 2 ? 0 : 11 - (sum % 11);
        if (result.toString() !== digits.charAt(1)) {
            return false;
        }
        return true;
    } else {
        return false;
    }
};
