import React from 'react';
import { makeStyles, Theme } from '@material-ui/core';
export const useStyles = makeStyles((theme: Theme) => {
    return {
        drawer: (props: { drawerWidth: string }) => ({
            '@media (min-width: 768px)': {
                width: props.drawerWidth,
                flexShrink: 0,
            },
        }),
        drawerPaper: (props: { drawerWidth: string }) => ({
            width: props.drawerWidth,
            borderRight: 'none',
            background: `linear-gradient(180deg, #023c58 0%, #065875 43%, #26999c 100%)`,
            borderRadius: '24px',
            height: '97vh',
            margin: '0.6vw',
            '@media (max-width: 768px)': {
                width: 'unset',
                transform: 'translateX(-150%)',
            },
        }),
        buttonRoot: {
            color: 'white',
            fontFamily: 'Avenir Next',
            fontSize: '14px',
            lineHeight: '15px',
            paddingLeft: theme.spacing(3),
            paddingTop: theme.spacing(1.5),
            paddingBottom: theme.spacing(1.5),
        },
        listItem: {
            marginBottom: theme.spacing(0.25),
        },
        svgIcon: {
            color: 'white',
            minWidth: '40px',
        },
        list: {
            marginTop: '10%',
        },
        logo: {
            marginLeft: theme.spacing(3),
            marginBottom: theme.spacing(3),
            width: 'auto',
        },
        listText: {
            fontSize: 14,
            lineHeight: '15px',
        },
        textSelected: {
            color: '#0DF2FF',
            fontSize: 14,
            lineHeight: '15px',
        },
        exit: {
            position: 'absolute',
            paddingBottom: theme.spacing(2),
        },
    };
});
