import { createStyles, makeStyles, Theme } from '@material-ui/core';
export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        paperContent: {
            padding: theme.spacing(2),
        },
        form: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'flex-end',
            flexGrow: 1,
            margin: theme.spacing(1.5, 0),
        },
        loginForm: {
            display: 'contents',
        },
        formItem: {
            maxWidth: '400px',
            '& .MuiFilledInput-root': {
                background: 'transparent',
            },
            '&:-webkit-autofill': {
                '-webkit-box-shadow': '0 0 0 100px #000 inset',
                '-webkit-text-fill-color': '#fff',
                WebkitBoxShadow: '0 0 0 1000px white inset',
            },
            WebkitBoxShadow: '0 0 0 1000px white inset',
        },
        label: {
            maxWidth: '400px',
            '& .MuiFilledInput-root': {
                background: 'transparent',
            },
            '&:-webkit-autofill': {
                '-webkit-box-shadow': '0 0 0 100px #000 inset',
                '-webkit-text-fill-color': '#fff',
                WebkitBoxShadow: '0 0 0 1000px white inset',
            },
            WebkitBoxShadow: '0 0 0 1000px white inset',
        },
        eye: {
            cursor: 'pointer',
        },
        sendButton: {
            backgroundColor: 'transparent',
            border: 'none',
        },
        backButton: {
            alignSelf: 'flex-start',
            backgroundColor: '#034663',
            color: theme.palette.background.paper,
            borderRadius: '30px',
            margin: theme.spacing(1, 0),
            padding: theme.spacing(1, 4),
        },
        submitbutton: {
            alignSelf: 'flex-start',
            backgroundColor: '#034663',
            color: theme.palette.background.paper,
            borderRadius: '30px',
            margin: theme.spacing(1, 1),
            padding: theme.spacing(1, 4),
            '&:hover': {
                backgroundColor: '#46D39A',
                cursor: 'pointer',
            },
        },
        signUpButton: {
            alignSelf: 'center',
            backgroundColor: '#F2F3F3',
            color: '#000',
            borderRadius: '30px',
            margin: theme.spacing(1, 1),
            padding: theme.spacing(1, 4),
            '&:hover': {
                backgroundColor: '#46D3',
                cursor: 'pointer',
            },
        },
    })
);
