import {
    Button, Chip, FormControl, MenuItem, Paper, Select, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography,
} from '@material-ui/core';
import { Add, Delete } from '@material-ui/icons';
import { DatePicker } from '@material-ui/pickers';
import { default as React } from 'react';
import { useSelector } from 'react-redux';
import { IStore } from '../../../../redux/types/store';
import unmaskCurrency from '../../../../utils/unmaskCurrency';
import MoneyAdornment from '../../../global/adornments/MoneyAdornment';
import YearsAdornment from '../../../global/adornments/YearsAdornment';
import CurrencyInput from '../../../global/masks/currencyMaskedInput';
import { Companies, consortiumCompanies, IConsortium } from '../types';
import { useStyles } from './style';

interface ITableRow {
    name: string;
    company: Companies;
    annualPayment: string;
    receivedValue: string;
    term: string;
    contemplationYear: number;
    startDate: Date;
}

interface IConsortiumProps {
    defaultConsortia: IConsortium[];
    setConsortia: React.Dispatch<React.SetStateAction<IConsortium[]>>;
    nextStep: () => void;
    previousStep: () => void;
    suggestion?: boolean;
    /*   errLabel: string;*/
}

const Consortium: React.FC<IConsortiumProps> = ({
    defaultConsortia,
    setConsortia,
    nextStep,
    previousStep,
    suggestion,
}) => {
    const classes = useStyles();


    const { customerConsortium } = useSelector((store: IStore) => ({ customerConsortium: store.planning.customerFormValues.financing.consortia }));

    const [hasConsortium, setHasConsortium] = React.useState(defaultConsortia.length > 0 || customerConsortium.length > 0);
    const [addedLine, setAddedLine] = React.useState(false);
    const [ready, setReady] = React.useState(false);

    const currentDate = new Date();
    const defaultRow: ITableRow = {
        annualPayment: '',
        company: 'bancorbras',
        name: '',
        receivedValue: '',
        term: '',
        contemplationYear: currentDate.getFullYear(),
        startDate: currentDate,
    };

    const newTableRows = () => {
        if (defaultConsortia?.length) {
            return defaultConsortia?.map(item => ({
                ...item,
                term: item.term.toString(),
                annualPayment: item.annualPayment.toString(),
                receivedValue: item.receivedValue.toString(),
                contemplationYear: item.contemplationYear,
            }));
        }
        return [{ ...defaultRow }];
    };

    const [tableRows, setTableRows] = React.useState<ITableRow[]>(newTableRows());

    const addLine = () => {
        setAddedLine(true);
        setTableRows([
            ...tableRows,
            {
                ...defaultRow,
            },
        ]);
    };

    const handleInputChange = (field: keyof ITableRow, value: string, rowIndex: number) => {
        setTableRows(tableRows.map((item, i) => (i === rowIndex ? { ...item, [field]: value } : item)));
    };

    const handleYearChange = (value: Date, index: number) => {
        const parsedVal = value.getFullYear();
        setTableRows(rows => rows.map((row, i) => (i === index ? { ...row, contemplationYear: parsedVal } : row)));
    };
    const handleStartChange = (value: Date, index: number) => {
        setTableRows(rows => rows.map((row, i) => (i === index ? { ...row, startDate: value } : row)));
    };
    const [btnDisabled, setBtnDisabled] = React.useState(false);

    const onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        if (hasConsortium) {
            setConsortia(
                tableRows.map(row => ({
                    ...row,
                    annualPayment: unmaskCurrency(row.annualPayment),
                    receivedValue: unmaskCurrency(row.receivedValue),
                    term: parseInt(row.term, 10),
                    contemplationYear: row.contemplationYear,
                }))
            );
        } else {
            setConsortia([]);
        }
        nextStep();
        setBtnDisabled(true);
    };

    const changeMouseHover = (hover: boolean, index: number) => {
        setMouseHover(rows => rows.map((_, i) => (index === i ? hover : false)));
    };

    const removeRow = (index: number) => {
        setTableRows(rows => [...rows.slice(0, index), ...rows.slice(index + 1)]);
    };

    const [mouseHover, setMouseHover] = React.useState(tableRows.map(row => false));
    React.useEffect(() => {
        if (addedLine) {
            setMouseHover(tableRows.map(row => false));
        }
    }, [tableRows]);

    React.useEffect(() => {
        if (addedLine) {
            setReady(true);
        }
    }, [mouseHover]);

    const showRowDel = async (row: number) => {
        setMouseHover(rows => rows.map((_, i) => row === i));
        setReady(false);
        setAddedLine(false);
        await new Promise(r => setTimeout(r, 4000));
        setMouseHover(rows => rows.map(item => false));
    };

    React.useEffect(() => {
        showRowDel(tableRows.length - 1);
    }, [ready]);

    const setInsuranceTypeOnRow = (company: Companies, rowIndex: number) => {
        setTableRows(rows => rows.map((item, idx) => (idx === rowIndex ? { ...item, company } : item)));
    };

    return (
        <div className={classes.root}>
            <div className={classes.head}>
                <Typography variant="h4" className={`${classes.header} mb-2`}>
                    {suggestion ? 'Deve contratar' : 'Tem'} algum consórcio?
                </Typography>
                <div className={`${classes.selectButton} mb-2`}>
                    {/* @ts-ignore */}
                    <Button
                        variant="outlined"
                        color={hasConsortium ? 'primary' : 'default'}
                        disableElevation
                        onClick={() => setHasConsortium(true)}
                    >
                        SIM
                    </Button>
                    {/* @ts-ignore */}
                    <Button
                        disableElevation
                        variant="outlined"
                        color={!hasConsortium ? 'primary' : 'default'}
                        onClick={() => setHasConsortium(false)}
                    >
                        NÃO
                    </Button>
                </div>
            </div>
            <form onSubmit={onSubmit}>
                {hasConsortium ? (
                    <div>
                        <TableContainer component={Paper}>
                            <Table className={classes.table}>
                                <TableHead>
                                    <TableRow>
                                        <TableCell align="left">Nome</TableCell>
                                        <TableCell align="right">Consórcio de Imóvel</TableCell>
                                        <TableCell align="right">Valor da carta</TableCell>
                                        <TableCell align="right">Parcela mensal</TableCell>
                                        <TableCell align="right">Data de contratação</TableCell>
                                        <TableCell align="right">Prazo em anos</TableCell>
                                        <TableCell align="right">Ano de contemplação</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {tableRows.map((row, i) => (
                                        <TableRow key={i.toString()} style={{ overflow: 'hidden' }}>
                                            <TableCell align="left">
                                                <FormControl>
                                                    <TextField
                                                        required
                                                        color="primary"
                                                        placeholder="Nome do consórcio"
                                                        InputLabelProps={{
                                                            shrink: true,
                                                        }}
                                                        value={row.name}
                                                        style={{ flexShrink: 1 }}
                                                        onChange={event =>
                                                            handleInputChange('name', event.target.value, i)
                                                        }
                                                    />
                                                </FormControl>
                                            </TableCell>
                                            <TableCell align="right">
                                                <FormControl>
                                                    <Select
                                                        labelId="-select-label"
                                                        id="demo-simple-select"
                                                        value={row.company}
                                                        onChange={event =>
                                                            setInsuranceTypeOnRow(event.target.value as Companies, i)
                                                        }
                                                        fullWidth
                                                        style={{ minWidth: '140px' }}
                                                    >
                                                        {consortiumCompanies.map(company => (
                                                            <MenuItem value={company}>{company.toUpperCase()}</MenuItem>
                                                        ))}
                                                    </Select>
                                                </FormControl>
                                            </TableCell>
                                            <TableCell align="right">
                                                <TextField
                                                    required
                                                    color="primary"
                                                    placeholder="0.00"
                                                    inputMode="numeric"
                                                    InputProps={{
                                                        inputComponent: CurrencyInput as any,
                                                        startAdornment: <MoneyAdornment />,
                                                    }}
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                    inputProps={{
                                                        style: { textAlign: 'right' },
                                                    }}
                                                    value={row.receivedValue}
                                                    style={{ flexShrink: 1 }}
                                                    onChange={event =>
                                                        handleInputChange('receivedValue', event.target.value, i)
                                                    }
                                                />
                                            </TableCell>
                                            <TableCell>
                                                <TextField
                                                    required
                                                    color="primary"
                                                    placeholder="0.00"
                                                    inputMode="numeric"
                                                    InputProps={{
                                                        inputComponent: CurrencyInput as any,
                                                        startAdornment: <MoneyAdornment />,
                                                    }}
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                    inputProps={{
                                                        style: { textAlign: 'right' },
                                                    }}
                                                    value={row.annualPayment}
                                                    style={{ flexShrink: 1 }}
                                                    onChange={event =>
                                                        handleInputChange('annualPayment', event.target.value, i)
                                                    }
                                                />
                                            </TableCell>
                                            <TableCell>
                                                <DatePicker
                                                    variant="inline"
                                                    format="MM/yyyy"
                                                    margin="normal"
                                                    id="date-picker-inline"
                                                    views={['month', 'year']}
                                                    openTo="year"
                                                    value={row.startDate}
                                                    onChange={date => date && handleStartChange(date as Date, i)}
                                                    style={{ marginTop: 0, marginBottom: 0 }}
                                                    inputProps={{
                                                        style: { textAlign: 'right' },
                                                    }}
                                                />
                                            </TableCell>
                                            <TableCell align="right">
                                                <TextField
                                                    required
                                                    color="primary"
                                                    inputMode="numeric"
                                                    InputProps={{
                                                        endAdornment: <YearsAdornment />,
                                                    }}
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                    inputProps={{ style: { textAlign: 'right' } }}
                                                    value={row.term}
                                                    onChange={event => handleInputChange('term', event.target.value, i)}
                                                />
                                            </TableCell>
                                            <TableCell>
                                                <DatePicker
                                                    variant="inline"
                                                    format="yyyy"
                                                    margin="normal"
                                                    id="date-picker-inline"
                                                    views={['year']}
                                                    openTo="year"
                                                    value={new Date(row.contemplationYear, 0)}
                                                    onChange={date => date && handleYearChange(date as Date, i)}
                                                    style={{ marginTop: 0, marginBottom: 0 }}
                                                    inputProps={{
                                                        style: { textAlign: 'right' },
                                                    }}
                                                />
                                            </TableCell>
                                            <div
                                                className={classes.deleteHidden}
                                                onMouseEnter={() => changeMouseHover(true, i)}
                                                onMouseLeave={() => changeMouseHover(false, i)}
                                                //@ts-ignore 
                                                onClick={() => removeRow(i)}
                                                // we dont need any border when mouse is hovering this div
                                                style={{ borderLeft: !mouseHover[i] ? '9px solid #f44336' : '' }}
                                            >
                                                {mouseHover[i] ? (
                                                    <Delete
                                                        style={{ marginTop: '100%', marginRight: '5%' }}
                                                        color="action"
                                                    />
                                                ) : null}
                                            </div>
                                        </TableRow>
                                    ))}
                                    <TableRow>
                                        <TableCell>
                                            {/* @ts-ignore */}
                                            <Chip
                                                variant="outlined"
                                                color="primary"
                                                size="medium"
                                                label="Adicionar nova linha"
                                                clickable
                                                onClick={addLine}
                                                onDelete={addLine}
                                                deleteIcon={<Add />}
                                            />
                                        </TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </TableContainer>
                        {/* <Typography align="center" color="error">
                            {errLabel}
                        </Typography>*/}
                    </div>
                ) : null}
                <div className={classes.formControllers}>
                    {/* @ts-ignore */}
                    <Button
                        variant="contained"
                        className={classes.outButton}
                        size="medium"
                        color="default"
                        disableElevation
                        onClick={previousStep}
                    >
                        Voltar
                    </Button>
                    <button type="submit" className={classes.chipButton} disabled={btnDisabled}>
                        <Chip
                            className={classes.chip}
                            color="primary"
                            size="medium"
                            label={suggestion ? 'Próximo' : 'Finalizar'}
                            clickable
                        />
                    </button>
                </div>
            </form>
        </div>
    );
};
export default Consortium;
