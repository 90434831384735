import { createStyles, makeStyles, Theme } from '@material-ui/core';
export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            padding: theme.spacing(2),
            backgroundColor: theme.palette.background.paper,
        },
        line: {
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'flex-end',
        },
        cardHeader: {
            fontWeight: 600,
            fontSize: 16,
            color: '#020202',
        },
        labelArea: {
            marginLeft: theme.spacing(2),
            padding: `${theme.spacing(4)}px 0`,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
        },
        labelLine: {
            display: 'flex',
            flexDirection: 'row',
        },
        labelHover: {
            fontWeight: 600,
        },
        valueText: {
            fontSize: 18,
            marginLeft: theme.spacing(2),
        },
        chartContainer: {
            height: 300,
        },
        cardTotal: {
            width: '100%',
            background: '#F8F8F8',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-end',
            paddingRight: theme.spacing(2),
            padding: theme.spacing(2),
            alignItems: 'baseline',
        },
        labelValue: {
            alignSelf: 'flex-end',
            fontWeight: 'inherit',
        },
        spacing: {
            padding: theme.spacing(0.5),
        },
        roundMarker: {
            marginTop: '1px',
        },
        grow: {
            flexGrow: 1,
        },
        totalLabel: {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            padding: theme.spacing(2),
            alignItems: 'center',
        },
        total: {
            backgroundColor: theme.palette.background.default,
        },
        suggested: { backgroundColor: theme.palette.primary.dark, color: '#FFFFFF' },
        suggestedText: { color: theme.palette.success.main },
        noSelect: {
            marginTop: 32,
        },
    })
);

export const colors = [
    '#0F62FE', 
    '#5F2EEA', 
    '#1CC8EE', 
    '#ED2E7E', 
    '#00BA88', 
    '#FEBA69', 
    '#FF6666', 
    '#009D9A', 

    '#002D9C', 
    '#2A00A2', 
    '#0096B7', 
    '#C30052', 
    '#005D5D', 
    '#D4790C', 
    '#A71818', 
    '#00966D', 

    '#D0E2FF', 
    '#8675FF', 
    '#7EE6FD', 
    '#F4A3C5', 
    '#9EF0F0', 
    '#FFE9CF', 
    '#FF6666', 
    '#66FFD6', 
];