import { createStyles, makeStyles, Theme } from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        rootContent: {
            backgroundColor: 'transparent',
            padding: theme.spacing(2),
        },
        flex: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            height: '80%',
        },
        whiteLabel: {
            color: '#ffffff',
        },
        durationLabel: {
            color: theme.palette.success.main,
        },
    })
);