import { Paper, Typography } from '@material-ui/core';
import React from 'react';
import Independence from '../../independence/independence';
import Objectives from '../../objectives/objectives';
import { IDesiredState } from '../../types';
import { useStyles } from './style'

interface IDesiredStateProps { desiredState: IDesiredState; }

const DesiredState: React.FC<IDesiredStateProps> = ({ desiredState }) => {
    const classes = useStyles();
    const age = desiredState.goals.financialIndependence.age;

    return (
        <Paper elevation={3} className={classes.root}>
            <Typography className={classes.subHeading} variant="h4">
                Seus Desejos
            </Typography>
            <div className={classes.innerPadding}>
                <Independence
                    age={age}
                    currentAge={desiredState.age}
                    desiredIncome={desiredState.goals.financialIndependence.desiredIncoming}
                    duration={desiredState.duration}
                    totalNeeded={desiredState.neededForIndependence}
                    yearsUntil={desiredState.yearsUntilIndependence}
                    monthsUntil={desiredState.monthsUntilIndependence}
                    daysUntil={desiredState.daysUntilIndependence}
                />
                <Objectives
                    className={classes.item}
                    goals={desiredState.goals.goals.map(item => ({
                        ...item,
                        percentage: item.totalUntilIndependence / desiredState.totalGoals,
                    }))}
                    totalGoals={desiredState.totalGoals}
                />
            </div>
        </Paper>
    );
};

export default DesiredState;
