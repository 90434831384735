import React from 'react';
import { Typography } from '@material-ui/core';

const PercentageAdornment: React.FC = () => (
    <Typography style={{ marginBottom: 1, marginLeft: 4 }} variant="body2">
        %
    </Typography>
);

export default PercentageAdornment;
