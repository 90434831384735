import { Button, Chip, createStyles, makeStyles, TextField, Theme, Typography, withStyles } from '@material-ui/core';
import { grey } from '@material-ui/core/colors';
import React from 'react';
import LandlineMaskedInput from '../../../global/masks/landlineMaskedInput';
import { IWorkInfo } from '../types';
import { useStyles } from './style';

const CustomizedTextInput = withStyles({
    root: {
        '& .MuiFilledInput-input': {
            padding: '11px 12px 10px',
        },
        '& .MuiInputBase-root': {
            padding: '3px',
        },
    },
})(TextField);

interface IWorkInfoProps {
    defaultWorkInfo: IWorkInfo;
    setWorkInfo: React.Dispatch<React.SetStateAction<IWorkInfo>>;
    errLabel: string;
    loading: boolean;
    nextStep: () => void;
    previousStep: () => void;
    hasPartner?: boolean;
    partner?: boolean;
}

const WorkInfo: React.FC<IWorkInfoProps> = ({
    setWorkInfo,
    nextStep,
    previousStep,
    errLabel,
    loading,
    hasPartner,
    partner,
    defaultWorkInfo,
}) => {
    const classes = useStyles();

    const [jobTitle, setJobTitle] = React.useState(defaultWorkInfo.jobTitle || '');
    const [company, setCompany] = React.useState(defaultWorkInfo.company || '');
    const [companyAddress, setAddress] = React.useState(defaultWorkInfo.address || '');
    const [companyPhone, setPhone] = React.useState(defaultWorkInfo.phoneNumber || '');

    const [btnDisabled, setBtnDisabled] = React.useState(false);

    const onSubmit = () => {
        setWorkInfo({
            address: companyAddress,
            company,
            jobTitle,
            phoneNumber: companyPhone,
        });
        setBtnDisabled(true);
        nextStep();
    };

    return (
        <div className={classes.content}>
            <Typography variant="h4" className={classes.header}>
                Onde o {partner !== true ? 'cliente' : 'cônjuge'} trabalha
            </Typography>
            <div className={classes.form}>
                <div>
                    <Typography className={classes.inputLabel} variant="h6">
                        Profissão
                    </Typography>
                    <CustomizedTextInput
                        variant="outlined"
                        size="small"
                        value={jobTitle}
                        onChange={event => setJobTitle(event.target.value)}
                        fullWidth
                    />
                </div>
                <div>
                    <Typography className={classes.inputLabel} variant="h6">
                        Empresa
                    </Typography>
                    <CustomizedTextInput
                        variant="outlined"
                        size="small"
                        value={company}
                        onChange={event => setCompany(event.target.value)}
                        fullWidth
                    />
                </div>
                <div>
                    <Typography className={classes.inputLabel} variant="h6">
                        Endereço Comercial
                    </Typography>
                    <CustomizedTextInput
                        variant="outlined"
                        size="small"
                        placeholder="ex. Ed. XPTO, Rua X, Bloco A, Loja 2211 Número X - Brasilia-DF"
                        value={companyAddress}
                        onChange={event => setAddress(event.target.value)}
                        fullWidth
                    />
                </div>
                <div>
                    <Typography className={classes.inputLabel} variant="h6">
                        Telefone Comercial
                    </Typography>
                    <CustomizedTextInput
                        variant="outlined"
                        size="small"
                        InputProps={{
                            inputComponent: LandlineMaskedInput as any,
                        }}
                        value={companyPhone}
                        onChange={event => setPhone(event.target.value)}
                        fullWidth
                    />
                </div>
                <Typography align="center" color="error">
                    {errLabel}
                </Typography>
                <div className={classes.formControllers}>
                {/* @ts-ignore */}
                    <Button
                        variant="contained"
                        className={classes.outButton}
                        size="medium"
                        color="default"
                        disableElevation
                        onClick={previousStep}
                    >
                        Voltar
                    </Button>
                {/* @ts-ignore */}
                    <Chip
                        size="medium"
                        label={hasPartner ? 'Próximo' : 'Finalizar'}
                        color={loading ? 'secondary' : 'primary'}
                        clickable={!loading}
                        onClick={onSubmit}
                        disabled={btnDisabled}
                    />
                </div>
            </div>
        </div>
    );
};

export default WorkInfo;
