export interface ChallengeParameters {
    USER_ID_FOR_SRP: string;
    requiredAttributes: string;
    userAttributes: string;
}

export type ChallengeName =
    | 'LOGIN'
    | 'SMS_MFA'
    | 'SOFTWARE_TOKEN_MFA'
    | 'SELECT_MFA_TYPE'
    | 'MFA_SETUP'
    | 'PASSWORD_VERIFIER'
    | 'CUSTOM_CHALLENGE'
    | 'DEVICE_SRP_AUTH'
    | 'DEVICE_PASSWORD_VERIFIER'
    | 'ADMIN_NO_SRP_AUTH'
    | 'NEW_PASSWORD_REQUIRED'
    | 'CONFIRM_PASSWORD'
    | 'FORGOT_PASSWORD'
    | 'FORCE_CHANGE_PASSWORD'
    | '';

export interface IUserAttributes {
    phone_number: string;
    email: string;
}

export interface IChallengeAttributes {
    username?: string;
    email?: string;
}

export interface IAuthResponse {
    AuthenticationResult?: any;
    ChallengeName: ChallengeName | '';
    ChallengeParameters: ChallengeParameters;
    ChallengeAttributes?: IChallengeAttributes;
    Session: string;
    done?: boolean;
    userAttributes: () => IUserAttributes;
}

export interface Challenge {
    ChallengeName:
        | 'LOGIN'
        | 'SMS_MFA'
        | 'SOFTWARE_TOKEN_MFA'
        | 'SELECT_MFA_TYPE'
        | 'MFA_SETUP'
        | 'PASSWORD_VERIFIER'
        | 'CUSTOM_CHALLENGE'
        | 'DEVICE_SRP_AUTH'
        | 'DEVICE_PASSWORD_VERIFIER'
        | 'ADMIN_NO_SRP_AUTH'
        | 'NEW_PASSWORD_REQUIRED'
        | '';
    ChallengeParameters: ChallengeParameters;
}

export class AuthResponse implements IAuthResponse {
    AuthenticationResult?: any;
    ChallengeName:
        | 'LOGIN'
        | 'SMS_MFA'
        | 'SOFTWARE_TOKEN_MFA'
        | 'SELECT_MFA_TYPE'
        | 'MFA_SETUP'
        | 'PASSWORD_VERIFIER'
        | 'CUSTOM_CHALLENGE'
        | 'DEVICE_SRP_AUTH'
        | 'DEVICE_PASSWORD_VERIFIER'
        | 'ADMIN_NO_SRP_AUTH'
        | 'NEW_PASSWORD_REQUIRED'
        | 'CONFIRM_PASSWORD'
        | 'FORGOT_PASSWORD'
        | '';
    ChallengeParameters: ChallengeParameters;
    Session: string;
    done?: boolean;
    constructor() {
        this.AuthenticationResult = null;
        this.ChallengeName = '';
        this.ChallengeParameters = {
            USER_ID_FOR_SRP: '',
            requiredAttributes: '',
            userAttributes: '',
        };
        this.Session = '';
        this.done = false;
    }
    userAttributes = () => {
        return (JSON.stringify(this.ChallengeParameters.userAttributes) as unknown) as IUserAttributes;
    };
}

export interface IAuthState {
    authResponse: IAuthResponse;
    username: string;
}

export interface NewPasswordChallengeResponse {
    challengeResponse: { USERNAME: string; NEW_PASSWORD: string };
    challengeName: string;
    session: string;
}

export interface IUserInfo {
    aud: string;
    auth_time: number;
    'cognito:groups': string[];
    'cognito:preferred_role': string;
    'cognito:roles': string[];
    'cognito:username': string;
    email: string;
    event_id: string;
    exp: number;
    iat: number;
    iss: string;
    phone_number: string;
    sub: string;
    token_use: string;
}

const plannerGroups = ['planners', 'admin'];
export const getGroup = (user: IUserInfo): [string, boolean] => {
    const group = user['cognito:groups'][0];
    const isplanner = plannerGroups.includes(group?.toLowerCase() || '');
    return [group, isplanner];
};

export interface IConfirmPassword {
    password: string;
    verificationCode: string;
    userName: string;
}
