import React from 'react'
import { createStyles, makeStyles, Theme } from '@material-ui/core';

export const disabledStyle: React.CSSProperties = {
    opacity: 0.65,
    backgroundColor: '#F8F8F8',
};

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        cardHeader: {
            fontWeight: 600,
            fontSize: 14,
            color: '#020202',
            maxWidth: 228,
        },
        cardSubtitle: {
            fontSize: 12,
            color: '#020202',
            lineHeight: 1.5,
        },
        content: {
            display: 'flex',
            flexDirection: 'row',
            alignContent: 'flex-start',
            alignItems: 'flex-end',
            '& > :not(:first-child)': {
                marginLeft: theme.spacing(0),
            },
        },
    })
);