import React from 'react';
import { Paper, Typography, Grid, Divider } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { IStore } from '../../../../redux/types/store';
import { useStyles } from './style'

interface IInvestimentSummaryProps {
    current?: boolean;
}

const InvestimentSummary: React.FC<IInvestimentSummaryProps> = ({ current }) => {
    const classes = useStyles();
    const currentKey = current ? 'current' : 'suggested';
    const { investimentSummary } = useSelector((store: IStore) => ({
        investimentSummary: store.customer.investimentPage.porfolioSummary[currentKey],
    }));

    return investimentSummary.classLabel ? (
        <Paper className={classes.root} style={{ height: '100%' }}>
            <Typography style={{ marginBottom: '8px' }}>{investimentSummary.classLabel}</Typography>
            <Grid className={classes.gridContainer} container justify="space-between" alignItems="center">
                <Grid item xs={6}><Typography align="left">Ativo</Typography></Grid>
                <Grid item xs={6}><Typography align="right">Rentabilidade anual</Typography></Grid>
                {investimentSummary.assets.map(item => (
                    <React.Fragment>
                        <Grid item xs={8}><Typography variant="body2">{item.label}</Typography></Grid>
                        <Grid item xs={4}>
                            <Typography variant="body2" align="right">{item.rentability} %</Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Divider />
                        </Grid>
                    </React.Fragment>
                ))}
            </Grid>
        </Paper>
    ) : null;
};
export default InvestimentSummary;
