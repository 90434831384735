import { Typography } from '@material-ui/core';
import React from 'react';
import { useStyles } from './style'

interface IInvalidPlanningProps {
    setupBar?: () => void;
}

const InvalidPlanning: React.FC<IInvalidPlanningProps> = ({ setupBar }) => {
    const classes = useStyles();

    React.useEffect(() => {
        setupBar && setupBar();
    }, []);

    return (
        <div className={classes.root}>
            <Typography align="center" style={{ width: '100%' }}>Oops... Parece que seu projeto de vida ainda não está pronto.</Typography>
        </div>
    );
};
export default InvalidPlanning;