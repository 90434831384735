import {
    Button,
    Chip,
    createStyles,
    InputAdornment,
    makeStyles,
    TextField,
    Theme,
    Typography,
    withStyles,
} from '@material-ui/core';
import { grey } from '@material-ui/core/colors';
import { Favorite } from '@material-ui/icons';
import React from 'react';
import { validateCPF } from '../../../../utils/validateCPF';
import { GreyedDateInput } from '../../../global/inputs/GreyedInput';
import PersonalData from '../personalData/personalData';
import { ICustomerInfo, IPersonalData } from '../types';
import { useStyles } from './style';

const CustomizedTextInput = withStyles({
    root: {
        '& .MuiFilledInput-input': {
            padding: '11px 12px 10px',
        },
        '& .MuiInputBase-root': {
            padding: '0.5em',
        },
    },
})(TextField);

const getHeirsLabel = (amount: number) => {
    if (amount === 0) {
        return 'Não';
    } else if (amount === 5) {
        return '5+';
    } else {
        return amount.toString();
    }
};

interface IPersonalInfoProps {
    setPersonalInfo: React.Dispatch<React.SetStateAction<ICustomerInfo>>;
    defaultPersonalInfo: ICustomerInfo;
    nextStep: (skip: boolean) => void;
    goBack: () => void;
}

const PersonalInfo: React.FC<IPersonalInfoProps> = ({ setPersonalInfo, nextStep, defaultPersonalInfo, goBack }) => {
    const classes = useStyles();
    const [customerInfo, setLocalPersonalInfo] = React.useState<ICustomerInfo>(defaultPersonalInfo);
    const setCustomerData = (personalData: IPersonalData) => {
        setLocalPersonalInfo(state => ({ ...state, personalInfo: personalData }));
    };

    const [heirsAmount, setHeirsAmount] = React.useState(customerInfo?.heirs || 0);
    const [heirs, setHeirs] = React.useState(
        customerInfo?.heirsName || [...Array(5)].map(() => ({ name: '', birthdate: defaultBirthdate }))
    );

    const defaultBirthdate = new Date();
    const onHeirNameChange = (name: string, index: number) => {
        let result: { name: string; birthdate: Date }[] = [...heirs];
        if (heirsAmount > heirs.length) {
            result = [...Array(heirsAmount)].map((_, idx) =>
                idx < heirs.length ? heirs[idx] : { name: '', birthdate: defaultBirthdate }
            );
        }
        result = result.map((item, idx) => (idx === index ? { ...item, name } : item));
        setHeirs(result);
        setLocalPersonalInfo(state => ({ ...state, heirs: heirsAmount, heirsName: result }));
    };

    const onDateOfBirthChange = (date: Date, index: number) => {
        let result: { name: string; birthdate: Date }[] = [...heirs];
        if (heirsAmount > heirs.length) {
            result = [...Array(heirsAmount)].map((_, idx) =>
                idx < heirs.length ? heirs[idx] : { name: '', birthdate: defaultBirthdate }
            );
        }
        result = result.map((item, idx) => (idx === index ? { ...item, birthdate: date } : item));
        setHeirs(result);
        setLocalPersonalInfo(state => ({ ...state, heirsName: result }));
    };

    const onHeirsAmountChange = (newHeirsAmount: number) => {
        setHeirsAmount(newHeirsAmount);
        let result: { name: string; birthdate: Date }[] = [...heirs];
        if (newHeirsAmount < heirs.length) {
            result = result.slice(0, newHeirsAmount);
        }
        setHeirs(result);
        setLocalPersonalInfo(state => ({ ...state, heirs: newHeirsAmount, heirsName: result }));
    };

    const [customerCpfError, setCustomerCpfError] = React.useState(false);

    const onSubmit = (event: React.FormEvent) => {
        event.preventDefault();
        const unmaskedCPF = customerInfo.personalInfo.cpf.replace(/\./g, '').replace('-', '');
        if (unmaskedCPF) {
            const validCPF = validateCPF(unmaskedCPF);
            setCustomerCpfError(!validCPF);
            if (!validCPF) {
                setCustomerCpfError(true);
                return;
            }
        }
        const hasPartner = customerInfo.personalInfo.maritalStatus === 'married';
        setPersonalInfo({
            ...customerInfo!,
            hasPartner: hasPartner,
        });
        nextStep(!hasPartner);
    };

    return (
        <div className={`${classes.content} container`}>
            <div className={classes.headerContainer}>
                <Typography align="center" variant="h4" className={classes.header}>
                    Dados do Cliente
                </Typography>
            </div>
            <form className={classes.form} onSubmit={event => onSubmit(event)}>
                <PersonalData
                    personalInfo={customerInfo.personalInfo}
                    setPersonalInfo={setCustomerData}
                    cpfErr={customerCpfError}
                />
                <div className="container d-flex flex-column gap-3">
                    <Typography variant="h6" className={classes.inputLabel}>
                        Possui herdeiros?
                    </Typography>
                    <div className={`${classes.heirsAmount} d-flex gap-1`}>
                        {[...Array(6)].map((_, i) => (
                            //@ts-ignore 
                            <Button
                                key={`heirs-button-${i}`}
                                variant="outlined"
                                size="large"
                                color={i === heirsAmount ? 'primary' : 'default'}
                                disableElevation
                                onClick={() => onHeirsAmountChange(i)}
                            >
                                {getHeirsLabel(i)}
                            </Button>
                        ))}
                    </div>
                </div>
                <div>
                    {[...Array(heirsAmount)].map((_, i) => (
                        <div key={`heir${i}`} className={classes.heirs}>
                            <div className={classes.heirsName}>
                                <Typography variant="h6" className={classes.inputLabel}>
                                    Nome do Herdeiro {i + 1}
                                </Typography>
                                <CustomizedTextInput
                                    required
                                    variant="outlined"
                                    size="small"
                                    placeholder="digite o nome completo do(a) herdeiro(a)"
                                    InputProps={{
                                        className: classes.heirsInput,
                                        startAdornment: (
                                            <InputAdornment style={{ marginTop: 0 }} position="start">
                                                <Favorite color="action" />
                                            </InputAdornment>
                                        ),
                                    }}
                                    value={heirs[i]?.name || ''}
                                    onChange={event => onHeirNameChange(event.target.value, i)}
                                    fullWidth
                                />
                            </div>
                            <div className={classes.heirsAge}>
                                <Typography variant="h6" className={classes.inputLabel}>
                                    Idade
                                </Typography>
                                <GreyedDateInput
                                    size="small"
                                    format="dd/MM/yyyy"
                                    margin="normal"
                                    variant="inline"
                                    inputVariant="outlined"
                                    id="date-picker-inline"
                                    InputProps={{
                                        className: classes.heirsInput,
                                    }}
                                    value={heirs[i]?.birthdate || defaultBirthdate}
                                    onChange={d => d && onDateOfBirthChange(d as Date, i)}
                                    KeyboardButtonProps={{
                                        'aria-label': 'change date',
                                    }}
                                />
                            </div>
                        </div>
                    ))}
                    <div className={classes.formControllers}>
                        {/* @ts-ignore */}
                        <Button
                            variant="contained"
                            className={classes.outButton}
                            size="medium"
                            color="default"
                            disableElevation
                            onClick={goBack}
                        >
                            {' '}
                            Sair
                        </Button>
                        <button type="submit" className={classes.chipButton}>
                            <Chip className={classes.chip} size="medium" label="Próximo" clickable />
                        </button>
                    </div>
                </div>
            </form>
        </div>
    );
};
export default PersonalInfo;
