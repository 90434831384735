import { createStyles, makeStyles, Theme } from '@material-ui/core';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import api from '../../../../apis/dreamrichapi';
import { ChangeCustomerPatrimony } from '../../../../redux/actions/planningActions';
import { IStore } from '../../../../redux/types/store';
import { IPlannerPageProps } from '../../types';
import FinancialAssets from '../financialAssets/financialAssets';
import Pensions from '../pension/pension';
import Properties from '../properties/properties';
import { IFinancialAssets, IPatrimony, IPension, IProperty } from '../types';
import {useStyles} from './style';

const Patrimony: React.FC<IPlannerPageProps> = ({ changeTitle }) => {
    const classes = useStyles();
    const history = useHistory();

    const { userID, patrimonyStored, currToolbar } = useSelector((store: IStore) => ({
        userID: store.planning.customerUserInfo.userID,
        patrimonyStored: store.planning.customerFormValues.patrimony,
        currToolbar: store.planning.toolbar.current,
    }));

    const patrimonyStorage = JSON.parse(localStorage.getItem('storageCustomer'+userID))?.patrimony;

    if (!userID) {
        history.push('/');
    }

    const [currentStep, setStep] = React.useState(1);
    React.useEffect(() => {
        if (!currToolbar || currentStep === currToolbar) return;
        setStep(currToolbar);
    }, [currToolbar]);

    const nextStep = () => {
        if (currentStep < totalSteps) {
            setStep(currentStep + 1);
        }
    };

    const [send, setSend] = React.useState(false);
    const finish = () => {
        setReady(true);
        setSend(true);
    };

    const [patrimony, setPatrimony] = React.useState<IPatrimony>(patrimonyStored);
    const [errLabel, setErrLabel] = React.useState('');
    // goal control to send just after finish
    const [ready, setReady] = React.useState(false);

    const dispatch = useDispatch();
    React.useEffect(() => {
        if (send && patrimony?.ready) {
            const patrimonyBody: IPatrimony = { ...patrimony, userID };
            delete patrimonyBody.ready;
            api.post('/patrimony ', patrimonyBody)
                .then(() => {
                    setErrLabel('');
                    dispatch(ChangeCustomerPatrimony(patrimonyBody));
                    history.push('/planner');
                })
                .catch(err => {
                    if (err.response) {
                        setErrLabel(`Erro salvando informações de independência financeira: ${err.response.data}`);
                    } else {
                        setErrLabel(`Erro interno do sistema: ${err}`);
                    }
                });
            setSend(false);
        }
    }, [send, history, patrimony]);

    const previousStep = () => {
        setStep(currentStep - 1);
    };

    const totalSteps = 3;
    const percentage = (currentStep / totalSteps) * 100;
    const [properties, setProperties] = React.useState<IProperty[]>(patrimonyStorage.properties || patrimony.properties);
    const [financialAssets, setFinancialAssets] = React.useState<IFinancialAssets[]>(patrimonyStorage.financialAssets ||patrimony.financialAssets);
    const [pensions, setPensions] = React.useState<IPension[]>(patrimonyStorage.pensions || patrimony.pensions);
    const [house, setHouse] = React.useState(patrimonyStorage.house || patrimony.house || { forSell: false, value: 0 });

    const Steps: JSX.Element[] = [
        <Properties
            //@ts-ignore
            defaultHouse={house}
            setHouseValue={setHouse}
            defaultProperties={properties}
            nextStep={nextStep}
            previousStep={history.goBack}
            setPropertiesValues={setProperties}
        />,
        <FinancialAssets
            previousStep={previousStep}
            defaultFinancialAssets={financialAssets}
            setFinancialAssetsValues={setFinancialAssets}
            nextStep={nextStep}
        />,
        <Pensions
            previousStep={previousStep}
            defaultPensions={pensions}
            setPensionsValues={setPensions}
            nextStep={finish}
            errLabel={errLabel}
        />,
    ];

    React.useEffect(() => {
        if (properties) {
            //@ts-ignore
            setPatrimony(previous => ({ ...previous!, properties, house, ready }));
        }
        if (financialAssets) {
            setPatrimony(previous => ({ ...previous!, financialAssets, ready }));
        }
        if (pensions) {
            setPatrimony(previous => ({ ...previous!, pensions, ready }));
        }
    }, [properties, financialAssets, pensions, house, ready]);

    React.useEffect(() => {
        changeTitle(`Patrimônio`, currentStep, totalSteps);
    }, [currentStep, totalSteps]);

    return <div className='container'>{Steps[currentStep - 1]}</div>;
};
export default Patrimony;
