import { AuthResponse, IAuthState } from './types';
import { ReduxAction } from '../../redux/actions/reduxAction';

export type AuthReducer = (state: IAuthState, action: ReduxAction<IAuthState>) => IAuthState;

export const AuthResponseReducer = (state: IAuthState, action: ReduxAction<IAuthState>) => {
    switch (action.type) {
        case 'set':
            return { ...(action.payload || state), username: state.username };
        case 'setUser':
            return { ...state, username: action.payload?.username || '' };
        case 'unset':
            return { authResponse: { ...new AuthResponse() }, username: '' };
        case 'done':
            return { authResponse: { ...new AuthResponse(), done: true }, username: state.username };
        case 'setState':
            return { ...action.payload! };
        default:
            throw new Error(`Cannot dispatch this action ${action.type}`);
    }
};

export const SetAuthState = (authState: IAuthState): ReduxAction<IAuthState> => ({
    type: 'setState',
    payload: authState,
});

export const SetAuthResponse = (authResponse: AuthResponse): ReduxAction<IAuthState> => ({
    type: 'set',
    payload: { authResponse, username: '' },
});

export const SetUsername = (username: string): ReduxAction<IAuthState> => ({
    type: 'setUser',
    payload: { authResponse: { ...new AuthResponse() }, username },
});

export const DoneAuth = (): ReduxAction<IAuthState> => ({
    type: 'done',
});