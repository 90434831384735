import { Grid, Paper, Typography } from '@material-ui/core';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Cell, Pie, PieChart, ResponsiveContainer, Sector } from 'recharts';
import { RoundIcon } from '../../../../assets/icons';
import { SetHomeworkPortfolioMap, setRentability } from '../../../../redux/actions/customerActions';
import { IStore } from '../../../../redux/types/store';
import ValueColorText from '../../../global/displays/valueColorText/valueColorText';
import formatter from '../../../global/formater';
import { IFinancialAssets } from '../../../planningForm/patrimony/types';
import { IPortfolioMap, ISummaryEntry } from '../types';
import { useStyles, colors } from './style'

interface IDataChart { name: string; value: number; yield: number; }

const renderActiveShape = (data: IDataChart[]) => (props: any) => {
    const { cx, cy, innerRadius, outerRadius, startAngle, endAngle, fill, payload, percent } = props;

    return (
        <g>
            <text x={cx} y={cy} dy={8} textAnchor="middle" fill="#000000">{data.filter(item => item.name === payload.name)[0].yield.toFixed(2)} %</text>

            <Sector
                cx={cx}
                cy={cy}
                innerRadius={innerRadius}
                outerRadius={outerRadius}
                startAngle={startAngle}
                endAngle={endAngle}
                fill={fill}
            />
            <Sector
                cx={cx}
                cy={cy}
                startAngle={startAngle}
                endAngle={endAngle}
                innerRadius={outerRadius + 6}
                outerRadius={outerRadius + 10}
                fill={fill}
            />
        </g>
    );
};

interface IExpensesProps {
    portfolio: IPortfolioMap;
    suggested?: boolean;
    summary: ISummaryEntry;
    dataHeight: number;
    controlFunction?: (pieChart: boolean, investmentProfile: number) => void;
    approach?: number;
}

const InvestmentPortfolio: React.FC<IExpensesProps> = ({
    portfolio,
    suggested,
    summary,
    dataHeight,
}) => {
    const classes = useStyles();
    const [currentIdx, setIdx] = React.useState(-1);
    const [hoverStyle, setHoverStyle] = React.useState('');
    const [data, setData] = React.useState<IDataChart[]>([]);
    const [total, setTotal] = React.useState(0);

    const dispatch = useDispatch();

    const { currSelectedLabel, suggSelectedLabel } = useSelector((store: IStore) => ({
        currSelectedLabel: store.customer.investimentPage.porfolioSummary.current.classLabel,
        suggSelectedLabel: store.customer.investimentPage.porfolioSummary.suggested.classLabel,
    }));

    React.useEffect(() => {
        const mineLabel = suggested ? suggSelectedLabel : currSelectedLabel;
        const otherLabel = suggested ? currSelectedLabel : suggSelectedLabel;

        if (mineLabel === '' && mineLabel !== otherLabel) {
            const portfolioEntry = portfolio.get(otherLabel);
            if (portfolioEntry) {
                const financialAssets = portfolioEntry.financialAssets;
                dispatch(SetHomeworkPortfolioMap(!suggested, otherLabel, financialAssets));
            }
        }
    }, [currSelectedLabel, suggSelectedLabel, dispatch, portfolio]);

    const hover = (dataIdx: number) => {
        setIdx(dataIdx);
        setHoverStyle(classes.labelHover);
    };

    React.useEffect(() => {
        const sum = (investments: IPortfolioMap) => {
            return [...investments.values()].reduce((prev, curr) => prev + curr.value, 0);
        };

        const convertInvestmentToDataChart = (investments: IPortfolioMap, total_: number): IDataChart[] => {
            const dataChart: IDataChart[] = Array.from(investments, ([className, value]) => ({
                name: className,
                ...value,
                yield: (value.value / total_) * 100,
            }))
                .filter(item => item.name)
                .sort((a, b) => a.yield - b.yield);
            return dataChart;
        };

        if (portfolio) {
            const newPort = convertInvestmentToDataChart(portfolio, sum(portfolio));
            const reduced = newPort.reduce((acc, curr) => acc + curr.value, 0);
            setData(newPort);
            setTotal(reduced);
        }
    }, [portfolio, setData, setTotal, setRentability]);



    const onLabelClick = (className: string) => {
        let financialAssets: IFinancialAssets[] = [];
        const portfolioEntry = portfolio.get(className);
        if (portfolioEntry) {
            financialAssets = portfolioEntry.financialAssets;
        }

        dispatch(SetHomeworkPortfolioMap(!suggested, className, financialAssets));
    };

    return (
        <Paper className={classes.root} elevation={1}>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Typography className={classes.cardHeader} gutterBottom>
                        Carteira {!suggested ? 'Atual' : 'Sugerida'}
                    </Typography>
                </Grid>
                <Grid item xs={12} className={classes.chartContainer}>
                    <ResponsiveContainer height="100%">
                        <PieChart width={300} height={200}>
                            <Pie
                                activeIndex={currentIdx}
                                activeShape={renderActiveShape(data)}
                                data={data}
                                isAnimationActive={true}
                                dataKey="value"
                                nameKey="name"
                                cx="50%"
                                cy="50%"
                                startAngle={90}
                                endAngle={450}
                                innerRadius={50}
                                outerRadius={100}
                                fill="#8884d8"
                                onMouseEnter={(_, index) => setIdx(index)}
                                onMouseLeave={() => setIdx(-1)}
                                onClick={props => onLabelClick(props.name)}
                            >
                                {colors.map(entry => (
                                    <Cell fill={entry} />
                                ))}
                            </Pie>
                        </PieChart>
                    </ResponsiveContainer>
                </Grid>
                <Grid container item xs={12}>
                    <Grid item xs={1} />
                    <Grid item xs={4}>
                        <Typography align="left">Classe de ativo</Typography>
                    </Grid>
                    <Grid item xs={4}>
                        <Typography align="right">Patrimônio</Typography>
                    </Grid>
                    <Grid item xs={3}>
                        <Typography align="right">% alocado</Typography>
                    </Grid>
                </Grid>
                <Grid
                    item
                    container
                    xs={12}
                    onMouseLeave={() => setIdx(-1)}
                    justify="space-between"
                    alignItems="baseline"
                    alignContent="center"
                    style={{ height: dataHeight, cursor: 'pointer' }}
                >
                    {data
                        .map((item, idx) => (
                            <React.Fragment>
                                <Grid item xs={1}><RoundIcon color={colors[idx]} width={10} height={10} /></Grid>
                                <Grid item xs={4}>
                                    {/* @ts-ignore */}
                                    <div onMouseEnter={() => hover(idx)} onClick={() => onLabelClick(item.name)}>
                                        <Typography
                                            className={currentIdx === idx ? hoverStyle : ''}
                                            style={{ fontSize: 14, textTransform: 'capitalize' }}
                                        >
                                            {item.name}
                                        </Typography>
                                    </div>
                                </Grid>
                                <Grid item xs={4}>
                                    {/* @ts-ignore */}
                                    <div onMouseEnter={() => hover(idx)} onClick={() => onLabelClick(item.name)}>
                                        <Typography
                                            className={classes.labelValue}
                                            color="primary"
                                            align="right"
                                            style={{ fontSize: 14, fontWeight: currentIdx === idx ? 600 : 400 }}
                                        >
                                            {formatter.format(item.value)}
                                        </Typography>
                                    </div>
                                </Grid>
                                <Grid item xs={3}>
                                    <div onMouseEnter={() => hover(idx)}>
                                        <Typography
                                            className={classes.labelValue}
                                            color="primary"
                                            align="right"
                                            style={{ fontSize: 14, fontWeight: currentIdx === idx ? 600 : 400 }}
                                        >
                                            {item.yield?.toFixed(2)}%
                                        </Typography>
                                    </div>
                                </Grid>
                            </React.Fragment>
                        ))
                        .reverse()}
                </Grid>
                <Grid item xs={12}><Typography variant="subtitle1">*Clique na classe de ativos para detalhar</Typography></Grid>
                <Grid item xs={2} />
                <Grid
                    item
                    xs={10}
                    style={{ marginTop: 8, paddingBottom: 0 }}
                    container
                    direction="row"
                    alignItems="flex-end"
                    justify="flex-end"
                >
                    <Grid item><Typography align="right">Rentabilidade da carteira</Typography></Grid>
                    <Grid item xs={4}>
                        <ValueColorText
                            sameColor
                            style={{ fontSize: 14 }}
                            align="right"
                            value={summary.rentability}
                            type="percentage"
                        >
                            a.a.
                        </ValueColorText>
                    </Grid>
                </Grid>
                <Grid item xs={2} />
                <Grid
                    item
                    style={{ paddingTop: 0, paddingBottom: 0 }}
                    xs={10}
                    container
                    direction="row"
                    alignItems="flex-end"
                    justify="flex-end"
                >
                    <Grid item><Typography align="right">% do CDI</Typography></Grid>
                    <Grid item xs={4}>
                        <ValueColorText
                            sameColor
                            style={{ fontSize: 14 }}
                            type="percentage"
                            value={summary.rentabilityCDI}
                            align="right"
                        >
                            a.a.
                        </ValueColorText>
                    </Grid>
                </Grid>
                <Grid item xs={2} />
                <Grid
                    item
                    xs={10}
                    style={{ paddingTop: 0 }}
                    container
                    direction="row"
                    alignItems="flex-end"
                    justify="flex-end"
                >
                    <Grid item><Typography align="right">Ganho real da carteira</Typography></Grid>
                    <Grid item xs={4}>
                        <ValueColorText
                            sameColor
                            style={{ fontSize: 14 }}
                            align="right"
                            type="percentage"
                            value={summary.realGain}
                        >
                            a.a.
                        </ValueColorText>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <Paper className={suggested ? classes.suggested : classes.total}>
                        <div className={classes.totalLabel}>
                            <>
                                <Typography style={{ fontSize: 16 }}>Total investido</Typography>
                            </>
                            <Typography
                                color="secondary"
                                className={suggested ? classes.suggestedText : ''}
                                style={{ fontSize: 18, fontWeight: 600 }} >
                                {formatter.format(total)}
                            </Typography>
                        </div>
                    </Paper>
                </Grid>
            </Grid>
        </Paper>
    );
};

export default InvestmentPortfolio;