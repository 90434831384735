import { Card, CardContent, Typography } from '@material-ui/core';
import React from 'react';
import { useStyles, disabledStyle } from './style'

const SuggestionCardPension: React.FC<{
    className?: string;
    header: string;
    value: string;
    disabled: boolean;
    typographyClassName?: string;
    subtitle?: string;
    onClick: () => void;
}> = ({ className, header, value, disabled, onClick, typographyClassName, subtitle }) => {
    const classes = useStyles();
    const [style, setStyle] = React.useState<React.CSSProperties>(disabled ? disabledStyle : {});

    const onLeave = (disabledItem: boolean) => {
        if (disabledItem) {
            setStyle(disabledStyle);
        }
    };

    const enableCard = () => {
        setStyle({});
        onClick();
    };

    React.useEffect(() => {
        setStyle(disabled ? disabledStyle : {});
    }, [disabled]);

    return (
        <Card
            elevation={3}
            className={className}
            style={{ ...style, height: '100%' }}
            onMouseEnter={() => setStyle({})}
            onMouseLeave={() => onLeave(disabled)}
            //@ts-ignore
            onClick={() => enableCard()}
        >
            <CardContent>
                <Typography className={classes.cardHeader}>{header}</Typography>
                <div className={classes.content}>
                    <Typography
                        style={{ fontSize: 18, minWidth: 100 }}
                        className={typographyClassName}
                        color="secondary"
                    >
                        {value}
                    </Typography>
                    {subtitle ? (
                        <Typography className={classes.cardSubtitle} variant="overline">
                            {subtitle}
                        </Typography>
                    ) : null}
                </div>
            </CardContent>
        </Card>
    );
};
export default SuggestionCardPension;
