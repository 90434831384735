import React from 'react';
import MaskedInput from 'react-text-mask';
import createNumberMask from 'text-mask-addons/dist/createNumberMask';

const defaultMaskOptions = {
    prefix: '',
    suffix: '',
    includeThousandsSeparator: true,
    thousandsSeparatorSymbol: '.',
    allowDecimal: true,
    decimalSymbol: ',',
    decimalLimit: 3, 
    integerLimit: 2, 
    allowNegative: false,
    allowLeadingZeroes: false,
};

interface IMaskOptions {
    prefix: string;
    suffix: string;
    includeThousandsSeparator: boolean;
    thousandsSeparatorSymbol: string;
    allowDecimal: boolean;
    decimalSymbol: string;
    decimalLimit: string;
    requireDecimal: boolean;
    allowNegative: boolean;
    allowLeadingZeroes: boolean;
    integerLimit: number;
}

interface IPercentageInputProps {
    inputRef: (ref: HTMLInputElement | null) => void;
    allowNegative: boolean;
    maskOptions: IMaskOptions;
}

const PercentageInput = (props: IPercentageInputProps) => {
    const { inputRef, allowNegative, ...other } = props;

    const currencyMask = createNumberMask({
        ...defaultMaskOptions,
        ...props.maskOptions,
        allowNegative,
    });

    return (
        <MaskedInput
            style={{ textAlign: 'right' }}
            mask={currencyMask}
            {...other}
            ref={(ref: any) => {
                inputRef(ref ? ref.inputElement : null);
            }}
        />
    );
};

export default PercentageInput;