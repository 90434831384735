import React from 'react';
import { Paper, Typography } from '@material-ui/core';
import formatter from '../../../global/formater';
import { IIncomingDestination } from '../types';
import { useStyles } from './style'

interface ITotalIncoming { incoming: IIncomingDestination; }

const TotalIncoming: React.FC<ITotalIncoming> = ({ incoming }) => {
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <Typography variant="h6">
                Veja o total de recursos gerados até a independência financeira
            </Typography>
            <Paper className={classes.content}>
                <div className={classes.child}>
                    <Typography variant="h6">Patrimônio Inicial</Typography>
                    <Typography variant="h5" color="secondary">{formatter.format(incoming.initialPatrimony)}</Typography>
                </div>
                <div className={classes.divider} />
                <div className={classes.childWide}>
                    <Typography variant="h6">Renda gerada até a Independência financeira </Typography>
                    <Typography variant="h5" color="secondary">{formatter.format(incoming.incomingGenerated)}</Typography>
                </div>
                <div className={classes.grow} />
                <Paper className={classes.free} elevation={0}>
                    <div className={classes.freeContent}>
                        <div style={{ margin: 'auto' }}>
                            <Typography align="left" className={classes.whiteLabel}>Total acumulado</Typography>
                            <Typography className={classes.freeLabel} align="left" variant="h4">
                                {formatter.format(incoming.initialPatrimony + incoming.incomingGenerated)}
                            </Typography>
                        </div>
                    </div>
                </Paper>
            </Paper>
        </div>
    );
};
export default TotalIncoming;