import { createStyles, makeStyles, Theme } from '@material-ui/core';
import { grey } from '@material-ui/core/colors';

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            display: 'flex',
            marginTop: theme.spacing(1),
            padding: theme.spacing(2),
            justifyContent: 'space-between',
        },
        gap: {
            backgroundColor: theme.palette.primary.dark,
            flexBasis: '30%',
            margin: `-${theme.spacing(2)}px`,
        },
        whiteLabel: {
            color: 'white',
            flexGrow: 1,
        },
        child: {
            flexGrow: 1,
        },
        overline: {
            lineHeight: 0,
        },
        gapContent: {
            display: 'flex',
            height: '100%',
            flexDirection: 'column',
            alignItems: 'center',
            alignContent: 'center',
        },
        divider: {
            borderLeft: `1px solid ${grey[400]}`,
            margin: `6px ${theme.spacing(2)}px`,
        },
    })
);