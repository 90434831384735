import { Card, CardContent, Typography } from '@material-ui/core';
import React from 'react';
import DisplayWithBag from '../../../global/displays/displayWithBag/displayWithBag';
import formatter from '../../../global/formater';
import { useStyles } from './style';

const Independence: React.FC<{
    age: number;
    desiredIncome: number;
    duration: number;
    totalNeeded: number;
    className?: string;
    yearsUntil: number;
    monthsUntil: number;
    daysUntil: number;
    currentAge: number;
}> = ({ age, desiredIncome, duration, totalNeeded, className, currentAge, yearsUntil, monthsUntil, daysUntil }) => {
    const classes = useStyles();

    return (
        <div className={classes.root}>
            {
                (
                    <>
                        <Typography className={classes.cardHeader} gutterBottom>Sua independência financeira</Typography>
                        <div className={classes.cardsLine}>
                            <div className={classes.cardRow}>
                                <Card className={classes.cardItemShrink} elevation={2}>
                                    <CardContent classes={{ root: classes.cardContent }}>
                                        <Typography variant="body2">Aos</Typography>
                                        <Typography className={classes.valueText} variant="h5">{age}</Typography>
                                        <Typography variant="overline">ANOS</Typography>
                                    </CardContent>
                                </Card>
                                <Card className={classes.cardItemShrink} elevation={2} style={{ marginLeft: '10px' }}>
                                    <CardContent className={classes.cardContent}>
                                        <Typography variant="body2">Desejável</Typography>
                                        <Typography className={classes.valueText} variant="h5">
                                            {formatter.format(desiredIncome)}
                                        </Typography>
                                        <Typography variant="overline">/MÊS</Typography>
                                    </CardContent>
                                </Card>
                            </div>
                            <div className={classes.cardRow}>
                                <Card className={classes.cardItemShrink} elevation={2}>
                                    <CardContent className={classes.cardContent}>
                                        <Typography variant="body2">Durante</Typography>
                                        <Typography className={classes.valueText} variant="h5">{duration}</Typography>
                                        <Typography variant="overline">ANOS</Typography>
                                    </CardContent>
                                </Card>
                                <Card className={classes.cardItemShrink} elevation={2} style={{ marginLeft: '10px' }}>
                                    <CardContent className={classes.cardContent}>
                                        <Typography variant="body2">Idade</Typography>
                                        <Typography className={classes.valueText} variant="h5">{currentAge}</Typography>
                                        <Typography variant="overline">ANOS</Typography>
                                    </CardContent>
                                </Card>
                            </div>
                        </div>
                        <>
                            <DisplayWithBag label="Você precisará acumular:" sublabel="*rendimento 4% a.a" value={totalNeeded} />
                        </>
                    </>
                )
            }
        </div>
    );
};

export default Independence;
