import { createStyles, CssBaseline, makeStyles, Theme, Typography } from '@material-ui/core';

export const drawerWidth = '15%';

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            width: '100%',
        },
        toolbar: theme.mixins.toolbar,
        content: {
            '@media (min-width: 768px)': {
                marginLeft: drawerWidth,
                paddingTop: theme.spacing(2),
            },
            '@media (max-width: 767px)': {
                display: 'flex',
            },
        },
    })
);