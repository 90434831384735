import React, { useState, InputHTMLAttributes, forwardRef, useEffect } from 'react';
import { styles, CustomizedTextInput } from './style'
import { Select, MenuItem, TextField } from '@material-ui/core';
import paises from '../../../../utils/ddis.json';
import PhoneMaskedInput from '../../masks/phoneMakedInput';

interface IPhoneInput extends InputHTMLAttributes<HTMLInputElement> {
    defaultNumber?: string;
    defaultDDI?: string;
}

const PhoneInput: React.RefForwardingComponent<HTMLInputElement, IPhoneInput> = ({ defaultNumber = '', defaultDDI = '55', ...rest }, ref) => {
    const [ddi, setDdi] = useState<string>(defaultDDI);
    const [number, setNumber] = useState<string>(defaultNumber);

    const [phoneError, setPhoneError] = React.useState({ error: false, errMsg: '' });
    const [partnerPhoneError, setPartnerPhoneError] = React.useState({ error: false, errMsg: '' });

    useEffect(() => {
        try {
            let [ddiReceived, numberReceived] = defaultNumber.split('=');

            if (numberReceived) {
                setDdi(ddiReceived);
                setNumber(numberReceived);
            } else {
                setNumber(ddiReceived);
            }
        } catch (err) { }
    }, [defaultNumber]);

    const classes = styles();

    const unmaskPhone = (cellphone: string) => {
        try {
            const unmasked = cellphone.replace(/\D/g, '');
            if (unmasked === '9') {
                return '';
            }
            return unmasked;
        } catch (err) {
            return '';
        }
    };

    const verifyNumber = (phone: string, partner?: boolean) => {
        const unmaskedPhone = unmaskPhone(phone);

        if (ddi === '55' && unmaskedPhone.length < 11) {
            partner ? setPartnerPhoneError({ error: true, errMsg: 'Telefone inválido' }) : setPhoneError({ error: true, errMsg: 'Telefone inválido' });
            return true;
        } else {
            partner ? setPartnerPhoneError({ error: false, errMsg: '' }) : setPhoneError({ error: false, errMsg: '' });
            return false;
        }
    };

    const handleChangeNumber = (event: React.ChangeEvent<{ value: unknown }> | null, value: string | null = null) => {
        let finalPhone = value || (event?.target.value as string);

        try {
            let [_, phone] = finalPhone.split('=');
            finalPhone = phone || finalPhone;
            setNumber(unmaskPhone(finalPhone));
        } catch (err) {
            console.log('err:', err);
        }
    };

    return (
        <div className={classes.root} {...rest}>
            <input value={`${ddi}=${number}`} hidden ref={ref} />
            <Select
                label="DDI"
                style={{ minWidth: 100 }}
                variant="outlined"
                value={ddi}
                renderValue={() => `+${ddi}`}
                required
                className={classes.item}
                onChange={(event: React.ChangeEvent<{ value: unknown }>) => {
                    setDdi(event.target.value as string);
                }}
            >
                {paises.map((data, idx) => (
                    <MenuItem value={data.code_ddi} id={`item-${idx}`}>
                        +{data.code_ddi} - {data.country}
                    </MenuItem>
                ))}
            </Select>

            {ddi?.toString() === '55' && (
                <CustomizedTextInput
                    required
                    className={classes.item}
                    error={phoneError.error}
                    helperText={phoneError.errMsg}
                    onBlur={() => verifyNumber(number || '')}
                    variant="outlined"
                    size="small"
                    InputProps={{
                        inputComponent: PhoneMaskedInput as any,
                    }}
                    value={number}
                    onChange={(event: React.ChangeEvent<{ value: unknown }>) => handleChangeNumber(event)}
                    fullWidth
                />
            )}
            {ddi?.toString() !== '55' && (
                <TextField
                    id="outlined-basic"
                    required
                    fullWidth={true}
                    variant="outlined"
                    defaultValue={defaultNumber}
                    value={number}
                    inputProps={{ maxLength: 11 }}
                    onChange={(event: React.ChangeEvent<{ value: unknown }>) => handleChangeNumber(event)}
                />
            )}
        </div>
    );
};

export default forwardRef(PhoneInput);