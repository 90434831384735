import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import api from '../../../../apis/dreamrichapi';
import { ChangeCustomerInfo } from '../../../../redux/actions/planningActions';
import { IStore } from '../../../../redux/types/store';
import { IPlannerPageProps } from '../../types';
import ContactInfo from '../contactInfo/contactInfo';
import PartnerInfo from '../partnerInfo/partnerInfo';
import PersonalInfo from '../personalInfo/personalInfo';
import { IContactInfo, ICustomerInfo, ICustomerInfoRequest, IPersonalData, IWorkInfo } from '../types';
import WorkInfo from '../workInfo/workInfo';
import { useStyles } from './style';

const CustomerInfo: React.FC<IPlannerPageProps> = ({ changeTitle }) => {
    const { userID, email, customerInfoStored, plannerUsername, username, currToolbar } = useSelector(
        (store: IStore) => ({
            userID: store.planning.customerUserInfo.userID,
            username: store.planning.customerUserInfo.user,
            email: store.planning.customerUserInfo.email,
            plannerUsername: store.loggedUser.currentUser['cognito:username'],
            customerInfoStored: store.planning.customerFormValues.customerInfo,
            currToolbar: store.planning.toolbar.current,
        })
    );

    const history = useHistory();
    if (!userID) {
        history.push('/');
    }

    const [planningDate] = React.useState(new Date());
    const [customerInfo, setCustomerInfo] = React.useState<ICustomerInfoRequest>({
        ...customerInfoStored.contactInfo,
        ...customerInfoStored.personalInfo,
        userID,
        planningDate,
        email,
        plannerUsername,
        username,
    });

    const classes = useStyles();
    const [totalSteps, setTotalSteps] = React.useState(customerInfo.hasPartner ? 5 : 3);

    const customerInfoStorage = JSON.parse(localStorage.getItem('storageCustomer'+userID))?.customerInfo;
    const personalInfoStorage:ICustomerInfo = {
        personalInfo: customerInfoStorage?.personalInfo,
        partnerInfo:customerInfoStorage?.partnerInfo,
        heirs: customerInfoStorage?.heirs,
        heirsName: customerInfoStorage?.heirsName,
        hasPartner: Boolean(customerInfoStorage?.personalInfo.maritalStatus === "married")
    }
    const partnerInfoStorage = customerInfoStorage?.partnerInfo;

    const contactInfoStorage:IContactInfo = {
        cep:customerInfoStorage?.cep,
        houseNumber:customerInfoStorage?.houseNumber,
        address:customerInfoStorage?.address,
        additionalAddressInfo:customerInfoStorage?.additionalAddressInfo,
        state:customerInfoStorage?.state,
        city:customerInfoStorage?.city,
        country:customerInfoStorage?.country,
        cellphone:customerInfoStorage?.cellphone,
        partnerCellphone:customerInfoStorage.partnerInfo.jobInfo.phoneNumber
    }
    const personalWorkInfoStorage: IWorkInfo = {
        jobTitle: customerInfoStorage.personalInfo.jobInfo.jobTitle,
        company: customerInfoStorage.personalInfo.jobInfo.company,
        address: customerInfoStorage.personalInfo.jobInfo.address,
        phoneNumber: customerInfoStorage.personalInfo.jobInfo.cellphone,
    }

    const partnerWorkInfoStorage: IWorkInfo = {
        jobTitle: customerInfoStorage.partnerInfo.jobInfo.jobTitle,
        company: customerInfoStorage.partnerInfo.jobInfo.company,
        address: customerInfoStorage.partnerInfo.jobInfo.address,
        phoneNumber: customerInfoStorage.partnerInfo.jobInfo.phoneNumber,
    }

    React.useEffect(() => {
        const total = customerInfo.hasPartner ? 5 : 3;
        setTotalSteps(total);
    }, [customerInfo.hasPartner, customerInfoStored.personalInfo.hasPartner]);

    const [currentStep, setStep] = React.useState(1);

    React.useEffect(() => {
        if (!currToolbar || currentStep === currToolbar) return;
        setStep(currToolbar);
    }, [currToolbar]);

    const nextStep = (skip: boolean = false) => {
        if (skip) {
            setStep(currentStep + 2);
            return;
        }
        setStep(currentStep + 1);
    };

    const previousStepCond = () => {
        if (!customerInfo.hasPartner) {
            setStep(currentStep - 2);
        } else {
            previousStep();
        }
    };

    const previousStep = () => {
        if (currentStep > 0) {
            setStep(currentStep - 1);
        }
    };

    const goBack = () => {
        history.push('/planner');
    };

    const finish = () => {
        // finish dipares send effect bellow
        setCustomerInfo(previous => ({ ...previous!, userID }));
        setSend(true);
    };

    const [send, setSend] = React.useState(false);
    const [sent, setSent] = React.useState(false);
    const [loading, setLoading] = React.useState(false);
    const dispatch = useDispatch();

    const [personalInfo, setPersonalInfo] = React.useState<ICustomerInfo>(customerInfoStored.personalInfo);
    const [contactInfo, setContactInfo] = React.useState<IContactInfo>(customerInfoStored.contactInfo);
    const [personalWorkInfo, setPersonalWorkInfo] = React.useState<IWorkInfo>(
        customerInfoStored.personalInfo.personalInfo.jobInfo
    );

    const [partnerWorkInfo, setPartnerWorkInfo] = React.useState<IWorkInfo>(
        customerInfoStored.personalInfo.partnerInfo.jobInfo
    );

    const [partnerInfo, setPartnerInfo] = React.useState<IPersonalData>(customerInfoStored.personalInfo.partnerInfo);
    React.useEffect(() => {
        if (send && !sent) {
            setLoading(true);
            api.post<ICustomerInfoRequest, string>('/clientinfo', customerInfo)
                .then(() => {
                    setSent(true);
                    dispatch(
                        ChangeCustomerInfo({
                            personalInfo: {
                                ...personalInfo,
                                personalInfo: { ...customerInfo.personalInfo },
                                partnerInfo: { ...customerInfo.partnerInfo },
                            },
                            contactInfo,
                        })
                    );
                    setErrLabel('');
                    history.push('/planner');
                })
                .catch(err => {
                    setLoading(false);
                    if (err.response) {
                        setErrLabel(`Erro salvando informações do cliente: ${err.response.data}`);
                    } else {
                        setErrLabel(`Erro interno do sistema: ${err}`);
                    }
                });
        }
    }, [customerInfo, send, sent, history, contactInfo, dispatch, partnerInfo, personalInfo, userID, personalWorkInfo]);

    const currentStepPercent = customerInfo.hasPartner ? currentStep : currentStep - 1;
    const percentage = (currentStepPercent / totalSteps) * 100;

    const [errLabel, setErrLabel] = React.useState('');

    const Steps = [
        <PersonalInfo
            defaultPersonalInfo={personalInfoStorage || personalInfo}
            setPersonalInfo={setPersonalInfo}
            nextStep={nextStep}
            goBack={goBack}
        />,
        <PartnerInfo
            personalInfo={partnerInfoStorage||partnerInfo}
            setPersonalInfo={setPartnerInfo}
            nextStep={nextStep}
            goBack={previousStep}
        />,
        <ContactInfo
            defaultContactInfo={contactInfoStorage||contactInfo}
            setContactInfo={setContactInfo}
            previousStep={previousStepCond}
            nextStep={nextStep}
            hasPartner={customerInfo.hasPartner}
        />,
        <WorkInfo
            key="work_personal_form_input"
            defaultWorkInfo={personalWorkInfoStorage||personalWorkInfo}
            setWorkInfo={setPersonalWorkInfo}
            previousStep={previousStep}
            nextStep={customerInfo.hasPartner ? nextStep : finish}
            errLabel={errLabel}
            loading={loading}
            hasPartner={customerInfo.hasPartner}
        />,
        <WorkInfo
            key="work_partner_form_input"
            defaultWorkInfo={partnerWorkInfoStorage|| partnerInfo.jobInfo}
            setWorkInfo={setPartnerWorkInfo}
            previousStep={previousStep}
            nextStep={finish}
            errLabel={errLabel}
            loading={loading}
            partner
        />,
    ];

    React.useEffect(() => {
        if (personalInfo) {
            setCustomerInfo(previousInfo => ({ ...previousInfo!, ...personalInfo! }));
        }
        if (contactInfo) {
            setCustomerInfo(previousInfo => ({ ...previousInfo!, ...contactInfo! }));
        }
        if (personalWorkInfo) {
            setCustomerInfo(previousInfo => ({
                ...previousInfo!,
                personalInfo: { ...previousInfo.personalInfo, jobInfo: personalWorkInfo },
            }));
        }

        if (partnerInfo) {
            setCustomerInfo(previousInfo => ({
                ...previousInfo,
                partnerInfo: { ...partnerInfo, jobInfo: previousInfo.partnerInfo.jobInfo },
            }));
        }

        if (partnerWorkInfo) {
            setCustomerInfo(previousInfo => ({
                ...previousInfo!,
                partnerInfo: { ...previousInfo.partnerInfo, jobInfo: partnerWorkInfo },
            }));
        }
    }, [personalInfo, contactInfo, personalWorkInfo, partnerInfo, partnerWorkInfo]);

    React.useEffect(() => {
        let currentShow = currentStep;
        if (!customerInfo.hasPartner) {
            currentShow = currentStep > 1 ? currentStep - 1 : currentStep;
        }
        changeTitle(`Passo ${currentShow} de ${totalSteps}`);
    }, [customerInfo.hasPartner, currentStep, totalSteps]);

    return (
        <div className={classes.content}>
            {/* <LinearProgress variant="determinate" value={percentage} color="primary"></LinearProgress> */}
            {Steps[currentStep - 1]}
        </div>
    );
};
export default CustomerInfo;
