import { ReduxAction } from '../../actions/reduxAction';
import { showYourNumber } from '../../actions/storageRedux';
import { IStorageRedux } from '../../types/storage';
import { initialState } from './initialState';

const loadState = (): IStorageRedux => {
    try {
        const serializedState = localStorage.getItem('state');
        if (serializedState === null) {
            return initialState;
        }
        return JSON.parse(serializedState);
    } catch (err) {
        return initialState;
    }
};

export const saveState = (state: IStorageRedux) => {
    try {
        const serializedState = JSON.stringify(state);
        localStorage.setItem('state', serializedState);
    } catch {
        // ignore write errors
    }
};

const storageReducer = (state = loadState(), action: ReduxAction<any>): IStorageRedux => {
    switch (action.type) {
        case showYourNumber:
            return { ...state, yourNumber: { ...state.yourNumber, [action.payload.userID]: action.payload.show } };
        default:
            return { ...state };
    }
};

export default storageReducer;
