import { Button, Chip, createStyles, makeStyles, Theme, Typography } from '@material-ui/core';
import { grey } from '@material-ui/core/colors';
import { default as React } from 'react';
import unmaskCurrency, { maskCurrency } from '../../../../utils/unmaskCurrency';
import MoneyAdornment from '../../../global/adornments/MoneyAdornment';
import { GreyedTextInput } from '../../../global/inputs/GreyedInput';
import AgeInput from '../../../global/masks/ageMaskedInput';
import CurrencyInput from '../../../global/masks/currencyMaskedInput';
import { IFinancialIndependence } from '../types';
import { useStyles } from './style';

interface IFIExpectationProps {
    defaultFI?: IFinancialIndependence;
    setFinancialIndependence: React.Dispatch<React.SetStateAction<IFinancialIndependence>>;
    errLabel: string;
    nextStep: () => void;
    previousStep: () => void;
    loading: boolean;
    currentAge: number;
}

const FIExpectation: React.FC<IFIExpectationProps> = ({
    defaultFI,
    setFinancialIndependence,
    nextStep,
    errLabel,
    previousStep,
    loading,
    currentAge,
}) => {
    const classes = useStyles();

    const [desiredIncoming, setDesiredIncoming] = React.useState(
        defaultFI?.desiredIncoming ? maskCurrency(defaultFI.desiredIncoming) : ''
    );
    const [age, setAge] = React.useState(defaultFI?.age || 0);
    const [lifeExpectation, setLifeExpectation] = React.useState(defaultFI?.lifeExpectation || 0);

    const onAgeChange = (val: string, setAgeFunc: React.Dispatch<React.SetStateAction<number>>) => {
        if (!val) {
            return;
        }
        const parsedInt = parseInt(val, 10);
        if (parsedInt) {
            setAgeFunc(parsedInt);
        }
    };
    const [btnDisabled, setBtnDisabled] = React.useState(false);

    const onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        setFinancialIndependence({
            desiredIncoming: unmaskCurrency(desiredIncoming),
            age,
            lifeExpectation,
        });
        nextStep();
        setBtnDisabled(true);
    };

    return (
        <div className={classes.root}>
            <div className={`mt-3 ${classes.head}`}>
                <Typography variant="h4" align="center" className={`mb-3 ${classes.header}`}>
                    Quando deseja alcançar sua Independência Financeira?
                </Typography>

                <form onSubmit={onSubmit} className={classes.form}>
                    <div>
                        <Typography className={classes.inputLabel} variant="body2">
                            Valor <i>mensal</i> desejado na Independência Financeira
                        </Typography>
                        <GreyedTextInput
                            required
                            color="primary"
                            variant="outlined"
                            size="small"
                            placeholder="0.00"
                            InputProps={{
                                startAdornment: <MoneyAdornment noMargin />,
                                inputComponent: CurrencyInput as any,
                            }}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            value={desiredIncoming}
                            onChange={event => setDesiredIncoming(event.target.value)}
                            fullWidth
                        />
                        <div className={classes.line}>
                            <div className='me-2 mt-4'>
                                <Typography className={classes.inputLabel} variant="body2">
                                    Idade
                                </Typography>
                                <GreyedTextInput
                                    disabled
                                    color="primary"
                                    variant="outlined"
                                    size="small"
                                    inputMode="numeric"
                                    InputProps={{
                                        inputComponent: AgeInput as any,
                                        endAdornment: <Typography style={{ marginRight: 8 }}>anos</Typography>,
                                    }}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    style={{ maxWidth: '28vw' }}
                                    value={currentAge}
                                    onChange={event => onAgeChange(event.target.value, setAge)}
                                    fullWidth
                                />
                            </div>
                            <div className='me-2 large-screen'>
                                <Typography className={classes.inputLabel} variant="body2">
                                    Idade desejada da Independência Financeira
                                </Typography>
                                <GreyedTextInput
                                    required
                                    color="primary"
                                    variant="outlined"
                                    size="small"
                                    inputMode="numeric"
                                    InputProps={{
                                        inputComponent: AgeInput as any,
                                        endAdornment: <Typography style={{ marginRight: 8 }}>anos</Typography>,
                                    }}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    style={{ maxWidth: '28vw' }}
                                    value={age}
                                    onChange={event => onAgeChange(event.target.value, setAge)}
                                    fullWidth
                                />
                            </div>
                            <div className='mt-4'>
                                <Typography className={classes.inputLabel} variant="body2">
                                    Expectativa de vida
                                </Typography>
                                <GreyedTextInput
                                    required
                                    color="primary"
                                    variant="outlined"
                                    size="small"
                                    placeholder=" anos"
                                    inputMode="numeric"
                                    InputProps={{
                                        inputComponent: AgeInput as any,
                                        endAdornment: <Typography style={{ marginRight: 8 }}>anos</Typography>,
                                    }}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    value={lifeExpectation}
                                    onChange={event => onAgeChange(event.target.value, setLifeExpectation)}
                                    fullWidth
                                />
                            </div>
                        </div>
                    </div>
                    <Typography align="center" color="error">
                        {errLabel}
                    </Typography>
                    <div className={classes.formControllers}>
                        {/* @ts-ignore */}
                        <Button
                            variant="contained"
                            className={classes.outButton}
                            size="medium"
                            color="default"
                            disableElevation
                            onClick={previousStep}
                        >
                            Voltar
                        </Button>
                        <button type="submit" className={classes.chipButton} disabled={btnDisabled}>
                            <Chip
                                className={classes.chip}
                                size="medium"
                                label="Finalizar"
                                color={loading ? 'secondary' : 'primary'}
                                clickable={!loading}
                            />
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
};
export default FIExpectation;
