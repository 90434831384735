import { createStyles, makeStyles, Theme } from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            '@media (min-width: 768px)': {
                padding: theme.spacing(0, 9, 0, 2),
            },
            '@media (max-width: 767px)': {
                padding: theme.spacing(0, 2, 0, 2),
            },
        },

        loading: {
            height: '100%',
            display: 'flex',
            justifyContent: 'center',
        },
        group: {
            '@media (min-width: 768px)': {
                backgroundColor: '#F8F8F8',
                width: '100%',
                borderRadius: theme.spacing(1),
                padding: theme.spacing(0, 2, 2),
            },
            '@media (max-width: 767px)': {
                backgroundColor: '#F8F8F8',
                width: '100%',
                borderRadius: theme.spacing(1),
                padding: theme.spacing(0, 2, 2),
            },
        },
        heading: {
            color: '#2C2F31',
            fontWeight: 600,
            paddingLeft: '5px',
        },
        content: {
            '@media (min-width: 768px)': {
                display: 'flex',
                flexDirection: 'row',
                marginTop: theme.spacing(2),
                marginLeft: theme.spacing(1),
                justifyContent: 'flex-start',
            },
            '@media (max-width: 767px)': {
                display: 'flex',
                flexDirection: 'column',
                marginTop: theme.spacing(2),
                marginLeft: theme.spacing(1),
                justifyContent: 'flex-start',
            },
        },
        ul: {
            listStyle: 'none',
            padding: 0,
        },
        a: {
            textDecoration: 'none',
            color: 'unset',

            '&:hover': {
                color: 'gray',
            },
        },
        grow: {
            flexGrow: 1,
        },
        basis: {
            flexBasis: '55%',
        },
        '& .text-blue': {
            color: '#55a1a8',
        },
        '& .top-0': {
            top: 0,
        },

        sidebar: {
            background: 'linear-gradient(180deg, #013e5a, #1f8e95) !important',
        },
        drawer: (props: { drawerWidth: string }) => ({
            '@media (min-width: 768px)': {
                width: props.drawerWidth,
                flexShrink: 0,
            },
        }),
        drawerPaper: (props: { drawerWidth: string }) => ({
            '@media (min-width: 768px)': {
                width: props.drawerWidth,
                borderRight: 'none',
                background: `linear-gradient(180deg, #023c58 0%, #065875 43%, #26999c 100%)`,
                borderRadius: '24px',
                height: '97vh',
                margin: '0.6vw',
            },
        }),
        buttonRoot: {
            color: 'white',
            fontFamily: 'Avenir Next Regular',
            fontSize: '14px',
            lineHeight: '15px',
            paddingLeft: theme.spacing(3),
            paddingTop: theme.spacing(1.5),
            paddingBottom: theme.spacing(1.5),
        },
        listItem: {
            marginBottom: theme.spacing(0.25),
        },
        svgIcon: {
            color: 'white',
            minWidth: '40px',
        },
        list: {
            marginTop: '10%',
        },
        logo: {
            marginLeft: theme.spacing(3),
            marginBottom: theme.spacing(3),
            width: 'auto',
        },
        listText: {
            fontSize: 14,
            lineHeight: '15px',
        },
        textSelected: {
            color: '#0DF2FF',
            fontSize: 14,
            lineHeight: '15px',
        },
        exit: {
            position: 'absolute',
            paddingBottom: theme.spacing(2),
        },
    })
);
