import React from 'react';
import MaskedInput from 'react-text-mask';

interface ITextMaskCustomProps {
    inputRef: (ref: HTMLInputElement | null) => void;
}

const CEPMaskedInput = (props: ITextMaskCustomProps) => {
    const { inputRef, ...other } = props;

    return (
        <MaskedInput
            required
            {...other}
            ref={(ref: any) => {
                inputRef(ref ? ref.inputElement : null);
            }}
            mask={[/\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/]}
            showMask
        />
    );
};

export default CEPMaskedInput;