import { CircularProgress } from '@material-ui/core';
import React, { useEffect } from 'react';
import { useStyles } from './style';
import { useHistory } from 'react-router';
import { IUserEntry } from '../../clientEntry';
import { Table } from './Table';

type Options = {
    value: IUserEntry;
    label: string;
    labelUser: string;
};

interface ICustomerTableProps {
    customers: IUserEntry[];
    loading: boolean;
    options: Options[];
    selectUser: (user: IUserEntry) => void;
    openModalChangePlanner: (user: IUserEntry) => void;
    fetchList: () => any;
}

export const TablePlanner: React.FC<ICustomerTableProps> = ({ customers, loading, openModalChangePlanner, selectUser }) => {
    const Total: string = 'https://cakeinvest-frontend.s3.amazonaws.com/total.svg';
    const Partial: string = 'https://cakeinvest-frontend.s3.amazonaws.com/partial.svg';
    const Rejected: string = 'https://cakeinvest-frontend.s3.amazonaws.com/rejected.svg';
    const NoSuggestion: string = 'https://cakeinvest-frontend.s3.amazonaws.com/no-suggestion.svg';

    const classes = useStyles();
    const history = useHistory();

    const createUser = () => {
        history.push('/planner/createuser');
    };

    const createPlanner = () => {
        history.push('/planner/createplanner');
    };

    const editPercent = () => {
        history.push('/planner/gainInfo');
    };

    return (
        <div className="row container-fluid mt-2 text-white ms-0 me-0 pb-4">
            <div className="col-md-12">
                <div className={classes.panel}>
                    <Table
                        customers={customers}
                        openModalChangePlanner={openModalChangePlanner}
                        loading={loading}
                        selectUser={selectUser}
                        createUser={createUser}
                        createPlanner={createPlanner}
                        editPercent={editPercent}
                        Total={Total}
                        Partial={Partial}
                        Rejected={Rejected}
                        NoSuggestion={NoSuggestion}
                    />
                </div>
            </div>
        </div>
    );
};