import { AppBar, ThemeProvider, Toolbar, Typography, useTheme } from '@material-ui/core';
import { useStyles, toolbarTheme } from './style';
import moment from 'moment';
import React from 'react';
import api from '../../../../../apis/dreamrichapi';
import { defaultProfileGain, IFinancialInfo } from '../../../../toolbarInfo/types';
import ValueColorText from '../../../displays/valueColorText/valueColorText';

const ToolbarInfoPlannerMobile: React.FC<{ drawerWidth: string; customerName: string; planningDate: Date }> = ({
    drawerWidth,
    customerName,
    planningDate,
}) => {
    const classes = useStyles({ drawerWidth });
    const theme = useTheme();
    const [financialInfo, setFinancialInfo] = React.useState<IFinancialInfo>({
        cdi: 0,
        ipca: 0,
        profileGain: defaultProfileGain,
        lastRefresh: new Date(),
        realGain: 0,
    });

    const pad = (n: number) => {
        return n < 10 ? '0' + n : n;
    };

    React.useEffect(() => {
        api.get<IFinancialInfo>('/financialinfo')
            .then(response => {
                setFinancialInfo(response.data);
            })
            .catch(e => {
                console.log(e);
            });
    }, []);

    const NewFinancialData = (title: string, value: number, institute: string, addcomma?: boolean) => (
        <div className={classes.line} style={{ marginLeft: theme.spacing(1) }}>
            <Typography className={classes.whiteInfos}>{title.toUpperCase()}</Typography>
            <ValueColorText type="percentage" value={value * 100} textColor={'#AAAAAA'}>
                a.a. {institute && `(${institute.toUpperCase()})`}
                {addcomma && ','}
            </ValueColorText>
        </div>
    );

    const formattedDate = moment(planningDate).format('DD/MM/yyyy');

    return (
        <ThemeProvider theme={toolbarTheme}>
            <AppBar position="fixed" className={classes.appBar} color={'transparent'} elevation={0} >
                <div className="d-flex position-absolute mt-3">
                    <Typography className={classes.whiteInfos}>Data de início: </Typography>
                    <Typography style={{ color: '#00878B' }}>{formattedDate}</Typography>
                    <Typography className={`${classes.clientName} text-capitalize`} color="primary">
                        {customerName.toLowerCase()}
                    </Typography>
                </div>
            </AppBar>
        </ThemeProvider>
    );
};

export default ToolbarInfoPlannerMobile;
