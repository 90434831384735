import { Button, InputAdornment, Paper, TextField, Typography, useTheme, } from '@material-ui/core';
import React from 'react';
import { CartesianGrid, ComposedChart, Label, Legend, Line, ResponsiveContainer, Text, Tooltip, XAxis, YAxis, } from 'recharts';
import unmaskCurrency, { maskCurrency } from '../../../../utils/unmaskCurrency';
import CurrencyInput from '../../../global/masks/currencyMaskedInput';
import DiffTooltip from '../diffTooltip/diffTooltip';
import { IInvestmentPortfolioProjection } from '../types';
import { useStyles } from './style';

const convertInvestmentProjectionToData = (projection: IInvestmentPortfolioProjection, currYear: number) => {
    const converted: IBarDataChart[] = projection.portfolioProjection.map((item, index) => ({
        name: `${currYear + index}`,
        Patrimônio: item.investmentAsset + item.interest,
        Sugerido:
            projection.suggestedProjection[index].investmentAsset + projection.suggestedProjection[index].interest,
        'Juros Sugerido': projection.suggestedProjection[index].interest,
    }));

    return converted;
};

interface IBarDataChart {
    name: string;
    Patrimônio: number;
    Sugerido: number;
    'Juros Sugerido': number;
}

interface IInvestmentProjectionProps {
    projection: IInvestmentPortfolioProjection;
    defaultContribution: number;
    controlFunction?: (pieChart: boolean, value: number, additionalValue?: number) => void;
}

const InvestmentProjection: React.FC<IInvestmentProjectionProps> = ({ projection, defaultContribution, controlFunction, }) => {
    const classes = useStyles();
    const theme = useTheme();

    const currentYear = new Date().getFullYear();
    const [data, setData] = React.useState<IBarDataChart[]>([]);
    // tslint:disable-next-line:no-console
    const ref = React.createRef<null | any>();
    const [position, setPosition] = React.useState(0);
    const [investmentProfile, setInvestmentProfile] = React.useState(projection.approach || 1);
    const [contribution, setContribution] = React.useState(maskCurrency(defaultContribution));

    const handleInputChange = (event: any) => {
         setContribution(event.target.value);
    };
    
    const handleChangeContribution = (e: React.FormEvent) => {
        e.preventDefault();
        const parsedContribution = unmaskCurrency(contribution);
        if (controlFunction !== undefined) controlFunction(false, investmentProfile, parsedContribution);
    };

    React.useEffect(() => {
        if (ref.current) {
            setPosition(ref.current.container.offsetWidth);
        }
    }, [ref, setPosition]);

    React.useEffect(() => {
        const parsedContribution = unmaskCurrency(contribution);

        if (controlFunction !== undefined) controlFunction(false, investmentProfile, parsedContribution);
    }, [contribution]);

    React.useEffect(() => {
        setData(convertInvestmentProjectionToData(projection, currentYear));
    }, [projection.portfolioProjection, setData]);

    return (
        <Paper className={classes.root}>
            <Typography className={classes.subHeading} variant="h6">Projeção</Typography>
            <>
                <form className={classes.form} autoComplete="off" onSubmit={handleChangeContribution}>
                    <Typography style={{ marginRight: '8px' }} variant="overline">Aporte Mensal Adicional</Typography>
                    <TextField
                        required
                        name="balance"
                        placeholder="000.00"
                        onChange={handleInputChange}
                        className={classes.textField}
                        InputProps={{
                            inputComponent: CurrencyInput as any,
                            startAdornment: <InputAdornment position="start">R$</InputAdornment>,
                        }}
                        variant="outlined"
                        size="small"
                    />
                {/* @ts-ignore */}
                    <Button type="submit" color="primary" onClick={handleChangeContribution}>OK</Button>
                </form>
            </>
            <div className={classes.innerPadding}>
                <ResponsiveContainer ref={ref} width="100%" height={350}>
                    <ComposedChart margin={{ right: 40, left: 40 }} data={data}>
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="name" padding={{ left: 12, right: 12 }} />
                        <Legend />
                        <YAxis
                            yAxisId="right"
                            orientation="right"
                            label={
                                ((
                                    <Text x={0} y={0} dx={position} dy={150} angle={-90}>
                                        Valor
                                    </Text>
                                ) as unknown) as Label
                            }
                            tickFormatter={tick => tick.toLocaleString()}
                        />
                        <Line
                            type="monotone"
                            yAxisId="right"
                            dataKey="Patrimônio"
                            stroke={theme.palette.secondary.light}
                        ></Line>
                        <Line yAxisId="right" dataKey="Sugerido" stroke={theme.palette.success.dark}></Line>
                        <Tooltip content={<DiffTooltip />} />
                    </ComposedChart>
                </ResponsiveContainer>
            </div>
        </Paper>
    );
};

export default InvestmentProjection;