import { createStyles, makeStyles, Theme } from '@material-ui/core';

export const useStyles = makeStyles(() =>
    createStyles({
        wrapper: {
            '@media (min-width: 768px)': {
                marginTop: '60px',
                marginLeft: '15%',
                marginBottom: '2%',
            },
            '@media (max-width: 767px)': {
                marginLeft: 0,
                marginBottom: '2%',
            },
        },
        grid: {
            '@media (min-width: 768px)': {
                paddingLeft: '2%',
            },
            '@media (max-width: 767px)': {
                paddingLeft: '0',
            },
        },

        loadingModal: {
            top: '100px',
            right: '50px',
            boxShadow: '0 0 30px 0 rgb(0 0 0 / 50%)',
            zIndex: 1,

            '&::after': {
                content: "''",
                position: 'absolute',
                bottom: '0',
                left: '0',
                zIndex: '-1',
                height: '100%',
                width: '5%',
                background: 'linear-gradient(to right, #034663, #218E96)',
                animation: '$loading 1.8s linear infinite',
            },
        },

        '@keyframes loading': {
            '25%': {
                width: '25%',
            },
            '35%': {
                width: '35%',
            },
            '45%': {
                width: '45%',
            },
            '55%': {
                width: '55%',
            },
            '65%': {
                width: '65%',
            },
            '75%': {
                width: '75%',
            },
            '85%': {
                width: '85%',
            },
            '100%': {
                width: '100%',
            },
        },
    })
);
