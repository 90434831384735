import { Paper, Typography } from '@material-ui/core';
import React from 'react';
import formatter from '../../../global/formater';
import { IEmergencySavings } from '../types';
import { useStyles } from './style'

interface IEmergencySavingsProsps extends IEmergencySavings {}

const EmergencySavings: React.FC<IEmergencySavingsProsps> = ({ duration, savings, investiment }) => {
    const classes = useStyles();
    const gap = investiment - savings > 0 ? 0 : investiment - savings;
    return (
        <>
            <Typography variant="h5">Reserva de emergência (diária por incapacidade temporária - DIT)</Typography>
            <Paper className={classes.root}>
                <div className={classes.child}>
                    <Typography variant="h6">Durante</Typography>
                    <Typography variant="h5" style={{ lineHeight: 1 }} color="primary">
                        {duration} meses
                    </Typography>
                    <Typography className={classes.overline} variant="overline">
                        custo fixo
                    </Typography>
                </div>
                <div className={classes.divider} />
                <div className={classes.child}>
                    <Typography variant="h6">Reserva de emergência necessária</Typography>
                    <Typography variant="h5" style={{ lineHeight: 1 }} color="primary">
                        {formatter.format(savings)}
                    </Typography>
                    <Typography className={classes.overline} variant="overline">
                        custo fixo
                    </Typography>
                </div>
                <div className={classes.divider} />
                <div className={classes.child}>
                    <Typography variant="h6">Investimentos com liquidez imediata</Typography>
                    <Typography variant="h5" style={{ lineHeight: 1 }} color="primary">
                        {formatter.format(investiment)}
                    </Typography>
                    <Typography className={classes.overline} variant="overline">
                        TOTAL
                    </Typography>
                </div>
                <Paper className={classes.gap} elevation={0}>
                    <div className={classes.gapContent}>
                        <div style={{ margin: 'auto' }}>
                            <Typography align="left" className={classes.whiteLabel}>
                                Gap de Risco
                            </Typography>
                            <Typography align="left" variant="h4" color="error">
                                {formatter.format(gap)}
                            </Typography>
                            <Typography>&nbsp;</Typography>
                        </div>
                    </div>
                </Paper>
            </Paper>
        </>
    );
};
export default EmergencySavings;
