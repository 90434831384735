import React from 'react';
import MaskedInput from 'react-text-mask';
// tslint:disable-next-line: no-submodule-imports
import createNumberMask from 'text-mask-addons/dist/createNumberMask';

const defaultMaskOptions = {
    prefix: '',
    includeThousandsSeparator: false,
    allowDecimal: false,
    decimalLimit: 0, 
    integerLimit: 3, 
    allowNegative: false,
    allowLeadingZeroes: false,
};

interface IMaskOptions {
    prefix: string;
    suffix: string;
    includeThousandsSeparator: boolean;
    thousandsSeparatorSymbol: string;
    allowDecimal: boolean;
    decimalSymbol: string;
    decimalLimit: string;
    requireDecimal: boolean;
    allowNegative: boolean;
    allowLeadingZeroes: boolean;
    integerLimit: number;
}

interface IAgeInputProps {
    inputRef: (ref: HTMLInputElement | null) => void;
    maskOptions: IMaskOptions;
}

const AgeInput = (props: IAgeInputProps) => {
    const { inputRef, ...other } = props;

    const ageMask = createNumberMask({
        ...defaultMaskOptions,
        ...props.maskOptions,
    });

    return (
        <MaskedInput
            mask={ageMask}
            {...other}
            ref={(ref: any) => {
                inputRef(ref ? ref.inputElement : null);
            }}
        />
    );
};

export default AgeInput;