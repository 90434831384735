import { createStyles, makeStyles, Theme } from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) =>

    createStyles({
        cardHeader: {
            fontWeight: 600,
            color: '#020202',
        },
        cardSubtitle: {
            fontSize: 12,
            color: '#020202',
        },
        card: {
            padding: '15px',
            flexShrink: 0,
            marginBottom: theme.spacing(2),
            width: '100%',
        },

        cardDisabled: {
            padding: '15px',
            flexShrink: 0,
            background: '#F2F4F5',
            marginBottom: theme.spacing(2),
            borderRadius: 0,
            width: '100%',
            opacity: 0.35,
        },
        formDiv: {
            width: '100%',
            marginTop: '5px',
            marginBottom: '5px',
        },
        textField: {
            width: '100%',
        },
    })
);