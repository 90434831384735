import {
    Button,
    Chip,
    createStyles,
    makeStyles,
    Modal,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TextField,
    Theme,
    Typography,
} from '@material-ui/core';
import { grey } from '@material-ui/core/colors';
import { Add, Delete, Edit, Home } from '@material-ui/icons';
import { default as React } from 'react';
import { useSelector } from 'react-redux';
import { IStore } from '../../../../redux/types/store';
import unmaskCurrency, { maskCurrency } from '../../../../utils/unmaskCurrency';
import MoneyAdornment from '../../../global/adornments/MoneyAdornment';
import formatter from '../../../global/formater';
import CurrencyInput from '../../../global/masks/currencyMaskedInput';
import House from '../house/house';
import { IHouse, IProperty } from '../types';
import { useStyles } from './style';
import store from '../../../../redux/store';

interface ITableRow {
    label: string;
    investimentFocus: boolean;
    marketValue: string;
    outstandingBalance: string;
}

interface IPropertiesProps {
    defaultProperties: IProperty[];
    defaultHouse: IHouse;
    setHouseValue: React.Dispatch<React.SetStateAction<IHouse>>;
    setPropertiesValues: React.Dispatch<React.SetStateAction<IProperty[]>>;
    previousStep: () => void;
    nextStep: () => void;
}

const Properties: React.FC<IPropertiesProps> = ({
    defaultProperties,
    setPropertiesValues,
    nextStep,
    previousStep,
    defaultHouse,
    setHouseValue,
}) => {
    const classes = useStyles();

    const { customerProperties } = useSelector((store: IStore) => ({ customerProperties: store.planning.customerFormValues.patrimony.properties }))

    const [hasProperties, sethasProperties] = React.useState(defaultProperties.length > 0 || customerProperties.length > 0);
    const [addedLine, setAddedLine] = React.useState(false);
    const [ready, setReady] = React.useState(false);

    const [tableRows, setTableRows] = React.useState<ITableRow[]>(
        defaultProperties.length
            ? defaultProperties.map(item => ({
                ...item,
                marketValue: `R$ ${maskCurrency(item.marketValue)}`,
                outstandingBalance: `R$ ${maskCurrency(item.outstandingBalance)}`,
            }))
            : [{ label: '', investimentFocus: false, marketValue: '', outstandingBalance: '' }]
    );

    const addLine = () => {
        setAddedLine(true);
        setTableRows([...tableRows, { label: '', investimentFocus: false, marketValue: '', outstandingBalance: '' }]);
    };

    const handleInputChange = (field: keyof ITableRow, value: string, rowIndex: number) => {
        setTableRows(tableRows.map((item, i) => (i === rowIndex ? { ...item, [field]: value } : item)));
    };

    const handleInvestimentFocus = (isInvestiment: boolean, rowIndex: number) => {
        setTableRows(rows =>
            rows.map((item, i) => (i === rowIndex ? { ...item, investimentFocus: isInvestiment } : item))
        );
    };

    const [house, setHouse] = React.useState<IHouse>(defaultHouse);
    const [btnDisabled, setBtnDisabled] = React.useState(false);

    const onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        const propValues = hasProperties
            ? tableRows.map(row => {
                return {
                    label: row.label,
                    investimentFocus: row.investimentFocus,
                    marketValue: unmaskCurrency(row.marketValue),
                    outstandingBalance: unmaskCurrency(row.outstandingBalance),
                };
            })
            : [];
        setPropertiesValues(propValues);
        setHouseValue(hasProperties ? house : defaultHouse);
        nextStep();
        setBtnDisabled(true);
    };

    const changeMouseHover = (hover: boolean, index: number) => {
        setMouseHover(rows => rows.map((_, i) => (index === i ? hover : false)));
    };

    const removeRow = (index: number) => {
        // create a new array without index entry
        setTableRows((rows: Array<any>) => {
            const isLastItem = rows.length == index;
            if (isLastItem) {
                rows.pop();
                return rows;
            }
            return rows.filter((value, indice) => index != indice);
        });
    };

    const [modalOpen, setModalOpen] = React.useState(false);
    const modalEnd = (h: IHouse) => {
        setHouse(h);
        setModalOpen(false);
    };

    const [mouseHover, setMouseHover] = React.useState([false, ...tableRows.map(row => false)]);
    React.useEffect(() => {
        setMouseHover([false, ...tableRows.map(() => false)]);
    }, [tableRows]);

    React.useEffect(() => {
        if (addedLine) {
            setReady(true);
        }
    }, [mouseHover]);

    const showRowDel = async (row: number) => {
        setMouseHover(rows => rows.map((_, i) => row === i));
        setReady(false);
        setAddedLine(false);
        await new Promise(r => setTimeout(r, 4000));
        setMouseHover(rows => rows.map(item => false));
    };

    const deleteHouse = () => {
        setHouse({
            forSell: false,
            value: 0,
            debit: 0,
            date: new Date(),
        });
    };

    React.useEffect(() => {
        showRowDel(tableRows.length - 1);
    }, [ready]);

    const canAddHouse = () => {
        return true;
    };

    return (
        <div className={classes.root}>
            <div className={classes.head}>
                <Typography variant="h4" className={classes.header}>
                    Tem algum ativo imobilizado ou móvel?
                </Typography>
                <div className={classes.subheader}>
                    <Typography align="center" variant="body2">
                        Ex. Carros, Motos, Moradia, Cotas Societárias, etc.
                    </Typography>
                </div>
                <div style={{ margin: '16px 0' }} className={classes.selectButton}>
                    {/* @ts-ignore */}
                    <Button
                        variant="outlined"
                        color={hasProperties ? 'primary' : 'default'}
                        disableElevation
                        onClick={() => sethasProperties(true)}
                    >
                        SIM
                    </Button>
                    {/* @ts-ignore */}
                    <Button
                        disableElevation
                        variant="outlined"
                        color={!hasProperties ? 'primary' : 'default'}
                        onClick={() => sethasProperties(false)}
                    >
                        NÃO
                    </Button>
                </div>
            </div>
            <form onSubmit={onSubmit}>
                {hasProperties ? (
                    <TableContainer component={Paper}>
                        <Table className={classes.table}>
                            <TableHead>
                                <TableRow>
                                    <TableCell style={{ minWidth: 200 }} align="left">
                                        Nome do ativo
                                    </TableCell>
                                    <TableCell align="center" style={{ minWidth: 300 }}>
                                        Foco em investimento
                                    </TableCell>
                                    <TableCell align="right">Valor de mercado(R$)</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {house.value > 0 ? (
                                    <TableRow style={{ overflow: 'hidden' }}>
                                        <TableCell align="left">Moradia/Lote</TableCell>
                                        <TableCell align="left">-</TableCell>
                                        <TableCell align="right">{formatter.format(house.value)}</TableCell>
                                        {house?.value ? (
                                            <div
                                                className={classes.deleteHidden}
                                                onMouseEnter={() => changeMouseHover(true, 0)}
                                                onMouseLeave={() => changeMouseHover(false, 0)}
                                                //@ts-ignore
                                                onClick={deleteHouse}
                                                style={{
                                                    borderLeft: !mouseHover[0] ? '9px solid #f44336' : '',
                                                    height: 50,
                                                }}
                                            >
                                                {mouseHover[0] ? (
                                                    <Delete
                                                        style={{ marginTop: '100%', marginRight: '5%' }}
                                                        color="action"
                                                    />
                                                ) : null}
                                            </div>
                                        ) : null}
                                    </TableRow>
                                ) : null}
                                {tableRows.map((row, i) => (
                                    <TableRow key={i.toString()} style={{ overflow: 'hidden' }}>
                                        <TableCell>
                                            <TextField
                                                autoFocus
                                                required
                                                fullWidth
                                                color="primary"
                                                placeholder="Ativo"
                                                value={row.label}
                                                onChange={event => handleInputChange('label', event.target.value, i)}
                                            />
                                        </TableCell>
                                        <TableCell align="center">
                                            <div className={classes.selectButton}>
                                                {/* @ts-ignore */}
                                                <Button
                                                    variant="outlined"
                                                    color={row.investimentFocus ? 'primary' : 'default'}
                                                    disableElevation
                                                    onClick={() => handleInvestimentFocus(true, i)}
                                                >
                                                    SIM
                                                </Button>
                                                {/* @ts-ignore */}
                                                <Button
                                                    disableElevation
                                                    variant="outlined"
                                                    color={!row.investimentFocus ? 'primary' : 'default'}
                                                    onClick={() => handleInvestimentFocus(false, i)}
                                                >
                                                    NÃO
                                                </Button>
                                            </div>
                                        </TableCell>
                                        <TableCell align="right">
                                            <TextField
                                                required
                                                color="primary"
                                                placeholder="0.00"
                                                inputMode="numeric"
                                                InputProps={{
                                                    inputComponent: CurrencyInput as any,
                                                    startAdornment: <MoneyAdornment />,
                                                }}
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                inputProps={{
                                                    style: { textAlign: 'right' },
                                                    allowNegative: true,
                                                }}
                                                value={row.marketValue}
                                                onChange={event =>
                                                    handleInputChange('marketValue', event.target.value, i)
                                                }
                                            />
                                        </TableCell>
                                        <div
                                            className={classes.deleteHidden}
                                            onMouseEnter={() => changeMouseHover(true, i + 1)}
                                            onMouseLeave={() => changeMouseHover(false, i + 1)}
                                            //@ts-ignore
                                            onClick={() => removeRow(i + 1)}
                                            style={{ borderLeft: !mouseHover[i + 1] ? '9px solid #f44336' : '' }}
                                        >
                                            {mouseHover[i + 1] ? (
                                                <Delete
                                                    style={{ marginTop: '100%', marginRight: '5%' }}
                                                    color="action"
                                                />
                                            ) : null}
                                        </div>
                                    </TableRow>
                                ))}
                                <TableRow>
                                    <TableCell>
                                        {/* @ts-ignore */}
                                        <Chip
                                            variant="outlined"
                                            color="primary"
                                            size="medium"
                                            label="Adicionar nova linha"
                                            clickable
                                            onClick={addLine}
                                            onDelete={addLine}
                                            deleteIcon={<Add />}
                                        />
                                    </TableCell>
                                    <TableCell align="center" colSpan={2}>
                                        {/* @ts-ignore */}
                                        <Chip
                                            disabled={!canAddHouse}
                                            variant="outlined"
                                            color="primary"
                                            size="medium"
                                            label={house?.value > 0 ? 'Editar moradia/lote' : 'Adicionar moradia/lote'}
                                            clickable
                                            onClick={() => setModalOpen(true)}
                                            onDelete={() => setModalOpen(true)}
                                            deleteIcon={house?.value > 0 ? <Edit /> : <Home />}
                                        />
                                    </TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>
                ) : null}
                <div className={classes.formControllers}>
                    {/* @ts-ignore */}
                    <Button
                        variant="contained"
                        className={classes.outButton}
                        size="medium"
                        color="default"
                        disableElevation
                        onClick={previousStep}
                    >
                        Voltar
                    </Button>
                    <button disabled={btnDisabled} type="submit" className={classes.chipButton}>
                        <Chip className={classes.chip} color="primary" size="medium" label="Próximo" clickable />
                    </button>
                </div>
            </form>

            <Modal
                open={modalOpen}
                onClose={() => setModalOpen(false)}
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
            >
                <House value={house} onEnd={modalEnd} />
            </Modal>
        </div>
    );
};
export default Properties;
