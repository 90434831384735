import { createStyles, makeStyles, Theme } from '@material-ui/core';
import { grey, red } from '@material-ui/core/colors';

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        header: {
            marginTop: theme.spacing(4),
            fontWeight: 600,
            fontSize: 26,
        },
        subHeader: {
            margin: `${theme.spacing(1)}px auto ${theme.spacing(4)}px`,
            width: '30vw',
        },
        row: {
            backgroundColor: '#F4F1F0',
            '&:hover': {
                backgroundColor: '#AFAFAF',
                cursor: 'pointer',
            },
        },
        button: {
            backgroundColor: '#022536',
            '&:hover': {
                backgroundColor: '#5665FF',
                cursor: 'pointer',
            },
            marginBottom: theme.spacing(2),
        },
        paper: {
            position: 'absolute',
            width: 500,
            backgroundColor: theme.palette.background.paper,
            boxShadow: theme.shadows[5],
            padding: theme.spacing(2, 4, 3),
            top: `50%`,
            outline: 0,
            left: `50%`,
            transform: `translate(-50%, -50%)`,
        },
        line: {
            marginTop: theme.spacing(2),
            display: 'flex',
            alignItems: 'center',
            color: '#000000',
        },
        noPaddingLine: {
            display: 'flex',
            alignItems: 'flex-end',
            color: '#000000',
            marginTop: -theme.spacing(1),
        },
        deleteButton: {
            color: 'white',
            marginRight: theme.spacing(2),
            backgroundColor: '#F84F47 !important',
            '&:hover': {
                backgroundColor: 'red !important',
                cursor: 'pointer !important',
            },
        },
        select: {
            minWidth: 250,
            marginLeft: '8px',
        },
        modalInput: {
            margin: theme.spacing(2, 2, 0, 0),
        },
        frase: {
            display: 'flex',
            marginRight: 10,
            fontSize: '16px',
            height: 20,
            justifyContent: 'center',
            alignItems: 'center',
        },
        marginRight: {
            marginRight: 4,
        },
    })
);
