import { Grid, LinearProgress, Paper, Typography } from '@material-ui/core';
import React from 'react';
import DisplayWithBag from '../../../global/displays/displayWithBag/displayWithBag';
import { IObjective } from '../types/objectiveType';
import { useStyle } from './style';

const sortObjectives = (a: IObjective, b: IObjective) => b.percentage - a.percentage;

interface IObjectivesProps { className?: string; goals: IObjective[]; totalGoals: number; }

const periodicityLabel = (goal: IObjective) => {
    if (goal.periodicity.unique) {
        return goal.firstYear.toString();
    }

    let periodStr = `de ${goal.periodicity.period} em ${goal.periodicity.period} `;

    switch (goal.periodicity.timeUnit) {
        case 'month':
            if (goal.periodicity.period === 1) {
                return 'mensal';
            }
            return periodStr + 'meses';
        case 'year':
            if (goal.periodicity.period === 1) {
                return 'anual';
            }
            return periodStr + 'anos';
    }
};

const Objectives: React.FC<IObjectivesProps> = ({ className, goals, totalGoals }) => {
    const classes = useStyle();

    return (
        <div className={className}>
            <div className={classes.root}>
                {
                    window.screen.width <= 767
                        ?
                        <>
                            <Paper className={classes.paper}>
                                <Typography className={classes.cardHeader}>Seus Objetivos até a independência financeira</Typography>
                                {
                                    goals
                                        .sort(sortObjectives)
                                        .map((item, _, sorted) => ({
                                            ...item,
                                            percentage: (item.totalUntilIndependence / sorted[0].totalUntilIndependence) * 100,
                                        }))
                                        .map((item, i) => (
                                            <Grid key={item.label + i} justify="space-between" container className={classes.objective}>
                                                <div className={classes.legendMobile}>
                                                    <Grid item xs={5} id={`${item.value + item.percentage}`}><Typography align="center">{item.label}</Typography></Grid>
                                                    <Grid item xs={7}>
                                                        <Typography variant="body2" align="right" style={{ paddingRight: '5px' }}>
                                                            {item.value.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}  {periodicityLabel(item)}
                                                        </Typography>
                                                    </Grid>
                                                </div>
                                                <Grid item xs={12}>
                                                    <Typography className={classes.lastInnerLabel} align="center">
                                                        Total na IF: {item.totalUntilIndependence.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <LinearProgress variant="determinate" value={item.percentage} color="primary" />
                                                </Grid>
                                            </Grid>
                                        ))
                                }
                            </Paper>
                            <DisplayWithBag label="Todos seus objetivos custam hoje" value={totalGoals} />
                        </>
                        :
                        <>
                            <Paper className={classes.paper}>
                                <Typography className={classes.cardHeader}>
                                    Seus Objetivos até a independência financeira
                                </Typography>
                                <Grid container>
                                    <Grid item xs={3}>
                                        <Typography className={classes.firstOuterLabel}>Objetivos</Typography>
                                    </Grid>
                                    <Grid item xs={3}>
                                        <Typography align="right" className={classes.middleOuterLabel}>
                                            Valor (R$)
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={3}>
                                        <Typography align="right" className={classes.middleOuterLabel}>
                                            Periodicidade
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={3}>
                                        <Typography align="right" className={classes.lastOuterLabel}>
                                            Total na IF (R$)
                                        </Typography>
                                    </Grid>
                                </Grid>

                                {goals
                                    .sort(sortObjectives)
                                    .map((item, _, sorted) => ({
                                        ...item,
                                        percentage: (item.totalUntilIndependence / sorted[0].totalUntilIndependence) * 100,
                                    }))
                                    .map((item, i) => (
                                        <Grid key={item.label + i} justify="space-between" container className={classes.objective}>
                                            <Grid item xs={3} id={`${item.value + item.percentage}`}>
                                                <Typography>{item.label}</Typography>
                                            </Grid>
                                            <Grid item xs={3}>
                                                <Typography variant="body2" align="right">
                                                    {item.value.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={3}>
                                                <Typography variant="body2" align="right">
                                                    {periodicityLabel(item)}
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={3}>
                                                <Typography className={classes.lastInnerLabel} align="right">
                                                    {item.totalUntilIndependence.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <LinearProgress variant="determinate" value={item.percentage} color="primary" />
                                            </Grid>
                                        </Grid>
                                    ))}
                            </Paper>
                            <DisplayWithBag label="Todos seus objetivos custam hoje" value={totalGoals} />
                        </>
                }
            </div>
        </div>
    );
};

export default Objectives;
