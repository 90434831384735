import { Button, Chip, createStyles, makeStyles, Theme, Typography } from '@material-ui/core';
import { grey } from '@material-ui/core/colors';
import React from 'react';
import formatter from '../../../global/formater';
import TableCost from '../../../global/inputs/tableCost/tableCost';
import CurrencyInput from '../../../global/masks/currencyMaskedInput';
import { IOtherValues, NewOtherValues } from '../types';
import { useStyles } from './style';

interface IOtherCostProps {
    defaultOtherValues?: IOtherValues;
    setOtherValues: React.Dispatch<React.SetStateAction<IOtherValues>>;
    errLabel: string;
    previousStep: () => void;
    nextStep: () => void;
    loading: boolean;
    total: number;
}

const OtherCost: React.FC<IOtherCostProps> = ({
    defaultOtherValues,
    setOtherValues,
    nextStep,
    errLabel,
    previousStep,
    loading,
    total,
}) => {
    const classes = useStyles();
    const [otherCosts, setOtherCosts] = React.useState(NewOtherValues(defaultOtherValues));
    const [totalState, setTotalState] = React.useState(total);
    const costsLabel = Object.values(otherCosts).map((cost): [string, number] => [cost.label, cost.value]);

    const setTableValues = (values: Map<string, number>) => {
        const oc = { ...otherCosts };
        let sum = 0;
        values.forEach((val, key) => {
            const objectKeyForLabel = Object.entries(oc).filter(([_, ocval]) => ocval.label === key)![0]![0];
            if (objectKeyForLabel) {
                const keyEntry = objectKeyForLabel as keyof IOtherValues;
                oc[keyEntry] = {
                    label: oc[keyEntry].label,
                    value: val,
                };
                sum += val;
            }
        });
        setTotalState(total + sum);
        setOtherCosts(oc);
    };
    const [btnDisabled, setBtnDisabled] = React.useState(false);

    const onSumbit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        const otherValue: IOtherValues = {
            tithe: otherCosts.tithe.value,
            other: otherCosts.other.value,
            pet: otherCosts.pet.value,
            alimony: otherCosts.alimony.value,
            classCouncil: otherCosts.classCouncil.value,
        };
        setOtherValues(otherValue);
        nextStep();
        setBtnDisabled(true);
    };
    return (
        <div className={classes.root}>
            <form onSubmit={onSumbit}>
                <div className={classes.content}>
                    <TableCost
                        risk=''
                        className={classes.table}
                        showTotal
                        header="Demais custos"
                        rows={costsLabel}
                        tableValues={setTableValues}
                        inputComponent={CurrencyInput as any}
                    ></TableCost>
                    <Typography align="center" color="error">
                        {errLabel}
                    </Typography>
                    <div className={classes.total}>
                        <Typography align="left" style={{ marginRight: '16px' }}>
                            Custo fixo total
                        </Typography>
                        <Typography>{formatter.format(totalState)}</Typography>
                    </div>
                    <div className={classes.formControllers}>
                    {/* @ts-ignore */}
                        <Button
                            variant="contained"
                            className={classes.outButton}
                            size="medium"
                            color="default"
                            disableElevation
                            onClick={previousStep}
                        >
                            Voltar
                        </Button>
                        <button disabled={btnDisabled} type="submit" className={classes.chipButton}>
                            <Chip
                                className={classes.chip}
                                size="medium"
                                label="Próximo"
                                color={loading ? 'secondary' : 'primary'}
                                clickable={!loading}
                            />
                        </button>
                    </div>
                </div>
            </form>
        </div>
    );
};
export default OtherCost;
