import { createStyles, makeStyles, Theme } from '@material-ui/core';
import { grey } from '@material-ui/core/colors';

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            marginTop: theme.spacing(2),
        },
        header: {
            fontWeight: 600,
            fontSize: 26,
            marginTop: theme.spacing(6),
            marginBottom: theme.spacing(2),
            
        },
        subHeader: {
            margin: theme.spacing(3, 0),
        },
        topInfo: {
            padding: '0 5vw',
        },
        input: {},
        form: {
            padding: theme.spacing(0, 6, 2),
            '& > :not(:first-child)': {
                marginTop: theme.spacing(2),
            },
        },
        sendControl: {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-end',
            alignItems: 'center',
            alignContent: 'flex-end',
        },
        submitButton: {
            color: 'transparent',
            borderColor: 'transparent',
            backgroundColor: 'transparent',
            padding: 0,
            marginTop: theme.spacing(2),
        },
        chip: {
            backgroundColor: theme.palette.primary.dark,
            color: 'white',
        },
        chipDisabled: {
            backgroundColor: theme.palette.grey[300],
            color: 'white',
        },
    })
);
