import { createStyles, makeStyles, Theme } from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) =>
createStyles({
    tableHeader: {
        fontWeight: 600,
    },
    table: {
        minWidth: 720,
    },
    deleteHidden: {
        backgroundColor: theme.palette.error.main,
        height: '50px',
        borderTopRightRadius: '5px',
        borderBottomRightRadius: '5px',
        float: 'right',
        position: 'absolute',
    },
    editFin: {
        cursor: 'pointer'
    },
    paper: {
        position: 'absolute',
        width: 500,
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
        top: `50%`,
        outline: 0,
        left: `50%`,
        transform: `translate(-50%, -50%)`,
    },
    selectButton: {
        backgroundColor: '#ffffff',  
        display: 'flex',
        flexFlow: 'row wrap',
        justifyContent: 'flex-start',
        alignItems: 'flex-end',
        margin: '-5px',
    },
    buttonItem: {
        margin: '5px',
    },
    dateInput: {
        minWidth: 170,
    },
})
);