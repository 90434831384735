import { Button, InputAdornment, Paper, TextField, Typography } from '@material-ui/core';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    Area,
    Bar,
    CartesianGrid,
    Cell,
    ComposedChart,
    Legend,
    ResponsiveContainer,
    Tooltip,
    XAxis,
    YAxis,
} from 'recharts';
import { ChangeSuccessionWithdraw } from '../../../../redux/actions/customerActions';
import { IStore } from '../../../../redux/types/store';
import unmaskCurrency from '../../../../utils/unmaskCurrency';
import CustomTooltip from '../../../global/charts/customTooltip/customTooltip';
import CurrencyInput from '../../../global/masks/currencyMaskedInput';
import { IPatrimonyProjection, IProjection } from '../../flow/types';
import { useStyles } from './style';

const convertProjectionToData = (projection: IProjection, currentYear: number) =>
    projection.objectivesProjection
        .map<[number, IPatrimonyProjection]>((item, i) => [item, projection.patrimonyProjection[i]])
        .map(([objectiveCost, patrimonyProjection], i) => {
            const negative = patrimonyProjection.interest < 0 || patrimonyProjection.investmentAsset < 0;
            let patrimony = patrimonyProjection.investmentAsset;
            if (negative) {
                patrimony = patrimonyProjection.interest + patrimonyProjection.investmentAsset;
            }

            return {
                name: `${currentYear + i}`,
                renda: objectiveCost,
                juros: negative ? 0 : patrimonyProjection.interest,
                patrimônio: patrimony,
            };
        });

interface IPatrimonyDurationProps {
    patrimonyProjection: IProjection;
    duration: number;
}

const PatrimonyDuration: React.FC<IPatrimonyDurationProps> = ({ patrimonyProjection, duration }) => {
    const classes = useStyles();
    let duracao = 0;

    const successionWithdraw = useSelector((store: IStore) => store.customer.successionWithdraw);
    const [monthlyOutputValue, setMonthlyOutputValue] = React.useState<string | null>(null);

    React.useEffect(() => {
        if (monthlyOutputValue === null && successionWithdraw) {
            setMonthlyOutputValue(successionWithdraw.toString());
        }
    }, [successionWithdraw, setMonthlyOutputValue]);

    const currentYear = new Date().getFullYear();
    const [data, setData] = React.useState(convertProjectionToData(patrimonyProjection, currentYear));
    React.useEffect(() => {
        setData(convertProjectionToData(patrimonyProjection, currentYear));
    }, [patrimonyProjection]);

    const dispatch = useDispatch();
    const onClick = () => {
        if (monthlyOutputValue !== null) {
            const value = unmaskCurrency(monthlyOutputValue);
            dispatch(ChangeSuccessionWithdraw(value || 0));
        }
    };

    return (
        <div>
            <Paper className={classes.content}>
                <Typography variant="h6">Duração do patrimônio atual (Juros real: 4% a.a.)</Typography>
                <div>
                    <ResponsiveContainer width="100%" height={220}>
                        <ComposedChart margin={{ right: 40, left: 40 }} data={data}>
                            <defs>
                                <linearGradient id="colorUv" x1="0%" y1="100%" x2="0%" y2="0%">
                                    <stop offset="0%" stopColor="#26999c" />
                                    <stop offset="63%" stopColor="#065875" />
                                    <stop offset="100%" stopColor="#023c58" />
                                </linearGradient>
                            </defs>
                            <CartesianGrid strokeDasharray="3 3" />
                            <XAxis dataKey="name" padding={{ left: 12, right: 12 }} />
                            <Tooltip content={<CustomTooltip />} />
                            <Legend
                                verticalAlign="top"
                                align="left"
                                wrapperStyle={{ paddingTop: '12px', paddingBottom: '16px' }}
                            />
                            <YAxis yAxisId="left" scale="sqrt" tickFormatter={tick => tick.toLocaleString()} />
                            <Bar barSize={10} stackId={'a'} yAxisId="left" dataKey="patrimônio" fill="url(#colorUv)">
                                {data.map((item, idx) => (
                                    <Cell
                                        key={`cell-${idx}`}
                                        fill={item.patrimônio > 0 ? 'url(#colorUv)' : '#ff422d'}
                                        {
                                            // @ts-ignore
                                            ...(item.patrimônio < 0 && duracao === 0
                                                ? (duracao = parseInt(item.name) - currentYear)
                                                : null)
                                        }
                                    />
                                ))}
                            </Bar>
                            <Bar barSize={10} stackId={'a'} yAxisId="left" dataKey="juros" fill="#ffa726" />
                            <Area type="monotone" yAxisId="left" dataKey="renda" fill="#f1f8e9" />
                        </ComposedChart>
                    </ResponsiveContainer>
                </div>
                <div className={classes.valueInput}>
                    <Typography variant="body2" style={{ marginRight: '8px' }}>
                        Qualidade de vida
                    </Typography>
                    <TextField
                        className={classes.input}
                        variant="outlined"
                        type="numeric"
                        color="primary"
                        value={monthlyOutputValue}
                        size="small"
                        margin="dense"
                        InputProps={{
                            inputComponent: CurrencyInput as any,
                            startAdornment: <InputAdornment position="start">R$</InputAdornment>,
                        }}
                        onChange={event => setMonthlyOutputValue(event.target.value || '')}
                        onKeyPress={ev => {
                            if (ev.key === 'Enter') {
                                onClick();
                            }
                        }}
                    />
                {/* @ts-ignore */}
                    <Button color="primary" variant="contained" onClick={onClick}>
                        Simular
                    </Button>
                </div>
                <div className={classes.duration}>
                    <Typography variant="body2" style={{ marginRight: 8 }}>
                        Duração {duracao > 0 ? duracao + ' anos' : 'vitalício'}
                    </Typography>
                </div>
            </Paper>
        </div>
    );
};
export default PatrimonyDuration;
