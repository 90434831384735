import { Button, Chip, Typography } from '@material-ui/core';
import React, { Dispatch, FC, SetStateAction, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { IStore } from '../../../../redux/types/store';
import InsuranceInput from '../insuranceInput/insuranceInput';
import InsuranceTable from '../insuranceTable/insuranceTable';
import { IInsurance } from '../types';
import { useStyles } from './style';

interface IInsuranceProps {
    defaultInsurance: IInsurance[];
    setInsurance: Dispatch<SetStateAction<IInsurance[]>>;
    previousStep: () => void;
    nextStep: () => void;
    errLabel: string;
}

export const insurance_categories = new Map([
    ['resgatable_life_insurance', 'Seguro de vida vitalício'],
    ['temporary_life_insurance', 'Seguro de vida temporário'],
    ['decreasing_lift_insurance', 'Seguro de vida decrescente'],
    ['accidental_death', 'Morte acidental'],
    ['accidental_invalidity', 'Invalidez parcial'],
    ['accidental_partial_invalidity', 'Invalidez total'],
    ['hospitalar_incoming', 'Renda hospitalar'],
    ['serious_illness', 'Doenças graves'],
    ['dit', 'DIT'],
]);

const Insurance: FC<IInsuranceProps> = ({ defaultInsurance, setInsurance, nextStep, previousStep, errLabel }) => {
    const classes = useStyles();
    const [insuranceRows, setInsuranceRow] = useState<IInsurance[]>(
        defaultInsurance.length
            ? defaultInsurance.map(item => ({
                ...item,
            }))
            : []
    );

    const removeRow = (index: number) => {
        // create a new array without index entry
        setInsuranceRow(rows => [...rows.slice(0, index), ...rows.slice(index + 1)]);
    };

    const { customerInsurance } = useSelector((store: IStore) => ({ customerInsurance: store.planning.customerFormValues.riskManagement.insurance }))

    const [hasInsurance, setHasInsurance] = useState(defaultInsurance.length > 0 || customerInsurance.length > 0);
    const onAddLine = (insuranceInput: IInsurance) => {
        setInsuranceRow(previousInsurance => [...previousInsurance, insuranceInput]);
    };

    useEffect(() => {
        setInsurance(insuranceRows);
    }, [insuranceRows, setInsurance]);
    const [btnDisabled, setBtnDisabled] = useState(false);


    const onFinish = () => {
        setInsurance(insuranceRows);
        nextStep();

        setBtnDisabled(true);
    };
    return (
        <div className='pb-4'>
            <div className={classes.head}>
                <Typography variant="h4" className={`${classes.header} mb-2`}>
                    Seguros já contratados
                </Typography>

                <div className={classes.selectButton}>
                    {/* @ts-ignore */}
                    <Button
                        variant="contained"
                        color={hasInsurance ? 'primary' : 'default'}
                        disableElevation
                        onClick={() => setHasInsurance(true)}
                    >
                        SIM
                    </Button>
                    {/* @ts-ignore */}
                    <Button
                        disableElevation
                        variant="contained"
                        color={!hasInsurance ? 'secondary' : 'default'}
                        onClick={() => setHasInsurance(false)}
                    >
                        NÃO
                    </Button>
                </div>
            </div>
            {hasInsurance ? (
                <div className='row'>
                    <div className='col-md-12'>
                        <InsuranceInput onClickAdd={onAddLine} />
                    </div>
                    <div className='col-md-12 mt-5'>
                        <InsuranceTable insuranceRows={insuranceRows} remove={removeRow} />
                        <Typography color="error" align="center" style={{ paddingTop: '16px' }}>
                            {errLabel}
                        </Typography>
                    </div>
                </div>
            ) : null}
            <div className={classes.formControllers}>
                {/* @ts-ignore */}
                <Button
                    variant="contained"
                    className={classes.outButton}
                    size="medium"
                    color="default"
                    disableElevation
                    onClick={previousStep}
                >
                    Voltar
                </Button>
                {/* @ts-ignore */}
                <Chip
                    disabled={btnDisabled}
                    color="primary"
                    size="medium"
                    onClick={onFinish}
                    label="Finalizar"
                    clickable
                />
            </div>
        </div>
    );
};
export default Insurance;
