import {
    Button, Chip, FormControl, MenuItem, Paper, Select, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Theme, Typography,
} from '@material-ui/core';
import { Add, Delete } from '@material-ui/icons';
import { default as React } from 'react';
import { useSelector } from 'react-redux';
import { IStore } from '../../../../redux/types/store';
import unmaskCurrency, { maskCurrency } from '../../../../utils/unmaskCurrency';
import MoneyAdornment from '../../../global/adornments/MoneyAdornment';
import CurrencyInput from '../../../global/masks/currencyMaskedInput';
import { insurance_categories } from '../../riskManagement/insurance/insurance';
import { ISuggestedInsurance } from '../types';
import { useStyles } from './style';

interface ITableRow {
    insuranceType: string;
    insuranceCompany: string;
    profit: string;
    prize: string;
    term: string;
}

interface ISuggestedInsuranceProps {
    defaultSuggestedInsurance: ISuggestedInsurance[];
    setSuggestedInsuranceValues: React.Dispatch<React.SetStateAction<ISuggestedInsurance[]>>;
    nextStep: () => void;
    previousStep: () => void;
    /*   errLabel: string;*/
}

const SuggestedInsurance: React.FC<ISuggestedInsuranceProps> = ({
    defaultSuggestedInsurance,
    setSuggestedInsuranceValues,
    nextStep,
    /*    errLabel,*/
    previousStep,
}) => {
    const classes = useStyles();

    const {customerSuggestedInsurance} = useSelector((store: IStore)=>({customerSuggestedInsurance: store.planning.customerFormValues.suggestions.suggestedInsurance}))

    const [hasSuggestedInsurance, setHasSuggestedInsurance] = React.useState(defaultSuggestedInsurance.length > 0 || customerSuggestedInsurance.length > 0);
    const [addedLine, setAddedLine] = React.useState(false);
    const [ready, setReady] = React.useState(false);

    const [tableRows, setTableRows] = React.useState<ITableRow[]>(
        defaultSuggestedInsurance.length
            ? defaultSuggestedInsurance.map(item => ({
                ...item,
                term: item.term.toString(),
                profit: maskCurrency(item.profit),
                prize: maskCurrency(item.prize),
            }))
            : [
                {
                    insuranceType: '',
                    insuranceCompany: '',
                    profit: '',
                    prize: '',
                    term: '',
                },
            ]
    );

    const addLine = () => {
        setAddedLine(true);
        setTableRows([
            ...tableRows,
            {
                insuranceType: '',
                insuranceCompany: '',
                profit: '',
                prize: '',
                term: '',
            },
        ]);
    };

    const handleInputChange = (field: keyof ITableRow, value: string, rowIndex: number) => {
        setTableRows(tableRows.map((item, i) => (i === rowIndex ? { ...item, [field]: value } : item)));
    };
    const [btnDisabled, setBtnDisabled] = React.useState(false);

    const onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        setSuggestedInsuranceValues(
            tableRows.map(row => ({
                ...row,
                profit: unmaskCurrency(row.profit),
                prize: unmaskCurrency(row.prize),
                term: parseInt(row.term, 10),
            }))
        );
        nextStep();
        setBtnDisabled(true);
    };

    const changeMouseHover = (hover: boolean, index: number) => {
        setMouseHover(rows => rows.map((_, i) => (index === i ? hover : false)));
    };

    const removeRow = (index: number) => {
        // create a new array without index entry
        setTableRows(rows => [...rows.slice(0, index), ...rows.slice(index + 1)]);
    };

    const [mouseHover, setMouseHover] = React.useState(tableRows.map(row => false));
    React.useEffect(() => {
        if (addedLine) {
            setMouseHover(tableRows.map(row => false));
        }
    }, [tableRows]);

    React.useEffect(() => {
        if (addedLine) {
            setReady(true);
        }
    }, [mouseHover]);
    const showRowDel = async (row: number) => {
        setMouseHover(rows => rows.map((_, i) => row === i));
        setReady(false);
        setAddedLine(false);
        await new Promise(r => setTimeout(r, 4000));
        setMouseHover(rows => rows.map(item => false));
    };
    React.useEffect(() => {
        showRowDel(tableRows.length - 1);
    }, [ready]);

    const setInsuranceTypeOnRow = (insuranceType: string, rowIndex: number) => {
        setTableRows(rows => rows.map((item, idx) => (idx === rowIndex ? { ...item, insuranceType } : item)));
    };
    const setInsuranceCompanyOnRow = (insuranceCompany: string, rowIndex: number) => {
        setTableRows(rows => rows.map((item, idx) => (idx === rowIndex ? { ...item, insuranceCompany } : item)));
    };
    const setDeficiencyYearOnRow = (deficiencyYear: Date, rowIndex: number) => {
        setTableRows(rows => rows.map((item, idx) => (idx === rowIndex ? { ...item, deficiencyYear } : item)));
    };
    const insuranceTypes = [
        'Seguro de vida vitalício',
        'Seguro de vida temporário',
        'Seguro de vida decrescente',
        'Morte acidental',
        'Invalidez total por acidente',
        'Invalidez parcial por acidente',
        'Doenças graves',
        'Renda hospitalar',
        'DIT',
    ];
    const insuranceCompanies = ['Prudential', 'Metlife', 'Mongeral'];

    return (
        <div className={classes.root}>
            <div className={classes.head}>
                <Typography variant="h4" className={`${classes.header} mb-3`}>
                    Sugestão de Seguro
                </Typography>
                <div className={`${classes.selectButton} mb-3`}>
                        {/* @ts-ignore */}
                    <Button
                        variant="outlined"
                        color={hasSuggestedInsurance ? 'primary' : 'default'}
                        disableElevation
                        onClick={() => setHasSuggestedInsurance(true)}
                    >
                        SIM
                    </Button>
                        {/* @ts-ignore */}
                    <Button
                        disableElevation
                        variant="outlined"
                        color={!hasSuggestedInsurance ? 'primary' : 'default'}
                        onClick={() => setHasSuggestedInsurance(false)}
                    >
                        NÃO
                    </Button>
                </div>
            </div>
            <form onSubmit={onSubmit}>
                {hasSuggestedInsurance ? (
                    <div>
                        <TableContainer component={Paper}>
                            <Table className={classes.table}>
                                <TableHead>
                                    <TableRow>
                                        <TableCell align="left">Tipo </TableCell>
                                        <TableCell align="left">Seguradora</TableCell>
                                        <TableCell align="center">Benefício</TableCell>
                                        <TableCell align="center">Prêmio anual</TableCell>
                                        <TableCell align="center">Prazo em anos</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {tableRows.map((row, i) => (
                                        <TableRow key={i.toString()} style={{ overflow: 'hidden' }}>
                                            <TableCell align="left">
                                                <FormControl>
                                                    <Select
                                                        labelId="-select-label"
                                                        id="demo-simple-select"
                                                        value={row.insuranceType}
                                                        onChange={event =>
                                                            setInsuranceTypeOnRow(event.target.value as string, i)
                                                        }
                                                        fullWidth
                                                        style={{ minWidth: '173px' }}
                                                    >
                                                        {[...insurance_categories].map(([catName, catLabel]) => (
                                                            <MenuItem value={catName}>
                                                                {catLabel.toUpperCase()}
                                                            </MenuItem>
                                                        ))}
                                                    </Select>
                                                </FormControl>
                                            </TableCell>
                                            <TableCell align="left">
                                                <FormControl>
                                                    <Select
                                                        labelId="-select-label"
                                                        id="demo-simple-select"
                                                        value={row.insuranceCompany}
                                                        onChange={event =>
                                                            setInsuranceCompanyOnRow(event.target.value as string, i)
                                                        }
                                                        fullWidth
                                                        style={{ minWidth: '173px' }}
                                                    >
                                                        {insuranceCompanies.map(insuranceCompany => (
                                                            <MenuItem value={insuranceCompany}>
                                                                {insuranceCompany}
                                                            </MenuItem>
                                                        ))}
                                                    </Select>
                                                </FormControl>
                                            </TableCell>
                                            <TableCell align="center">
                                                <TextField
                                                    required
                                                    color="primary"
                                                    placeholder="0.00"
                                                    inputMode="numeric"
                                                    InputProps={{
                                                        inputComponent: CurrencyInput as any,
                                                        startAdornment: <MoneyAdornment />,
                                                    }}
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                    value={row.profit}
                                                    style={{ flexShrink: 1 }}
                                                    onChange={event =>
                                                        handleInputChange('profit', event.target.value, i)
                                                    }
                                                    inputProps={{
                                                        style: { textAlign: 'right' },
                                                    }}
                                                />
                                            </TableCell>
                                            <TableCell align="right">
                                                <TextField
                                                    required
                                                    color="primary"
                                                    placeholder="R$ 0.00"
                                                    inputMode="numeric"
                                                    InputProps={{
                                                        inputComponent: CurrencyInput as any,
                                                        startAdornment: <MoneyAdornment />,
                                                    }}
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                    value={row.prize}
                                                    style={{ flexShrink: 1 }}
                                                    onChange={event =>
                                                        handleInputChange('prize', event.target.value, i)
                                                    }
                                                    fullWidth
                                                    inputProps={{
                                                        style: { textAlign: 'right' },
                                                    }}
                                                />
                                            </TableCell>
                                            <TableCell align="right">
                                                <TextField
                                                    required
                                                    color="primary"
                                                    placeholder="anos"
                                                    inputMode="numeric"
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                    inputProps={{ style: { textAlign: 'right' } }}
                                                    value={row.term}
                                                    onChange={event => handleInputChange('term', event.target.value, i)}
                                                />
                                            </TableCell>
                                            <div
                                                className={classes.deleteHidden}
                                                onMouseEnter={() => changeMouseHover(true, i)}
                                                onMouseLeave={() => changeMouseHover(false, i)}
                                                //@ts-ignore
                                                onClick={() => removeRow(i)}
                                                // we dont need border when mouse is hovering this div
                                                style={{ borderLeft: !mouseHover[i] ? '9px solid #f44336' : '' }}
                                            >
                                                {mouseHover[i] ? (
                                                    <Delete
                                                        style={{ marginTop: '100%', marginRight: '5%' }}
                                                        color="action"
                                                    />
                                                ) : null}
                                            </div>
                                        </TableRow>
                                    ))}
                                    <TableRow>
                                        <TableCell>
                        {/* @ts-ignore */}
                                            <Chip
                                                variant="outlined"
                                                color="primary"
                                                size="medium"
                                                label="Adicionar nova linha"
                                                clickable
                                                onClick={addLine}
                                                onDelete={addLine}
                                                deleteIcon={<Add />}
                                            />
                                        </TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </TableContainer>
                        {/* <Typography align="center" color="error">
                            {errLabel}
                        </Typography>*/}
                    </div>
                ) : null}
                <div className={classes.formControllers}>
                        {/* @ts-ignore */}
                    <Button
                        variant="contained"
                        className={classes.outButton}
                        size="medium"
                        color="default"
                        disableElevation
                        onClick={previousStep}
                    >
                        Sair
                    </Button>
                    <button type="submit" className={classes.chipButton} disabled={btnDisabled}>
                        <Chip className={classes.chip} color="primary" size="medium" label="Próximo" clickable />
                    </button>
                </div>
            </form>
        </div>
    );
};
export default SuggestedInsurance;
