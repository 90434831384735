import React from 'react';
import { useStyles } from './style'
import { Typography } from '@material-ui/core';

interface ITwoLineDisplayProps {
    label: string;
    labelHelper?: string;
    value: string;
    valueColor?: string;
    className?: string;
}

const TwoLineDisplay: React.FC<ITwoLineDisplayProps> = ({ className, labelHelper, valueColor, label, value }) => {
    const classes = useStyles(valueColor)();

    return (
        <div className={className}>
            <div>
                <Typography className={classes.heading} align="left" variant="body1">
                    {label}
                </Typography>
                {labelHelper && (
                    <Typography className={classes.heading} align="right">
                        {labelHelper}
                    </Typography>
                )}
            </div>
            <Typography variant="subtitle2" className={classes.value} align="left">
                {value}
            </Typography>
        </div>
    );
};
export default TwoLineDisplay;