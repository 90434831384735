import { createStyles, makeStyles, Theme } from '@material-ui/core';
import { blueGrey } from '@material-ui/core/colors';

export const useStyles = (color: string | undefined) =>
    makeStyles((theme: Theme) =>
        createStyles({
            adjustLabel: {
                marginTop: '-1px',
                marginLeft: theme.spacing(2),
            },
            flex: {
                display: 'flex',
                justifyContent: 'flex-start',
            },
            heading: {
                color: blueGrey[900],
            },
            value: {
                color: color || '#023c58',
            },
        })
    );