import { ITableEntry } from '../../global/inputs/tableCost/tableCost';

export interface IEmergencySavings {
    mensalIncoming: number;
    months: number;
    successionValue: number;
    successionDuration: number;
}

export interface ISuccessionCosts {
    itcmd: number;
    oab: number;
    notary: number;
}

export interface ISuccession {
    current: ISuccessionCosts;
    future: ISuccessionCosts;
}
export interface IInsurance {
    category: string;
    insuranceCompany: string;
    benefit: number;
    deductible: number;
    sourceDeduction: string;
    timeInYears: number;
}

export interface IRiskManagement {
    userID?: string;
    savings: IEmergencySavings;
    successionCosts: ISuccession;
    insurance: IInsurance[];
    ready?: boolean;
}

export const NewSuccessionCost = (successionValues: ISuccessionCosts | undefined): ITableEntry<ISuccessionCosts> => ({
    itcmd: {
        label: 'ITCMD',
        value: successionValues?.itcmd || 0,
    },
    oab: {
        label: 'Advogado',
        value: successionValues?.oab || 0,
    },
    notary: {
        label: 'Cartório',
        value: successionValues?.notary || 0,
    },
});
