import { createStyles, makeStyles, Theme } from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            '@media (min-width: 768px)': {
                marginBottom: theme.spacing(2),
            },
            '@media (max-width: 767px)': {
                marginBottom: theme.spacing(2),
            },
        },
        cardHeader: {
            fontWeight: 600,
            fontSize: 16,
            color: '#020202',
        },
        cardContent: {
            '@media (min-width: 768px)': {},
            '@media (max-width: 767px)': {
                width: '50vw',
            },
        },
        cardsLine: {
            '@media (min-width: 768px)': {
                display: 'flex',
                justifyContent: 'flex-start',
                alignContent: 'space-around',
                alignItems: 'flex-start',
                margin: '0px',
                marginBottom: theme.spacing(2),
            },
            '@media (max-width: 767px)': {
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignContent: 'space-around',
                alignItems: 'center',
                margin: '0px',
                marginBottom: theme.spacing(2),
                marginTop: theme.spacing(2),
            },
        },
        cardItemGrow: {
            height: '100%',
            marginRight: 16,
            borderRadius: 8,
        },
        cardRow: {
            display: 'flex',
            flexDirection: 'row',
        },
        cardItemShrink: {
            // this can shrink
            '@media (min-width: 768px)': {
                height: '100%',
                marginRight: 16,
                borderRadius: 8,
            },
            '@media (max-width: 767px)': {
                height: '100%',
                marginBottom: 16,
                borderRadius: 8,
                width: '35vw',
            },
        },
        subHeader: {
            fontSize: '14px',
        },
        valueText: {
            color: theme.palette.secondary.light,
        },
        display: {
            '@media (max-width: 767px)': {},
        },
    })
);