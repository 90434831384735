import { Button, Chip, createStyles, makeStyles, Theme } from '@material-ui/core';
import { grey } from '@material-ui/core/colors';
import React from 'react';
import TableCost, { BackToValue } from '../../../global/inputs/tableCost/tableCost';
import CurrencyInput from '../../../global/masks/currencyMaskedInput';
import { IEducationalValues, NewEducationValues } from '../types';
import { useStyles } from './style';

interface IEducationalCostProps {
    defaultEducationalValues?: IEducationalValues;
    setEducationalValues: React.Dispatch<React.SetStateAction<IEducationalValues>>;
    nextStep: () => void;
    previousStep: () => void;
}

const EducationalCost: React.FC<IEducationalCostProps> = ({
    setEducationalValues,
    nextStep,
    defaultEducationalValues,
    previousStep,
}) => {
    const classes = useStyles();
    const [educationalCosts, setEducationalCosts] = React.useState(NewEducationValues(defaultEducationalValues));

    const costsLabel = Object.values(educationalCosts).map((cost): [string, number] => [cost.label, cost.value]);

    const setTableValues = (values: Map<string, number>) => {
        const hc = { ...educationalCosts };
        values.forEach((val, key) => {
            // forEach value find it in EducationalCost by its label
            const objectKeyForLabel = Object.entries(hc).filter(
                ([_, hcval]) => hcval.label === key
            )![0]![0] as keyof IEducationalValues;
            // if there's a key for that label, then modify its value
            if (objectKeyForLabel) {
                hc[objectKeyForLabel] = {
                    label: hc[objectKeyForLabel].label,
                    value: val,
                };
            }
        });
        setEducationalCosts(hc);
    };

    const [btnDisabled, setBtnDisabled] = React.useState(false);
    const onSumbit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        const educationalValue = BackToValue(educationalCosts);
        setEducationalValues(educationalValue);
        nextStep();
        setBtnDisabled(true);
    };
    return (
        <div className={classes.root}>
            <form onSubmit={onSumbit}>
                <div className={classes.content}>
                    <TableCost
                        risk=''
                        className={classes.table}
                        showTotal
                        header="Custos de Educação"
                        rows={costsLabel}
                        tableValues={setTableValues}
                        inputComponent={CurrencyInput as any}
                    ></TableCost>

                    <div className={classes.formControllers}>
                    {/* @ts-ignore */}
                        <Button
                            variant="contained"
                            className={classes.outButton}
                            size="medium"
                            color="default"
                            disableElevation
                            onClick={previousStep}
                        >
                            Voltar
                        </Button>
                        <button disabled={btnDisabled} type="submit" className={classes.chipButton}>
                            <Chip className={classes.chip} color="primary" size="medium" label="Próximo" clickable />
                        </button>
                    </div>
                </div>
            </form>
        </div>
    );
};
export default EducationalCost;
