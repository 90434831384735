import { Grid, Typography } from '@material-ui/core';
import React from 'react';
import formatter from '../../../../global/formater';
import FIGCard from '../../../homework/FIGCard/FIGCard';
import { IFinancialIndependenceGoals } from '../../types';
import { useStyles } from './style';

interface IFinancialIndependenceGoalsProps {
    FIGoals: IFinancialIndependenceGoals;
}

const FinancialIndependenceGoalsMobile: React.FC<IFinancialIndependenceGoalsProps> = ({ FIGoals }) => {
    const classes = useStyles();

    React.useEffect(() => {}, [FIGoals]);

    const accumulated = () => {
        if (FIGoals.updatedAccumulated) {
            return FIGoals.updatedAccumulated;
        }
        return FIGoals.totalAccumulated;
    };

    return (
        <div className={classes.root}>
                <Typography className={classes.subHeading} variant="h6">
                    Meta até a independência financeira
                </Typography>
                <Grid item xs={12} className={classes.container} container spacing={2} justify="space-between">
                    <Grid item xs={12}>
                        <FIGCard
                            header="Seu número"
                            value={formatter.format(FIGoals.independenceGoal)}
                            subtitle="PARA A INDEPENDÊNCIA"
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <FIGCard header="Acumulado até o momento" value={formatter.format(accumulated())} />
                    </Grid>
                    <Grid item xs={12}>
                        <FIGCard header="Até a meta" value={`${FIGoals.timeToIndependence}`} subtitle="ANOS" />
                    </Grid>
                    <Grid item xs={12}>
                        <FIGCard
                            header="% alcançada da meta"
                            value={`${((accumulated() * 100) / FIGoals.independenceGoal).toFixed(2)}%`}
                            negative
                            color={FIGoals.achievedIncoming > 0 ? 'positive' : 'negative'}
                        />
                    </Grid>
                </Grid>
        </div>
    );
};
export default FinancialIndependenceGoalsMobile;
