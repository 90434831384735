import { Button, Chip, createStyles, makeStyles, TextField, Theme, Typography, withStyles } from '@material-ui/core';
import { grey } from '@material-ui/core/colors';
import React, { createRef, useEffect } from 'react';
import PhoneInput from '../../../global/inputs/PhoneInput/PhoneInput';
import { CEPapi } from '../../../../apis/CEPapi';
import CEPMaskedInput from '../../../global/masks/cepMaskedInput';
// import PhoneMaskedInput from '../../global/masks/phoneMakedInput';
import { IAddress, IContactInfo } from '../types';
import { HtmlAttributes } from 'csstype';
import { useStyles } from './style';

const CustomizedTextInput = withStyles({
    root: {
        '& .MuiFilledInput-input': {
            padding: '11px 12px 10px',
        },
        '& .MuiInputBase-root': {
            padding: '3px',
        },
    },
})(TextField);

interface IContactInfoProps {
    setContactInfo: React.Dispatch<React.SetStateAction<IContactInfo>>;
    nextStep: () => void;
    previousStep: () => void;
    defaultContactInfo: IContactInfo;
    hasPartner: boolean;
}

const ContactInfo: React.FC<IContactInfoProps> = ({
    defaultContactInfo,
    setContactInfo,
    nextStep,
    previousStep,
    hasPartner,
}) => {
    const classes = useStyles();

    const [ddiNumber, setDDINumber] = React.useState('55');
    const [ddiPartnerNumber, setDDIPartnerNumber] = React.useState('55');
    const [cellPhoneNumber, setCellPhoneNumber] = React.useState(defaultContactInfo.cellphone || '');
    const [partnerCellPhoneNumber, setPartnerCellPhoneNumber] = React.useState(
        defaultContactInfo.partnerCellphone || ''
    );
    const [sendCellPhone, setSendCellPhone] = React.useState(defaultContactInfo.cellphone || '');
    const [sendCellPhonePartner, setSendCellPhonePartner] = React.useState(defaultContactInfo.partnerCellphone || '');

    const [cep, setCep] = React.useState(defaultContactInfo.cep || '     -   ');
    const [lockCep, setLockCep] = React.useState(false);
    const [cepErr, setCepErr] = React.useState('');

    const [addressInfo, setAddress] = React.useState<IAddress>({
        houseNumber: defaultContactInfo.houseNumber || 0,
        address: defaultContactInfo.address || '',
        cep: defaultContactInfo.cep || '',
        city: defaultContactInfo.city || '',
        country: defaultContactInfo.country || '',
        additionalAddressInfo: defaultContactInfo.additionalAddressInfo || '',
        state: defaultContactInfo.state || '',
    });

    const phoneRef = createRef<HTMLInputElement>();
    const phonePartnerRef = createRef<HTMLInputElement>();

    useEffect(() => {
        try {
            let [ddiNumber, phoneNumber] = cellPhoneNumber.split('=');
            if (phoneNumber) {
                setDDINumber(ddiNumber);
                setCellPhoneNumber(phoneNumber);
            } else {
                setCellPhoneNumber(ddiNumber);
            }
        } catch (err) {
            console.log('err', err)
        }
        try {
            let [ddiPartern, phonePartern] = partnerCellPhoneNumber.split('=');
            if (phonePartern) {
                setDDIPartnerNumber(ddiPartern);
                setPartnerCellPhoneNumber(phonePartern);
            } else {
                setPartnerCellPhoneNumber(ddiPartern);
            }
        } catch (err) {
            console.log('err', err)
        }
    }, []);

    const searchCepInfo = async () => {
        setLockCep(true);
        // TODO cep validation before state change
        CEPapi(cep)
            .then(cepResponse => {
                setAddress({
                    address: cepResponse.logradouro,
                    cep,
                    country: 'BR',
                    city: cepResponse.localidade,
                    state: cepResponse.uf,
                    houseNumber: 0,
                    additionalAddressInfo: '',
                });
                setCepErr('');
            })
            .catch(e => {
                setCepErr('Verifique o CEP');
            });
        setLockCep(false);
    };

    const setHouseNumber = (houseNumber: string) => {
        const parsed = parseInt(houseNumber, 10);
        const housenum = houseNumber === '' ? 0 : parsed;
        setAddress({
            ...addressInfo,
            houseNumber: housenum,
        });
    };
    const [btnDisabled, setBtnDisabled] = React.useState(false);

    const onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        const contactInfo: IContactInfo = {
            ...addressInfo,
            cellphone: sendCellPhone,
            partnerCellphone: sendCellPhonePartner,
        };
        setContactInfo(contactInfo);
        nextStep();
        setBtnDisabled(true);
    };

    const changeNumber = () => {
        let newCell = phoneRef.current?.value || '';
        setCellPhoneNumber(newCell);
        setSendCellPhone(newCell);
    };

    const changeNumberPartner = () => {
        let newCell = phonePartnerRef.current?.value || '';
        setPartnerCellPhoneNumber(newCell);
        setSendCellPhonePartner(newCell);
    };

    return (
        <div className={classes.content}>
            <Typography variant="h4" className={classes.header}>
                Contato
            </Typography>
            <form className={classes.form} onSubmit={event => onSubmit(event)}>
                <div>
                    <Typography className={classes.inputLabel} variant="h6">
                        Celular 
                    </Typography>
                    <PhoneInput
                        defaultNumber={cellPhoneNumber}
                        defaultDDI={ddiNumber}
                        ref={phoneRef}
                        onBlur={changeNumber}
                    />
                </div>
                {hasPartner && (
                    <div>
                        <Typography className={classes.inputLabel} variant="h6">
                            Celular do Cônjuge
                        </Typography>
                        <PhoneInput
                            defaultNumber={partnerCellPhoneNumber}
                            defaultDDI={ddiPartnerNumber}
                            ref={phonePartnerRef}
                            onBlur={changeNumberPartner}
                        />
                    </div>
                )}
                <div>
                    <div className={classes.address}>
                        <div style={{ flexBasis: '33%' }}>
                            <Typography className={classes.inputLabel} variant="h6">
                                CEP
                            </Typography>
                            <CustomizedTextInput
                                required
                                variant="outlined"
                                size="small"
                                InputProps={{
                                    inputComponent: CEPMaskedInput as any,
                                }}
                                disabled={lockCep}
                                value={cep}
                                error={cepErr !== ''}
                                helperText={cepErr}
                                onChange={event => setCep(event.target.value)}
                                onBlur={() => searchCepInfo()}
                                fullWidth
                            />
                        </div>
                        <div className={classes.spacing} />
                        <div style={{ flexBasis: '20%', flexGrow: 1 }}>
                            <Typography className={classes.inputLabel} variant="h6">
                                Cidade
                            </Typography>
                            <CustomizedTextInput
                                required
                                variant="outlined"
                                size="small"
                                value={addressInfo?.city}
                                disabled
                                fullWidth
                            />
                        </div>
                        <div className={classes.spacing} />
                        <div style={{ flexBasis: '10%' }}>
                            <Typography className={classes.inputLabel} variant="h6">
                                UF
                            </Typography>
                            <CustomizedTextInput
                                required
                                variant="outlined"
                                size="small"
                                placeholder="UF"
                                value={addressInfo?.state}
                                disabled
                                fullWidth
                            />
                        </div>
                        <div className={classes.break} />
                        <div style={{ flexGrow: 1, marginRight: '4px' }}>
                            <Typography className={classes.inputLabel} variant="h6">
                                Endereço
                            </Typography>
                            <CustomizedTextInput
                                required
                                variant="outlined"
                                size="small"
                                disabled
                                value={addressInfo?.address}
                                fullWidth
                            />
                        </div>
                        <div className={classes.spacing} />
                        <div style={{ flexShrink: 1, flexBasis: '10%' }}>
                            <Typography className={classes.inputLabel} variant="h6">
                                Número
                            </Typography>
                            <CustomizedTextInput
                                required
                                variant="outlined"
                                size="small"
                                placeholder="00"
                                value={addressInfo.houseNumber}
                                onChange={event => setHouseNumber(event.target.value)}
                                fullWidth
                            />
                        </div>
                        <div className={classes.grow}>
                            <Typography className={classes.inputLabel} variant="h6">
                                Complemento
                            </Typography>
                            <CustomizedTextInput
                                variant="outlined"
                                size="small"
                                value={addressInfo?.additionalAddressInfo}
                                onChange={event =>
                                    setAddress({ ...addressInfo!, additionalAddressInfo: event.target.value })
                                }
                                fullWidth
                            />
                        </div>
                    </div>
                </div>
                <div className={classes.formControllers}>
                {/* @ts-ignore */}
                    <Button
                        variant="contained"
                        className={classes.outButton}
                        size="medium"
                        color="default"
                        disableElevation
                        onClick={previousStep}
                    >
                        Voltar
                    </Button>
                    <button disabled={btnDisabled} type="submit" className={classes.chipButton}>
                        <Chip className={classes.chip} size="medium" label="Próximo" clickable />
                    </button>
                </div>
            </form>
        </div>
    );
};
export default ContactInfo;
