import {
    Button,
    Checkbox,
    Chip,
    createStyles,
    FormControl,
    makeStyles,
    MenuItem,
    Paper,
    Select,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TextField,
    Theme,
    Tooltip,
    Typography,
} from '@material-ui/core';
import { grey } from '@material-ui/core/colors';
import { Add, Delete, FileCopy } from '@material-ui/icons';
import { default as React } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ChangeSuggestedAssets, CopyInvestiments } from '../../../../redux/actions/planningActions';
import { IStore } from '../../../../redux/types/store';
import unmaskCurrency, { maskCurrency, unmaskPercentage } from '../../../../utils/unmaskCurrency';
import MoneyAdornment from '../../../global/adornments/MoneyAdornment';
import PercentageAdornment from '../../../global/adornments/PercentageAdornment';
import formatter from '../../../global/formater';
import CurrencyInput from '../../../global/masks/currencyMaskedInput';
import PercentageInput from '../../../global/masks/percentageMaskedInput';
import { IFinancialAssets, IInvestimentClass, investimentLabel } from '../types';
import { useStyles } from './style';

interface ITableRow {
    label: string;
    investimentClass: keyof IInvestimentClass;
    appliedValue: string;
    rentability: string;
    xp?: boolean;
}

interface IFinancialAssetsProps {
    defaultFinancialAssets: IFinancialAssets[];
    setFinancialAssetsValues: React.Dispatch<React.SetStateAction<IFinancialAssets[]>>;
    nextStep: () => void;
    previousStep: () => void;
    suggested?: boolean;
}

const FinancialAssets: React.FC<IFinancialAssetsProps> = ({
    defaultFinancialAssets,
    setFinancialAssetsValues,
    nextStep,
    previousStep,
    suggested,
}) => {
    const classes = useStyles();

    const { customerFinancialAssets } = useSelector((store: IStore) => ({ customerFinancialAssets: store.planning.customerFormValues.patrimony.financialAssets }));

    const [hasFinancialAssets, setHasFinancialAssets] = React.useState(defaultFinancialAssets.length > 0 || customerFinancialAssets.length > 0);
    const [addedLine, setAddedLine] = React.useState(false);
    const [ready, setReady] = React.useState(false);
    const dispatch = useDispatch();

    const { suggestedInvestiments } = useSelector((store: IStore) => ({
        suggestedInvestiments: store.planning.customerFormValues.suggestions.suggestedInvestments,
    }));

    const rentabilityMask = (value: number) => {
        if (value) {
            return value.toString().replace('.', ',');
        }
        return '';
    };

    const loadTable = (fa: IFinancialAssets[]): ITableRow[] => {
        return fa.length
            ? fa.map(item => ({
                ...item,
                appliedValue: maskCurrency(item.appliedValue),
                rentability: rentabilityMask(item.rentability),
            }))
            : [{ label: '', investimentClass: 'fixedIncoming', appliedValue: '', rentability: '' }];
    };
    const [tableRows, setTableRows] = React.useState<ITableRow[]>(loadTable(defaultFinancialAssets));

    React.useEffect(() => {
        if (suggested) {
            setTableRows(loadTable(suggestedInvestiments));
            setMouseHover(suggestedInvestiments.map(() => false));
        }
    }, [suggested, suggestedInvestiments, setTableRows]);

    const addLine = () => {
        setAddedLine(true);
        setTableRows([
            ...tableRows,
            { label: '', investimentClass: 'fixedIncoming', appliedValue: '', rentability: '' },
        ]);
    };

    const checkXP = (rowIndex: number, check: boolean) => {
        setTableRows(rows => rows.map((item, idx) => (idx === rowIndex ? { ...item, xp: check } : item)));
    };
    const handleInputChange = (field: keyof ITableRow, value: string, rowIndex: number) => {
        setTableRows(tableRows.map((item, i) => (i === rowIndex ? { ...item, [field]: value } : item)));
    };
    const [btnDisabled, setBtnDisabled] = React.useState(false);

    const onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        const financialValues = tableRows.map(row => ({
            ...row,
            appliedValue: unmaskCurrency(row.appliedValue),
            rentability: unmaskPercentage(row.rentability),
        }));

        if (!hasFinancialAssets) {
            setFinancialAssetsValues([]);
        } else {
            setFinancialAssetsValues(financialValues);
        }
        if (suggested) {
            dispatch(ChangeSuggestedAssets(financialValues));
        }
        nextStep();
        setBtnDisabled(true);
    };

    const changeMouseHover = (hover: boolean, index: number) => {
        setMouseHover(rows => rows.map((_, i) => (index === i ? hover : false)));
    };

    const removeRow = (index: number) => {
        // create a new array without index entry
        setTableRows(rows => [...rows.slice(0, index), ...rows.slice(index + 1)]);
    };

    const setAssetTypeOnRow = (assetType: keyof IInvestimentClass, rowIndex: number) => {
        setTableRows(rows =>
            rows.map((item, idx) => (idx === rowIndex ? { ...item, investimentClass: assetType } : item))
        );
    };

    const [mouseHover, setMouseHover] = React.useState(tableRows.map(row => false));
    React.useEffect(() => {
        if (addedLine) {
            setMouseHover(tableRows.map(row => false));
        }
    }, [tableRows]);

    const [selectAll, setSelectAll] = React.useState(false);
    const onClick = () => {
        const select = !selectAll;
        setTableRows(rows => rows.map(item => ({ ...item, xp: select })));
        setSelectAll(select);
    };

    React.useEffect(() => {
        if (addedLine) {
            setReady(true);
        }
    }, [mouseHover]);
    const showRowDel = async (row: number) => {
        setMouseHover(rows => rows.map((_, i) => row === i));
        setReady(false);
        setAddedLine(false);
        await new Promise(r => setTimeout(r, 4000));
        setMouseHover(rows => rows.map(item => false));
    };
    React.useEffect(() => {
        showRowDel(tableRows.length - 1);
    }, [ready]);

    const getCheckBox = (index: number, row: ITableRow) => (
        <Checkbox checked={row.xp} onChange={event => checkXP(index, event.target.checked)} />
    );

    return (
        <div className={classes.root}>
            <div className={classes.head}>
                <Typography variant="h4" className={classes.header}>
                    {suggested ? 'Sugestão de investimento' : 'Tem algum ativo líquido?'}
                </Typography>
                <div className={classes.subheader}>
                    <Typography variant="body2">
                        Ex: Renda fixa, ações, fundos de investimento, previdência, etc
                    </Typography>
                </div>
                <div className={classes.selectButton}>
                    {/* @ts-ignore */}
                    <Button
                        variant="outlined"
                        color={hasFinancialAssets ? 'primary' : 'default'}
                        disableElevation
                        onClick={() => setHasFinancialAssets(true)}
                    >
                        SIM
                    </Button>
                    {/* @ts-ignore */}
                    <Button
                        disableElevation
                        variant="outlined"
                        color={!hasFinancialAssets ? 'primary' : 'default'}
                        onClick={() => setHasFinancialAssets(false)}
                    >
                        NÃO
                    </Button>
                </div>
            </div>
            <form onSubmit={onSubmit}>
                {hasFinancialAssets ? (
                    <div>
                        <TableContainer component={Paper}>
                            <Table className={classes.table}>
                                <TableHead>
                                    <TableRow>
                                        <TableCell align="left">{!suggested ? 'Ativo' : 'Investimento'}</TableCell>
                                        <TableCell align="left">
                                            {!suggested ? 'Classe do ativo' : 'Classe do Investimento'}
                                        </TableCell>
                                        <TableCell></TableCell>
                                        <TableCell align="right">
                                            {!suggested ? 'Valor aplicado (R$)' : 'Investimento Inicial'}
                                        </TableCell>
                                        <TableCell align="right">Rentabilidade(%a.a.)</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {tableRows.map((row, i) => (
                                        <TableRow key={i.toString()} style={{ overflow: 'hidden' }}>
                                            <TableCell>
                                                <TextField
                                                    autoFocus
                                                    required
                                                    color="primary"
                                                    placeholder={suggested ? 'Investimento' : 'Ativo'}
                                                    value={row.label}
                                                    onChange={event =>
                                                        handleInputChange('label', event.target.value, i)
                                                    }
                                                />
                                            </TableCell>
                                            <TableCell align="left">
                                                <FormControl>
                                                    <Select
                                                        labelId="-select-label"
                                                        id="demo-simple-select"
                                                        value={row.investimentClass}
                                                        onChange={event =>
                                                            setAssetTypeOnRow(
                                                                event.target.value as keyof IInvestimentClass,
                                                                i
                                                            )
                                                        }
                                                        fullWidth
                                                        style={{ minWidth: '173px' }}
                                                    >
                                                        {Object.entries(investimentLabel)
                                                            .sort((a, b) => {
                                                                if (a[1] < b[1]) return -1;
                                                                if (a[1] > b[1]) return 1;
                                                                return 0;
                                                            })
                                                            .map(([investimentType, label]) => (
                                                                <MenuItem key={investimentType} value={investimentType}>
                                                                    {label}
                                                                </MenuItem>
                                                            ))}
                                                    </Select>
                                                </FormControl>
                                            </TableCell>
                                            <TableCell></TableCell>
                                            <TableCell align="right">
                                                <TextField
                                                    color="primary"
                                                    placeholder="0.00"
                                                    inputMode="numeric"
                                                    InputProps={{
                                                        inputComponent: CurrencyInput as any,
                                                        startAdornment: <MoneyAdornment />,
                                                    }}
                                                    inputProps={{
                                                        style: { textAlign: 'right' },
                                                    }}
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                    value={row.appliedValue}
                                                    style={{ maxWidth: '75%', flexShrink: 1 }}
                                                    onChange={event =>
                                                        handleInputChange('appliedValue', event.target.value, i)
                                                    }
                                                />
                                            </TableCell>
                                            <TableCell align="right">
                                                <TextField
                                                    color="primary"
                                                    placeholder="0.00"
                                                    inputMode="numeric"
                                                    InputProps={{
                                                        inputComponent: PercentageInput as any,
                                                        endAdornment: <PercentageAdornment />,
                                                    }}
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                    inputProps={{
                                                        style: { textAlign: 'right' },
                                                        allowNegative: true,
                                                    }}
                                                    value={row.rentability}
                                                    style={{ maxWidth: '75%', flexShrink: 1 }}
                                                    onChange={event =>
                                                        handleInputChange('rentability', event.target.value, i)
                                                    }
                                                />
                                            </TableCell>
                                            <div
                                                className={classes.deleteHidden}
                                                onMouseEnter={() => changeMouseHover(true, i)}
                                                onMouseLeave={() => changeMouseHover(false, i)}
                                                //@ts-ignore
                                                onClick={() => removeRow(i)}
                                                // we dont need border when mouse is hovering this div
                                                style={{ borderLeft: !mouseHover[i] ? '9px solid #f44336' : '' }}
                                            >
                                                {mouseHover[i] ? (
                                                    <Delete
                                                        style={{ marginTop: '100%', marginRight: '5%' }}
                                                        color="action"
                                                    />
                                                ) : null}
                                            </div>
                                        </TableRow>
                                    ))}
                                    <TableRow>
                                        <TableCell>
                                            {/* @ts-ignore */}
                                            <Chip
                                                variant="outlined"
                                                color="primary"
                                                size="medium"
                                                label="Adicionar nova linha"
                                                clickable
                                                onClick={addLine}
                                                onDelete={addLine}
                                                deleteIcon={<Add />}
                                            />
                                        </TableCell>
                                        {suggested && (
                                            <TableCell align="center">
                                                {/* @ts-ignore */}
                                                <Chip
                                                    variant="outlined"
                                                    color="primary"
                                                    size="medium"
                                                    label="Copiar patrimônio"
                                                    clickable
                                                    onClick={() => dispatch(CopyInvestiments())}
                                                    onDelete={() => dispatch(CopyInvestiments())}
                                                    deleteIcon={<FileCopy />}
                                                />
                                            </TableCell>
                                        )}
                                        <TableCell align="right" colSpan={suggested ? 5 : 4}>
                                            <div className={classes.controlCell}>
                                                <Typography>Total Aplicado</Typography>
                                                <Typography style={{ marginLeft: 8 }}>
                                                    {formatter.format(
                                                        tableRows.reduce(
                                                            (prev, curr) => prev + unmaskCurrency(curr.appliedValue),
                                                            0
                                                        )
                                                    )}
                                                </Typography>
                                            </div>
                                        </TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </div>
                ) : null}
                <div className={classes.formControllers}>
                    {/* @ts-ignore */}
                    <Button
                        variant="contained"
                        className={classes.outButton}
                        size="medium"
                        color="default"
                        disableElevation
                        onClick={previousStep}
                    >
                        Voltar
                    </Button>
                    <button disabled={btnDisabled} type="submit" className={classes.chipButton}>
                        <Chip className={classes.chip} color="primary" size="medium" label="Próximo" clickable />
                    </button>
                </div>
            </form>
        </div>
    );
};
export default FinancialAssets;
