import { createStyles, makeStyles, Theme } from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        content: {
            flexBasis: '70%',
        },
        header: {
            fontSize: 18,
            fontWeight: 600,
            paddingBottom: '1em',
        },
    })
);