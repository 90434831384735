import { TextField, withStyles } from '@material-ui/core';
import { KeyboardDatePicker } from '@material-ui/pickers';

export const GreyedTextInput = withStyles({
    root: {
        '& .MuiFilledInput-input': {
            padding: '11px 12px 10px',
        },
        '& .MuiInputBase-root': {
            padding: '3px',
        },
    },
})(TextField);

export const GreyedDateInput = withStyles({
    root: {
        '&.MuiFormControl-root': {
            marginTop: '0px',
            marginBottom: '0px',
        },
        '& .MuiOutlinedInput-adornedEnd': {
            paddingTop: '3px',
            paddingBottom: '2px',
        },
        '& .MuiInputBase-input': {},
    },
})(KeyboardDatePicker);
