import { createStyles, makeStyles, Theme } from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        content: {
            margin: 'auto',
            maxWidth: '65vw',
            display: 'flex',
            justifyContent: 'center',
            flexDirection: 'column',
            alignContent: 'center',
            alignItems: 'center',
        },
        header: {
            marginTop: theme.spacing(2),
        },
        formControllers: {
            paddingTop: theme.spacing(2),
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            alignContent: 'flex-end',
            marginBottom: '25px'
        },
        head: {
            padding: theme.spacing(2),
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            alignItems: 'center',
        },
        outButton:{
            marginRight: '16px',
            marginLeft: '8px',
        },
        selectButton: {
            backgroundColor: '#FFFFFF',
            marginTop: theme.spacing(4),
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            '& > :first-child': {
                marginRight: theme.spacing(2),
            },
            header: {
                marginTop: theme.spacing(2),
            },
            head: {
                padding: theme.spacing(2),
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                alignItems: 'center',
            },
            selectButton: {
                backgroundColor: '#FFFFFF',
                marginTop: theme.spacing(4),
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center',
                '& > :first-child': {
                    marginRight: theme.spacing(2),
                },
            },
            container: {
                display: 'flex',
                flexDirection: 'column',
                alignContent: 'center',
                alignItems: 'center',
            },
            tableInput: {
                marginTop: theme.spacing(1),
                maxWidth: '80%',
            },
            outButton: {
                backgroundColor: 'white',
                color: 'grey[400]',
            },
            tableShow: {
                paddingTop: theme.spacing(2),
                position: 'relative',
            },
            formControllers: {
                width: '100%',
                margin: theme.spacing(2, 0),
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                alignContent: 'flex-end',
            },
            chipButton: {
                backgroundColor: 'transparent',
                border: 'none',
            },
            chip: {
                color: 'grey[50]',
                marginTop: '20px'
            }
        }
    })
);
