import { createStyles, makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(() =>
    createStyles({
        root: {
            // '& .MuiButtonBase-root': {
            //     display: 'flex !important',
            //     justifyContent: 'center !important',
            // },
            // '& .MuiTableSortLabel-root': {
            //     display: 'flex !important',
            //     justifyContent: 'center !important',
            // },

            '& .MuiButtonBase-root MuiTableSortLabel-root': {
                display: 'flex !important',
                justifyContent: 'center !important',
            },
            '& .MuiTableSortLabel-icon': {
                opacity: '0.5'
            },
            rowStyle: {
                color: '#000',
                backgroundColor: '#FFF',
            },
            '&¨.MuiTableRow-root': {
                backgroundColor: '#FFF !important',
            },
            '& .MuiTableCell-head': {
            },
            '& .MuiTableCell-root MuiTableCell-head': {
                padding: 0,
            },
            '& .MuiTableCell-alignLeft': {
                paddingTop: 0,
                paddingBottom: 0,
                paddingRight: 0,
                textAlign: 'center',
            },
            '& .jss54': {
                paddingTop: 0,
                paddingBottom: 0,
                paddingRight: 0,
            },
            '& .MuiTableCell-body': {
                color: '#FFFFFF',
            },
            '& .MuiInput-underline:before': {
                borderBottom: '1px solid #ffffff',
            },
            '& .MuiInput-underline:hover': {
                borderBottom: '1px solid #ffffff',
            },
            '& .MuiTableCell-paddingCheckbox': {
                background: 'rgba(255, 255, 255, 0.3) !important',
            },
            '& .jss23 .MuiTableCell-paddingCheckbox': {
                backgroundColor: 'transparent',
            },
            '& .MuiIconButton-root': {
                color: '#FFFFFF',
            },
            '& .MuiSelect-icon': {
                color: '#FFFFFF',
            },
            '& .MuiTypography-caption': {
                color: '#FFFFFF',
            },
            '& .MuiTablePagination-root': {
                color: '#FFFFFF',
            },
            ' & .MuiInput-root': {
                color: '#FFFFFF',
            },
            '&.MuiInput-underline:after': {
                color: '#FFFFFF',
            },

            '&.MuiInput-underline:before': {
                color: '#FFFFFF',
            },
            color: '#000000',
            overflow: 'hidden !important',
        },

        //This panel is style of actions in header table

        panel: {
            background: 'rgba(255, 255, 255, 0.3) !important',
            width: '100%',
            height: '100%',
            borderRadius: '10px',
            border: 'none',
            boxShadow: '4px 4px 10px 0 rgba(0, 0, 0, 0.5), -4px -4px 5px 0 rgba(255, 255, 255, 0.5)',
        },
        panelHeading: {
            padding: '20px 15px',
            borderRadius: '10px 10px 0 0',
            margin: '0',
        },
        title: {
            color: '#fff',
            fontSize: '28px',
            fontWeight: 500,
            textTransform: 'uppercase',
            lineHeight: '40px',
            margin: '0',
        },

        btn: {
            margin: '10px',
            color: 'rgba(255, 255, 255, 05)',
            background: 'transparent',
            fontSize: '16px',
            textTransform: 'capitalize',
            border: '2px solid #fff',
            borderRadius: '50px',
            transition: 'all 03s ease 0s',

            '&:hover': {
                color: '#fff',
            },
        },

        btnGroup: {
            textAlign: 'end',
            width: '100%',
        },

        btnHeader: {
            backgroundColor: 'unset',
            color: '#fff',
            border: '1px solid #fff',
            borderRadius: '50px',
            padding: '10px',
            marginRight: '20px',
            transition: '.8s ease',

            '&:hover': {
                backgroundColor: '#fff',
                color: '#034663',
            },
        },

        btnEdit: {
            background: 'transparent',
            color: '#000000',

            '&:hover': {
                transform: 'scale(1.7)',
            },
        },

        tableTh: {
            color: '#fff',
            backgroundColor: 'rgba(255, 255, 255, 0.2)',
            fontSize: '16px',
            textTransform: 'uppercase',
            padding: '10px',
            border: 'none',
            textAlign: 'center',
        },
        tableTd: {
            color: '#000000',
            fontSize: '15px',
            padding: '10px 12px',
            verticalAlign: 'middle',
            border: 'none',
        },
        tableTr: {
            backgroundColor: '#FFF',
            color: '#000000',
            cursor: 'pointer',
            '&:hover': {
                backgroundColor: '#f2f3f3',
            },
        },

        actionList: {
            padding: '0',
            margin: '0',
            listStyleType: 'none',
        },

        panelFooter: {
            color: '#fff',
            backgroundColor: 'transparent',
            padding: '15px',
            border: 'none',
            marginTop: '20px',
        },
        tooltipStyle: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
        },
        tooltipName: {
            whiteSpace: 'nowrap',
            width: 140,
            overflow: 'hidden',
            textOverflow: 'ellipsis',
        },
        divPagination: {
            display: 'contents',
        },
    })
);
