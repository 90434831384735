import { createStyles, makeStyles, Theme } from '@material-ui/core';
import { grey } from '@material-ui/core/colors';

export const useStyles = makeStyles((theme: Theme) =>
createStyles({
    divider: {
        borderLeft: `1px solid ${grey[400]}`,
        height: '100%',
        maxWidth: '16px',
    },
    paperPadding: {
        padding: theme.spacing(2),
    },
    container: {
        marginTop: theme.spacing(2),
        padding: theme.spacing(1),
        paddingLeft: theme.spacing(1),
    },
    horizontalDivider: {
        margin: `${theme.spacing(4)}px 0`,
    },
    display: {
        marginTop: theme.spacing(2),
    },
})
);
