import { createStyles, makeStyles, Theme } from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            padding: 0,
            background: 'transparent',
        },
        labelArea: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            paddingTop: theme.spacing(2),
        },
        cardHeader: {
            fontWeight: 600,
            fontSize: 16,
            color: '#020202',
        },
        labelLine: {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-start',
            alignItems: 'center',
        },
        labelHover: {
            fontWeight: 600,
        },
        valueText: {
            marginLeft: theme.spacing(2),
            color: '#FF422D',
        },
        cardTotal: {
            width: '100%',
            background: '#F8F8F8',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-end',
            paddingRight: theme.spacing(2),
            padding: theme.spacing(2),
            alignItems: 'baseline',
        },
        labelValue: {
            alignSelf: 'flex-end',
            fontWeight: 'inherit',
        },
        spacing: {
            padding: theme.spacing(0.5),
        },
        roundMarker: {
            marginTop: '1px',
        },
        grow: {
            flexGrow: 1,
        },
        wrapperChart: {
            '@media (min-width: 768px)': {
                alignItems: 'center',
            },
            '@media (max-width: 767px)': {
                alignItems: 'center',
                justifyContent: 'center',
            },
        },
        pieChart: {
            '@media (min-width: 768px)': {
                minHeight: 280,
            },
            '@media (max-width: 767px)': {
                minHeight: 280,
                maxWidth: '100%',
            },
        },
        totalValue: {
            fontSize: 18,
            marginLeft: theme.spacing(2),
            color: theme.palette.secondary.light,
        },
    })
);