import React from 'react';
import { Paper, Typography } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import { useStyles } from './style';

const FIGCard: React.FC<{
    header: string;
    value: string;
    subtitle?: string;
    negative?: boolean;
    color?: 'positive' | 'negative';
    customColor?: string;
    rentability?: string;
    style?: any;
}> = ({ header, value, subtitle, negative, color, rentability, customColor, style }) => {
    const classes = useStyles();
    const theme = useTheme();

    const getColor = () => {
        if (customColor) {
            return customColor;
        }
        switch (color) {
            case 'positive':
                return theme.palette.success.main;
            case 'negative':
                return theme.palette.error.main;
            default:
                return theme.palette.secondary.main;
        }
    };

    return (
        <Paper
            style={{ height: '100%', borderRadius: 8, flexGrow: 1, ...style }}
            elevation={3}
            className={negative ? classes.negativeRoot : undefined}
        >
            <div className={classes.cardContent}>
                <Typography style={negative ? { color: 'white' } : {}} className={classes.cardHeader} variant="body1">{header}</Typography>
                <Typography variant="h5" style={{ color: getColor(), marginBottom: subtitle ? undefined : 12 }}>{value}</Typography>
                {subtitle ? (
                    <Typography
                        style={negative ? { color: 'white' } : {}}
                        className={classes.cardSubtitle}
                        variant="overline"
                    >
                        {subtitle} {rentability && ` (${rentability} a.a)`}
                    </Typography>
                ) : null}
            </div>
        </Paper>
    );
};
export default FIGCard;
