import { Checkbox, CircularProgress, List, Tooltip, Typography } from '@material-ui/core';
import moment from 'moment';
import React from 'react';
import formatter from '../../../global/formater';
import { IMonthlyObject } from '../types';
import { useStyles } from './style';

const Total: string = 'https://cakeinvest-frontend.s3.amazonaws.com/total.svg';
const Partial: string = 'https://cakeinvest-frontend.s3.amazonaws.com/partial.svg';
const Rejected: string = 'https://cakeinvest-frontend.s3.amazonaws.com/rejected.svg';
const NoSuggestion: string = 'https://cakeinvest-frontend.s3.amazonaws.com/no-suggestion.svg';

const ObjectiveList: React.FC<{
    listName: string;
    month: number;
    monthlyObjectList: IMonthlyObject[];
    year: number;
    loading: boolean;
    disabled?: boolean;
    changeImplementation: (impl: IMonthlyObject) => void;
}> = ({ listName, monthlyObjectList, loading, month, changeImplementation, year, disabled }) => {

    const classes = useStyles();

    const isDisabled = (mo: IMonthlyObject) => {
        if (!mo.completed) {
            return false;
        }
        if (!(year === mo.completedYear && mo.month === month)) {
            return true;
        }

        return false;
    };

    const onClick = (disabledItem: boolean, impl: IMonthlyObject) => {
        if (!disabledItem && !disabled) {
            changeImplementation(impl);
        }
    };

    const label = monthlyObjectList.length ? listName : '';

    const [checked, setChecked] = React.useState(true);

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setChecked(event.target.checked);
    };
    return (
        <>
            <Typography variant="body2">{label}</Typography>
            {monthlyObjectList.length ? (
                <List>
                    {monthlyObjectList.map((monthlyObject, index) => {
                        const labelId = `checkbox-list-label-${monthlyObject.name}`;
                        return (
                            <>
                                <div
                                    className={disabled ? classes.listItemInactive : classes.listItem}
                                    key={monthlyObject.name + index}
                                    // @ts-ignore
                                    onClick={() =>
                                        onClick(isDisabled(monthlyObject), { ...monthlyObject, month, year })
                                    }
                                >
                                    {loading ? (
                                        <CircularProgress size={20} style={{ marginRight: 8 }} />
                                    ) : (
                                        <div>
                                            <Checkbox
                                                disabled={isDisabled(monthlyObject)}
                                                edge="start"
                                                checked={monthlyObject.completed}
                                                onChange={handleChange}
                                                tabIndex={-1}
                                                disableRipple
                                                inputProps={{ 'aria-labelledby': labelId }}
                                            />
                                        </div>
                                    )}
                                    <div className={classes.line}>
                                        <Typography variant="body2" id={labelId}>
                                            {monthlyObject.name}
                                        </Typography>
                                        <Typography variant="overline">
                                            {monthlyObject.completed &&
                                                moment(monthlyObject.completedDate).format('MM/yyyy')}
                                        </Typography>
                                        <div style={{ width: 20 }}>
                                            {checked || monthlyObjectList[index].completed ? (
                                                monthlyObject.value > 0 ? (
                                                    monthlyObjectList[index].completed ? (
                                                        <>
                                                            <Tooltip
                                                                title={
                                                                    <>{formatter.format(monthlyObject.value)}</>
                                                                }
                                                            >
                                                                <img
                                                                    src={Total}
                                                                    alt="Total"
                                                                    width="20px"
                                                                    height="20px"
                                                                ></img>
                                                            </Tooltip>
                                                        </>
                                                    ) : (
                                                        <>
                                                            <Tooltip
                                                                title={
                                                                    <>{formatter.format(monthlyObject.value)}</>
                                                                }
                                                            >
                                                                <img
                                                                    src={Partial}
                                                                    alt="Total"
                                                                    width="20px"
                                                                    height="20px"
                                                                ></img>
                                                            </Tooltip>
                                                        </>
                                                    )
                                                ) : (
                                                    <>
                                                        <Tooltip
                                                            title={<>{formatter.format(monthlyObject.value)}</>}
                                                        >
                                                            <img
                                                                src={NoSuggestion}
                                                                alt="Total"
                                                                width="20px"
                                                                height="20px"
                                                            ></img>
                                                        </Tooltip>
                                                    </>
                                                )
                                            ) : index !== 0 &&
                                              monthlyObjectList[index - 1].completed &&
                                              monthlyObject.value > 0 ? (
                                                <>
                                                    <Tooltip title={<>{formatter.format(monthlyObject.value)}</>}>
                                                        <img src={Partial} alt="Total" width="20px" height="20px"></img>
                                                    </Tooltip>
                                                </>
                                            ) : monthlyObject.value > 0 ? (
                                                <>
                                                    <Tooltip title={<>{formatter.format(monthlyObject.value)}</>}>
                                                        <img
                                                            src={Rejected}
                                                            alt="Total"
                                                            width="20px"
                                                            height="20px"
                                                        ></img>
                                                    </Tooltip>
                                                </>
                                            ) : (
                                                <>
                                                    <Tooltip title={<>{formatter.format(monthlyObject.value)}</>}>
                                                        <img src={NoSuggestion} alt="Total" width="20px" height="20px"></img>
                                                    </Tooltip>
                                                </>
                                            )}
                                        </div>
                                    </div>
                                    {monthlyObjectList[index].completed}
                                </div>
                            </>
                        );
                    })}
                </List>
            ) : null}
        </>
    );
};
export default ObjectiveList;
