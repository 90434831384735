import { Button, Chip, createStyles, makeStyles, Theme, Typography } from '@material-ui/core';
import { grey } from '@material-ui/core/colors';
import React from 'react';
import CostChangeEntry from '../costChanges/costChange';
import { ICostChange } from '../types';
import { useStyles } from './style';

interface IChangesProps {
    changes: ICostChange[];
    setChanges: React.Dispatch<React.SetStateAction<ICostChange[]>>;
    nextStep: () => void;
    previousStep: () => void;
}

const defaultChange = { value: 0, date: new Date(), name: '' };

const Changes: React.FC<IChangesProps> = ({ changes, setChanges, nextStep, previousStep }) => {
    const classes = useStyles();
    const updateChange = (i: number, item: ICostChange) => {
        setChanges(currentChanges => [...currentChanges.slice(0, i), { ...item }, ...currentChanges.slice(i + 1)]);
    };

    React.useEffect(() => {
        if (!changes.length) {
            setChanges([{ ...defaultChange }]);
        }
    }, []);

    const removePerspectiveLine = (index: number) => {
        if (changes.length === 1) {
            setChanges([{ ...defaultChange }]);
            return;
        }
        setChanges(change => [...change.slice(0, index), ...change.slice(index + 1)]);
    };

    const lastChange = (i: number): boolean => {
        return i === changes.length - 1;
    };

    const addChangesLine = () => {
        setChanges(change => [...change, { ...defaultChange }]);
    };

    const [btnDisabled, setBtnDisabled] = React.useState(false);
    const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        nextStep();
        setBtnDisabled(true);
    };

    return (
        <div className="container">
            <div>
                <Typography align="center">Alterações futuras no seu Custo Fixo</Typography>
            </div>
            {changes.map((item, i) => (
                <CostChangeEntry
                    removeSelf={() => removePerspectiveLine(i)}
                    val={item}
                    autoFocus={true}
                    isLast={lastChange(i)}
                    setChange={(change: ICostChange) => updateChange(i, change)}
                    addLine={lastChange(i) ? addChangesLine : undefined}
                />
            ))}
            <form onSubmit={onSubmit}>
                <div className={classes.formControllers}>
                    {/* @ts-ignore */}
                    <Button
                        variant="contained"
                        className={classes.outButton}
                        size="medium"
                        color="default"
                        disableElevation
                        onClick={previousStep}
                    >
                        Voltar
                    </Button>
                    <button type="submit" disabled={btnDisabled} className={classes.chipButton}>
                        <Chip className={classes.chip} color="primary" size="medium" label="Finalizar" clickable />
                    </button>
                </div>
            </form>
        </div>
    );
};
export default Changes;
