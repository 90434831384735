import { createStyles, makeStyles, Theme } from '@material-ui/core';
import { grey, red } from '@material-ui/core/colors';
import { TextField, withStyles } from '@material-ui/core';

export const CustomizedTextInput = withStyles({
    root: {
        '& .MuiFilledInput-input': {
            padding: '11px 12px 10px',
        },
        '& .MuiInputBase-root': {
            padding: '0.5em',
        },
    },
})(TextField);

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        header: {
            marginTop: theme.spacing(4),
        },
        head: {
            padding: theme.spacing(1),
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            alignItems: 'center',
        },
        inputLabel: {
            padding: '1em 0 0.5em',
        },
        form: {
            width: '100%',
            margin: '0 auto',
        },
        buttonsArea: {
            display: 'flex',
            justifyContent: 'flex-start',
            flexWrap: 'wrap',
            alignContent: 'space-between',
            margin: '-3px',
        },
        button: {
            flex: '0 1',
            margin: '3px',
            width: '48px',
            height: '48px',
        },
        formControllers: {
            paddingTop: theme.spacing(2),
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            alignContent: 'flex-end',
        },
        chipButton: {
            backgroundColor: 'transparent',
            border: 'none',
        },
        chip: {
            color: grey[50],
        },
        buttonsLine: {
            backgroundColor: '#FFFFFF',  
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-start',
        },
        flexDiv: {
            display: 'flex',
        },
        flexDiv1: {
            flexGrow: 2,
            marginRight: '2em',
        },
        flexDiv2: {
            flexGrow: 1,
        },
        subheader: {
            margin: theme.spacing(2, 0),
        },
        outButton: {
            backgroundColor: 'white',
            color: grey[400],
        },
    })
);
