import { Paper, Typography } from '@material-ui/core';
import { useStyles } from './style'
import React from 'react';
import formatter from '../../formater';

interface DisplayLineProps {
    label: string;
    value: number;
}

const DisplayLine: React.FC<DisplayLineProps> = ({ label, value }) => {
    const classes = useStyles();

    return (
        <Paper className={classes.total} elevation={5}>
            <Typography className={classes.label}>{label}</Typography>
            <div className={classes.grow} />
            <Typography className={classes.totalValue}>{formatter.format(value)}</Typography>
        </Paper>
    );
};
export default DisplayLine;