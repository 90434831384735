import { Typography } from '@material-ui/core';
import React from 'react';

const MoneyAdornment: React.FC<{ negative?: boolean; noMargin?: boolean; paddingLeft?: number }> = ({
    negative,
    noMargin,
    paddingLeft,
}) => (
    <Typography style={{ marginBottom: noMargin ? 0 : 1, marginRight: 4, paddingLeft }} variant="body2">
        {negative ? '-R$' : 'R$'}
    </Typography>
);
export default MoneyAdornment;
