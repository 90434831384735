import { ICustomerInfo } from '../../../components/planningForm/customerInfo/types';
import {
    changeCurrentStep,
    changeSuggestedAssets,
    copyInvestiments,
    resetUserInfo,
    selectPage,
    setClientFinancing,
    setClientFixedCost,
    setClientGoal,
    setClientIncoming,
    setClientInfo,
    setClientPatrimony,
    setClientRiskManagement,
    setClientSuggestions,
    setExit,
    setFormValues,
    setLogout,
    setToolbarTitle,
    setUserInfo,
} from '../../actions/planningActions';
import { ReduxAction } from '../../actions/reduxAction';
import { IPlanningRedux } from '../../types/formPlanning';
import { initialState } from './initialState';

const checkFinancing = (financing: any) => {
    if (financing?.financing?.length || financing?.consortia?.length) return true;
};

const checkGoal = (goal: any) => {
    if (goal?.goals?.length) return true;
};

const checkSuggestions = (suggestions: any) => {
    if (suggestions.suggestedInvestments?.length || suggestions.suggestedConsortia?.length) return true;
};

const planningFormReducer = (state = initialState, action: ReduxAction<any>): IPlanningRedux => {
    switch (action.type) {
        case setUserInfo:
            return { ...initialState, customerUserInfo: action.payload, userCreationStep: true };
        case setClientInfo:
            return {
                ...state,
                customerFormValues: { ...state.customerFormValues, customerInfo: action.payload },
                userInfoStep: true,
            };
        case resetUserInfo:
            return { ...initialState };
        case setClientFinancing:
            return {
                ...state,
                customerFormValues: { ...state.customerFormValues, financing: action.payload },
                financingStep: true,
            };
        case setClientFixedCost:
            return {
                ...state,
                customerFormValues: { ...state.customerFormValues, fixedCost: action.payload },
                fixedCostStep: true,
            };
        case setClientSuggestions:
            return {
                ...state,
                customerFormValues: { ...state.customerFormValues, fixedCost: action.payload },
                suggestionsStep: true,
            };
        case selectPage:
            return {
                ...state,
                customerPageIndex: action.payload.menuIndex,
            };
        case setClientGoal:
            return {
                ...state,
                customerFormValues: { ...state.customerFormValues, goal: action.payload },
                goalStep: true,
            };
        case setClientIncoming:
            return {
                ...state,
                customerFormValues: { ...state.customerFormValues, incoming: action.payload },
                incomingStep: true,
            };

        case setClientPatrimony:
            return {
                ...state,
                customerFormValues: { ...state.customerFormValues, patrimony: action.payload },
                patrimonyStep: true,
            };
        case setClientRiskManagement:
            return {
                ...state,
                customerFormValues: { ...state.customerFormValues, riskManagement: action.payload },
                riskmanagementStep: true,
            };
        case setExit:
            return {
                ...state,
                logout: false,
            };
        case setLogout:
            return {
                ...state,
                logout: true,
            };

        case copyInvestiments:
            return {
                ...state,
                customerFormValues: {
                    ...state.customerFormValues,
                    suggestions: {
                        ...state.customerFormValues.suggestions,
                        suggestedInvestments: [...state.customerFormValues.patrimony.financialAssets],
                    },
                },
            };
        case changeCurrentStep:
            return {
                ...state,
                toolbar: {
                    ...state.toolbar,
                    current: action.payload,
                },
            };
        case setToolbarTitle:
            return {
                ...state,
                toolbar: {
                    title: action.payload.title,
                    color: action.payload.color,
                    goBack: action.payload.navigation,
                    current: action.payload.current,
                    total: action.payload.total,
                },
            };
        case setFormValues:
            const customerInfo = action.payload?.customerInfo.personalInfo as ICustomerInfo;
            const userInfoStep = 'customerInfo' in action.payload && Boolean(customerInfo.personalInfo.fullName);
            const financingStep = 'financing' in action.payload && checkFinancing(action.payload.financing);
            const fixedCostStep = 'fixedCost' in action.payload && Boolean(action.payload.fixedCost.total);
            const goalStep = 'goal' in action.payload && checkGoal(action.payload.goal);
            const suggestionsStep = 'suggestions' in action.payload && checkSuggestions(action.payload.suggestions);
            const incomingStep = 'incoming' in action.payload && Boolean(action.payload.incoming.personalIncome.salary);
            const patrimonyStep = 'patrimony' in action.payload && Boolean(action.payload.patrimony.total);
            const riskmanagementStep = 'riskManagement' in action.payload && action.payload.riskManagement.savings.mensalIncoming > 0;
            return {
                ...state,
                customerFormValues: {
                    ...state.customerFormValues,
                    ...action.payload,
                    customerInfo: action.payload.customerInfo,
                },
                userInfoStep,
                financingStep,
                suggestionsStep,
                fixedCostStep,
                goalStep,
                incomingStep,
                patrimonyStep,
                riskmanagementStep,
                fetch: true,
            };
        case changeSuggestedAssets:
            return {
                ...state,
                customerFormValues: {
                    ...state.customerFormValues,
                    suggestions: {
                        ...state.customerFormValues.suggestions,
                        suggestedInvestments: action.payload,
                    },
                },
            };
        default:
            return state;
    }
};

export default planningFormReducer;
