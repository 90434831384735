import {
    Button,
    Card,
    Chip,
    createStyles,
    FormControl,
    InputAdornment,
    makeStyles,
    MenuItem,
    Select,
    Theme,
    Typography,
} from '@material-ui/core';
import { Add } from '@material-ui/icons';
import React from 'react';
import unmaskCurrency from '../../../../utils/unmaskCurrency';
import YearsAdornment from '../../../global/adornments/YearsAdornment';
import { GreyedTextInput } from '../../../global/inputs/GreyedInput';
import CurrencyInput from '../../../global/masks/currencyMaskedInput';
import { insurance_categories } from '../insurance/insurance';
import { IInsurance } from '../types';
import {useStyles} from './style';

interface IInsuranceInputProps {
    onClickAdd: (insuranceInput: IInsurance) => void;
}

const InsuranceInput: React.FC<IInsuranceInputProps> = ({ onClickAdd }) => {
    const classes = useStyles();
    const [defaultCategory] = React.useState(insurance_categories.keys().next().value);
    const [category, setCategory] = React.useState(defaultCategory);
    const [insuranceCompany, setInsuranceCompany] = React.useState('');
    const [benefit, setBenefit] = React.useState('');
    const [deductible, setDeductible] = React.useState('');
    const [timeInYears, setTimeInYears] = React.useState('');
    const [discountSource, setDiscountSource] = React.useState('leftMonth');

    const handleSubmit = (event: React.FormEvent) => {
        event.preventDefault();

        onClickAdd({
            category,
            insuranceCompany,
            benefit: unmaskCurrency(benefit),
            deductible: unmaskCurrency(deductible),
            timeInYears: parseInt(timeInYears, 10),
            sourceDeduction: discountSource,
        });
        setCategory(defaultCategory);
        setInsuranceCompany('');
        setBenefit('');
        setDeductible('');
        setTimeInYears('');
    };

    return (
        <div>
            <Card elevation={3} className={classes.paper}>
                <form onSubmit={handleSubmit}>
                    <div className={classes.inputGroup}>
                        <Typography variant="body2">Categoria</Typography>
                        <FormControl>
                            <Select
                                labelId="category"
                                value={category}
                                onChange={event => setCategory(event.target.value as string)}
                            >
                                {[...insurance_categories].map(([catName, catLabel]) => (
                                    <MenuItem value={catName}>{catLabel.toUpperCase()}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </div>
                    <div className={classes.inputGroup}>
                        <Typography className='mb-2' variant="body2">Seguradora</Typography>
                        <GreyedTextInput
                            required
                            color="primary"
                            variant="outlined"
                            size="small"
                            placeholder="Nome da companhia"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            value={insuranceCompany}
                            onChange={event => setInsuranceCompany(event.target.value)}
                            fullWidth
                        />
                    </div>
                    <div className={classes.inputGroup}>
                        <Typography className='mb-2' variant="body2">Valor do benefício</Typography>
                        <GreyedTextInput
                            required
                            color="primary"
                            variant="outlined"
                            size="small"
                            placeholder="0.00"
                            InputProps={{
                                inputComponent: CurrencyInput as any,
                                startAdornment: (
                                    <InputAdornment style={{ marginLeft: '8px' }} position="start">
                                        R$
                                    </InputAdornment>
                                ),
                            }}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            value={benefit}
                            onChange={event => setBenefit(event.target.value)}
                            fullWidth
                        />
                    </div>
                    <div className={classes.inputGroup}>
                        <Typography className='mb-2' variant="body2">Valor da parcela (mensal)</Typography>
                        <GreyedTextInput
                            required
                            color="primary"
                            variant="outlined"
                            size="small"
                            placeholder="0.00"
                            InputProps={{
                                inputComponent: CurrencyInput as any,
                                startAdornment: (
                                    <InputAdornment style={{ marginLeft: '8px' }} position="start">
                                        R$
                                    </InputAdornment>
                                ),
                            }}
                            InputLabelProps={{ shrink: true }}
                            value={deductible}
                            onChange={event => setDeductible(event.target.value)}
                            fullWidth
                        />
                    </div>
                    <div className={classes.inputGroup}>
                        <Typography className='mb-2'>De onde a parcela deve ser descontada?</Typography>
                        <div className={classes.discountDiv}>
                        {/* @ts-ignore */}
                            <Button
                                variant="outlined"
                                onClick={() => setDiscountSource('patrimony')}
                                color={discountSource == 'patrimony' ? 'primary' : 'default'}
                            >
                                Patrimônio
                            </Button>
                        {/* @ts-ignore */}
                            <Button
                            className='ms-2'
                                variant="outlined"
                                color={discountSource == 'leftMonth' ? 'primary' : 'default'}
                                onClick={() => setDiscountSource('leftMonth')}
                            >
                                Sobras mensais
                            </Button>
                        </div>
                    </div>
                    <div className={classes.inputGroup}>
                        <Typography className='mb-2' variant="body2">Prazo restante (em anos)</Typography>
                        <GreyedTextInput
                            required
                            color="primary"
                            variant="outlined"
                            size="small"
                            placeholder="0"
                            type="numeric"
                            InputProps={{
                                endAdornment: <YearsAdornment />,
                            }}
                            InputLabelProps={{ shrink: true }}
                            value={timeInYears}
                            onChange={event => setTimeInYears(event.target.value)}
                            fullWidth
                        />
                    </div>
                    <button type="submit" style={{ backgroundColor: 'transparent', border: 0, float: 'right' }}>
                        <Chip
                            deleteIcon={<Add />}
                            variant="outlined"
                            color="secondary"
                            size="medium"
                            label="ADICIONAR PROTEÇÃO"
                            clickable
                            onDelete={() => {}}
                        />
                    </button>
                </form>
            </Card>
        </div>
    );
};
export default InsuranceInput;
