import { createStyles, makeStyles, Theme  } from '@material-ui/core';
export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            padding: theme.spacing(2),
        },
        content: {
            padding: theme.spacing(2),
            paddingBottom: 0,
        },
        flexLine: {
            display: 'flex',
            alignContent: 'flex-end',
            alignItems: 'center',
            justifyContent: 'space-between',
            marginTop: theme.spacing(1),
        },
        flexCenterLine: {
            display: 'flex',
            alignContent: 'flex-end',
            alignItems: 'baseline',
            justifyContent: 'space-between',
            marginTop: theme.spacing(1),
            [theme.breakpoints.between('xs', 1367)]: {
                alignItems: 'center',
            },
        },
        protectionText: {
            [theme.breakpoints.between('xs', 1367)]: {
                maxWidth: 80,
                marginRight: 0,
            },
            [theme.breakpoints.up(1600)]: {
                marginRight: 2,
            },
        },
        qualityText: {
            [theme.breakpoints.between('xs', 1367)]: {
                maxWidth: 200,
                marginRight: 0,
            },
            [theme.breakpoints.up(1600)]: {
                marginRight: 2,
            },
        },
        grow: {
            flexGrow: 1,
        },
        bold: {
            fontWeight: 600,
        },
        total: {
            fontWeight: 600,
            color: theme.palette.success.main,
        },
        divider: {
            marginTop: theme.spacing(1),
            marginBottom: theme.spacing(1),
            marginLeft: '70%',
        },
    })
);