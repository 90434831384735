import { createStyles, makeStyles, Theme } from '@material-ui/core';
import React from 'react';
import { useHistory } from 'react-router';
import { LoginFormControl } from './forms/LoginForm/LoginForm';

interface ILoginProps {
    finishLogin: () => void;
}

const Login: React.FC<ILoginProps> = ({ finishLogin }) => {
    const history = useHistory();

    const onDone = () => {
        finishLogin();
        history.push('/');
    };

    return (

        <div>
            {
                window.screen.width <= 767 ? (

                    <div id="bgMobile" className="d-md-none">
                        <div className="container d-column justify-content-center">
                            <div className="flex-column">
                                <img
                                    style={{ marginLeft: '-3%', marginTop: '30%' }}
                                    className="img-fluid"
                                    width={300}
                                    src="https://cakeinvest-frontend.s3.amazonaws.com/cake-logo-login.png"
                                ></img>
                                <h2 className="text-white">Você sabe qual o número para realizar seus sonhos?</h2>
                                <p className="text-white">
                                    Somos a única plataforma capaz de te ajudar a transformar um projeto financeiro de vida em
                                    realidade.
                                </p>
                            </div>
                            <LoginFormControl onDone={onDone} />
                        </div>
                    </div>

                ) : (

                    <div className="bgLogin">
                        <div className="d-flex">
                            <div className="align-items-start flex-column ms-3" style={{ marginTop: '37%' }}>
                                <h2 className="text-white">Você sabe qual o número para realizar seus sonhos?</h2>
                                <p className="text-white">
                                    Somos a única plataforma capaz de te ajudar a transformar um projeto financeiro de vida em
                                    realidade.
                                </p>
                            </div>
                            <div className="container col" style={{ marginLeft: '10%', marginTop: '15%' }}>
                                <div className="d-flex align-items-center flex-column">
                                    <img
                                        className="img-fluid"
                                        width={300}
                                        src="https://cakeinvest-frontend.s3.amazonaws.com/cake-logo-login.png"
                                    ></img>
                                    <LoginFormControl onDone={onDone} />
                                </div>
                            </div>
                        </div>
                    </div>
                )
            }
        </div>

    )
}

export default Login;
