import { createStyles, makeStyles, Theme } from '@material-ui/core';
import { grey } from '@material-ui/core/colors';


export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        formControllers: {
            display: 'flex',
            minWidth: '61%',
            justifyContent: 'space-between',
            alignItems: 'center',
            alignContent: 'flex-end',
            marginTop: theme.spacing(2),
            marginBottom: theme.spacing(1),
        },
        outButton: {
            backgroundColor: 'white',
            color: grey[400],
        },
        chipButton: {
            backgroundColor: 'transparent',
            border: 'none',
        },
        chip: {
            color: grey[50],
        },
    })
);
