import React from 'react';
import PlanningSteps from './planningSteps/planningSteps';
import { IPlannerPageProps } from './types';
import { ICustomerUserInfo } from '../../redux/types/formPlanning';

interface IPlanningFormProps extends IPlannerPageProps {
    profileTitle: string
    customcustomers: boolean;
    userStorage: ICustomerUserInfo;
}

const PlanningForm: React.FC<IPlanningFormProps> = ({ profileTitle, changeTitle, customcustomers, userStorage }) => {

    React.useEffect(() => {
        if (profileTitle == 'admin') {
            changeTitle('PORTAL DO ADMIN');
        } else if ("customcustomers") {
            changeTitle('PORTAL DO CLIENTE');
        } else {
            changeTitle('PORTAL DO ASSESSOR');
        }
    }, []);

    return (
        <PlanningSteps customcustomers={customcustomers} userStorage={userStorage}/>
    );
};
export default PlanningForm;
