import { createStyles, makeStyles, Theme } from '@material-ui/core';
import { grey } from '@material-ui/core/colors';

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        line: {
            display: 'flex',
            flex: '1 1 100%',
            flexDirection: 'row',
            justifyContent: 'space-around',
            flexWrap: 'wrap',
        },
        spacing: {
            padding: theme.spacing(2),
        },
        grow: {
            flexGrow: 1,
        },
        bottomPadding: {
            fontSize: '14px',
            transition: '.6s linear',
            opacity: '1',
            cursor: 'pointer',
            '&:hover': {
                transform: 'scale(0.98)',
            },
        },
        disabled: {
            opacity: '0.4'
        }
    })
);
