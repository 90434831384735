import { Card, CardContent, Divider, Grid, Typography } from '@material-ui/core';
import { useStyles } from './style'
import partition from 'lodash.partition';
import React, { useEffect } from 'react';
import { Cell, Pie, PieChart, ResponsiveContainer, Sector } from 'recharts';
import { RoundIcon } from '../../../../assets/icons';
import formatter from '../../../global/formater';

const slices = ['#5890A6', '#416CA6', '#9712BF', '#58880A6', '#446CA6', '#3212BF', '#BF6374', '#F2D8C9'].reverse();

const renderActiveShape = (props: any) => {
    const { cx, cy, innerRadius, outerRadius, startAngle, endAngle, fill, payload, percent } = props;

    return (
        <g>
            <text
                fontSize={payload.name.length > 15 ? '10px' : ''}
                x={cx}
                y={cy}
                dy={8}
                textAnchor="middle"
                fill="#000000"
            >
                {payload.name}
            </text>
            <text x={cx} y={cy + 15} dy={8} textAnchor="middle" fill="#000000">{`${(percent * 100).toFixed(2)}%`}</text>
            <Sector
                cx={cx}
                cy={cy}
                innerRadius={innerRadius}
                outerRadius={outerRadius}
                startAngle={startAngle}
                endAngle={endAngle}
                fill={fill}
            />
            <Sector
                cx={cx}
                cy={cy}
                startAngle={startAngle}
                endAngle={endAngle}
                innerRadius={outerRadius + 6}
                outerRadius={outerRadius + 10}
                fill={fill}
            />
        </g>
    );
};

export interface IDataChart {
    name: string;
    value: number;
    additionalInfo?: {
        [k: string]: boolean;
    };
}

interface IPieChart {
    data: IDataChart[];
    title: string;
    sumTotal?: boolean;
    colorSlice?: string[];
    firstSeparator?: string;
    secondSeparator?: string;
    fieldSeparator?: string;
    netWorth: number[];
    setNetWorth: any;
}

const transformData = (data: IDataChart[], fieldSeparator: string): [IDataChart[], number] => {
    const part = partition(data, o => !o.additionalInfo?.[fieldSeparator]);

    return [[...part[0], ...part[1]], part[0].length];
};

const RenderPieChart: React.FC<IPieChart> = ({
    data,
    title,
    colorSlice,
    fieldSeparator,
    firstSeparator,
    secondSeparator,
    netWorth,
    setNetWorth
}) => {
    const classes = useStyles();

    const [currentIdx, setIdx] = React.useState(-1);
    const [hoverStyle, setHoverStyle] = React.useState('');

    const hover = (dataIdx: number) => {
        setIdx(dataIdx);
        setHoverStyle(classes.labelHover);
    };
    const [transformedData, indexSeparator] = fieldSeparator ? transformData(data, fieldSeparator) : [data, -1];
    const [total, setTotal] = React.useState(0);
    const [subTotais, setSubTotais] = React.useState([0, 0]);
    React.useEffect(() => {
        const reduced = data.map(item => item.value).reduce((acc, curr) => acc + curr, 0);
        setTotal(reduced);
    }, []);

    useEffect(() => {
        if (title == 'Passivos Totais' && total != 0) {
            setNetWorth(netWorth => [...netWorth, total]);
        } else if (title == 'Ativos Totais' && total != 0) {
            setNetWorth(netWorth => [...netWorth, total]);
        }
    }, [total])

    React.useEffect(() => {
        let preCalcSubTotais = [0, 0];
        transformedData.map((item, idx) => {
            preCalcSubTotais[+(idx >= indexSeparator)] += item.value;
        });
        setSubTotais(preCalcSubTotais);
    }, []);

    const colors = colorSlice ? colorSlice : slices;

    return (
        <div>
            {
                window.screen.width <= 767

                    ?
                    <div>
                        <Card className={classes.root} elevation={0}>
                            <CardContent>
                                <Typography className={classes.cardHeader} gutterBottom>
                                    {title}
                                </Typography>
                                <div>
                                    <Grid container className="wrapperChart">
                                        <Grid item xs={12} className={classes.pieChart}>
                                            <ResponsiveContainer height={250}>
                                                <PieChart width={350} height={250} margin={{ top: 10 }}>
                                                    <Pie
                                                        activeIndex={currentIdx}
                                                        activeShape={renderActiveShape}
                                                        data={transformedData}
                                                        isAnimationActive={false}
                                                        dataKey="value"
                                                        nameKey="name"
                                                        cx="50%"
                                                        cy="50%"
                                                        startAngle={450}
                                                        endAngle={90}
                                                        innerRadius={57}
                                                        outerRadius={90}
                                                        fill="#8884d8"
                                                        onMouseEnter={(_, index) => setIdx(index)}
                                                    >
                                                        {colors.map(entry => (
                                                            <Cell key={`color${entry}`} fill={entry} />
                                                        ))}
                                                    </Pie>
                                                </PieChart>
                                            </ResponsiveContainer>
                                        </Grid>
                                        <Grid
                                            item
                                            container
                                            xs={12}
                                            sm
                                            onMouseLeave={() => setIdx(-1)}
                                            justify="space-between"
                                            alignItems="baseline"
                                            alignContent="center"
                                            style={{ marginBottom: 32 }}
                                        >
                                            {firstSeparator && (
                                                <Grid item xs={12} style={{ marginBottom: 8 }}>
                                                    <div style={{ display: 'flex' }}>
                                                        <div style={{ flexGrow: 1 }} />{' '}
                                                        <Typography align="right" variant="overline" style={{ fontWeight: 600 }}>
                                                            {firstSeparator}
                                                        </Typography>
                                                    </div>
                                                    {firstSeparator && <Divider style={{ maxWidth: '100%' }} />}
                                                </Grid>
                                            )}
                                            {transformedData.map((item, idx) => (
                                                <React.Fragment key={'pie-item' + item.name + idx}>
                                                    {idx === indexSeparator && (
                                                        <Grid
                                                            item
                                                            xs={12}
                                                            justify="flex-end"
                                                            style={{ marginTop: 16, marginBottom: 8 }}
                                                        >
                                                            <div style={{ display: 'flex' }}>
                                                                <div style={{ flexGrow: 1 }} />
                                                                <Typography
                                                                    align="right"
                                                                    variant="overline"
                                                                    style={{ marginLeft: 'auto', fontWeight: 600 }}
                                                                >
                                                                    {secondSeparator}
                                                                </Typography>
                                                            </div>
                                                            {secondSeparator && <Divider />}
                                                        </Grid>
                                                    )}
                                                    <Grid item xs={1}>
                                                        <RoundIcon color={colors[idx]} width={10} height={10} />
                                                    </Grid>
                                                    <Grid item xs={5}>
                                                        <div onMouseEnter={() => hover(idx)}>
                                                            <Typography
                                                                className={currentIdx === idx ? hoverStyle : ''}
                                                                style={{ fontSize: 14, textTransform: 'capitalize' }}
                                                            >
                                                                {item.name}
                                                            </Typography>
                                                        </div>
                                                    </Grid>
                                                    <Grid item xs={6}>
                                                        <div onMouseEnter={() => hover(idx)}>
                                                            <Typography
                                                                className={classes.labelValue}
                                                                color="primary"
                                                                align="right"
                                                                style={{ fontSize: 14, fontWeight: currentIdx === idx ? 600 : 400 }}
                                                            >
                                                                {formatter.format(item.value)}
                                                            </Typography>
                                                        </div>
                                                    </Grid>
                                                    {//Condicao para mostrar subtotais
                                                        (idx === indexSeparator - 1 || idx === transformedData.length - 1) && (
                                                            <Grid
                                                                item
                                                                xs={12}
                                                                justify="flex-end"
                                                                style={{
                                                                    marginTop: 16,
                                                                    marginBottom: 8,
                                                                    display: 'flex',
                                                                    justifyContent: 'space-between',
                                                                }}
                                                            >
                                                                <Typography
                                                                    align="right"
                                                                    variant="overline"
                                                                    style={{
                                                                        marginRight: 'auto',
                                                                        fontWeight: 600,
                                                                    }}
                                                                >
                                                                    Sub total:
                                                                </Typography>
                                                                <Typography style={{ marginLeft: 'auto' }}>
                                                                    {formatter.format(subTotais[+(idx >= indexSeparator)])}
                                                                </Typography>
                                                            </Grid>
                                                        )}
                                                </React.Fragment>
                                            ))}
                                        </Grid>

                                        <Grid item xs={6} />
                                        {/* Total */}
                                        <Grid
                                            item
                                            style={{ paddingRight: 0 }}
                                            container
                                            alignItems="flex-end"
                                            justify="flex-end"
                                            spacing={2}
                                        >
                                            <Grid item>
                                                <Typography align="right">Total</Typography>
                                            </Grid>
                                            <Grid item alignItems="flex-end" style={{ paddingRight: 0 }}>
                                                <Typography className={classes.totalValue} align="right" color="secondary">
                                                    {formatter.format(total)}
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </div>
                            </CardContent>
                        </Card>
                    </div>
                    :
                    <div>
                        <Card className={classes.root} elevation={0}>
                            <CardContent>
                                <Typography className={classes.cardHeader} gutterBottom>
                                    {title}
                                </Typography>
                                <div className='row d-flex flex-column justify-content-center align-items-center'>
                                    <Grid item xs={6} className={classes.pieChart}>
                                        <ResponsiveContainer height={250}>
                                            <PieChart width={400} height={300} margin={{ top: 10 }}>
                                                <Pie
                                                    activeIndex={currentIdx}
                                                    activeShape={renderActiveShape}
                                                    data={transformedData}
                                                    isAnimationActive={false}
                                                    dataKey="value"
                                                    nameKey="name"
                                                    cx="50%"
                                                    cy="50%"
                                                    startAngle={450}
                                                    endAngle={90}
                                                    innerRadius={57}
                                                    outerRadius={90}
                                                    fill="#8884d8"
                                                    onMouseEnter={(_, index) => setIdx(index)}
                                                >
                                                    {colors.map(entry => (
                                                        <Cell key={`color${entry}`} fill={entry} />
                                                    ))}
                                                </Pie>
                                            </PieChart>
                                        </ResponsiveContainer>
                                    </Grid>

                                    <Grid
                                        item
                                        container
                                        xs={6}
                                        sm
                                        onMouseLeave={() => setIdx(-1)}
                                        justify="space-between"
                                        alignItems="baseline"
                                        alignContent="center"
                                        style={{ marginBottom: 32 }}
                                    >
                                        {firstSeparator && (
                                            <Grid item xs={12} style={{ marginBottom: 8 }}>
                                                <div style={{ display: 'flex' }}>
                                                    <div style={{ flexGrow: 1 }} />{' '}
                                                    <Typography align="right" variant="overline" style={{ fontWeight: 600 }}>
                                                        {firstSeparator}
                                                    </Typography>
                                                </div>
                                                {firstSeparator && <Divider style={{ maxWidth: '100%' }} />}
                                            </Grid>
                                        )}
                                        {transformedData.map((item, idx) => (
                                            <React.Fragment key={'pie-item' + item.name + idx}>
                                                {idx === indexSeparator && (
                                                    <Grid
                                                        item
                                                        xs={12}
                                                        justify="flex-end"
                                                        style={{ marginTop: 16, marginBottom: 8 }}
                                                    >
                                                        <div style={{ display: 'flex' }}>
                                                            <div style={{ flexGrow: 1 }} />
                                                            <Typography
                                                                align="right"
                                                                variant="overline"
                                                                style={{ marginLeft: 'auto', fontWeight: 600 }}
                                                            >
                                                                {secondSeparator}
                                                            </Typography>
                                                        </div>
                                                        {secondSeparator && <Divider />}
                                                    </Grid>
                                                )}
                                                <Grid item xs={1}>
                                                    <RoundIcon color={colors[idx]} width={10} height={10} />
                                                </Grid>
                                                <Grid item xs={5}>
                                                    <div onMouseEnter={() => hover(idx)}>
                                                        <Typography
                                                            className={currentIdx === idx ? hoverStyle : ''}
                                                            style={{ fontSize: 14, textTransform: 'capitalize' }}
                                                        >
                                                            {item.name}
                                                        </Typography>
                                                    </div>
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <div onMouseEnter={() => hover(idx)}>
                                                        <Typography
                                                            className={classes.labelValue}
                                                            color="primary"
                                                            align="right"
                                                            style={{ fontSize: 14, fontWeight: currentIdx === idx ? 600 : 400 }}
                                                        >
                                                            {formatter.format(item.value)}
                                                        </Typography>
                                                    </div>
                                                </Grid>
                                                {
                                                    (idx === indexSeparator - 1 || idx === transformedData.length - 1) && (
                                                        <Grid
                                                            item
                                                            xs={12}
                                                            justify="flex-end"
                                                            style={{
                                                                marginTop: 16,
                                                                marginBottom: 8,
                                                                display: 'flex',
                                                                justifyContent: 'space-between',
                                                            }}
                                                        >
                                                            <Typography
                                                                align="right"
                                                                variant="overline"
                                                                style={{
                                                                    marginRight: 'auto',
                                                                    fontWeight: 600,
                                                                }}
                                                            >
                                                                Sub total:
                                                            </Typography>
                                                            <Typography style={{ marginLeft: 'auto' }}>
                                                                {formatter.format(subTotais[+(idx >= indexSeparator)])}
                                                            </Typography>
                                                        </Grid>
                                                    )}
                                            </React.Fragment>
                                        ))}
                                    </Grid>

                                    <Grid
                                        item
                                        style={{ paddingRight: 0 }}
                                        container
                                        alignItems="flex-end"
                                        justify="flex-end"
                                        spacing={2}
                                    >
                                        <Grid item>
                                            <Typography align="right">Total</Typography>
                                        </Grid>
                                        <Grid item alignItems="flex-end" style={{ paddingRight: 0 }}>
                                            <Typography className={classes.totalValue} align="right" color="secondary">
                                                {formatter.format(total)}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </div>
                            </CardContent>
                        </Card>
                    </div>
            }
        </div>
    );
};

export default RenderPieChart;
