import { createStyles, makeStyles, Theme } from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        selectButton: {
            backgroundColor: '#ffffff',  
            display: 'flex',
            flexFlow: 'row wrap',
            justifyContent: 'flex-start',
            alignItems: 'flex-end',
            margin: '-5px',
        },
        buttonItem: {
            margin: '5px',
        },
        paper: {
            padding: theme.spacing(2),
        },
    })
);
