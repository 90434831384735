import React from 'react';
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography, } from '@material-ui/core';
import { lineSpacing } from '../../../../theme/spacingBetweenComponents';
import formatter from '../../../global/formater';
import { IPatrimonyProjection, IProjection } from '../../flow/types';
import { IFamily } from '../types';
import { useStyles } from './style';

interface IGrowthFamilyProps { className?: string; elevation: number; family: IFamily; projection: IProjection; independenceYear: number; }

const totalPatrimony = (projection: IPatrimonyProjection) => {
    const total = projection.interest + projection.fixedAsset + projection.investmentAsset;
    return total;
};
const capitalize = (s: string | undefined) => {
    if (!s) {
        return '';
    }
    return s.charAt(0).toUpperCase() + s.slice(1);
};

const GrowthFamily: React.FC<IGrowthFamilyProps> = ({ className, elevation, family, projection, independenceYear }) => {
    const classes = useStyles();

    return (
        <div className={className}>
            {lineSpacing()}
            <Typography variant="h6" className={classes.header}>Crescimento do patrimônio e família</Typography>
            <TableContainer component={props => <Paper {...props} elevation={elevation} />}>
                <Table className={classes.table} aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell>Ano</TableCell>
                            <TableCell align="center">{capitalize(family.customer.name)} - Cliente</TableCell>
                            {family.partner?.name && (
                                <TableCell align="center">{capitalize(family.partner?.name) || ''} - Cônjuge</TableCell>
                            )}
                            {family.children?.map(child => (
                                <TableCell align="center">{capitalize(child.name)} - Filho(a)</TableCell>
                            ))}

                            <TableCell align="right">Patrimônio</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {projection.patrimonyProjection.map((row, i) => (
                            <TableRow key={`projection-${i}`}>
                                <TableCell component="th" scope="row">{independenceYear + i}</TableCell>
                                <TableCell align="center">{family.customer.initialAge + i} anos</TableCell>

                                {family.partner?.name && (
                                    <TableCell align="center">
                                        {family.partner?.name ? `${family.partner.initialAge + i} anos` : '-'}
                                    </TableCell>
                                )}

                                {family.children?.map(child => (
                                    <TableCell align="center">{child.initialAge + i}&nbsp; anos</TableCell>
                                ))}

                                <TableCell style={totalPatrimony(row) < 0 ? { color: 'red' } : undefined} align="right">
                                    {formatter.format(totalPatrimony(row))}
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </div>
    );
};
export default GrowthFamily;