import { createStyles, makeStyles, Theme } from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        customTooltip: {},
        head: {
            marginLeft: '4px',
            marginBlockStart: 0,
            marginBlockEnd: 0,
        },
        value: {
            marginBlockStart: '0.5em',
            marginBlockEnd: 0,
            marginLeft: '6px',
            marginRight: '4px',
        },
    })
);