import { Paper, Typography } from '@material-ui/core';
import { useStyles } from './style'
import React from 'react';
import { BagIcon } from '../../../../assets/icons';
import formatter from '../../formater';

interface DisplayWithBagAndPercentageProps {
    value: number;
    percentage?: number;
}

const DisplayWithBagAndPercentage: React.FC<DisplayWithBagAndPercentageProps> = ({ value, percentage }) => {
    const classes = useStyles();

    return (
        <Paper className={classes.total} elevation={5}>
            <BagIcon />
            <div style={{ paddingLeft: '15px' }}>
                <Typography className={classes.label}>Guardar</Typography>
                <Typography className={classes.totalValue}>{formatter.format(value < 0 ? 0 : value)}</Typography>
            </div>
            <div className={classes.grow} />
            {percentage && percentage !== Infinity && (
                <Typography className={percentage > 0 ? classes.percentageGreater : classes.percentageLesser}>
                    {percentage?.toFixed(1)}%
                </Typography>
            )}
        </Paper>
    );
};
export default DisplayWithBagAndPercentage;