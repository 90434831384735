import { blueGrey, lightBlue } from '@material-ui/core/colors';
import React from 'react';
import RenderPieChart, { IDataChart } from '../../global/charts/renderPieChart/renderPieChart';
import { IConsortium } from '../../planningForm/financing/types';
import { investimentLabel, IPatrimony } from '../../planningForm/patrimony/types';

const convertPatrimonyToData = (patrimony: IPatrimony, consortia: IConsortium[]): IDataChart[] => {
    const propertiesConverted: IDataChart[] =
        patrimony.properties
            ?.map(property => ({
                name: property.label,
                value: property.marketValue,
                additionalInfo: {
                    investimentFocus: property.investimentFocus,
                },
            }))
            .sort((a, b) => a.value - b.value) || [];
    const investimentConverted = Object.entries(investimentLabel).map(([key, label]) => {
        const value = patrimony.financialAssets
            ?.filter(asset => asset.investimentClass === key)
            .reduce((acc, curr) => acc + curr.appliedValue, 0);
        return {
            name: label,
            value,
            additionalInfo: {
                investimentFocus: true,
            },
        };
    });
    const pensionsConverted: IDataChart[] = patrimony.pensions
        ? patrimony.pensions.map(pension => ({
            name: pension.name,
            value: pension.balance,
            additionalInfo: {
                investimentFocus: true,
            },
        }))
        : [];

    const consortiaConverted: IDataChart[] =
        consortia?.map(consortium => ({
            name: 'Carta de Crédito',
            value: consortium.receivedValue,
        })) || [];
    return [
        ...propertiesConverted,
        ...investimentConverted,
        ...pensionsConverted,
        ...consortiaConverted,
        { name: 'Moradia', value: patrimony.house.value },
    ]
        .filter(item => item.value)
        .sort((a, b) => a.value - b.value);
};
interface ICurrentPatrimonyProps {
    patrimony: IPatrimony;
    consortia: IConsortium[];
    netWorth: number[];
    setNetWorth: any;
}

const CurrentPatrimony: React.FC<ICurrentPatrimonyProps> = ({ patrimony, consortia, netWorth, setNetWorth }) => {
    const [data] = React.useState(convertPatrimonyToData(patrimony, consortia));
    const colors = [
        '#5F2EEA',

        '#00BA88',
        '#FEBA69',
        blueGrey[700],
        lightBlue[900],
        '#009D9A',
        '#023C58',
        lightBlue[400],
        '#ED2E7E',
        '#0F62FE',
        '#FF6666',
        '#1CC8EE',
        '#A0D633',
    ];

    return (
        <div>
            <RenderPieChart
                colorSlice={colors}
                sumTotal
                data={data}
                title={'Ativos Totais'}
                netWorth={netWorth} setNetWorth={setNetWorth}
                fieldSeparator="investimentFocus"
                firstSeparator="Sem foco em investimentos"
                secondSeparator="Com foco em investimentos"
            />
        </div>
    );
};

export default CurrentPatrimony;
