import { ReduxAction } from '../../actions/reduxAction';
import { updateCurrentLogged } from '../../actions/userActions';
import { IUserRedux } from '../../types/user';
import { initialState } from './initialState';

export const userReducer = (state = initialState, action: ReduxAction<any>): IUserRedux => {
    switch (action.type) {
        case updateCurrentLogged:
            return { currentUser: action.payload };
        default:
            return state;
    }
};
