import { createStyles, makeStyles, Theme } from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) =>
createStyles({

    header: {
        fontWeight: 600,
    },
    formControllers: {
        width: '100%',
        margin: theme.spacing(2, 0),
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        alignContent: 'flex-end',
    },
    outButton: {
        backgroundColor: 'white',
        color: 'grey[400]',
    },
})
);