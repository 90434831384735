import { createStyles, makeStyles, Theme } from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            padding: theme.spacing(0, 4, 4, 4),
        },
        loading: {
            height: '100%',
            display: 'flex',
            justifyContent: 'center',
        },
        independenceInfo: {
            display: 'flex',
            flexWrap: 'wrap',
            alignItems: 'flex-end',
        },
        childInfo: {
            margin: '8px',
        },
        incomingDisplay: {
            margin: '8px',
            flex: '1 0',
        },
        grow: {
            margin: '8px',
            flex: '1 0 95%',
        },
    })
);
