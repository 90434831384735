import { Button, Chip, createStyles, makeStyles, Theme } from '@material-ui/core';
import { grey } from '@material-ui/core/colors';
import React from 'react';
import TableCost, { ICostValue, ITableEntry } from '../../../global/inputs/tableCost/tableCost';
import CurrencyInput from '../../../global/masks/currencyMaskedInput';
import { ICareValues, IFeedingValues, IPersonalValues, NewCareCost, NewFeedingCost } from '../types';
import { useStyles } from './style';

interface IPersonalCostProps {
    defaultPersonalValues?: IPersonalValues;
    setPersonalValues: React.Dispatch<React.SetStateAction<IPersonalValues>>;
    previousStep: () => void;
    nextStep: () => void;
}

const PersonalCost: React.FC<IPersonalCostProps> = ({
    setPersonalValues,
    nextStep,
    defaultPersonalValues,
    previousStep,
}) => {
    const classes = useStyles();

    const [feedingCosts, setFeedingCosts] = React.useState(NewFeedingCost(defaultPersonalValues?.feeding));
    const feedingCostsLabel: [string, number][] = Object.values(feedingCosts).map((cost): [string, number] => [
        cost.label,
        cost.value,
    ]);

    const [careCosts, setCareCosts] = React.useState(NewCareCost(defaultPersonalValues?.care));
    const careCostsLabel: [string, number][] = Object.values(careCosts).map((cost): [string, number] => [
        cost.label,
        cost.value,
    ]);

    // this function intends to modify the argument object
    function changeCostByType<T extends ICostValue<T>>(
        key: keyof T,
        value: number,
        cost: ITableEntry<IFeedingValues | ICareValues>
    ) {
        ((cost as unknown) as ITableEntry<T>)[key] = {
            ...((cost as unknown) as ITableEntry<T>)[key],
            value,
        };
    }

    const setTableValues = (tableName: 'feeding' | 'care', values: Map<string, number>) => {
        const costs = tableName === 'feeding' ? { ...feedingCosts } : { ...careCosts };

        values.forEach((val, key) => {
            // forEach value find it in habitationalCost by its label
            const objectKeyForLabel = Object.entries(costs).filter(([_, costVal]) => costVal.label === key)![0]![0];
            // if there's a key for that label, then modify its value
            if (objectKeyForLabel) {
                switch (tableName) {
                    case 'feeding':
                        {
                            const keyEntry = objectKeyForLabel as keyof IFeedingValues;
                            changeCostByType<IFeedingValues>(keyEntry, val, costs);
                        }
                        break;
                    case 'care':
                        {
                            const keyEntry = objectKeyForLabel as keyof ICareValues;
                            changeCostByType<ICareValues>(keyEntry, val, costs);
                        }
                        break;
                }
            }
        });

        if (tableName === 'feeding') {
            setFeedingCosts(costs as ITableEntry<IFeedingValues>);
        } else {
            setCareCosts(costs as ITableEntry<ICareValues>);
        }
    };
    const [btnDisabled, setBtnDisabled] = React.useState(false);

    const onSumbit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        const personalValues: IPersonalValues = {
            feeding: {
                lunch: feedingCosts.lunch.value,
                superMarket: feedingCosts.superMarket.value,
                supplement: feedingCosts.supplement.value,
                breadWine: feedingCosts.breadWine.value,
            },
            care: {
                clinical: careCosts.clinical.value,
                gym: careCosts.gym.value,
                other: careCosts.other.value,
                perfume: careCosts.perfume.value,
            },
        };
        setPersonalValues(personalValues);
        nextStep();
        setBtnDisabled(true);
    };

    return (
        <div className={classes.root}>
            <form onSubmit={onSumbit}>
                <div className={classes.content}>
                    <TableCost
                        risk=''
                        className={classes.table}
                        header="Custos de Alimentação"
                        rows={feedingCostsLabel}
                        tableValues={values => setTableValues('feeding', values)}
                        showTotal
                        inputComponent={CurrencyInput as any}
                    />
                    <TableCost
                        risk=''
                        className={classes.table}
                        header="Custos de Cuidados Pessoais"
                        rows={careCostsLabel}
                        tableValues={values => setTableValues('care', values)}
                        showTotal
                        inputComponent={CurrencyInput as any}
                    />
                    <div className={classes.formControllers}>
                    {/* @ts-ignore */}
                        <Button
                            variant="contained"
                            className={classes.outButton}
                            size="medium"
                            color="default"
                            disableElevation
                            onClick={previousStep}
                        >
                            Voltar
                        </Button>
                        <button type="submit" className={classes.chipButton} disabled={btnDisabled}>
                            <Chip className={classes.chip} color="primary" size="medium" label="Próximo" clickable />
                        </button>
                    </div>
                </div>
            </form>
        </div>
    );
};
export default PersonalCost;
