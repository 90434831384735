import { IMonthlyObject } from '../../../components/customerPages/homework/types';
import { ICustomerRedux } from '../../types/customer';

const objectList: IMonthlyObject[] = [];
const monthlyGoal = {
    saved: 0,
    saveGoal: 0,
};
const monthlyGoalsArray = [
    monthlyGoal,
    monthlyGoal,
    monthlyGoal,
    monthlyGoal,
    monthlyGoal,
    monthlyGoal,
    monthlyGoal,
    monthlyGoal,
    monthlyGoal,
    monthlyGoal,
    monthlyGoal,
    monthlyGoal,
];
const portItem = {
    value: 1,
    yield: 1,
};
const port = {
    fixedIncome: portItem,
    variableIncome: portItem,
    multimarkets: portItem,
    pensions: portItem,
    exchange: portItem,
    realState: portItem,
    savings: portItem,
    current: portItem,
};
const projection = [
    {
        interest: 50,
        patrimony: 300,
    },
];

export const initialState: ICustomerRedux = {
    financialHealth: {
        planningDate: new Date(),
        currentState: {
            consortia: [],
            passiveConsortia: 0,
            bonuses: 0,
            fixedCost: {
                habitational: {
                    condoFee: 0,
                    rent: 0,
                    domesticWorkers: 0,
                    eletricityBill: 0,
                    communicationBill: 0,
                    gasBill: 0,
                    iptu: 0,
                    waterBill: 0,
                    houseMaintenance: 0,
                    houseInsurance: 0,
                    other: 0,
                },
                education: {
                    school: 0,
                    books: 0,
                    other: 0,
                    sports: 0,
                },
                transport: {
                    fuel: 0,
                    maintenance: 0,
                    sharedTransport: 0,
                    ipva: 0,
                    insurance: 0,
                    uber: 0,
                    other: 0,
                },
                feeding: {
                    superMarket: 0,
                    lunch: 0,
                    supplement: 0,
                    breadWine: 0,
                },
                care: {
                    clinical: 0,
                    perfume: 0,
                    gym: 0,
                    other: 0,
                },
                recreation: {
                    diversion: 0,
                    streaming: 0,
                    hobbies: 0,
                    club: 0,
                    other: 0,
                },
                health: { insurancePlan: 0, medicalExpenses: 0, medicament: 0, other: 0 },
                other: {
                    tithe: 0,
                    pet: 0,
                    alimony: 0,
                    classCouncil: 0,
                    other: 0,
                },
                costChange: [],
            },
            left: 0,
            monthlyIncoming: 0,
            patrimony: {
                house: {
                    forSell: false,
                    value: 0,
                    debit: 0,
                },
                properties: [],
                financialAssets: [],
                pensions: [],
            },
            profits: 0,
            totalActives: 0,
        },
        desiredState: {
            duration: 0,
            goals: {
                financialIndependence: {
                    age: 0,
                    desiredIncoming: 0,
                    lifeExpectation: 0,
                },
                goals: [],
            },
            age: 0,
            neededForIndependence: 0,
            totalGoals: 0,
            yearsUntilIndependence: 0,
            monthsUntilIndependence: 0,
            daysUntilIndependence: 0,
        },
        financialIndependenceGoals: {
            independenceGoal: 0,
            totalAccumulated: 0,
            updatedAccumulated: 0,
            timeToIndependence: 0,
            achievedIncoming: 0,
        },
        considerationsText: '',
        actions: { goalReduction: 0, monthlyLeft: 0, years: 0, rate: 0 },
        chart: {
            patrimonyAtIndependence: 0,
            neededForIndependence: 0,
            achievedIncoming: 0,
        },
        houseSell: {
            value: 0,
            yearIndex: -1,
        },
        table: {
            financingProjection: [],
            fixedCostProjection: [],
            incomingProjection: [],
            objectiveProjection: [],
            patrimonyProjection: [],
            consortiumProjection: [],
            insuranceProjection: [],
            contributionProjection: [],
            pensionProjection: [],
            planningDuration: 0,
            pensionPayment: 0,
            planningYear: 0,
        },
        rentability: 0.04,
        userRentability: 0,
        monthsToTheEnd: 1,
    },
    incomingDestination: {
        cars: 0,
        trips: 0,
        expenses: 0,
        financing: 0,
        goals: 0,
        financialIndependence: 0,
        pension: 0,
        protection: 0,
        incomingGenerated: 0,
        initialPatrimony: 0,
    },
    financialIndependence: {
        duration: 0,
        longlifeWithdraw: 0,
        family: {
            children: [],
            customer: {
                initialAge: 0,
                name: '',
                relationship: '',
            },
        },
        patrimonyAtIndependence: 0,
        projection: {
            objectivesProjection: [],
            patrimonyProjection: [],
            incomingChanges: [],
        },
        withdraw: 0,
        independenceYear: 0,
    },
    flow: {
        actions: { goalReduction: 0, monthlyLeft: 0, years: 0, rate: 0 },
        chart: {
            patrimonyAtIndependence: 0,
            neededForIndependence: 0,
            achievedIncoming: 0,
            independenceYear: 0,
            interestAcc: 0,
            projectionChart: {
                objectivesProjection: [],
                patrimonyProjection: [],
                incomingChanges: [],
            },
        },
        houseSell: {
            value: 0,
            yearIndex: -1,
        },
        table: {
            financingProjection: [],
            fixedCostProjection: [],
            incomingProjection: [],
            objectiveProjection: [],
            patrimonyProjection: [],
            consortiumProjection: [],
            insuranceProjection: [],
            contributionProjection: [],
            pensionProjection: [],
            planningDuration: 0,
            pensionPayment: 0,
            planningYear: 0,
        },
        rentability: 0.04,
        userRentability: 0,
        monthsToTheEnd: 1,
    },
    homework: {
        financialIndependenceGoals: {
            achievedIncoming: 0,
            timeToIndependence: 0,
            totalAccumulated: 0,
            updatedAccumulated: 0,
            independenceGoal: 0,
            accumulated: 0,
            expectedAccumulated: 0,
        },
        considerationsText: '',
        lastYear: 2020,
        planningYear: 2020,
        currentYear: new Date().getFullYear(),
        minYear: 2020,
        maxYear: 2025,
        yearlyGoals: [
            {
                yearAmount: 0,
                year: 0,
                goalPercentage: 0,
                yearLeftovers: 0,
                totalObjectives: 0,
                monthlyGoals: monthlyGoalsArray,
                implementations: objectList,
            },
        ],
    },
    investmentPortfolio: {
        portfolio: [],
        suggestedPortfolio: [],
        suggestedPortfolioApproach: 1,
        portfolioProjection: {
            suggestedProjection: [],
            portfolioProjection: [],
            independenceYear: 0,
            additionalValue: 0,
            approach: 1,
        },
        summary: {
            current: {
                applied: 0,
                realGain: 0,
                rentability: 0,
                rentabilityCDI: 0,
            },
            suggested: {
                applied: 0,
                realGain: 0,
                rentability: 0,
                rentabilityCDI: 0,
            },
        },
    },
    riskManagement: {
        corrections: {
            anualLeft: {
                percentage: 0,
                value: 0,
            },
            investedPatrimony: {
                percentage: 0,
                value: 0,
            },
        },
        emergencySavings: {
            duration: 0,
            investiment: 0,
            savings: 0,
        },
        inventoryFreeAssets: {
            temporaryLifeInsurance: 0,
            permanentLifeInsurance: 0,
            lifeInsurance: 0,
            privatePension: 0,
        },
        lifeQuality: {
            projectionDuration: 0,
            duration: 0,
            monthlyIncoming: 0,
            necessaryPatrimony: 0,
            totalInvested: 0,
        },
        projection: {
            incomingChanges: [],
            objectivesProjection: [],
            patrimonyProjection: [],
        },
        protectionNeeded: {
            current: {
                inventoryFreeAssets: 0,
                lifeQuality: 0,
                successionCost: 0,
            },
            future: {
                inventoryFreeAssets: 0,
                lifeQuality: 0,
                successionCost: 0,
            },
        },
        successionCosts: {
            current: 0,
            future: 0,
            currentTaxes: {
                itcmd: 0,
                oab: 0,
                notary: 0,
            },
            futureTaxes: {
                itcmd: 0,
                oab: 0,
                notary: 0,
            },
        },
        contracted: {
            contractedInsurances: [],
            dedicatedPercentage: 0,
            patrimonyPercentage: 0,
            paidYearly: 0,
        },
        actions: { goalReduction: 0, monthlyLeft: 0, years: 0, rate: 0 },
        chart: {
            patrimonyAtIndependence: 0,
            neededForIndependence: 0,
            achievedIncoming: 0,
            independenceYear: 0,
            interestAcc: 0,
            projectionChart: {
                objectivesProjection: [],
                patrimonyProjection: [],
                incomingChanges: [],
            },
        },
        houseSell: {
            value: 0,
            yearIndex: -1,
        },
        table: {
            financingProjection: [],
            fixedCostProjection: [],
            incomingProjection: [],
            objectiveProjection: [],
            patrimonyProjection: [],
            consortiumProjection: [],
            insuranceProjection: [],
            contributionProjection: [],
            pensionProjection: [],
            planningDuration: 0,
            pensionPayment: 0,
            planningYear: 0,
        },
        rentability: 0.04,
        userRentability: 0,
        monthsToTheEnd: 1,
    },
    successionWithdraw: 0,
    rentability: 0,

    homeworkPage: {
        currentMonth: 0,
    },
    investimentPage: {
        porfolioSummary: {
            current: {
                classLabel: '',
                assets: [],
            },
            suggested: {
                classLabel: '',
                assets: [],
            },
        },
    },
};
