import { CssBaseline } from '@material-ui/core';
import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Redirect, Route, Switch } from 'react-router';
import api from '../apis/dreamrichapi';
import InvalidPlanning from '../components/customerPages/InvalidPlanning/invalidPlanning';
import ToolbarInfoPlanner from '../components/global/navigation/navbar/desktop/navbar';
import SideBar from '../components/global/navigation/sideBar/desktop/sideBar';
import menuOptions from '../menuOptions';
import { IStore } from '../redux/types/store';
import { useStyles, drawerWidth } from './style';

interface IClientPageProps {
    onSignOut: () => void;
}

const CustomerPage: React.FC<IClientPageProps> = ({ onSignOut }) => {
    const classes = useStyles();

    const { planningDate, group } = useSelector((store: IStore) => ({
        planningDate: store.customer.financialHealth.planningDate,
        group: store.loggedUser.currentUser['cognito:groups']?.[0] || '',
    }));

    const realName = useSelector((store: IStore) => store.planning.customerFormValues.customerInfo.personalInfo.personalInfo.fullName);

    const signOut = async () => {
        try {
            await api.get('/signout');
            onSignOut();
        } catch (e) {
            console.debug(e);
        }
    };

    const isCustomers = group === 'Customers';

    return isCustomers ? (
        <div className={classes.root}>
            <ToolbarInfoPlanner planningDate={planningDate} drawerWidth={drawerWidth} customerName={realName} />
            <SideBar onSignOut={signOut} drawerWidth={drawerWidth} useRedirect />
            <div className={classes.content}>
                <div className={classes.toolbar} />
                <CssBaseline />
                <Switch>
                    {menuOptions.map(({ Component, ...item }, i) =>
                        item.name !== 'Gestão de Riscos' && item.name !== 'Carteira & Investimentos' ? (
                            <Route path={item.url}>
                                <Component pageNumber={i} />
                            </Route>
                        ) : null
                    )}
                    <Route path="/invalid_planning" exact>
                        <InvalidPlanning />
                    </Route>
                    <Redirect exact from="/" to="/home" />
                    <Redirect exact from="/home" to="/financialHealth" />
                </Switch>
            </div>
        </div>
    ) : (
        <div className={classes.root}>
            <ToolbarInfoPlanner planningDate={planningDate} drawerWidth={drawerWidth} customerName={realName} />
            <SideBar onSignOut={signOut} drawerWidth={drawerWidth} useRedirect />
            <div className={classes.content}>
                <div className={classes.toolbar} />
                <CssBaseline />
                <Switch>
                    {menuOptions.map(({ Component, ...item }, i) => (
                        <Route path={item.url}>
                            <Component pageNumber={i} />
                        </Route>
                    ))}
                    <Route path="/invalid_planning" exact>
                        <InvalidPlanning />
                    </Route>
                    <Redirect exact from="/" to="/home" />
                    <Redirect exact from="/home" to="/financialHealth" />
                </Switch>
            </div>
        </div>
    );
};
export default CustomerPage;
