import {   Paper,  Typography } from '@material-ui/core';
import { useStyles } from './style'
import React from 'react';
import { BagIcon } from '../../../../assets/icons';
import formatter from '../../formater';

interface DisplayWithBagProps {
    label: string;
    value: number;
    sublabel?: string;
}

const DisplayWithBag: React.FC<DisplayWithBagProps> = ({ label, value, sublabel }) => {
    const classes = useStyles();

    return (
        <>
            {
                window.screen.width <= 767 
                ?
                <>
                    <Paper className={classes.total} elevation={5}>
                        <div className={classes.cardRow}>
                            <><BagIcon width={38} height={38} /></>
                            <div className={classes.cardColumn}>
                                <Typography className={classes.label}>{label}</Typography>
                                <Typography className={classes.sublabel}>{sublabel}</Typography>
                            </div>
                        </div>
                        <>
                            <div className={classes.grow} />
                            <Typography variant="h5" align="right" className={classes.totalValue}>
                                {formatter.format(value)}
                            </Typography>
                        </>
                    </Paper>
                </>
                :
                <>
                    <Paper className={classes.total} elevation={5}>
                        <BagIcon width={38} height={38} />
                        <>
                            <Typography className={classes.label}>{label}</Typography>
                            <Typography className={classes.sublabel}>{sublabel}</Typography>
                        </>
                        <div className={classes.grow} />
                        <Typography variant="h5" align="right" className={classes.totalValue}>
                            {formatter.format(value)}
                        </Typography>
                    </Paper>
                </>
            }
        </>
    );
};
export default DisplayWithBag;
