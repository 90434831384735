import React from 'react';
import { useSelector } from 'react-redux';
import { IStore } from '../../../../redux/types/store';
import { IUserEntry } from '../clientEntry';
import { TablePlanner } from './TablePlanner/TablePlanner';
import { TableAdmin } from './TableAdmin/TableAdmin';

type Options = {
    value: IUserEntry;
    label: string;
    labelUser: string;
};

interface ICustomerTableProps {
    customers: IUserEntry[];
    loading: boolean;
    options: Options[];
    selectUser: (user: IUserEntry) => void;
    openModalChangePlanner: (user: IUserEntry) => void;
    openModalDeletePlanner: any;
    openDeleteClient: any;
    fetchList: () => any;
}

const CustomerTable: React.FC<ICustomerTableProps> = ({
    customers,
    options,
    loading,
    openModalChangePlanner,
    openModalDeletePlanner,
    openDeleteClient,
    selectUser,
    fetchList,
}) => {
    const { group, myUserID, myUsername } = useSelector((store: IStore) => ({
        group: store.loggedUser.currentUser['cognito:groups']?.[0] || '',
        myUserID: store.loggedUser.currentUser.sub,
        myUsername: store.loggedUser.currentUser['cognito:username'],
    }));

    const isPlanner = group === 'Planners';

    return (
        <>
            {isPlanner ? (
                <TablePlanner
                    customers={customers}
                    options={options}
                    loading={loading}
                    openModalChangePlanner={openModalChangePlanner}
                    selectUser={selectUser}
                    fetchList={fetchList}
                />
            ) : (
                <TableAdmin
                    customers={customers}
                    options={options}
                    loading={loading}
                    openModalChangePlanner={openModalChangePlanner}
                    openModalDeletePlanner={openModalDeletePlanner}
                    openDeleteClient={openDeleteClient}
                    selectUser={selectUser}
                    fetchList={fetchList}
                />
            )}
        </>
    );
};

export default CustomerTable;
