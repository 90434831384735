import { Button, CircularProgress, Typography } from '@material-ui/core';
import React from 'react';
import ReactMde from 'react-mde';
import Showdown from 'showdown';
import { getDefaultToolbarCommands } from 'react-mde';
import 'react-mde/lib/styles/css/react-mde-all.css';
import { useStyles } from './style';

const converter = new Showdown.Converter({ tables: true, simplifiedAutoLink: true, strikethrough: true, tasklists: true, });

interface IConsiderationsProps { 
    customer?: boolean; 
    onUpdate: (considerations: string) => Promise<boolean>; 
    defaultText: string; 
}

const Considerations: React.FC<IConsiderationsProps> = ({ customer, onUpdate, defaultText }) => {
    const classes = useStyles();

    const [value, setValue] = React.useState(
        defaultText || 'Escreva aqui as considerações necessárias acerca do planejamento'
    );

    const [selectedTab, setSelectedTab] = React.useState<'write' | 'preview'>(customer ? 'preview' : 'write');
    const [loading, setLoading] = React.useState(false);
    const [error, setError] = React.useState(false);

    const update = async () => {
        setLoading(true);
        setError(false);
        const updated = await onUpdate(value);
        setError(!updated);
        setLoading(false);
    };

    return (
        <div style={{ margin: '2vh' }}>
            <Typography className={classes.subHeading} variant="h6">Considerações</Typography>
            <div className={classes.container}>
                <ReactMde
                    value={value}
                    onChange={setValue}
                    selectedTab={selectedTab}
                    l18n={{
                        write: customer ? '' : 'Editar',
                        preview: 'Visualizar',
                        uploadingImage: 'Subindo imagem',
                        pasteDropSelect: 'Solte para adicionar a imagem',
                    }}
                    readOnly={customer || loading}
                    toolbarCommands={customer ? [] : getDefaultToolbarCommands()}
                    onTabChange={customer ? () => {} : setSelectedTab}
                    generateMarkdownPreview={markdown => Promise.resolve(converter.makeHtml(markdown))}
                />
            </div>
            <div hidden={customer} className={classes.button}>
                {/* @ts-ignore */}
                <Button
                    variant="outlined"
                    color={!loading ? 'primary' : 'default'}
                    style={{ backgroundColor: error ? 'red' : undefined }}
                    onClick={update}
                >
                    {loading ? <CircularProgress size={24} /> : 'Salvar'}
                </Button>
            </div>
        </div>
    );
};

export default Considerations;
