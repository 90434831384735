import { createMuiTheme, Theme } from '@material-ui/core';

const appTheme = createMuiTheme({
    palette: {
        primary: {
            light: '#023c58',
            main: '#086b88',
            dark: '#023c58',
        },
        secondary: {
            light: '#03a9f4',
            main: '#00878b',
            dark: '#00575b ',
        },

        error: {
            light: '#FF9C8A',
            main: '#FF7258',
            dark: '#FF553D',
        },
        info: {
            light: '#72C8FF',
            main: '#3FB5FF',
            dark: '#0094F1',
        },
        warning: {
            light: '#FF9C8A',
            main: '#ff7258',
            dark: '#FF422D',
        },

        success: {
            light: '#d6ff66',
            main: '#a1d830',
            dark: '#a0d633',
        },

        text: {
            primary: '#263238',
            secondary: '#455a64',
            disabled: '#78909c',
        },

        action: {
            active: `rgba(38, 50, 56, 0.87)`,
            hover: `rgba(38, 50, 56, 0.54)`,
            selected: `rgba(38, 50, 56, 0.38)`,
            disabled: `rgba(38, 50, 56, 0.38)`,
            disabledBackground: `rgba(38, 50, 56, 0.38)`,
        },

        background: {
            default: '#ffffff',
            paper: '#ffffff',
        },

        contrastThreshold: 3,
        tonalOffset: 0.2,
    },

    typography: {
        fontFamily: ['Avenir Next', 'Source Sans', '"Helvetica Neue"', 'Arial', 'sans-serif'].join(','),
    },
    overrides: {
        MuiPaper: {
            rounded: {
                borderRadius: '8px',
            },
            root: {
                borderRadius: 8,
            },
        },
    },
});

const {
    breakpoints,
    typography: { pxToRem },
} = appTheme;

const theme: Theme = {
    ...appTheme,
    overrides: {
        MuiTypography: {
            overline: {
                letterSpacing: '1.5px',
                fontSize: '10px',
            },
            h4: {
                fontWeight: 700,
                fontSize: '26px',
                lineHeight: '28px',
                [breakpoints.down('lg')]: {
                    fontSize: '22px',
                    lineHeight: '26px',
                },
            },
            h5: {
                fontSize: 22,
                fontWeight: 'bold',
                [breakpoints.down('lg')]: {
                    fontSize: '18px',
                },
            },
            h6: {
                fontSize: '18px',
                letterSpacing: '0.15px',
                fontWeight: 'bold',
                [breakpoints.down('lg')]: {
                    fontSize: '16px',
                },
            },
            body1: {
                letterSpacing: '0.5px',
                fontSize: '16px',
                lineHeight: '28px',
                [breakpoints.down('lg')]: {
                    fontSize: '16px',
                },
            },
            body2: {
                fontSize: '16px',
                lineHeight: 1.43,
                letterSpacing: 0.25,
            },
            subtitle2: {
                fontSize: 20,
                fontWeight: 500,
            },
        },
        MuiButton: {
            outlined: {
                borderRadius: appTheme.spacing(1),
            },
            outlinedPrimary: {
                backgroundColor: appTheme.palette.primary.light,
                color: 'white',
                '&:hover': {
                    color: 'black',
                },
            },
        },
        MuiChip: {
            colorPrimary: {
                backgroundColor: appTheme.palette.primary.dark,
                color: 'white',
            },
        },
        MuiLinearProgress: {
            root: {
                height: 7,
                borderRadius: 25,
            },
        },
        MuiCardContent: {
            root: {
                '&:last-child': {
                    paddingBottom: appTheme.spacing(2),
                },
            },
        },
    },
};

export default theme;
