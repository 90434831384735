import { createStyles, makeStyles, Theme } from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        content: {
            padding: '50px',
        },
        header: {
            fontWeight: 600,
        },
        formControllers: {
            paddingTop: theme.spacing(2),
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            alignContent: 'flex-end',
        },
        outButton: {
            backgroundColor: 'white',
            color: '#bdbdbd',
        },
        chip: {
            color: '#FFFFFF',
            backgroundColor: theme.palette.primary.dark,
        },
        subHeader: {
            margin: 'auto',
            width: '30vw',
        },
    })
);
