import { Button, Card, createStyles, makeStyles, Theme, Typography } from '@material-ui/core';
import React from 'react';
import { GreyedTextInput } from '../../../global/inputs/GreyedInput';
import FinancingInputForm from '../financingInputForm/financingInputForm';
import { IFinancingValue } from '../types';
import { useStyles } from './style'

interface IFinancingInputProps {
    onClickAdd: (financingInput: IFinancingValue) => void;
}

const FinancingInput: React.FC<IFinancingInputProps> = ({ onClickAdd }) => {
    const classes = useStyles();
    const [selected, setSelected] = React.useState<'price' | 'sac' | 'other'>('other');
    const [name, setName] = React.useState('');
    const [error, setError] = React.useState('');

    const PMT = (ir: number, np: number, pv: number, fv = 0, t = 0) => {
        if (ir === 0) {
            if (np === 0) {
                return pv;
            }
            return pv / np;
        }

        const rate = Math.pow(1 + ir, np) * ir;
        const factor = Math.pow(1 + ir, np) - 1;
        const pmt = (pv * rate) / factor;
        return -pmt;
    };

    const onAdd = (financing: IFinancingValue) => {
        if (!name) {
            setError('Defina um apelido para o financiamento');
        } else {
            setError('');
            const monthlyRate = Math.pow(1.0 + financing.interestRate / 100.0, 1.0 / 12) - 1;
            switch (selected) {
                case 'sac': {
                    const amortization = financing.debit / financing.monthsLeft;
                    const interest = financing.debit * monthlyRate;
                    const monthPayment = interest + amortization;
                    financing.installment = monthPayment;
                    break;
                }
                case 'price': {
                    const monthPayment = PMT(monthlyRate, financing.monthsLeft, -financing.debit);
                    financing.installment = Math.round((monthPayment + Number.EPSILON) * 100) / 100;
                    break;
                }
                case 'other': {
                    financing.debit = financing.installment * financing.monthsLeft;
                    break;
                }
            }
            onClickAdd(financing);
        }
    };

    return (
        <div>
            <Card elevation={3} className={classes.paper}>
                <Typography className='mb-2' variant="body2">Nome do Financiamento</Typography>
                <GreyedTextInput
                    color="primary"
                    variant="outlined"
                    size="small"
                    placeholder="Apelido do financiamento"
                    value={name}
                    onChange={event => setName(event.target.value)}
                    fullWidth
                    error={error !== ''}
                    helperText={error}
                />
                <Typography className='mb-2' style={{ marginTop: 8 }} variant="body2">
                    Categoria
                </Typography>
                <div className={classes.selectButton}>
                    {/* @ts-ignore */}
                    <Button
                        className={classes.buttonItem}
                        disableElevation
                        variant="outlined"
                        color={selected === 'other' ? 'primary' : 'default'}
                        onClick={() => setSelected('other')}
                    >
                        Outros
                    </Button>
                    {/* @ts-ignore */}
                    <Button
                        className={classes.buttonItem}
                        variant="outlined"
                        color={selected === 'price' ? 'primary' : 'default'}
                        disableElevation
                        onClick={() => setSelected('price')}
                    >
                        Price
                    </Button>
                    {/* @ts-ignore */}
                    <Button
                        className={classes.buttonItem}
                        disableElevation
                        variant="outlined"
                        color={selected === 'sac' ? 'primary' : 'default'}
                        onClick={() => setSelected('sac')}
                    >
                        SAC
                    </Button>
                </div>
                <FinancingInputForm type={selected} name={name} addFinancing={onAdd} />
            </Card>
        </div>
    );
};
export default FinancingInput;
