import { createStyles, makeStyles, Theme } from '@material-ui/core';
import { grey, red } from '@material-ui/core/colors';

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        header: {
            marginTop: theme.spacing(2),
        },
        head: {
            padding: theme.spacing(1),
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            alignItems: 'center',
        },
        form: {
            display: 'flex',
            flexFlow: 'column wrap',
            position: 'absolute',
            margin: 'auto',
            right: 0,
            left: 0,
        },
        table: {
            minWidth: '60%',
        },
        formControllers: {
            width: '100%',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            alignContent: 'flex-end',
        },
        chipButton: {
            backgroundColor: 'transparent',
            border: 'none',
        },
        chip: {
            color: grey[50],
        },
        subheader: {
            margin: theme.spacing(2, 0),
        },
        outButton: {
            backgroundColor: 'white',
            color: grey[400],
        },
    })
);