import React from 'react';
import { Card, CardActionArea, CardContent, Grid, MenuItem, Paper, Select, Typography, } from '@material-ui/core';
import { Cell, Pie, PieChart, ResponsiveContainer } from 'recharts';
import { RoundIcon } from '../../../../../assets/icons';
import renderActiveShape from '../../../../global/charts/renderPieShape';
import formatter from '../../../../global/formater';
import { IFixedCost } from '../../../../planningForm/fixedCost/types';
import { useStyles, slices } from '../mobile/style';

const fieldLabelTable = new Map<string, string>([
    ['habitational', 'Habitação'],
    ['feeding', 'Alimentação'],
    ['care', 'Cuidado Pessoal'],
    ['recreation', 'Recreação e cultura'],
    ['health', 'Saúde'],
    ['education', 'Educação'],
    ['transport', 'Transporte'],
    ['other', 'Outros'],
]);

interface IDataChart { name: string; value: number; }

const convertFixedCostToData = (expenses: IFixedCost, monthly: boolean): IDataChart[] => {
    const converted: IDataChart[] = Object.entries(expenses)
        .filter(([field, value]) => value && typeof value === 'object')
        .map(([field, value]) => ({
            name: fieldLabelTable.get(field) || '',
            value: Object.entries(value)
                .map(([_, costValue]) => costValue as number)
                .reduce((acc, curr) => acc + curr * (monthly ? 1 : 12), 0),
        }))
        .filter(item => item.name)
        .sort((a, b) => a.value - b.value);
    return converted;
};

interface IExpensesProps { expenses: IFixedCost; left: number; }

const Expenses: React.FC<IExpensesProps> = ({ expenses, left }) => {
    const classes = useStyles();

    const [currentIdx, setIdx] = React.useState(-1);
    const [hoverStyle, setHoverStyle] = React.useState('');

    const hover = (dataIdx: number) => {
        setIdx(dataIdx);
        setHoverStyle(classes.labelHover);
    };

    const [monthly, setMonthly] = React.useState(false);

    const handleChange = (
        event: React.ChangeEvent<{
            value: unknown;
        }>
    ) => {
        const isMonthly = event.target.value === 1 ? true : false;
        setMonthly(isMonthly);
    };

    const [data, setData] = React.useState(convertFixedCostToData(expenses, monthly));

    React.useEffect(() => setData(convertFixedCostToData(expenses, monthly)), [monthly]);

    const [total, setTotal] = React.useState(0);

    React.useEffect(() => {
        const reduced = data.map(item => item.value).reduce((acc, curr) => acc + curr, 0);

        setTotal(reduced);
    }, [data, monthly]);

    return (
        <>
            <Card className={classes.root} elevation={0}>
                <CardContent className={classes.cardContent}>
                    <Typography className={classes.cardHeader} gutterBottom>Despesas</Typography>
                    <Grid container justify="flex-end" alignItems="flex-end">
                        <Grid item xs={8} />
                        <Grid item xs={4}>
                            <Select
                                labelId="monthly-selector"
                                id="monthly-select"
                                value={monthly ? 1 : 0}
                                defaultValue={1}
                                onChange={handleChange}
                                style={{ width: '150px' }} >
                                <MenuItem value={1}>Mensal</MenuItem>
                                <MenuItem value={0}>Anual</MenuItem>
                            </Select>
                        </Grid>
                        <div className={classes.chart}>
                            <Grid className={classes.cardMargin} container item xs={12}>
                                <Grid item xs={6}>
                                    <ResponsiveContainer height={250}>
                                        <PieChart width={300} height={600}>
                                            <Pie
                                                activeIndex={currentIdx}
                                                activeShape={renderActiveShape}
                                                data={data}
                                                isAnimationActive={false}
                                                dataKey="value"
                                                nameKey="name"
                                                cx="50%"
                                                cy="50%"
                                                startAngle={90}
                                                endAngle={450}
                                                innerRadius={57}
                                                outerRadius={90}
                                                fill="#8884d8"
                                                onMouseEnter={(_, index) => setIdx(index)}
                                                onMouseLeave={() => setIdx(-1)} >
                                                {slices.map(entry => (
                                                    <Cell fill={entry} />
                                                ))}
                                            </Pie>
                                        </PieChart>
                                    </ResponsiveContainer>
                                </Grid>
                                <div className={classes.chartDescription}>
                                    <Grid
                                        item
                                        container
                                        xs={6}
                                        sm
                                        onMouseLeave={() => setIdx(-1)}
                                        justify="space-between"
                                        alignItems="baseline"
                                        alignContent="center" >
                                        {data
                                            .map((item, idx) => (
                                                <React.Fragment>
                                                    <Grid item xs={1}>
                                                        <RoundIcon color={slices[idx]} width={10} height={10} />
                                                    </Grid>
                                                    <Grid item xs={5}>
                                                        <div onMouseEnter={() => hover(idx)}>
                                                            <Typography
                                                                className={currentIdx === idx ? hoverStyle : ''}
                                                                style={{ fontSize: 14 }}
                                                            >
                                                                {item.name}
                                                            </Typography>
                                                        </div>
                                                    </Grid>
                                                    <Grid item xs={6}>
                                                        <div onMouseEnter={() => hover(idx)}>
                                                            <Typography
                                                                className={classes.labelValue}
                                                                color="primary"
                                                                align="right"
                                                                style={{
                                                                    fontSize: 14,
                                                                    fontWeight: currentIdx === idx ? 600 : 400,
                                                                }}
                                                            >
                                                                {formatter.format(item.value)}
                                                            </Typography>
                                                        </div>
                                                    </Grid>
                                                </React.Fragment>
                                            ))
                                            .reverse()}
                                    </Grid>
                                </div>
                            </Grid>
                        </div>
                    </Grid>
                </CardContent>
                <CardActionArea disabled classes={{ root: classes.cardTotal }}>
                    <Typography>Custo Total {monthly ? 'Mensal' : 'Anual'}</Typography>
                    <Typography className={classes.valueText}>{formatter.format(total)}</Typography>
                </CardActionArea>
            </Card>
            <Paper elevation={3}>
                <div className={classes.totalLabel}>
                    <>
                        <Typography style={{ lineHeight: '20px' }}>
                            Capacidade de sobras {monthly ? 'Mensal' : 'Anual'}
                        </Typography>
                        <Typography style={{ fontSize: 10, lineHeight: '10px' }} variant="overline">
                            Receitas menos despesas
                        </Typography>
                    </>
                    <Typography variant="h4" style={{ color: '#03a9f4' }}>
                        {formatter.format(left * (monthly ? 1 : 12))}
                    </Typography>
                </div>
            </Paper>
        </>
    );
};

export default Expenses;
