import { createStyles, makeStyles, Theme } from '@material-ui/core';
import { grey, red } from '@material-ui/core/colors';

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        paper: {
            position: 'absolute',
            width: 500,
            backgroundColor: theme.palette.background.paper,
            boxShadow: theme.shadows[5],
            padding: theme.spacing(2, 4, 3),
            top: `50%`,
            outline: 0,
            left: `50%`,
            transform: `translate(-50%, -50%)`,
            '& > :not(:first-child)': {
                marginTop: theme.spacing(1),
            },
        },
        line: {
            backgroundColor: '#FFFFFF',  
            display: 'flex',
            '& > :not(:first-child)': {
                marginLeft: theme.spacing(2),
            },
        },
        spacedLine: {
            display: 'flex',
            justifyContent: 'flex-start',
        },
        actions: {
            display: 'flex',
            justifyContent: 'flex-end',
            width: '100%',
        },
    })
);
