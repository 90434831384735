import React from 'react';
import { Paper, Typography } from '@material-ui/core';
import { ResponsiveContainer, ComposedChart, CartesianGrid, XAxis, Legend, YAxis, Bar, Cell, Tooltip } from 'recharts';
import { IProjection } from '../../flow/types';
import CustomTooltip from '../../../global/charts/customTooltip/customTooltip';
import { sumData } from '../../../../utils/utils';
import { useStyles } from './style'

interface IProjectionProps { className?: string; elevation?: number; projection: IProjection; withDraw: number; independenceYear: number; }

const convertProjectionToData = (projection: IProjection, withDraw: number, independenceYear: number) =>
    projection.patrimonyProjection.map((patrimonyProjection, i) => {
        const patrimônio =
            patrimonyProjection.investmentAsset +
            patrimonyProjection.interest +
            patrimonyProjection.fixedAsset +
            patrimonyProjection.pension;

        return {
            name: `${independenceYear + i}`,
            saque: withDraw,
            patrimônio,
        };
    });

const Projection: React.FC<IProjectionProps> = ({ className, elevation, projection, withDraw, independenceYear }) => {
    const classes = useStyles();
    const projectionData = convertProjectionToData(projection, withDraw, independenceYear);

    return (
        <div className={className}>
            <Paper elevation={elevation} className={classes.rootContent}>
                <Typography variant="h6" className={classes.header}>Projeção do patrimônio vs. saques mensais</Typography>
                <>
                    <ResponsiveContainer width="100%" height={300}>
                        <ComposedChart data={projectionData} margin={{ left: 30 }}>
                            <defs>
                                <linearGradient id="colorUv" x1="0%" y1="100%" x2="0%" y2="0%">
                                    <stop offset="0%" stopColor="#26999c" />
                                    <stop offset="63%" stopColor="#065875" />
                                    <stop offset="100%" stopColor="#023c58" />
                                </linearGradient>
                            </defs>
                            <CartesianGrid strokeDasharray="3 3" />
                            <XAxis dataKey="name" padding={{ left: 12, right: 12 }} />
                            <Tooltip content={<CustomTooltip />} />
                            <Legend />
                            <YAxis yAxisId="left" tickFormatter={tick => tick.toLocaleString()} />
                            <Bar barSize={15} stackId={'a'} yAxisId="left" dataKey="patrimônio" fill="url(#colorUv)">
                                {projectionData.map((_, index) => {
                                    let fill = 'url(#colorUv)';
                                    if (sumData(projectionData[index]) < 0) {
                                        fill = '#ff422d';
                                    }
                                    return <Cell fill={fill} />;
                                })}
                            </Bar>
                        </ComposedChart>
                    </ResponsiveContainer>
                </>
            </Paper>
        </div>
    );
};
export default Projection;
