import {  Divider,  Paper,  Typography, useTheme } from '@material-ui/core';
import React from 'react';
import ValueColorText from '../../../global/displays/valueColorText/valueColorText';
import formatter from '../../../global/formater';
import { IInventoryFreeAssets } from '../types';
import { useStyles } from './style'

interface IProtectionCardProps {
    lifeQuality: number;
    successionCost: number;
    freeAssets: IInventoryFreeAssets;
    inventoryFreeAssets: number;
    type: 'current' | 'future';
    totalInvested: number;
}

const getColoredTypography = (className: string, total: number) => {
    return (
        <Typography className={className} color={total < 0 ? 'error' : undefined}>
            {formatter.format(total > 0 ? 0 : Math.abs(total))}
        </Typography>
    );
};

const ProtectionCard: React.FC<IProtectionCardProps> = ({
    freeAssets,
    type,
    lifeQuality,
    successionCost,
    inventoryFreeAssets,
    totalInvested,
}) => {
    const classes = useStyles();
    const title = type === 'current' ? 'Hoje' : 'Na independência financeira';
    const free = freeAssets.privatePension + freeAssets.permanentLifeInsurance;
    const total = freeAssets.temporaryLifeInsurance - lifeQuality + (free - successionCost);
    const theme = useTheme();

    const needPermanent = () => {
        const zeroedNegative = (value: number) => (value > 0 ? value : 0);
        const needValue = successionCost - (type === 'current' ? free : inventoryFreeAssets);

        return zeroedNegative(needValue);
    };

    return (
        <Paper className={classes.root}>
            <Typography variant="h6">{title}</Typography>
            <div className={classes.content}>
                {type === 'current' ? (
                    <React.Fragment>
                        <div className={classes.flexLine} style={{ alignItems: 'baseline' }}>
                            <Typography className={classes.qualityText}>Qualidade de vida para a família </Typography>
                            <Typography variant="overline" style={{ marginLeft: 2 }}>
                                (risco temporário)
                            </Typography>
                            <div style={{ flexGrow: 1 }} />
                            <ValueColorText
                                type="currency"
                                value={lifeQuality}
                                style={{ marginRight: 0, color: theme.palette.error.main }}
                            />
                        </div>
                        <div className={classes.flexLine}>
                            <Typography>Investimentos + Imóveis + Seguros</Typography>
                            <Typography style={{ color: theme.palette.success.main }}>
                                {formatter.format(totalInvested)}
                            </Typography>
                        </div>
                        <Divider className={classes.divider} variant="inset" />
                        <div className={classes.flexLine} style={{ marginBottom: 32 }}>
                            <Typography variant="body2" style={{ fontWeight: 'bold', marginRight: theme.spacing(1) }}>
                                Necessidade de proteção temporária
                            </Typography>
                            <div style={{ flexGrow: 1 }} />

                            <ValueColorText
                                type="currency"
                                value={totalInvested - lifeQuality > 0 ? 0 : Math.abs(totalInvested - lifeQuality)}
                                style={{ marginRight: 0, color: 'black' }}
                            />
                        </div>
                    </React.Fragment>
                ) : null}
                <div className={classes.flexLine} style={{ alignItems: 'baseline' }}>
                    <Typography>Custos de sucessão </Typography>
                    <Typography variant="overline" style={{ marginLeft: 2 }}>
                        (risco vitálicio)
                    </Typography>
                    <div style={{ flexGrow: 1 }} />
                    <ValueColorText
                        type="currency"
                        value={successionCost}
                        style={{ marginRight: 0, color: theme.palette.error.main }}
                    />
                </div>
                <div className={classes.flexCenterLine}>
                    <Typography className={classes.protectionText}>Proteção Vitalícia</Typography>
                    <Typography variant="overline">
                        (s. vitalício +{type === 'current' ? ' previdência atual' : ' previdência na IF'})
                    </Typography>
                    <div style={{ flexGrow: 1 }} />
                    <Typography style={{ color: theme.palette.success.dark }}>
                        {type === 'current' ? formatter.format(free) : formatter.format(inventoryFreeAssets)}
                    </Typography>
                </div>
                <Divider className={classes.divider} variant="inset" />
                <div className={classes.flexLine}>
                    <Typography variant="body2" style={{ fontWeight: 'bold', marginRight: theme.spacing(1) }}>
                        Necessidade de proteção vitalícia
                    </Typography>

                    <div style={{ flexGrow: 1 }} />

                    <ValueColorText
                        type="currency"
                        value={needPermanent()}
                        style={{ marginRight: 0, color: 'black' }}
                    />
                </div>
            </div>
        </Paper>
    );
};
export default ProtectionCard;
