import { TextField, withStyles } from '@material-ui/core';
import { blueGrey } from '@material-ui/core/colors';

const color = blueGrey[400];

export const RectangularInput = withStyles({
    root: {
        '& .MuiInputLabel-outlined.MuiInputLabel-shrink': {
            marginTop: '5px'
        },
        '& .MuiInputBase-root': {
            borderRadius: '0px',
        },
        '& label.Mui-focused': {
            color: color,
        },
        '& .MuiInput-underline:after': {
            borderBottomColor: color,
        },
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                borderColor: color,
                borderWidth: 2,
            },
            '&:hover fieldset': {
                borderColor: color,
            },
            '&.Mui-focused fieldset': {
                borderColor: color,
            },
        },
    },
})(TextField);
