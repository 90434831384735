import { createStyles, makeStyles, Theme } from '@material-ui/core';
export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        total: {
            backgroundColor: theme.palette.primary.dark,
            marginTop: theme.spacing(1),
            borderRadius: '5px',
            display: 'flex',
            alignItems: 'center',
            alignContent: 'center',
            justifyContent: 'flex-start',
            maxWidth: '100%',
            maxHeight: '69px',
            padding: theme.spacing(2),
        },
        totalValue: {
            margin: `0 ${theme.spacing(1)}px`,
            fontSize: 18,
            color: theme.palette.success.main,
        },
        label: {
            color: theme.palette.background.paper,
            marginLeft: theme.spacing(1),
        },

        valueText: {
            fontSize: 18,
            fontWeight: 600,
        },
        grow: {
            flexGrow: 1,
        },
        percentageGreater: {
            color: theme.palette.success.main,
        },
        percentageLesser: {
            color: theme.palette.error.main,
        },
    })
);