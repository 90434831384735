import { Button, Chip, createStyles, makeStyles, Theme, Typography } from '@material-ui/core';
import { grey } from '@material-ui/core/colors';
import React from 'react';
import { useSelector } from 'react-redux';
import { IStore } from '../../../../redux/types/store';
import TableCost, { ITableEntry } from '../../../global/inputs/tableCost/tableCost';
import PercentageInput from '../../../global/masks/percentageMaskedInput';
import { ISuccession, ISuccessionCosts, NewSuccessionCost } from '../types';
import { useStyles } from './style';

interface ISuccessionCostProps {
    defaultSuccession?: ISuccession;
    setSuccession: React.Dispatch<React.SetStateAction<ISuccession>>;
    nextStep: () => void;
    previousStep: () => void;
    errLabel: string;
}

const SuccessionCosts: React.FC<ISuccessionCostProps> = ({
    defaultSuccession,
    setSuccession,
    previousStep,
    nextStep,
    errLabel,
}) => {
    const classes = useStyles();
    const nickname = useSelector((store: IStore) => store.planning.customerUserInfo.nickname);
    const [currentSuccessionCosts, setCurrentSuccessionCosts] = React.useState(
        NewSuccessionCost(defaultSuccession?.current)
    );
    const [futureSuccessionCosts, setFutureSuccessionCosts] = React.useState(
        NewSuccessionCost(defaultSuccession?.future)
    );

    React.useEffect(() => {
        setCurrentSuccessionCosts(NewSuccessionCost(defaultSuccession?.current));
        setFutureSuccessionCosts(NewSuccessionCost(defaultSuccession?.future));
    }, [defaultSuccession]);

    const labelSuccession = (table: ITableEntry<ISuccessionCosts>) =>
        Object.values(table).map((cost): [string, number] => [cost.label, cost.value]);

    const setTableValues = (table: 'current' | 'future', values: Map<string, number>) => {
        const sc = table === 'current' ? { ...currentSuccessionCosts } : { ...futureSuccessionCosts };
        values.forEach((val, key) => {
            // forEach value find it in habitationalCost by its label
            const objectKeyForLabel = Object.entries(sc).filter(([_, scval]) => scval.label === key)![0]![0];
            // if there's a key for that label, then modify its value
            if (objectKeyForLabel) {
                const keyEntry = objectKeyForLabel as keyof ISuccessionCosts;
                sc[keyEntry] = {
                    label: sc[keyEntry].label,
                    value: val,
                };
            }
        });
        if (table === 'current') {
            setCurrentSuccessionCosts(sc);
        } else {
            setFutureSuccessionCosts(sc);
        }
    };
    const [btnDisabled, setBtnDisabled] = React.useState(false);
    const onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        const current: ISuccessionCosts = {
            itcmd: currentSuccessionCosts.itcmd.value,
            oab: currentSuccessionCosts.oab.value,
            notary: currentSuccessionCosts.notary.value,
        };
        const future: ISuccessionCosts = {
            itcmd: futureSuccessionCosts.itcmd.value,
            oab: futureSuccessionCosts.oab.value,
            notary: futureSuccessionCosts.notary.value,
        };
        setSuccession({ current, future });
        nextStep();
        setBtnDisabled(true);
    };

    return (
        <div className='pb-4'>
            <div className={classes.head}>
                <Typography variant="h4" className={classes.header} align="center">
                    Prepare a reserva de emergência
                </Typography>
                <div className={classes.subheader}>
                    <Typography variant="body2">Colete os dados básicos do cliente e preencha aqui.</Typography>
                </div>
            </div>
            <form onSubmit={onSubmit} className='container'>
                <div className={classes.table}>
                    <TableCost
                        required
                        header="Custos atuais"
                        rows={labelSuccession(currentSuccessionCosts)}
                        percentage
                        risk='risk'
                        tableValues={values => setTableValues('current', values)}
                        inputComponent={PercentageInput as any}
                    />
                </div>
                <div className={classes.table}>
                    <TableCost
                        required
                        risk='risk'
                        header="Custos na independência financeira"
                        rows={labelSuccession(futureSuccessionCosts)}
                        tableValues={values => setTableValues('future', values)}
                        percentage
                        inputComponent={PercentageInput as any}
                    />
                </div>
                <Typography align="center" color="error">
                    {errLabel}
                </Typography>
                <div className={classes.formControllers}>
                        {/* @ts-ignore */}
                    <Button
                        variant="contained"
                        className={classes.outButton}
                        size="medium"
                        color="default"
                        disableElevation
                        onClick={previousStep}
                    >
                        Voltar
                    </Button>
                    <button type="submit" className={classes.chipButton} disabled={btnDisabled}>
                        <Chip color="primary" size="medium" label="Próximo" clickable />
                    </button>
                </div>
            </form>
        </div>
    );
};
export default SuccessionCosts;
