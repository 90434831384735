import { createStyles, makeStyles, Theme } from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) =>
createStyles({
    inputLabel: {
        padding: '1em 0 0.5em',
    },
    perspectiveLine: {
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'flex-end',
    },
    line: {
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'flex-end',
    },
    removeButton: {
        backgroundColor: 'red[400]',
        color: 'red',
        height: '30px',
        '&:hover': { backgroundColor: 'red[800] '},
    },
    raised: {
        backgroundColor: '#A1D830',
        color: '#ffffff',
        height: '30px',
        '&:hover': { color: '#000000' },
    },
    reduction: {
        backgroundColor: 'red[400]',
        color: '#ffffff',
        height: '30px',
        '&:hover': { color: '#000000' },
    },
    unraised: {
        height: '30px',
        backgroundColor: '#E1E1E1',
    },
})
);