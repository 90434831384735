import { createStyles, makeStyles, Theme } from '@material-ui/core';
export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        infoCard: {
            padding: theme.spacing(2),
        },
        separator: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
        },
        paid: {
            display: 'flex',
            margin: theme.spacing(1, 0),
        },
        dedicationDisplay: {
            display: 'flex',
            '& > :not(:first-child)': {
                marginLeft: theme.spacing(2),
            },
        },
        input: {
            padding: '10px 14px',
        },
        border: {
            borderBottom: '2px solid lightgray',
            width: '100%',
        },
        dividerText: {
            paddingTop: theme.spacing(0.5),
            paddingBottom: theme.spacing(0.5),
            paddingRight: theme.spacing(2),
            paddingLeft: theme.spacing(2),
            fontWeight: 500,
            fontSize: 22,
        },
        displayContainer: {
            position: 'sticky',
            display: 'flex',
            flexDirection: 'column',
            top: 80,
        },
    })
);