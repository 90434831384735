import React from 'react';
import { TooltipProps } from 'recharts';
import formatter from '../../../global/formater';
import { useStyles } from './style'

interface ICustomTooltipProps extends TooltipProps {}

const capitalize = (s: string | undefined) => {
    if (!s) {
        return '';
    }
    return s.charAt(0).toUpperCase() + s.slice(1);
};

const DiffTooltip: React.FC<ICustomTooltipProps> = props => {
    const classes = useStyles();

    const { active, payload, label, coordinate } = props;

    const [style, setStyle] = React.useState<React.CSSProperties>({});

    React.useEffect(() => {
        const tempStyle: React.CSSProperties = {
            backgroundColor: coordinate!.y > 50 ? 'rgba(255, 255, 255, 0.93)' : 'rgba(255, 255, 255, 0.6)',
        };

        setStyle(tempStyle);
    }, [coordinate!.y, setStyle]);

    const [total, setTotal] = React.useState(0);

    React.useEffect(() => {
        const parse = (value: string | number | ReadonlyArray<string | number>): number => {
            const strParse = (val: string): number => {
                try {
                    const numValue = parseFloat(val);
                    return numValue;
                } catch (e) {
                    console.debug(e);
                    return 0;
                }
            };

            if (Array.isArray(value)) {
                return strParse(value.toString());
            }

            switch (typeof value) {
                case 'string':
                    return strParse(value);
                case 'number':
                    return value;
                default:
                    return 0;
            }
        };

        if (payload) {
            setTotal(
                payload.reduce((prev, curr) => {
                    switch (curr.name) {
                        case 'Patrimônio':
                            return prev - parse(curr.value);
                        case 'Sugerido':
                            return prev + parse(curr.value);
                        default:
                            return prev;
                    }
                }, 0)
            );
        }
    }, [payload, setTotal]);

    const getFormattedValue = (value: string | number | ReadonlyArray<string | number>): string => {
        const parse = (val: string): number => {
            try {
                const numValue = parseFloat(val);
                return numValue;
            } catch (e) {
                console.debug(e);
                return 0;
            }
        };

        if (Array.isArray(value)) {
            return formatter.format(parse(value.toString()));
        }

        switch (typeof value) {
            case 'string':
                return formatter.format(parse(value));
            case 'number':
                return formatter.format(value);
            default:
                return formatter.format(0);
        }
    };

    if (!active || !payload) {
        return null;
    }

    return (
        <div className={classes.customTooltip} style={style}>
            <p className={classes.head}>
                <strong>{label}</strong>
            </p>
            {payload.map((item, i) => (
                <p className={classes.value} key={i}>
                    {capitalize(item.name)}:{' '}
                    <strong style={{ color: item.color }}>{getFormattedValue(item.value)}</strong>
                </p>
            ))}
            {payload.length > 1 ? (
                <p className={classes.value}>Diferença: <strong>{formatter.format(total)}</strong></p>
            ) : null}
        </div>
    );
};

export default DiffTooltip;