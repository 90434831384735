import { createStyles, makeStyles, Theme } from '@material-ui/core'
import { blueGrey, lightBlue, teal } from '@material-ui/core/colors';

export const slices = [
    '#2D819A',
    '#023C58',
    '#A0D633',
    blueGrey[700],
    lightBlue[900],
    lightBlue[800],
    lightBlue[400],
    teal[400],
    teal[500],
    teal[600],
    teal[700],
    teal[800],
    teal[900],
];

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            padding: 0,
            backgroundColor: 'transparent',
        },
        cardHeader: {
            fontWeight: 600,
            fontSize: 16,
            color: '#020202',
        },
        labelArea: {
            marginLeft: theme.spacing(2),
            padding: `${theme.spacing(4)}px 0`,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
        },
        labelLine: {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-start',
            alignItems: 'center',
        },
        labelHover: {
            fontWeight: 600,
        },
        valueText: {
            fontSize: 18,
            marginLeft: theme.spacing(2),
            color: theme.palette.secondary.light,
        },
        chart: {
            '@media (min-width: 768px)': {
                display: 'flex',
                flexDirection: 'row',
            },
            '@media (max-width: 767px)': {
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
            },
        },
        chartDescription: {
            '@media (min-width: 768px)': {
                flexGrow: '0',
                flexBasis: '50%',
            },
            '@media (max-width: 767px)': {
                display: 'flex',
                justifyContent: 'center',
            },
        },
        cardContent: {
            paddingBottom: 0,
            justifyContent: 'center',
        },
        cardMargin: {
            '@media (min-width: 768px)': {
                marginTop: theme.spacing(5),
            },
            '@media (max-width: 767px)': {
                marginTop: theme.spacing(5),
                justifyContent: 'center',
            },
        },
        cardTotal: {
            width: '100%',
            background: '#F8F8F8',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-end',
            paddingRight: theme.spacing(2),
            padding: theme.spacing(2),
            alignItems: 'baseline',
        },
        labelValue: {
            alignSelf: 'flex-end',
            fontWeight: 'inherit',
        },
        spacing: {
            padding: theme.spacing(0.5),
        },
        roundMarker: {
            marginTop: '5px',
        },
        grow: {
            flexGrow: 1,
        },
        totalLabel: {
            '@media (min-width: 768px)': {
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                padding: theme.spacing(2),
                alignItems: 'center',    
            },
            '@media (max-width: 767px)': {
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center',
                alignItems: 'center',
                alignContent: 'center',
                padding: theme.spacing(2),
            },
        },
        totalCost: {
            color: theme.palette.secondary.light,
        },
        
    })
);