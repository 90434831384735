import {
    Button,
    Chip,
    createStyles,
    FormControl,
    Input,
    makeStyles,
    MenuItem,
    Paper,
    Select,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TextField,
    Theme,
    Typography,
} from '@material-ui/core';
import { grey } from '@material-ui/core/colors';
import { Add, Delete } from '@material-ui/icons';
import { default as React } from 'react';
import { useSelector } from 'react-redux';
import { IStore } from '../../../../redux/types/store';
import unmaskCurrency, { maskCurrency } from '../../../../utils/unmaskCurrency';
import MoneyAdornment from '../../../global/adornments/MoneyAdornment';
import CNPJMaskedInput from '../../../global/masks/CNPJMaskedInput';
import CurrencyInput from '../../../global/masks/currencyMaskedInput';
import { IPension, TaxType } from '../types';
import { useStyles } from './style';

interface ITableRow {
    name: string;
    cnpj: string;
    vgbl: boolean;
    taxType: string;
    balance: string;
    monthlyContribution: string;
}

interface IPensionProps {
    defaultPensions: IPension[];
    setPensionsValues: React.Dispatch<React.SetStateAction<IPension[]>>;
    previousStep: () => void;
    nextStep: () => void;
    errLabel: string;
    suggested?: boolean;
}

function unmaskCNPJ(cnpj: string) {
    return cnpj.replace(/[^0-9]+/g, '');
}

const Pensions: React.FC<IPensionProps> = ({
    defaultPensions,
    setPensionsValues,
    nextStep,
    previousStep,
    errLabel,
    suggested,
}) => {
    const classes = useStyles();

    const { customerPension } = useSelector((store: IStore) => ({ customerPension: store.planning.customerFormValues.patrimony.pensions }))

    const [hasPensions, setHasPensions] = React.useState(defaultPensions.length > 0 || customerPension.length > 0);
    const [addedLine, setAddedLine] = React.useState(false);
    const [ready, setReady] = React.useState(false);
    const [tableRows, setTableRows] = React.useState<ITableRow[]>(
        defaultPensions.length
            ? defaultPensions.map(item => ({
                ...item,
                cnpj: `${item.cnpj}`,
                vgbl: item.type === 'vgbl',
                balance: maskCurrency(item.balance),
                monthlyContribution: maskCurrency(item.monthlyContribution),
            }))
            : [{ name: '', cnpj: '', vgbl: true, taxType: 'progressive', balance: '', monthlyContribution: '' }]
    );

    const addLine = () => {
        setAddedLine(true);
        setTableRows([
            ...tableRows,
            {
                name: '',
                cnpj: '',
                vgbl: true,
                taxType: 'progressive',
                balance: '',
                monthlyContribution: '',
            },
        ]);
    };

    const handleInputChange = (field: keyof ITableRow, value: string, rowIndex: number) => {
        setTableRows(tableRows.map((item, i) => (i === rowIndex ? { ...item, [field]: value } : item)));
    };
    const changeMouseHover = (hover: boolean, index: number) => {
        setMouseHover(rows => rows.map((_, i) => (index === i ? hover : false)));
    };

    const removeRow = (index: number) => {
        setTableRows(rows => [...rows.slice(0, index), ...rows.slice(index + 1)]);
    };

    const [mouseHover, setMouseHover] = React.useState(tableRows.map(row => false));
    React.useEffect(() => {
        if (addedLine) {
            setMouseHover(tableRows.map(row => false));
        }
    }, [tableRows]);

    React.useEffect(() => {
        if (addedLine) {
            setReady(true);
        }
    }, [mouseHover]);
    const showRowDel = async (row: number) => {
        setMouseHover(rows => rows.map((_, i) => row === i));
        setReady(false);
        setAddedLine(false);
        await new Promise(r => setTimeout(r, 4000));
        setMouseHover(rows => rows.map(item => false));
    };
    React.useEffect(() => {
        showRowDel(tableRows.length - 1);
    }, [ready]);
    const handleVgblFocus = (isVgbl: boolean, rowIndex: number) => {
        setTableRows(rows => rows.map((item, i) => (i === rowIndex ? { ...item, vgbl: isVgbl } : item)));
    };
    const [btnDisabled, setBtnDisabled] = React.useState(false);

    const onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        setPensionsValues(
            tableRows.map(row => ({
                ...row,
                cnpj: unmaskCNPJ(row.cnpj),
                type: row.vgbl ? 'vgbl' : 'pgbl',
                balance: unmaskCurrency(row.balance),
                monthlyContribution: unmaskCurrency(row.monthlyContribution),
                taxType: (row.taxType as unknown) as TaxType,
            }))
        );
        nextStep();
        setBtnDisabled(true);
    };
    return (
        <div className={classes.root}>
            <div className={classes.head}>
                <Typography variant="h4" className={`${classes.header} mb-3`}>
                    {suggested ? 'Sugestão de previdência' : 'Previdência contratada'}
                </Typography>
                <div className={`${classes.selectButton} mb-3`}>
                    {/* @ts-ignore */}
                    <Button
                        variant="outlined"
                        color={hasPensions ? 'primary' : 'default'}
                        disableElevation
                        onClick={() => setHasPensions(true)}
                    >
                        SIM
                    </Button>
                    {/* @ts-ignore */}
                    <Button
                        disableElevation
                        variant="outlined"
                        color={!hasPensions ? 'primary' : 'default'}
                        onClick={() => setHasPensions(false)}
                    >
                        NÃO
                    </Button>
                </div>
            </div>
            <form onSubmit={onSubmit}>
                {hasPensions ? (
                    <div>
                        <TableContainer component={Paper}>
                            <Table className={classes.table}>
                                <TableHead>
                                    <TableRow>
                                        <TableCell align="left">Nome</TableCell>
                                        <TableCell align="left">CNPJ</TableCell>
                                        <TableCell align="center">Tipo</TableCell>
                                        <TableCell align="right">Imposto</TableCell>
                                        <TableCell align="right">Saldo(R$)</TableCell>
                                        <TableCell align="right">Aporte Mensal(R$)</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {tableRows.map((row, i) => (
                                        <TableRow key={i.toString()} style={{ overflow: 'hidden' }}>
                                            <TableCell>
                                                <TextField
                                                    autoFocus
                                                    required
                                                    color="primary"
                                                    placeholder="Nome"
                                                    value={row.name}
                                                    name="name"
                                                    onChange={event => handleInputChange('name', event.target.value, i)}
                                                />
                                            </TableCell>
                                            <TableCell>
                                                <FormControl>
                                                    <Input
                                                        value={row.cnpj}
                                                        onChange={event =>
                                                            handleInputChange('cnpj', event.target.value, i)
                                                        }
                                                        name="cnpj"
                                                        placeholder="00.000.000/0000-00"
                                                        inputComponent={CNPJMaskedInput as any}
                                                        inputProps={{
                                                            style: { textAlign: 'left' },
                                                            allowNegative: true,
                                                        }}
                                                    />
                                                </FormControl>
                                            </TableCell>
                                            <TableCell>
                                                <div className={classes.selectButton}>
                                                    {/* @ts-ignore */}
                                                    <Button
                                                        variant="outlined"
                                                        color={row.vgbl ? 'primary' : 'default'}
                                                        disableElevation
                                                        onClick={() => handleVgblFocus(true, i)}
                                                    >
                                                        VGBL
                                                    </Button>
                                                    {/* @ts-ignore */}
                                                    <Button
                                                        disableElevation
                                                        variant="outlined"
                                                        color={!row.vgbl ? 'primary' : 'default'}
                                                        onClick={() => handleVgblFocus(false, i)}
                                                    >
                                                        PGBL
                                                    </Button>
                                                </div>
                                            </TableCell>
                                            <TableCell align="right">
                                                <FormControl>
                                                    <Select
                                                        labelId="taxTypeId"
                                                        value={row.taxType}
                                                        onChange={event =>
                                                            handleInputChange(
                                                                'taxType',
                                                                event.target.value as string,
                                                                i
                                                            )
                                                        }
                                                    >
                                                        <MenuItem value={'progressive'}>Progressivo</MenuItem>
                                                        <MenuItem value={'regressive'}>Regressivo</MenuItem>
                                                    </Select>
                                                </FormControl>
                                            </TableCell>

                                            <TableCell align="right">
                                                <FormControl>
                                                    <TextField
                                                        value={row.balance}
                                                        onChange={event =>
                                                            handleInputChange('balance', event.target.value, i)
                                                        }
                                                        name="balance"
                                                        placeholder="000.00"
                                                        InputProps={{
                                                            inputComponent: CurrencyInput as any,
                                                            startAdornment: <MoneyAdornment />,
                                                        }}
                                                        inputProps={{
                                                            style: { textAlign: 'right' },
                                                        }}
                                                    />
                                                </FormControl>
                                            </TableCell>
                                            <TableCell align="right">
                                                <FormControl>
                                                    <TextField
                                                        value={row.monthlyContribution}
                                                        onChange={event =>
                                                            handleInputChange(
                                                                'monthlyContribution',
                                                                event.target.value,
                                                                i
                                                            )
                                                        }
                                                        name="monthlyContribution"
                                                        placeholder="000.00"
                                                        InputProps={{
                                                            inputComponent: CurrencyInput as any,
                                                            startAdornment: <MoneyAdornment />,
                                                        }}
                                                        inputProps={{
                                                            style: { textAlign: 'right' },
                                                        }}
                                                    />
                                                </FormControl>
                                            </TableCell>

                                            <div
                                                className={classes.deleteHidden}
                                                onMouseEnter={() => changeMouseHover(true, i)}
                                                onMouseLeave={() => changeMouseHover(false, i)}
                                                //@ts-ignore
                                                onClick={() => removeRow(i)}
                                                // we dont need border when mouse is hovering this div
                                                style={{ borderLeft: !mouseHover[i] ? '9px solid #f44336' : '' }}
                                            >
                                                {mouseHover[i] ? (
                                                    <Delete
                                                        style={{ marginTop: '100%', marginRight: '5%' }}
                                                        color="action"
                                                    />
                                                ) : null}
                                            </div>
                                        </TableRow>
                                    ))}
                                    <TableRow>
                                        <TableCell>
                                            {/* @ts-ignore */}
                                            <Chip
                                                variant="outlined"
                                                color="primary"
                                                size="medium"
                                                label="Adicionar nova linha"
                                                clickable
                                                onClick={addLine}
                                                onDelete={addLine}
                                                deleteIcon={<Add />}
                                            />
                                        </TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <Typography align="center" color="error">
                            {errLabel}
                        </Typography>
                    </div>
                ) : null}
                <div className={classes.formControllers}>
                    {/* @ts-ignore */}
                    <Button
                        variant="contained"
                        className={classes.outButton}
                        size="medium"
                        color="default"
                        disableElevation
                        onClick={previousStep}
                    >
                        Voltar
                    </Button>
                    <button type="submit" className={classes.chipButton} disabled={btnDisabled}>
                        <Chip className={classes.chip} color="primary" size="medium" label="Finalizar" clickable />
                    </button>
                </div>
            </form>
        </div>
    );
};
export default Pensions;
