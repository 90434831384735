const unmaskCurrency = (value: string): number => {
    value = value.toString()

    // remove dot and change comma to dot
    let floatstr = value.replace(/\./g, '').replace(',', '.');
    // only numbers and dot stay
    floatstr = floatstr.replace(/[^0-9.]/g, '');

    const parsed = parseFloat(floatstr);
    if (!Number.isNaN(parsed)) {
        return parsed;
    }
    return 0;
};

export const unmaskPercentage = (value: string): number => {
    const floatstr = value.replace(/\./g, '').replace(',', '.');
    // only numbers and dot stay
    const parsed = parseFloat(floatstr);
    if (!Number.isNaN(parsed)) {
        return parsed;
    }
    return 0;
};

export const maskCurrency = (value: number | undefined): string => {
    if (!value) {
        return '';
    }
    return value.toString().replace('.', ',');
};

export default unmaskCurrency;
