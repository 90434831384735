import { Button, Chip, createStyles, makeStyles, TextField, Theme, Typography, withStyles } from '@material-ui/core';
import { grey } from '@material-ui/core/colors';
import React from 'react';
import { useSelector } from 'react-redux';
import { IStore } from '../../../../redux/types/store';
import unmaskCurrency, { maskCurrency } from '../../../../utils/unmaskCurrency';
import MoneyAdornment from '../../../global/adornments/MoneyAdornment';
import { GreyedTextInput } from '../../../global/inputs/GreyedInput';
import AgeInput from '../../../global/masks/ageMaskedInput';
import CurrencyInput from '../../../global/masks/currencyMaskedInput';
import { IEmergencySavings } from '../types';
import { useStyles } from './style';
import { CustomizedTextInput } from './style';

interface IEmergencySavingsProps {
    defaultEmergencySavings?: IEmergencySavings;
    setEmergencySavings: React.Dispatch<React.SetStateAction<IEmergencySavings>>;
    previousStep: () => void;
    nextStep: () => void;
}

const EmergencySavings: React.FC<IEmergencySavingsProps> = ({
    defaultEmergencySavings,
    setEmergencySavings,
    nextStep,
    previousStep,
}) => {
    const classes = useStyles();

    const nickname = useSelector((store: IStore) => store.planning.customerUserInfo.nickname);

    const [mensalIncoming, setMensalIncoming] = React.useState(
        `R$ ${maskCurrency(defaultEmergencySavings?.mensalIncoming)}'` || ''
    );
    const [months, setMonths] = React.useState(defaultEmergencySavings?.months || 0);
    const [succession, setSuccession] = React.useState(defaultEmergencySavings?.successionValue ? true : false);
    const [successionValue, setSuccessionValue] = React.useState(
        defaultEmergencySavings?.successionValue ? `R$ ${maskCurrency(defaultEmergencySavings.successionValue)}` : ''
    );
    const [successionDuration, setSuccessionDuration] = React.useState(
        defaultEmergencySavings?.successionDuration || 0
    );

    const [btnDisabled, setBtnDisabled] = React.useState(false);
    const onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        setEmergencySavings({
            mensalIncoming: unmaskCurrency(mensalIncoming),
            months,
            successionValue: succession ? unmaskCurrency(successionValue) || 0 : 0,
            successionDuration: succession ? successionDuration : 0,
        });
        nextStep();
        setBtnDisabled(true);
    };
    const onAgeChange = (val: string, setAgeFunc: React.Dispatch<React.SetStateAction<number>>) => {
        if (!val) {
            return;
        }
        const parsedInt = parseInt(val, 10);
        if (parsedInt) {
            setAgeFunc(parsedInt);
        }
    };
    return (
        <div>
            <div className={classes.head}>
                <Typography variant="h4" className={classes.header} align="center">
                    Prepare a reserva de emergência
                </Typography>
                <div className={classes.subheader}>
                    <Typography variant="body2" align="center">
                        Colete os dados básicos do cliente e preencha aqui.
                    </Typography>
                </div>
            </div>
            <form className='container' onSubmit={onSubmit}>
                <div>
                    <Typography className={classes.inputLabel} variant="h6">
                        Valor Mensal
                    </Typography>
                    <GreyedTextInput
                        required
                        color="primary"
                        variant="outlined"
                        size="small"
                        placeholder="0,00"
                        helperText="Normalmente idêntico ao valor do salário atual"
                        InputProps={{ inputComponent: CurrencyInput as any, startAdornment: <MoneyAdornment /> }}
                        InputLabelProps={{
                            shrink: true,
                        }}
                        value={mensalIncoming}
                        onChange={event => setMensalIncoming(event.target.value)}
                        fullWidth
                    />
                </div>
                <div>
                    <Typography className={classes.inputLabel} variant="h6">
                        Prazo
                    </Typography>
                    <div className={classes.buttonsArea}>
                        {Array(12)
                            .fill(0)
                            .map((_, i) => i + 3)
                            .map(monthTime => (
                                //@ts-ignore
                                <Button
                                    disableElevation
                                    key={`savings-${monthTime}`}
                                    onClick={() => setMonths(monthTime)}
                                    variant="outlined"
                                    color={months === monthTime ? 'primary' : 'default'}
                                    className={classes.button}
                                >
                                    {monthTime}
                                </Button>
                            ))}
                    </div>
                </div>
                <div>
                    <Typography className={classes.inputLabel} variant="h6">
                        Gostaria de deixar qualidade de vida para a família na falta?
                    </Typography>
                    <div className={classes.buttonsLine}>
                        <div style={{ marginRight: '5em' }}>
                            {/* @ts-ignore */}
                            <Button
                                variant="outlined"
                                color={succession ? 'primary' : 'default'}
                                onClick={() => setSuccession(true)}
                            >
                                Sim
                            </Button>
                        </div>
                        <div>
                            {/* @ts-ignore */}
                            <Button
                                variant="outlined"
                                color={!succession ? 'primary' : 'default'}
                                onClick={() => setSuccession(false)}
                            >
                                Não
                            </Button>
                        </div>
                    </div>
                    <div className={classes.flexDiv} style={{ display: succession ? 'flex' : 'none' }}>
                        <div className={classes.flexDiv1}>
                            <Typography className={classes.inputLabel} variant="h6">
                                Qual o valor estimado? (mensal){succession ? '*' : ''}
                            </Typography>
                            <CustomizedTextInput
                                required={succession}
                                color="primary"
                                variant="outlined"
                                size="small"
                                disabled={!succession}
                                placeholder="0,00"
                                InputProps={{
                                    inputComponent: CurrencyInput as any,
                                    startAdornment: <MoneyAdornment />,
                                }}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                value={successionValue}
                                onChange={event => setSuccessionValue(event.target.value)}
                                fullWidth
                            />
                        </div>
                        <div className={classes.flexDiv2}>
                            <Typography className={classes.inputLabel} variant="h6">
                                Duração? (anos){succession ? '*' : ''}
                            </Typography>
                            <CustomizedTextInput
                                required={succession}
                                color="primary"
                                variant="outlined"
                                size="small"
                                disabled={!succession}
                                placeholder="00"
                                InputProps={{
                                    inputComponent: AgeInput as any,
                                    endAdornment: <Typography>anos</Typography>,
                                }}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                value={successionDuration}
                                onChange={event => onAgeChange(event.target.value, setSuccessionDuration)}
                                fullWidth
                            />
                        </div>
                    </div>
                </div>
                <div className={classes.formControllers}>
                    {/* @ts-ignore */}
                    <Button
                        variant="contained"
                        className={classes.outButton}
                        size="medium"
                        color="default"
                        disableElevation
                        onClick={previousStep}
                    >
                        Sair
                    </Button>
                    <button disabled={btnDisabled} type="submit" className={classes.chipButton}>
                        <Chip className={classes.chip} color="primary" size="medium" label="Próximo" clickable />
                    </button>
                </div>
            </form>
        </div>
    );
};
export default EmergencySavings;
