import { Paper, Typography } from '@material-ui/core';
import { ArrowLeft, ArrowRight } from '@material-ui/icons';
import { ButtonBack, ButtonNext, CarouselProvider, Dot, DotGroup, Slide, Slider } from 'pure-react-carousel';
import 'pure-react-carousel/dist/react-carousel.es.css';
import React from 'react';
import formatter from '../../../global/formater';
import { ICorrections } from '../types';
import { useStyles } from './style'

interface ICorrectionsProps extends ICorrections {}

interface RenderDotsProps {
    readonly currentSlide?: number;
    readonly totalSlides?: number;
    readonly visibleSlides?: number;
    readonly disableActiveDots?: boolean;
    readonly showAsSelectedForCurrentSlideOnly?: boolean;
}

const Corrections: React.FC<ICorrectionsProps> = ({ anualLeft, investedPatrimony }) => {
    const classes = useStyles();

    const dots = (props: RenderDotsProps) => (
        <div className={classes.dotLine}>
            {[...Array(props.totalSlides).fill(0)].map((_, i) => {
                const disabled = i === props.currentSlide;
                return (
                    <Dot className={classes.dotRoot} slide={i} disabled={disabled}>
                        <div className={classes.dot}></div>
                    </Dot>
                );
            })}
        </div>
    );

    return (
        <div className={classes.root}>
            <Typography variant="h5">Quanto você pode destinar para ter sua proteção anualmente</Typography>
            <Paper className={classes.content}>
                <CarouselProvider naturalSlideWidth={100} naturalSlideHeight={12} totalSlides={2}>
                    <Slider>
                        <Slide index={0}>
                            <div className={classes.flexLine}>
                                <Typography variant="h6" className={classes.whiteLabel}>
                                    {anualLeft.percentage * 100}% das sobras anuais
                                </Typography>
                                <Typography variant="h4" className={classes.valueLabel}>
                                    {formatter.format(anualLeft.value)}
                                </Typography>
                            </div>
                        </Slide>
                        <Slide index={1}>
                            <div className={classes.flexLine}>
                                <Typography variant="h6" className={classes.whiteLabel}>
                                    {investedPatrimony.percentage * 100}% do patrimônio investido
                                </Typography>
                                <Typography variant="h4" className={classes.valueLabel}>
                                    {formatter.format(investedPatrimony.percentage * investedPatrimony.value)}
                                </Typography>
                            </div>
                        </Slide>
                    </Slider>
                    <ButtonNext className={classes.buttonBack}>
                        <ArrowRight />
                    </ButtonNext>
                    <ButtonBack className={classes.buttonBack}>
                        <ArrowLeft />
                    </ButtonBack>
                    <DotGroup className={classes.dotLine} renderDots={dots} />
                </CarouselProvider>
            </Paper>
            <Typography variant="overline">clique nas setas para explorar as opçoẽs</Typography>
        </div>
    );
};
export default Corrections;