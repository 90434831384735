import { createStyles, makeStyles, Theme } from '@material-ui/core';
export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        total: {
            '@media (min-width: 768px)': {
                backgroundColor: theme.palette.primary.dark,
                marginTop: theme.spacing(1),
                display: 'flex',
                alignItems: 'center',
                alignContent: 'center',
                justifyContent: 'flex-start',
                maxWidth: '100%',
                maxHeight: '69px',
                padding: theme.spacing(2),
                borderRadius: '8px',
            },
            '@media (max-width: 767px)': {
                backgroundColor: theme.palette.primary.dark,
                marginTop: theme.spacing(1),
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                alignContent: 'center',
                justifyContent: 'flex-start',
                maxWidth: '100%',
                maxHeight: '100px',
                padding: theme.spacing(2),
                borderRadius: '8px',
            },
        },
        cardColumn: {
            display: 'flex',
            flexDirection: 'column',
        },
        cardRow: {
            display: 'flex',
            flexDirection: 'row',
        },
        totalValue: {
            margin: `0 ${theme.spacing(1)}px`,
            marginRight: 0,
            color: theme.palette.success.main,
        },
        label: {
            color: theme.palette.background.paper,
            marginLeft: '16px',
            lineHeight: '16px',
        },
        sublabel: {
            color: theme.palette.background.paper,
            marginLeft: '16px',
            fontSize: '0.75rem',
            lineHeight: 1.4,
        },
        valueText: {
            fontSize: 18,
            fontWeight: 600,
        },
        grow: {
            flexGrow: 1,
        },
    })
);