import { createStyles, makeStyles, Theme } from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        subHeading: {
            marginTop: theme.spacing(2),
            paddingLeft: theme.spacing(1),
        },
        group: {
            borderRadius: '8px',
            marginBottom: theme.spacing(1),
        },
        innerPadding: {
            padding: '0.6em',
        },
        content: {
            display: 'flex',
            flexDirection: 'row',
            marginTop: theme.spacing(2),
            marginLeft: theme.spacing(1),
        },
        grow: {
            flexGrow: 1,
            flexBasis: '50%',
        },
        spacing: {
            margin: `${theme.spacing(2)}px  0`,
            height: '2px',
        },
        totalLabel: {
            '@media (min-width: 768px)': {
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                padding: theme.spacing(2),
                alignItems: 'center',
            },
            '@media (max-width: 767px)': {
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center',
                alignItems: 'center',
                alignContent: 'center',
                padding: theme.spacing(2),
            },
        },
    })
);