import throttle from 'lodash.throttle';
import { combineReducers, createStore } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import customerReducer from './reducers/customer/customerReducer';
import planningFormReducer from './reducers/planningForm/planningFormReducer';
import storageReducer, { saveState } from './reducers/storage/storageReducer';
import { userReducer } from './reducers/user/userReducer';
import { IStore } from './types/store';

const reducers = combineReducers<IStore>({
    planning: planningFormReducer,
    customer: customerReducer,
    loggedUser: userReducer,
    storage: storageReducer,
});

const store = createStore(reducers, composeWithDevTools());

store.subscribe(
    throttle(() => {
        saveState(store.getState().storage);
    }, 1000)
);
export default store;
