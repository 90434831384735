import { createStyles, makeStyles, Theme } from '@material-ui/core';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import api from '../../../../apis/dreamrichapi';
import { ChangeCustomerRiskManagement } from '../../../../redux/actions/planningActions';
import { IStore } from '../../../../redux/types/store';
import { IPlannerPageProps } from '../../types';
import EmergencySavings from '../emergencySavings/emergencySavings';
import Insurance from '../insurance/insurance';
import SuccessionCosts from '../successionCosts/successionCosts';
import { IEmergencySavings, IInsurance, IRiskManagement, ISuccession } from '../types';
import {useStyles} from './style';


const RiskManagement: React.FC<IPlannerPageProps> = ({ changeTitle }) => {
    const classes = useStyles();
    const history = useHistory();

    // store vars
    const { userID, storedRisk, currToolbar } = useSelector((store: IStore) => ({
        userID: store.planning.customerUserInfo.userID,
        currToolbar: store.planning.toolbar.current,
        storedRisk: store.planning.customerFormValues.riskManagement,
    }));

    const riskStorage = JSON.parse(localStorage.getItem('storageCustomer'+userID))?.riskManagement;

    if (!userID) {
        history.push('/');
    }

    // step control
    const [currentStep, setStep] = React.useState(1);
    React.useEffect(() => {
        if (!currToolbar || currentStep === currToolbar) return;
        setStep(currToolbar);
    }, [currToolbar]);

    const nextStep = () => {
        if (currentStep < totalSteps) {
            setStep(currentStep + 1);
        }
    };
    const previousStep = () => {
        setStep(currentStep - 1);
    };

    // send control
    const [send, setSend] = React.useState(false);
    const finish = () => {
        setReady(true);
        // finish dipares send effect bellow
        setSend(true);
    };

    const [riskManagement, setRiskManagement] = React.useState<IRiskManagement>(storedRisk);
    const [errLabel, setErrLabel] = React.useState('');
    // risk control to send just after finish
    const [ready, setReady] = React.useState(false);

    const dispatch = useDispatch();

    React.useEffect(() => {
        // When clientInfo changes it doesn't necessarily is ready to be sent
        // send state is enabled when a finish function is called
        // verifyClientInfo checks for required fields before sending it through api then we just send data when all required fields where filled
        // this prevines to send twice
        // remove ready field before sending it isn't a problem to send this to server
        // but I rather not
        if (send && riskManagement?.ready) {
            const riskManagementBody: IRiskManagement = { ...riskManagement, userID };
            delete riskManagementBody.ready;
            api.post('/riskmanagement ', riskManagementBody)
                .then(() => {
                    setErrLabel('');
                    dispatch(ChangeCustomerRiskManagement(riskManagementBody));
                    history.push('/planner');
                })
                .catch(err => {
                    if (err.response) {
                        setErrLabel(`Erro salvando informações de independência financeira: ${err.response.data}`);
                    } else {
                        setErrLabel(`Erro interno do sistema: ${err}`);
                    }
                });
            setSend(false);
        }
    }, [send, history, riskManagement]);

    const totalSteps = 3;
    const percentage = (currentStep / totalSteps) * 100;

    const [emergencialSavings, setEmergencialSavings] = React.useState<IEmergencySavings>(riskStorage.savings || riskManagement.savings);
    const [successionCosts, setSuccessionCosts] = React.useState<ISuccession>(riskStorage.successionCosts || riskManagement.successionCosts);
    const [insurance, setInsurance] = React.useState<IInsurance[]>(riskStorage.insurance || riskManagement.insurance || []);

    const Steps = [
        <EmergencySavings
            previousStep={history.goBack}
            defaultEmergencySavings={emergencialSavings}
            setEmergencySavings={setEmergencialSavings}
            nextStep={nextStep}
        />,
        <SuccessionCosts
            previousStep={previousStep}
            defaultSuccession={successionCosts}
            nextStep={nextStep}
            setSuccession={setSuccessionCosts}
            errLabel={errLabel}
        />,
        <Insurance
            previousStep={previousStep}
            defaultInsurance={insurance}
            nextStep={finish}
            setInsurance={setInsurance}
            errLabel={errLabel}
        />,
    ];

    React.useEffect(() => {
        // this effects handles the returns from steps components, everytime each returns it saves to a new state on goal
        // if is the last step, set ready to true
        // makes sure child component just update state at nextbutton
        if (emergencialSavings) {
            setRiskManagement(previousRisk => ({ ...previousRisk!, savings: emergencialSavings, ready }));
        }
        if (successionCosts) {
            setRiskManagement(previousRisk => ({ ...previousRisk!, successionCosts, ready }));
        }
        if (insurance) {
            setRiskManagement(previousRisk => ({ ...previousRisk!, insurance, ready }));
        }
    }, [emergencialSavings, successionCosts, insurance, ready]);

    React.useEffect(() => {
        changeTitle(`Gestão de risco`, currentStep, totalSteps);
    }, [currentStep, totalSteps]);

    return (
        <div className='container'>
            {Steps[currentStep - 1]}
        </div>
    );
};
export default RiskManagement;
