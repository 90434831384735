import React from 'react';
import { Typography } from '@material-ui/core';

const YearsAdornment: React.FC = () => (
    <Typography style={{ marginBottom: 1, marginLeft: 4, marginRight: 8 }} variant="body2">
        anos
    </Typography>
);

export default YearsAdornment;
