import { createStyles, makeStyles, Theme } from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        header: {
            margin: theme.spacing(4, 0),
        },
        content: {
            paddingBottom: '30px',
        },
    })
);