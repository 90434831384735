import { makeStyles, createStyles, Theme } from '@material-ui/core';
export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        container: {
            width: '100%',
            padding: '10px',
        },
        subHeading: {
            color: '#2C2F31',
            fontWeight: 600,
        },
        button: {
            marginLeft: '10px',
        },
    })
);