import { createStyles, makeStyles, Theme } from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            marginTop: '16px',
            padding: theme.spacing(2),
            borderRadius: '12px',
        },
        subHeading: {
            marginTop: theme.spacing(2),
            marginBottom: theme.spacing(1),
        },
        spacing: {
            padding: theme.spacing(1),
        },
        group: {
            backgroundColor: '#F8F8F8',
            maxWidth: '35vw',
            borderRadius: theme.spacing(1),
            marginBottom: theme.spacing(2),
        },
        groupTotal: {
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between',
            padding: theme.spacing(2),
            marginTop: theme.spacing(2),
        },
        innerPadding: {
            '@media (min-width: 768px)': {
                display: 'flex',
                flexFlow: 'column',
                justifyContent: 'flex-start',
                height: '95.5%',
                paddingRight: theme.spacing(1),
            },
            '@media (max-width: 767px)': {
                display: 'flex',
                flexFlow: 'column',
                justifyContent: 'flex-start',
                height: '95.5%',
            },
        },
        item: {
            flex: '1 1',
        },
    })
);