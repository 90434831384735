import axios from 'axios';
import { History } from 'history';

let baseURL = process.env.REACT_APP_URL_API;

const api = axios.create({
    baseURL,
    timeout: 120000,
    headers: {
        'Content-Type': 'application/json',
        //'Access-Control-Allow-Origin': '*',
        //'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
    },

    withCredentials: true,
});

export const setupApi = (history: History<History.UnknownFacade>) => {
    api.interceptors.response.use(
        response => {
            return response;
        },
        error => {
            if (error.response && error.response?.status === 514) {
                history.push('/invalid_planning');
                return Promise.reject(error);
            }
            throw error;
        }
    );
};

export default api;
