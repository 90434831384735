import { Typography } from '@material-ui/core';
import React from 'react';
import { useDispatch } from 'react-redux';
import api from '../../../../../apis/dreamrichapi';
import { ChangeFlowFinancialHealth } from '../../../../../redux/actions/customerActions';
import formatter from '../../../../global/formater';
import SuggestionCard from '../../suggestionCard/suggestionCard';
import { IActions, IFinancialHealth } from '../../types';
import { useStyles } from './style'

interface ISuggestionProps {
    actions: IActions;
    rentability: number;
    userID?: string;
    patrimonyLag: number;
}

type CorrectionType = 'goalreduction' | 'gain' | 'left' | 'age';

const SuggestionsMobile: React.FC<ISuggestionProps> = ({ actions, rentability, userID, patrimonyLag }) => {
    const classes = useStyles();
    const left = actions.monthlyLeft;
    const goalReduction = actions.goalReduction;
    const agePredicted = actions.years;
    const achievedIndependence = patrimonyLag >= 0;
    const [disabled, setDisabled] = React.useState([true, true, true, true]);
    const dispatch = useDispatch();

    const setItemEnabled = (correction: CorrectionType, value: number, index: number) => {
        const params = new URLSearchParams();

        params.append('correction', correction);
        params.append('value', value.toString());
        params.append('rentability', rentability.toString());

        if (userID) {
            params.append('userID', userID);
        }

        const url = `/financialhealth?${params.toString()}`;

        api.get<IFinancialHealth>(url.toString())
            .then(response => dispatch(ChangeFlowFinancialHealth(response.data)))
            .catch(e => console.debug(e));
        setDisabled(disabled.map((_, idx) => (idx === index ? false : true)));

    };
    return (
        <div className={classes.root}>
            <Typography className={classes.subHeading} variant="h6">Resultados aproximados, devido ao fluxos de aportes irregulares</Typography>
            <>
                <div className={classes.cardLine}>
                    <SuggestionCard
                        className={classes.cardItem}
                        header="Rentabilidade anual necessária (juros real)"
                        value={
                            achievedIndependence
                                ? 'Alcançado'
                                : actions.rate < 0
                                ? 'Alcançado'
                                : actions.rate
                                      .toFixed(2)
                                      .toString()
                                      .replace('.', ',') + ' %'
                        }
                        disabled={false}
                        typographyClassName={achievedIndependence || actions.rate < 0 ? classes.successTextColor : ''}
                        onClick={() => setItemEnabled('left', left, 1)}
                        subtitle={!achievedIndependence && actions.rate > 0 ? 'aproximado' : ''}
                    />
                    <SuggestionCard
                        className={classes.cardItem}
                        header="Redução necessária dos alvos"
                        value={
                            achievedIndependence
                                ? 'Alcançado'
                                : goalReduction < 0
                                ? 'Insuficiente'
                                : `${goalReduction.toFixed(2).replace('.', ',')}%`
                        }
                        disabled={false}
                        typographyClassName={
                            achievedIndependence
                                ? classes.successTextColor
                                : goalReduction < 0
                                ? classes.errorTextColor
                                : ''
                        }
                        onClick={() => setItemEnabled('goalreduction', goalReduction / 100, 2)}
                    />
                    <SuggestionCard
                        className={classes.cardItem}
                        header="Idade estimada para a independência financeira"
                        value={
                            achievedIndependence
                                ? 'Alcançado'
                                : agePredicted < 0
                                ? 'Insuficiente'
                                : `${agePredicted} anos`
                        }
                        disabled={false}
                        typographyClassName={
                            achievedIndependence
                                ? classes.successTextColor
                                : agePredicted < 0
                                ? classes.errorTextColor
                                : ''
                        }
                        onClick={() => setItemEnabled('age', agePredicted, 3)}
                    />
                    <SuggestionCard className={classes.cardItem}
                        header="Sobras mensais adicionais necessárias"
                        value={
                            achievedIndependence
                                ? 'Alcançado'
                                : actions.monthlyLeft < 0
                                ? 'Insuficiente'
                                : formatter.format(actions.monthlyLeft)
                        }
                        disabled={false}
                        typographyClassName={
                            achievedIndependence
                                ? classes.successTextColor
                                : actions.monthlyLeft < 0
                                ? classes.errorTextColor
                                : ''
                        }
                        onClick={() => {}} />
                </div>
            </>
        </div>
    );
};

export default SuggestionsMobile;
