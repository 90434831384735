import { createStyles, makeStyles, Theme } from '@material-ui/core';
import { grey, red } from '@material-ui/core/colors';

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        content: {
            margin: 'auto',
            maxWidth: '65vw',
            display: 'flex',
            justifyContent: 'center',
            flexDirection: 'column',
            alignContent: 'center',
            alignItems: 'center',
        },
        header: {
            fontWeight: 600,
        },
        head: {
            padding: theme.spacing(2),
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            alignItems: 'center',
        },
        subheader: {
            margin: theme.spacing(2, 0),
        },
        selectButton: {
            backgroundColor: '#FFFFFF',  
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            '& > :first-child': {
                marginRight: theme.spacing(1),
            },
        },
        container: {
            display: 'flex',
            flexDirection: 'column',
            alignContent: 'center',
            alignItems: 'center',
        },
        tableInput: {
            marginTop: theme.spacing(2),
            flex: '1 0 100%',
            minWidth: '50%',
        },
        tableShow: {
            paddingTop: theme.spacing(2),
            position: 'relative',
        },
        formControllers: {
            paddingTop: theme.spacing(2),
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            alignContent: 'flex-end',
        },
        chipButton: {
            backgroundColor: 'transparent',
            border: 'none',
        },
        chip: {
            color: grey[50],
        },
        outButton: {
            backgroundColor: 'white',
            color: grey[400],
        },
    })
);
