import React from 'react';

const renderLegend: React.FC<any> = props => {
    const { payload } = props;

    return (
        <ul
            style={{
                padding: 0,
                margin: 0,
                textAlign: 'center',
                display: 'block',
                listStyleType: 'disc',
            }}
        >
            {payload.map((entry: any, index: number) => (
                <li
                    style={{
                        display: 'inline-flex',
                        marginRight: 10,
                        alignItems: 'flex-end',
                    }}
                    key={`item-${index}`}
                >
                    <svg width="15" height="10" style={{ borderRadius: 3, marginBottom: 3 }}>
                        <rect width="15" height="10" fill={entry.color} />
                    </svg>
                    <div style={{ marginLeft: 5, textTransform: 'capitalize' }}>{entry.value}</div>
                </li>
            ))}
            <li
                style={{
                    display: 'inline-flex',
                    marginRight: 10,
                    alignItems: 'flex-end',
                }}
                key={`item-${payload.length + 1}`}
            >
                <svg width="15" height="10" style={{ borderRadius: 3, marginBottom: 3 }}>
                    <rect width="15" height="10" fill="#29B6F6" />
                </svg>
                <div style={{ marginLeft: 5 }}>Ano da independência</div>
            </li>
        </ul>
    );
};
export default renderLegend;
