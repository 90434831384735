import { ReduxAction } from './reduxAction';

export const showYourNumber = 'SHOW_YOUR_NUMBER';
export const ShowYourNumber = (userID: string, show: boolean): ReduxAction<{ userID: string; show: boolean }> => ({
    type: showYourNumber,
    payload: {
        show,
        userID,
    },
});
