import { createStyles, makeStyles, Theme } from '@material-ui/core';
import { grey } from '@material-ui/core/colors';


export const useStyles = makeStyles((theme: Theme) =>
createStyles({
    root: {
        paddingTop: theme.spacing(2),
        display: 'flex',
        justifyContent: 'center',
    },
    table: {},
    periodicity: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-start',
        alignItems: 'flex-end',
    },
    input: {
        '& .MuiInputBase-input': {
            width: '75%',
        },
    },
    inputWidth: {
        flexBasis: '5%',
    },
    nextButton: {
        paddingTop: theme.spacing(2),
        float: 'right',
    },
    deleteHidden: {
        backgroundColor: theme.palette.error.main,
        height: '63px',
        borderTopRightRadius: '5px',
        borderBottomRightRadius: '5px',
        float: 'right',
        position: 'absolute',
    },
    yearSelector: {
        '&.MuiFormControl-marginNormal': {
            margin: 0,
        },
    },
    header: {},
    head: {
        padding: theme.spacing(2),
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    outButton: {
        backgroundColor: 'white',
        color: grey[400],
    },
    subHeader: {
        margin: theme.spacing(2, 0),
    },
    formControllers: {
        paddingTop: theme.spacing(2),
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        alignContent: 'flex-end',
    },
    chipButton: {
        backgroundColor: 'transparent',
        border: 'none',
    },
    chip: {
        color: grey[50],
    },
})
);