import { createStyles, makeStyles, Theme } from '@material-ui/core';
import { blueGrey } from '@material-ui/core/colors';

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            overflowX: 'hidden',
            overflowY: 'scroll',
            height: '100%',
            padding: theme.spacing(0, 4, 4, 4),
        },
        firstLevel: {
            marginTop: theme.spacing(2),
            marginLeft: theme.spacing(2),
        },
        loaded: {
            width: '97%',
        },
        header: {
            display: 'flex',
            justifyContent: 'flex-start',
            alignItems: 'baseline',
            marginLeft: theme.spacing(2),
        },
        adjustLabel: {
            marginTop: '4px',
            marginLeft: theme.spacing(2),
        },
        heading: {
            color: blueGrey[900],
            marginBottom: theme.spacing(2),
        },
        rootContent: {},
        chart: {
            flexBasis: '75%',
            flexGrow: 1,
        },
        paperContent: {
            padding: theme.spacing(2),
        },

        info: {},
    })
);