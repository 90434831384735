import { createStyles, makeStyles, Theme } from '@material-ui/core';
export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        tableHead: {
            fontWeight: 600,
        },
        table: {
            margin: 'auto',
            marginBottom: theme.spacing(1),
        },
    })
);