import { Button, createStyles, makeStyles, Paper, Theme, Typography } from '@material-ui/core';
import React from 'react';
import unmaskCurrency, { maskCurrency } from '../../../../utils/unmaskCurrency';
import MoneyAdornment from '../../../global/adornments/MoneyAdornment';
import { GreyedDateInput, GreyedTextInput } from '../../../global/inputs/GreyedInput';
import CurrencyInput from '../../../global/masks/currencyMaskedInput';
import { IHouse } from '../types';
import {useStyles} from './style';


interface IHouseProps {
    value: IHouse;
    onEnd: (h: IHouse) => any;
    children?: React.ReactNode;
}

const House = React.forwardRef((props: IHouseProps, ref) => {
    const classes = useStyles();

    const { value, onEnd } = props;

    const [date, setDate] = React.useState(value.date || new Date());
    const [debit, setDebit] = React.useState(maskCurrency(value.debit));
    const [houseValue, setValue] = React.useState(maskCurrency(value.value));
    const [sell, setSell] = React.useState(value.forSell);

    const finish = () => {
        const house: IHouse = {
            value: unmaskCurrency(houseValue),
            debit: unmaskCurrency(debit),
            forSell: sell,
            date: sell ? date : undefined,
        };

        onEnd(house);
    };

    return (
        <Paper className={classes.paper}>
            <Typography>Valor do imóvel</Typography>
            <GreyedTextInput
                color="primary"
                variant="outlined"
                size="small"
                placeholder="0.00"
                InputProps={{
                    inputComponent: CurrencyInput as any,
                    startAdornment: <MoneyAdornment />,
                }}
                InputLabelProps={{
                    shrink: true,
                }}
                value={houseValue}
                onChange={event => setValue(event.target.value)}
                fullWidth
            />
            <div>
                <Typography>Pretende vender?</Typography>
                <div className={classes.line}>
                        {/* @ts-ignore */}
                    <Button
                        variant="outlined"
                        color={sell ? 'primary' : 'default'}
                        disableElevation
                        onClick={() => setSell(true)}
                    >
                        SIM
                    </Button>
                        {/* @ts-ignore */}
                    <Button
                        disableElevation
                        variant="outlined"
                        color={!sell ? 'primary' : 'default'}
                        onClick={() => setSell(false)}
                    >
                        NÃO
                    </Button>
                </div>
            </div>
            {sell ? (
                <React.Fragment>
                    <Typography>Data da venda</Typography>
                    <GreyedDateInput
                        size="small"
                        value={date}
                        format="dd/MM/yyyy"
                        margin="normal"
                        variant="inline"
                        inputVariant="outlined"
                        onChange={d => d && setDate(d)}
                    />
                </React.Fragment>
            ) : null}
            <div className={classes.actions}>
                {/* @ts-ignore */}
                <Button color="primary" variant="outlined" onClick={finish}>
                    ok
                </Button>
            </div>
        </Paper>
    );
});

export default House;
