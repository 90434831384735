import { createStyles, makeStyles, Theme } from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        inputGroup: {
            margin: `${theme.spacing(1)}px 0`,
        },
        monthsInput: {
            display: 'flex',
            justifyContent: 'flex-start',
            alignItems: 'flex-end',
        },
        form: {
            marginTop: theme.spacing(2),
        },
        rateGroup: {
            display: 'flex',
            flexWrap: 'wrap',
            justifyContent: 'flex-start',
            margin: '-4px',
        },
        rateItem: {
            flex: '1 0 48%',
            margin: '4px',
        },
        spacedColumn: { display: 'flex', justifyContent: 'space-between', flexDirection: 'column' },
        dateInput: {
            minWidth: 170,
        },
    })
);