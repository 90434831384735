import { IUserRedux } from '../../types/user';

export const initialState: IUserRedux = {
    currentUser: {
        'cognito:groups': [],
        'cognito:preferred_role': '',
        'cognito:roles': [],
        'cognito:username': '',
        aud: '',
        auth_time: 0,
        email: '',
        event_id: '',
        exp: 0,
        iat: 0,
        iss: '',
        phone_number: '',
        sub: '',
        token_use: '',
    },
};
