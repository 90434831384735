import {  Divider,  Paper,  Typography } from '@material-ui/core';
import React from 'react';
import formatter from '../../../global/formater';
import { ISuccessionTaxes } from '../types';
import { useStyles } from './style'


interface ISuccessionCardProps {
    patrimonyTotal: number;
    taxes: ISuccessionTaxes;
    type: 'current' | 'future';
}

const SuccessionCard: React.FC<ISuccessionCardProps> = ({ type, patrimonyTotal, taxes }) => {
    const classes = useStyles();

    const title = 'Custos de sucessão' + (type === 'future' ? ' na Indepedência Financeira' : '');

    const total = patrimonyTotal * (taxes.itcmd + taxes.oab + taxes.notary);

    return (
        <Paper className={classes.cardContent}>
            <Typography variant="h6">{title}</Typography>
            <div className={classes.patrimonyTotal}>
                <Typography>Ativos totais</Typography>
                <Typography color="primary">{formatter.format(patrimonyTotal)}</Typography>
            </div>
            <div className={classes.flexLine}>
                <div className={classes.percentageInfo}>{taxes.itcmd * 100}%</div>
                <Typography variant="body2">ITCMD</Typography>
                <Typography style={{ marginLeft: 'auto' }} color="error">
                    {formatter.format(taxes.itcmd * patrimonyTotal)}
                </Typography>
            </div>
            <div className={classes.flexLine}>
                <div className={classes.percentageInfo}>{taxes.oab * 100}%</div>
                <Typography variant="body2">OAB</Typography>
                <Typography style={{ marginLeft: 'auto' }} color="error">
                    {formatter.format(taxes.oab * patrimonyTotal)}
                </Typography>
            </div>
            <div className={classes.flexLine}>
                <div className={classes.percentageInfo}>{taxes.notary * 100}%</div>
                <Typography variant="body2">Cartório e Outros</Typography>
                <Typography style={{ marginLeft: 'auto' }} color="error">
                    {formatter.format(taxes.notary * patrimonyTotal)}
                </Typography>
            </div>
            <Divider className={classes.divider} variant="inset" />
            <div className={classes.total}>
                <Typography className={classes.bold}>Total</Typography>
                <Typography className={classes.bold} variant="h6" color="error">
                    {formatter.format(total)}
                </Typography>
            </div>
        </Paper>
    );
};
export default SuccessionCard;
