
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import api from '../../../../apis/dreamrichapi';
import { ChangeCustomerSuggestions } from '../../../../redux/actions/planningActions';
import { IStore } from '../../../../redux/types/store';
import Consortium from '../../financing/consortium/consortium';
import FinancialAssets from '../../patrimony/financialAssets/financialAssets';
import Pensions from '../../patrimony/pension/pension';
import { IFinancialAssets, IPension } from '../../patrimony/types';
import { IPlannerPageProps } from '../../types';
import SuggestedInsurance from '../suggestedInsurance/suggestedInsurance';
import { ISuggestedInsurance, ISuggestionRequest, ISuggestions } from '../types';

const Suggestions: React.FC<IPlannerPageProps> = ({ changeTitle }) => {
    const history = useHistory();
    // store vars
    const { userID, defaultSuggestions, currToolbar } = useSelector((store: IStore) => ({
        userID: store.planning.customerUserInfo.userID,
        currToolbar: store.planning.toolbar.current,
        defaultSuggestions: store.planning.customerFormValues.suggestions,
    }));

    const suggestionStorage = JSON.parse(localStorage.getItem('storageCustomer'+userID))?.suggestions;

    if (!userID) history.push('/');

    // step control
    const [currentStep, setStep] = React.useState(1);
    React.useEffect(() => {
        if (!currToolbar || currentStep === currToolbar) return;
        setStep(currToolbar);
    }, [currToolbar]);
    const nextStep = () => {
        if (currentStep < totalSteps) {
            setStep(currentStep + 1);
        }
    };

    // send control
    const [send, setSend] = React.useState(false);
    const finish = () => {
        setReady(true);
        // finish dipares send effect bellow
        setSend(true);
    };

    const [errLabel, setErrLabel] = React.useState('');
    // goal control to send just after finish
    const [ready, setReady] = React.useState(false);

    const dispatch = useDispatch();

    const previousStep = () => {
        setStep(currentStep - 1);
    };
    const [suggestions, setSuggestions] = React.useState<ISuggestions>(defaultSuggestions);
    React.useEffect(() => {
        // When clientInfo changes it doesn't necessarily is ready to be sent
        // send state is enabled when a finish function is called
        // verifyClientInfo checks for required fields before sending it through api then we just send data when all required fields where filled
        // this prevines to send twice
        // remove ready field before sending it isn't a problem to send this to server
        // but I rather not
        if (send && suggestions?.ready) {
            api.post<ISuggestionRequest>('/suggestions', {
                ...suggestions,
                userID,
            })
                .then(res => {
                    history.push('/planner');
                    setErrLabel('');
                    dispatch(ChangeCustomerSuggestions(suggestions));
                })
                .catch(err => {
                    if (err.response) {
                        setErrLabel(`Não foi possivel salvar as sugestões: ${err.response.data}`);
                    } else {
                        setErrLabel(`Erro interno do sistema, favor contatar o suporte: ${err}`);
                    }
                });

            setSend(false);
        }
    }, [send, history, suggestions]);
    const totalSteps = 4;
    const [insurance, setInsurance] = React.useState<ISuggestedInsurance[]>(suggestionStorage.suggestedInsurance || suggestions.suggestedInsurance);
    const [pension, setPension] = React.useState<IPension[]>(suggestionStorage.suggestedPension || suggestions.suggestedPension);
    const [investments, setInvestments] = React.useState<IFinancialAssets[]>(suggestionStorage.suggestedInvestments || suggestions.suggestedInvestments);
    const [consortia, setConsortia] = React.useState(suggestionStorage.suggestedConsortia || suggestions.suggestedConsortia);

    const Steps: JSX.Element[] = [
        <SuggestedInsurance
            defaultSuggestedInsurance={insurance}
            setSuggestedInsuranceValues={setInsurance}
            previousStep={history.goBack}
            nextStep={nextStep}
        />,
        <FinancialAssets
            defaultFinancialAssets={investments}
            setFinancialAssetsValues={setInvestments}
            nextStep={nextStep}
            previousStep={previousStep}
            suggested={true}
        />,
        <Consortium
            defaultConsortia={consortia}
            setConsortia={setConsortia}
            nextStep={nextStep}
            suggestion
            previousStep={previousStep}
        />,
        <Pensions
            defaultPensions={pension}
            setPensionsValues={setPension}
            nextStep={finish}
            previousStep={previousStep}
            errLabel={errLabel}
            suggested={true}
        />,
    ];

    React.useEffect(() => {
        // this effects handles the returns from steps components, everytime each returns it saves to a new state on goal
        // if is the last step, set ready to true
        // makes sure child component just update state at nextbutton

        if (insurance) {
            setSuggestions(previous => ({ ...previous!, suggestedInsurance: insurance, ready }));
        }
        if (pension) {
            setSuggestions(previous => ({ ...previous!, suggestedPension: pension, ready }));
        }
        if (investments) {
            setSuggestions(previous => ({ ...previous!, suggestedInvestments: investments, ready }));
        }
        setSuggestions(previous => ({
            ...previous!,
            suggestedConsortia: consortia?.filter(item => item.receivedValue > 0) || [],
            ready,
        }));
    }, [insurance, pension, investments, consortia, ready]);

    React.useEffect(() => {
        changeTitle(`Sugestões`, currentStep, totalSteps);
    }, [currentStep, totalSteps]);

    return (
        <div className='container'>
            {/* <LinearProgress variant="determinate" value={percentage} color="primary" /> */}
            {Steps[currentStep - 1]}
        </div>
    );
};
export default Suggestions;
