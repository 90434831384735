import { Button, createStyles, Grid, IconButton, makeStyles, Theme, Typography } from '@material-ui/core';
import { red } from '@material-ui/core/colors';
import { Delete } from '@material-ui/icons';
import React from 'react';
import CreatableSelect from 'react-select/creatable';
import { GreyedDateInput, GreyedTextInput } from '../../../global/inputs/GreyedInput';
import CurrencyInput from '../../../global/masks/currencyMaskedInput';
import { costSelectOptions, ICostChange } from '../types';
import { useStyles } from './style';

interface ICostChangeProps {
    val: ICostChange;
    setChange: (item: ICostChange) => void;
    removeSelf: () => void;
    isLast: boolean;
    autoFocus: boolean;
    addLine?: () => void;
}

const CostChangeEntry: React.FC<ICostChangeProps> = ({ val, setChange, autoFocus, isLast, removeSelf, addLine }) => {
    const classes = useStyles();
    const isRaise = () => {
        if (isNaN(val.value)) return true;
        if (val.value >= 0) return true;
        return false;
    };
    const [raise, setRaise] = React.useState(isRaise());
    const [value, setValue] = React.useState(val.value.toString().replace('-', ''));
    React.useEffect(() => {
        if (val.value === 0) {
            setValue('');
        }
    }, [val.value, setValue]);

    const unmask = (v: string) => Math.abs(parseFloat(v.replace('.', '').replace(',', '.'))) * (raise ? 1 : -1);

    const setName = (name: string) => setChange({ ...val, name });

    const setDate = (dateEv: Date) => {
        setChange({ ...val, date: dateEv });
    };

    const changeValue = (v: string) => {
        setValue(v);
        const valueUnmasked = unmask(v);
        setChange({ ...val, value: valueUnmasked });
    };

    React.useEffect(() => {
        const unmask_ = (v: string) => Math.abs(parseFloat(v.replace('.', '').replace(',', '.'))) * (raise ? 1 : -1);

        setChange({ ...val, value: unmask_(value) });
    }, [raise, value, setRaise]);

    const [selected, setSelected] = React.useState({ value: val.name, label: val.name });

    const handleChange = (newValue: any, actionMeta: any) => {
        setName(newValue ? newValue.label : '');
        setSelected(newValue ? newValue : { value: '', label: '' });
    };

    return (
        <div className="row">
            <div className="col-md-3">
                <Typography className={classes.inputLabel} variant="body2">
                    Custo
                </Typography>
                <CreatableSelect
                    formatCreateLabel={input => 'Criar ' + input}
                    isClearable
                    onChange={handleChange}
                    options={costSelectOptions}
                    value={selected}
                />
            </div>
            <div className="col-md-3">
                <Typography className={classes.inputLabel} variant="body2">
                    Data
                </Typography>
                <GreyedDateInput
                    defaultValue={new Date()}
                    variant="inline"
                    format="dd/MM/yyyy"
                    margin="normal"
                    value={val.date}
                    onChange={dateEvt => setDate(dateEvt as Date)}
                    KeyboardButtonProps={{
                        'aria-label': 'change-date',
                    }}
                    inputVariant="outlined"
                    size="small"
                    className='w-100 p-0'
                />
            </div>
            <div className="col-md-3">
                <Typography className={classes.inputLabel} variant="body2">
                    Valor mensal
                </Typography>
                <GreyedTextInput
                    color="primary"
                    variant="outlined"
                    size="small"
                    type="numeric"
                    placeholder="0.00"
                    InputProps={{
                        inputComponent: CurrencyInput as any,
                        startAdornment: <div style={{ margin: '0 8px' }}>R$</div>,
                    }}
                    InputLabelProps={{
                        shrink: true,
                    }}
                    value={value}
                    onChange={event => changeValue(event.target.value as string)}
                    onFocus={e => {
                        const v = e.target.value;
                        e.target.value = '';
                        e.target.value = v;
                    }}
                    className='p-0'
                    fullWidth
                />
            </div>
            <div className="col-md-3 d-flex align-items-center mt-3 justify-content-center">
                <div className={`${classes.line} mt-4 w-100 d-flex justify-content-end`}>
                    {/* @ts-ignore */}
                    <Button
                        classes={{ root: raise ? classes.raised : classes.unraised }}
                        focusRipple={false}
                        onClick={() => setRaise(true)}
                    >
                        Aumento
                    </Button>
                    {/* @ts-ignore */}
                    <Button
                        className={!raise ? classes.reduction : classes.unraised}
                        focusRipple={false}
                        onClick={() => setRaise(false)}
                        style={{ marginLeft: 8 }}
                    >
                        Redução
                    </Button>
                    {isLast ? (
                        //@ts-ignore
                        <Button
                            size="small"
                            variant="contained"
                            disableElevation
                            color="primary"
                            style={{ color: 'white', maxWidth: '100px' }}
                            fullWidth
                            onClick={addLine}
                            className='ms-2'
                        >
                            Adicionar
                        </Button>
                    ) : null}
                    {/* @ts-ignore */}
                    <IconButton className={`${classes.removeButton} p-0 me-2 ms-2`} onClick={removeSelf}>
                        <Delete />
                    </IconButton>
                </div>
            </div>
        </div>
    );
};
export default CostChangeEntry;
