import { Chip, createStyles, makeStyles, Theme, Typography } from '@material-ui/core';
import { grey, red } from '@material-ui/core/colors';
import React from 'react';
import { useSelector } from 'react-redux';
import { IStore } from '../../../../redux/types/store';
import unmaskCurrency from '../../../../utils/unmaskCurrency';
import MoneyAdornment from '../../../global/adornments/MoneyAdornment';
import { GreyedTextInput } from '../../../global/inputs/GreyedInput';
import CurrencyInput from '../../../global/masks/currencyMaskedInput';
import PerspectiveLine from '../perspectiveLine/perspectiveLine';
import { IIncoming } from '../types';
import { useStyles } from './style';

interface IIncomePageProps {
    defaultIncome: IIncoming;
    errLabel: string;
    setIncome: React.Dispatch<React.SetStateAction<IIncoming>>;
    previousStep: () => void;
    nextStep: () => void;
    partner?: boolean;
}

export const Incoming: React.FC<IIncomePageProps> = ({
    defaultIncome,
    errLabel,
    setIncome,
    previousStep,
    nextStep,
    partner,
}) => {
    const classes = useStyles();
    const [salary, setSalary] = React.useState(defaultIncome.salary ? defaultIncome.salary.toString() : '');
    const [lastSalaryBonus, setLastSalaryBonus] = React.useState(
        defaultIncome.lastSalaryBonus ? defaultIncome.lastSalaryBonus.toString() : ''
    );
    const [vacationBonus, setVacationBonus] = React.useState(
        defaultIncome.vacationBonus ? defaultIncome.vacationBonus.toString() : ''
    );
    const [prl, setPrl] = React.useState(defaultIncome.prl ? defaultIncome.prl.toString() : '');
    const [bonus, setBonus] = React.useState(defaultIncome.bonus ? defaultIncome.bonus.toString() : '');
    const [dividend, setDividend] = React.useState(defaultIncome.dividend ? defaultIncome.dividend.toString() : '');
    const [alimony, setAlimony] = React.useState(defaultIncome.alimony ? defaultIncome.alimony.toString() : '');
    const [rent, setRent] = React.useState(defaultIncome.rent ? defaultIncome.rent.toString() : '');
    const [other, setOther] = React.useState(defaultIncome.other ? defaultIncome.other.toString() : '');

    const [perspectiveAutoFocus, setPerspectiveAutoFocus] = React.useState(false);

    const [perspectiveInput, setPerspective] = React.useState<{ value: string; date: Date }[]>([]);
    React.useEffect(() => {
        setPerspective(
            defaultIncome.perspective && defaultIncome.perspective.length
                ? defaultIncome.perspective.map(item => ({ value: item.value.toString(), date: item.date }))
                : [{ value: '', date: new Date() }]
        );
    }, [defaultIncome, setPerspective]);

    const setPerspectiveItem = (item: { value: number; date: Date }, index: number) => {
        const value = item.value ? item.value.toString() : '';
        setPerspective(perspective => [
            ...perspective.slice(0, index),
            { ...item, value },
            ...perspective.slice(index + 1),
        ]);
    };
    const addPerspectiveLine = () => {
        setPerspective(perspective => [...perspective, { value: '', date: new Date() }]);
        setPerspectiveAutoFocus(true);
    };
    const removePerspectiveLine = (index: number) => {
        if (perspectiveInput.length === 1) {
            setPerspective([{ value: '', date: new Date() }]);
            return;
        }
        setPerspective(perspective => [...perspective.slice(0, index), ...perspective.slice(index + 1)]);
        setPerspectiveAutoFocus(false);
    };

    const endPage = React.useRef<any | null>(null);
    const scrollToBottom = () => {
        if (perspectiveAutoFocus) {
            endPage.current.scrollIntoView({ behavior: 'smooth' });
        }
    };

    React.useEffect(scrollToBottom, [perspectiveInput, perspectiveAutoFocus]);
    React.useEffect(() => {
        setSalary(defaultIncome.salary ? defaultIncome.salary.toString() : '');
        setLastSalaryBonus(defaultIncome.lastSalaryBonus ? defaultIncome.lastSalaryBonus.toString() : '');
        setVacationBonus(defaultIncome.vacationBonus ? defaultIncome.vacationBonus.toString() : '');
        setPrl(defaultIncome.prl ? defaultIncome.prl.toString() : '');
        setBonus(defaultIncome.bonus ? defaultIncome.bonus.toString() : '');
        setDividend(defaultIncome.dividend ? defaultIncome.dividend.toString() : '');
        setPerspective(
            defaultIncome.perspective && defaultIncome.perspective.length
                ? defaultIncome.perspective.map(item => ({ value: item.value.toString(), date: item.date }))
                : [{ value: '', date: new Date() }]
        );
    }, [defaultIncome]);

    const [btnDisabled, setBtnDisabled] = React.useState(false);

    const onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        const incomingBody: IIncoming = {
            salary: unmaskCurrency(salary),
            lastSalaryBonus: unmaskCurrency(lastSalaryBonus),
            vacationBonus: unmaskCurrency(vacationBonus),
            prl: unmaskCurrency(prl),
            bonus: unmaskCurrency(bonus),
            dividend: unmaskCurrency(dividend),
            perspective: perspectiveInput
                .filter(item => item.value)
                .map(item => ({ ...item, value: parseFloat(item.value) })),
            alimony: unmaskCurrency(alimony),
            rent: unmaskCurrency(rent),
            other: unmaskCurrency(other),
        };
        setIncome(incomingBody);
        nextStep();
        setBtnDisabled(true);
    };

    return (
        <div className='container pb-4'>
            <div className={classes.head}>
                <Typography variant="h4" className={classes.header}>
                    Renda do(a) {partner ? 'cônjuge' : 'cliente'}
                </Typography>
            </div>
            <form className='container' onSubmit={onSubmit}>
                <div className="row">
                    <div className="col-md-6 d-flex flex-column gap-2">
                        <Typography className={classes.inputLabel} variant="body2">
                            Salário*
                        </Typography>
                        <GreyedTextInput
                            autoFocus
                            color="primary"
                            variant="outlined"
                            size="small"
                            placeholder="0.00"
                            InputProps={{
                                inputComponent: CurrencyInput as any,
                                startAdornment: <MoneyAdornment paddingLeft={8} noMargin />,
                            }}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            value={salary}
                            onChange={event => setSalary(event.target.value)}
                            fullWidth
                        />
                    </div>
                    <div className="col-md-6 d-flex flex-column gap-2">
                        <Typography className={classes.inputLabel} variant="body2">
                            Décimo Terceiro
                        </Typography>
                        <GreyedTextInput
                            color="primary"
                            variant="outlined"
                            size="small"
                            placeholder="0.00"
                            InputProps={{
                                inputComponent: CurrencyInput as any,
                                startAdornment: <MoneyAdornment paddingLeft={8} noMargin />,
                            }}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            value={lastSalaryBonus}
                            onChange={event => setLastSalaryBonus(event.target.value)}
                            fullWidth
                        />
                    </div>
                </div>

                <div className="row">
                    <div className="col-md-4 d-flex flex-column gap-2">
                        <Typography className={classes.inputLabel} variant="body2">
                            Adicional de Férias
                        </Typography>
                        <GreyedTextInput
                            color="primary"
                            variant="outlined"
                            size="small"
                            placeholder="0.00"
                            InputProps={{
                                inputComponent: CurrencyInput as any,
                                startAdornment: <MoneyAdornment paddingLeft={8} noMargin />,
                            }}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            value={vacationBonus}
                            onChange={event => setVacationBonus(event.target.value)}
                            fullWidth
                        />
                    </div>
                    <div className="col-md-4 d-flex flex-column gap-2">
                        <Typography className={classes.inputLabel} variant="body2">
                            PLR (anual)
                        </Typography>
                        <GreyedTextInput
                            color="primary"
                            variant="outlined"
                            size="small"
                            placeholder="0.00"
                            InputProps={{
                                inputComponent: CurrencyInput as any,
                                startAdornment: <MoneyAdornment paddingLeft={8} noMargin />,
                            }}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            value={prl}
                            onChange={event => setPrl(event.target.value)}
                            fullWidth
                        />
                    </div>
                    <div className="col-md-4 d-flex flex-column gap-2">
                        <Typography className={classes.inputLabel} variant="body2">
                            Bônus (anual)
                        </Typography>
                        <GreyedTextInput
                            color="primary"
                            variant="outlined"
                            size="small"
                            placeholder="0.00"
                            InputProps={{
                                inputComponent: CurrencyInput as any,
                                startAdornment: <MoneyAdornment paddingLeft={8} noMargin />,
                            }}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            value={bonus}
                            onChange={event => setBonus(event.target.value)}
                            fullWidth
                        />
                    </div>
                </div>

                <div className="row">
                    <div className="col-md-6 d-flex flex-column gap-2">
                        <Typography className={classes.inputLabel} variant="body2">
                            Dividendos (anual)
                        </Typography>
                        <GreyedTextInput
                            color="primary"
                            variant="outlined"
                            size="small"
                            placeholder="0.00"
                            InputProps={{
                                inputComponent: CurrencyInput as any,
                                startAdornment: <MoneyAdornment paddingLeft={8} noMargin />,
                            }}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            value={dividend}
                            onChange={event => setDividend(event.target.value)}
                            fullWidth
                        />
                    </div>
                    <div className="col-md-6 d-flex flex-column gap-2">
                        <Typography className={classes.inputLabel} variant="body2">
                            Pensão Alimentícia (mensal)
                        </Typography>
                        <GreyedTextInput
                            color="primary"
                            variant="outlined"
                            size="small"
                            placeholder="0.00"
                            InputProps={{
                                inputComponent: CurrencyInput as any,
                                startAdornment: <MoneyAdornment paddingLeft={8} noMargin />,
                            }}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            value={alimony}
                            onChange={event => setAlimony(event.target.value)}
                            fullWidth
                        />
                    </div>
                </div>

                <div className="row">
                    <div className="col-md-6 d-flex flex-column gap-2">
                        <Typography className={classes.inputLabel} variant="body2">
                            Aluguéis (mensal)
                        </Typography>
                        <GreyedTextInput
                            color="primary"
                            variant="outlined"
                            size="small"
                            placeholder="0.00"
                            InputProps={{
                                inputComponent: CurrencyInput as any,
                                startAdornment: <MoneyAdornment paddingLeft={8} noMargin />,
                            }}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            value={rent}
                            onChange={event => setRent(event.target.value)}
                            fullWidth
                        />
                    </div>
                    <div className="col-md-6 d-flex flex-column gap-2">
                        <Typography className={classes.inputLabel} variant="body2">
                            Outras fontes de renda (mensal)
                        </Typography>
                        <GreyedTextInput
                            color="primary"
                            variant="outlined"
                            size="small"
                            placeholder="0.00"
                            InputProps={{
                                inputComponent: CurrencyInput as any,
                                startAdornment: <MoneyAdornment paddingLeft={8} noMargin />,
                            }}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            value={other}
                            onChange={event => setOther(event.target.value)}
                            fullWidth
                        />
                    </div>
                </div>

                <div>
                    <Typography className={`${classes.inputLabel} mb-2`} variant="body2">
                        Perspectiva de aumento ou diminuição
                    </Typography>

                    {perspectiveInput.map((item, index) => (
                        <div key={`perspective-${partner ? 'partner' : 'default'}-${index}`}>
                            <PerspectiveLine
                                key={`perspective-${partner ? 'partner' : 'default'}-${index}-line`}
                                value={item.value}
                                date={item.date}
                                autoFocus={perspectiveAutoFocus}
                                setPerspective={i => setPerspectiveItem(i, index)}
                                removeSelf={() => removePerspectiveLine(index)}
                                isLast={index === perspectiveInput.length - 1}
                                addLine={index === perspectiveInput.length - 1 ? addPerspectiveLine : undefined}
                            />
                        </div>
                    ))}
                </div>
                <Typography align="center" color="error">
                    {errLabel}
                </Typography>
                <div className={classes.formControllers}>
                {/* @ts-ignore */}
                    <Chip
                        className={classes.chip}
                        onClick={previousStep}
                        color="primary"
                        size="medium"
                        label="Voltar"
                        clickable
                    />
                    <button type="submit" className={classes.chipButton} disabled={btnDisabled}>
                        <Chip className={classes.chip} color="secondary" size="medium" label="Próximo" clickable />
                    </button>
                </div>
            </form>
            <div ref={endPage} />
        </div>
    );
};
