import { Button, Card, InputAdornment, TextField, Typography } from '@material-ui/core';
import React from 'react';
import unmaskCurrency from '../../../../utils/unmaskCurrency';
import DisplayWithBagAndPercentage from '../../../global/displays/displayWithBagAndPercentage/displayWithBagAndPercentage';
import CurrencyInput from '../../../global/masks/currencyMaskedInput';
import ObjectiveList from '../objectiveList/objectiveList';
import { IMonthlyGoals, IMonthlyObject } from '../types';
import { useStyles } from './style';

const MonthlyCard: React.FC<{
    monthlyGoal: IMonthlyGoals;
    index: number;
    loading: boolean;
    month: string;
    year: number;
    active?: boolean;
    saved: number;
    implementations: {
        investiments: IMonthlyObject[];
        objectives: IMonthlyObject[];
        protections: IMonthlyObject[];
        consortium: IMonthlyObject[];
    };
    userID?: string;
    changeImplementation: (year: number, impl: IMonthlyObject) => void;
    send: (goal: number, index: number) => void;
}> = ({
    monthlyGoal,
    month,
    year,
    active,
    index,
    loading,
    saved,
    send,
    changeImplementation,
    implementations,
}) => {
    const classes = useStyles();
    const [monthlyValue, setMonthlyValue] = React.useState(saved ? saved.toString() : '');

    React.useEffect(() => {
        if (saved === 0) {
            return setMonthlyValue('');
        }

        const newVal = saved.toString();

        return setMonthlyValue(val => (val !== newVal ? newVal : val));
    }, [setMonthlyValue, saved]);

    const [percentage, setPercentage] = React.useState(0);

    React.useEffect(() => {
        if (monthlyValue) {
            let calcpercentage = (unmaskCurrency(monthlyValue) - monthlyGoal.saveGoal) / monthlyGoal.saveGoal;
            if (Math.abs(calcpercentage) === 0) {
                calcpercentage = 0;
            }
            setPercentage(calcpercentage * 100);
        } else {
            setPercentage(0);
        }
    }, [setPercentage, monthlyGoal, monthlyValue]);

    return (
        <Card elevation={active ? 4 : 0} className={active ? classes.card : classes.cardDisabled}>
            <>
                <Typography variant="h6" className={classes.cardHeader}>{month}</Typography>
                <DisplayWithBagAndPercentage value={monthlyGoal.saveGoal} percentage={percentage} />
                <div className={classes.formDiv}>
                    <div style={{ display: 'flex', marginTop: '10px', marginBottom: '10px' }}>
                        <TextField
                            disabled={!active}
                            value={monthlyValue}
                            onChange={e => {
                                setMonthlyValue(e.target.value);
                            }}
                            onKeyPress={e => {
                                if (e.key === 'Enter') send(unmaskCurrency(monthlyValue), index);
                            }}
                            name="balance"
                            placeholder="000.00"
                            className={classes.textField}
                            InputProps={{
                                inputComponent: CurrencyInput as any,
                                startAdornment: <InputAdornment position="start">R$</InputAdornment>,
                            }}
                        />
                {/* @ts-ignore */}
                        <Button color="primary" onClick={() => send(unmaskCurrency(monthlyValue), index)}>
                            <Typography align="right">OK</Typography>
                        </Button>
                    </div>
                </div>
                <ObjectiveList
                    year={year}
                    month={index}
                    loading={loading}
                    changeImplementation={impl => changeImplementation(year, impl)}
                    listName={'Objetivos'}
                    monthlyObjectList={implementations.objectives}
                    disabled={!active}
                />
                <ObjectiveList
                    year={year}
                    month={index}
                    loading={loading}
                    changeImplementation={impl => changeImplementation(year, impl)}
                    listName={'Seguros'}
                    disabled={!active}
                    monthlyObjectList={implementations.protections}
                />
                <ObjectiveList
                    year={year}
                    month={index}
                    loading={loading}
                    changeImplementation={impl => changeImplementation(year, impl)}
                    disabled={!active}
                    listName={'Investimentos'}
                    monthlyObjectList={implementations.investiments}
                />
                <ObjectiveList
                    year={year}
                    month={index}
                    loading={loading}
                    changeImplementation={impl => changeImplementation(year, impl)}
                    disabled={!active}
                    listName="Consórcio"
                    monthlyObjectList={implementations.consortium}
                />
            </>
        </Card>
    );
};
export default MonthlyCard;
