import { createStyles, makeStyles, Theme } from '@material-ui/core';
import { blueGrey } from '@material-ui/core/colors';

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        header: {
            display: 'flex',
            justifyContent: 'flex-start',
            marginLeft: theme.spacing(1),
            marginBottom: theme.spacing(2),
        },
        adjustLabel: {
            marginTop: '-1px',
            marginLeft: theme.spacing(2),
        },
        heading: {
            color: blueGrey[900],
            paddingLeft: '5px',
        },
    })
);