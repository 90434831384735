import { createStyles, makeStyles, Theme } from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        subHeading: {
            color: '#2C2F31',
            fontWeight: 600,
        },
        cardHeader: {
            fontWeight: 600,
            fontSize: 16,
            color: '#020202',
        },
        cardLine: {
            overflowX: 'hidden',
            whiteSpace: 'nowrap',
            display: 'flex',
            '& > *': {},
        },
        cardItem: {
            flexGrow: 1,
        },
        spacing: {
            padding: theme.spacing(2),
        },
        lastCard: {
            maxWidth: '30vw',
        },
        successTextColor: {
            color: theme.palette.success.main,
        },
        flex: {
            display: 'flex',
            alignItems: 'center',
        },
        button: {
            width: '20px',
        },
    })
);