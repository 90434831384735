import { createStyles, makeStyles } from '@material-ui/core';
export const useStyles = makeStyles(() =>
    createStyles({
        root: {
            marginTop: '50px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            width: '100%',
            height: '100%',
            minWidth: 97,
        },
    })
);