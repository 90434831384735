import { createStyles, makeStyles, Theme } from '@material-ui/core';
import { grey } from '@material-ui/core/colors';

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            paddingTop: theme.spacing(2),
            marginLeft: '200px',
        },
        nextButton: {
            paddingTop: theme.spacing(2),
            float: 'right',
        },
        inputLabel: {
            padding: '1em 0 0.5em',
        },
        header: {
            fontWeight: 600,
        },
        form: {
            padding: '0 2em',
            width: '100%',
            maxWidth: '55vw',
            margin: '0 auto',
        },
        head: {
            maxWidth: '70vw',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            alignItems: 'center',
        },
        subhead: {
            margin: theme.spacing(2, 0),
        },
        outButton: {
            backgroundColor: 'white',
            color: grey[400],
        },
        line: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
        },
        buttonsLine: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-start',
        },
        formControllers: {
            paddingTop: theme.spacing(2),
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            alignContent: 'flex-end',
        },
        chipButton: {
            backgroundColor: 'transparent',
            border: 'none',
        },
        chip: {
            color: grey[50],
        },
        flexDiv: {
            display: 'flex',
        },
        flexDiv1: {
            flexGrow: 2,
            marginRight: '2em',
        },
        flexDiv2: {
            flexGrow: 1,
        },
    })
);