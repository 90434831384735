import { createStyles, makeStyles, Theme } from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        cardInvisible: {
            padding: '15px',
            flexShrink: 0,
            marginBottom: theme.spacing(2),
            width: '350px',
            backgroundColor: 'transparent',
        },
    })
);