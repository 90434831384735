import { createStyles, makeStyles, Theme } from '@material-ui/core';
import { grey, red } from '@material-ui/core/colors';

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        content: {
            margin: '0 auto',
        },
        header: {
            fontWeight: 600,
        },
        form: {
            width: '100%',
            margin: '0 auto',
            '& > :not(:first-child)': {
                marginTop: theme.spacing(1),
            },
        },
        inputLabel: {
            fontWeight: 600,
            marginTop: '40px',
        },
        head: {
            margin: theme.spacing(4, 0),
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            alignItems: 'center',
        },
        inputBlock: {
            flexWrap: 'wrap',
            justifyContent: 'flex-start',
            alignContent: 'space-between',
            margin: '-6px',
        },
        item: {
            flex: '0 1',
            margin: '6px',
            flexBasis: '48%',
        },
        nextButton: {
            paddingTop: theme.spacing(2),
            float: 'right',
        },
        formControllers: {
            paddingTop: theme.spacing(2),
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            alignContent: 'flex-end',
        },
        iconButtonGreen: {
            marginTop: '55px',
            marginLeft: '15px',
            paddingTop: '1px',
            paddingLeft: '1px',
            background: '#E1E1E1',
            width: '49px',
            height: '49px',
            '&:hover': { backgroundColor: theme.palette.primary.main },
        },
        iconButtonRed: {
            marginTop: '55px',
            marginLeft: '7px',
            paddingTop: '1px',
            paddingLeft: '1px',
            background: '#E1E1E1',
            width: '49px',
            height: '49px',
            '&:hover': { backgroundColor: red[400] },
        },
        subheader: {
            marginTop: theme.spacing(2),
        },
        chipButton: {
            backgroundColor: 'transparent',
            border: 'none',
        },
        chip: {
            color: grey[50],
            marginBottom: ''
        },
    })
);
