import { Button, Chip, Typography } from '@material-ui/core';
import React, { Dispatch, FC, SetStateAction, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { IStore } from '../../../../redux/types/store';
import FinancingInput from '../financingInput/financingInput';
import FinancingTable from '../financingTable/financingTable';
import { IFinancingRow, IFinancingValue } from '../types';
import { useStyles } from './style';

interface IFinancingProps {
    nickname: string;
    defaultFinancing: IFinancingValue[];
    setFinancingValues: Dispatch<SetStateAction<IFinancingValue[]>>;
    previousStep: () => void;
    nextStep: () => void;
}

const Financing: FC<IFinancingProps> = ({
    nickname,
    defaultFinancing,
    setFinancingValues,
    previousStep,
    nextStep,
}) => {
    const classes = useStyles({});

    const { customerFinancing } = useSelector((store: IStore) => ({ customerFinancing: store.planning.customerFormValues.financing.financing }));

    const [hasFinancing, setHasFinancial] = useState(customerFinancing.length > 0);

    const onAddLine = (financingInput: IFinancingValue) => {
        if (financingInput.name) {
            setFinancingValues(previousFinancing => [...previousFinancing, financingInput]);
        }
    };

    const [saved, setSaved] = useState(defaultFinancing);
    useEffect(() => {
        if (!hasFinancing) {
            setSaved(defaultFinancing);
            setFinancingValues([]);
        } else {
            setFinancingValues(saved);
        }
    }, [hasFinancing]);

    const [financingRows, setFinancingRows] = useState<IFinancingRow[]>([]);

    useEffect(() => {
        if (defaultFinancing) {
            setFinancingRows(
                defaultFinancing.map(item => ({
                    name: item.name,
                    type: item.type,
                    interestRate: item.interestRate,
                    monthAmount: item.monthsLeft,
                    installment: item.installment,
                    debit: item.debit,
                    startDate: item.startDate,
                }))
            );
        }
    }, [defaultFinancing]);

    const [loading, setLoading] = useState(false);

    const removeRow = (index: number) => {
        // create a new array without index entry
        setFinancingValues(rows => [...rows.slice(0, index), ...rows.slice(index + 1)]);
    };

    return (
        <div className={classes.content}>
            {/* <LinearProgress variant="determinate" value={100} color="primary" /> */}
            <div className={classes.head}>
                <Typography variant="h4" className={classes.header}>
                    Financiamentos do cliente
                </Typography>
                <Typography variant="body2">Ex: Carros, Motos, Moradia, Cotas Societárias, etc.</Typography>
                <div className={classes.selectButton}>
                    {/* @ts-ignore */}
                    <Button
                        variant="outlined"
                        color={hasFinancing ? 'primary' : 'default'}
                        disableElevation
                        onClick={() => setHasFinancial(true)}
                    >
                        SIM
                    </Button>
                    {/* @ts-ignore */}
                    <Button
                        disableElevation
                        variant="outlined"
                        color={!hasFinancing ? 'primary' : 'default'}
                        onClick={() => setHasFinancial(false)}
                    >
                        NÃO
                    </Button>
                </div>
            </div>
            {hasFinancing ? (
                <div>
                    <div>
                        <FinancingInput onClickAdd={onAddLine} />
                    </div>
                    <div >
                        <FinancingTable financingRows={financingRows} remove={removeRow} />
                    </div>
                </div>
            ) : null}
            <div className={classes.formControllers}>
                {/* @ts-ignore */}
                <Button className={classes.outButton}
                    variant="contained"
                    size="medium"
                    color="default"
                    disableElevation
                    onClick={previousStep}
                >
                    Sair
                </Button>
                {/* @ts-ignore */}
                <Chip
                    size="medium"
                    label="Próximo"
                    color={!loading ? 'primary' : 'primary'}
                    clickable={!loading}
                    onClick={nextStep}
                />
            </div>
        </div>
    );
};
export default Financing;
