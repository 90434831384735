import {  Grid,   Typography } from '@material-ui/core';
import React from 'react';
import SuccessionCard from '../successionCard/successionCard';
import { ISuccessionCosts } from '../types';
import { useStyles } from './style'

interface ISuccessionCostProps extends ISuccessionCosts {}

const SuccessionCost: React.FC<ISuccessionCostProps> = ({ current, future, currentTaxes, futureTaxes }) => {
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <Typography variant="h5">Custos de sucessão (seguro vitalício) </Typography>
            <Grid container spacing={2} className={classes.content}>
                <Grid item xs={6}>
                    <SuccessionCard patrimonyTotal={current} taxes={currentTaxes} type="current" />
                </Grid>
                <Grid item xs={6}>
                    <SuccessionCard patrimonyTotal={future} taxes={futureTaxes} type="future" />
                </Grid>
            </Grid>
        </div>
    );
};
export default SuccessionCost;
