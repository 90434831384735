import { blueGrey, lightBlue, teal } from '@material-ui/core/colors';
import React from 'react';
import { useSelector } from 'react-redux';
import { IStore } from '../../../redux/types/store';
import RenderPieChart, { IDataChart } from '../../global/charts/renderPieChart/renderPieChart';
import formatter from '../../global/formater';
import { IFinancingValue } from '../../planningForm/financing/types';

const convertPatrimonyToData = (financing: IFinancingValue[]): IDataChart[] => {
    const today = new Date();

    let arrayFees = [];
    let arrayInstallments = [];

    let dateFinancing = today;

    function incrementSAC(financing: IFinancingValue[], nameToVerify: string) {
        let valueToSend = 0
        for (let i = 0; i < financing.length; i++) {
            if (financing[i].name === nameToVerify) {
                dateFinancing = financing[i].startDate;
                let timeDiff = Math.abs(new Date(today).getTime() - new Date(dateFinancing).getTime());
                let diffMonths = Math.ceil(timeDiff / (1000 * 3600 * 24 * 30));
                let amortization = financing[i].debit / financing[i].monthsLeft;
                let debitForFees = financing[i].debit;

                for (let j = i; j < diffMonths; j++) {
                    arrayFees[j] = debitForFees * financing[i].interestRate;
                    debitForFees = debitForFees - amortization;
                    arrayInstallments[j] = arrayFees[j] + amortization;
                    valueToSend = debitForFees;
                }

                if (diffMonths < 0) {
                    valueToSend = 0
                }
            }
        }

        if (valueToSend < 0) {
            valueToSend = 0
        }

        return valueToSend;
    }

    function incrementOTHER(financing: IFinancingValue[], nameToVerify: string) {
        let valueToSend = 0
        for (let i = 0; i < financing.length; i++) {
            if (financing[i].name === nameToVerify) {
                dateFinancing = financing[i].startDate;
                let timeDiff = Math.abs(new Date(today).getTime() - new Date(dateFinancing).getTime());
                let diffMonths = Math.ceil(timeDiff / (1000 * 3600 * 24 * 30));

                valueToSend = financing[i].debit - financing[i].installment * diffMonths;

                if (diffMonths < 0) {
                    valueToSend = 0
                }
            }
        }

        if (valueToSend < 0) {
            valueToSend = 0
        }

        return valueToSend;
    }

    function incrementPRICE(financing: IFinancingValue[], nameToVerify: string) {
        let valueToSend = 0
        for (let i = 0; i < financing.length; i++) {
            if (financing[i].name === nameToVerify) {
                dateFinancing = financing[i].startDate;
                let timeDiff = Math.abs(new Date(today).getTime() - new Date(dateFinancing).getTime());
                let diffMonths = Math.ceil(timeDiff / (1000 * 3600 * 24 * 30));

                let percentInFloat = financing[i].interestRate / 100;

                let fee = Math.pow(1 + percentInFloat, 1 / 12) - 1;

                valueToSend = financing[i].debit;

                for (let j = 0; j < diffMonths; j++) {
                    let debitForFees = valueToSend * fee;
                    let amortization = financing[i].installment - debitForFees;
                    valueToSend = valueToSend - amortization;
                }

                if (diffMonths < 0) {
                    valueToSend = 0
                }
            }
        }

        if (valueToSend < 0) {
            valueToSend = 0
        }

        return valueToSend;
    }

    // @ts-ignore
    const propertiesConverted: IDataChart[] =
        [
            ...financing.map((_, i) =>
                financing[i].type === 'sac'
                    ? {
                        name: financing[i].name,
                        value: incrementSAC(financing, financing[i].name),
                    } || []
                    : financing[i].type === 'other'
                        ? {
                            name: financing[i].name,
                            value: incrementOTHER(financing, financing[i].name),
                        } || []
                        : {
                            name: financing[i].name,
                            value: incrementPRICE(financing, financing[i].name),
                        } || []
            ),
            // @ts-ignore
        ].sort((a, b) => a.value - b.value) || [];

    return propertiesConverted.filter(item => item.value).sort((a, b) => a.value - b.value);
};

interface ICurrentLiabilitiesProps {
    netWorth: number[];
    setNetWorth: any;
}

const CurrentLiabilities: React.FC<ICurrentLiabilitiesProps> = ({ netWorth, setNetWorth }) => {
    const { financing } = useSelector((store: IStore) => ({
        financing: store.planning.customerFormValues.financing.financing,
    }));

    const [data] = React.useState(convertPatrimonyToData(financing));

    const colors = [
        '#2D819A',
        '#023C58',
        '#A0D633',
        blueGrey[700],
        lightBlue[900],
        lightBlue[800],
        lightBlue[400],
        teal[400],
        teal[500],
        teal[600],
        teal[700],
        teal[800],
        teal[900],
    ];
    return (
        <div>
            {data.length ? <RenderPieChart colorSlice={colors} netWorth={netWorth} setNetWorth={setNetWorth} sumTotal data={data} title={'Passivos Totais'} /> : null}
        </div>
    );
};

export default CurrentLiabilities;
