import {Typography} from '@material-ui/core'
import { useStyles } from './style'
import React from 'react';
import { IconProps } from '../../../../assets/icons';

interface IPageHeaderProps {
    label: string;
    Icon: React.FC<IconProps>;
    style?: React.CSSProperties;
}

const PageHeader: React.FC<IPageHeaderProps> = ({ label, Icon, style }) => {
    const classes = useStyles();

    return (
        <div style={style}>
            <div className={classes.header}>
                <Icon style={{ fill: '#16191d', width: '18px', height: '18px' }} />
                <div className={classes.adjustLabel}>
                    <Typography className={classes.heading} align="left" style={{ fontSize: 16 }}>
                        {label}
                    </Typography>
                </div>
            </div>
        </div>
    );
};
export default PageHeader;