import { newStepForm } from '../forms';
import { Button, InputAdornment, TextField, Typography } from '@material-ui/core';
import { useStyles } from '../style';
import { RemoveRedEye } from '@material-ui/icons';
import api from '../../../../apis/dreamrichapi';
import { AuthResponseReducer, DoneAuth, SetAuthResponse, SetAuthState } from '../../localReducer';
import { AuthResponse } from '../../types';
import { IAuthFormsProps, ILoginFormControlProps } from '../interface';
import React, { useEffect } from 'react';
import {
    ChangePasswordForm,
    ForceChangePassword,
    ConfirmPasswordForm,
    ForgotPasswordForm,
} from '../PasswordForm/Password';

export const LoginForm: React.FC<IAuthFormsProps> = props => {
    const classes = useStyles();
    const [user, setUser] = React.useState('');
    const [password, setPassword] = React.useState('');
    const [isPassword, setIsPassword] = React.useState(true);
    const [err, setErr] = React.useState('');

    const login = (e) => {
        e.preventDefault()
        setErr('');

        console.log("ANTES DO LOGIN")
        api.get<AuthResponse>('/signin', {
            auth: {
                username: user.trim(),
                password,
            },
        })
            .then(res => {

                console.log('res', res)

                setErr('');
                if (res.data) {
                    props.dispatchResponse(res.data);
                } else {
                    props.doneAuth();
                }
            })
            .catch(err => {
                console.log(err);
                if (err.response) {
                    setErr(err.response.data);
                } else {
                    setErr('Erro inesperado: ' + err.message);
                }
            });
    };

    return (
        <form className={classes.loginForm} onSubmit={login}>
            <div className="d-flex flex-column">
                <TextField
                    className={`${classes.label} mt-3`}
                    label="Email"
                    variant="filled"
                    value={user}
                    onChange={event => setUser(event.target.value)}
                />
                <TextField
                    className={`${classes.label} mt-3`}
                    variant="filled"
                    label="Senha"
                    value={password}
                    type={isPassword ? 'password' : 'text'}
                    onChange={event => setPassword(event.target.value)}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                {/* @ts-ignore */}
                                <RemoveRedEye className={classes.eye} onClick={() => setIsPassword(isPwd => !isPwd)} />
                            </InputAdornment>
                        ),
                    }}
                />
                <Typography color="error" align="left" variant="caption">
                    {err}
                </Typography>
                <div className="btn-group d-flex mt-2">
                    <Button type="submit" variant="contained" className={`${classes.submitbutton} ms-0`}>
                        Fazer Login
                    </Button>
                    <button
                        className="btn bg-transparent text-success text-start ps-2 text-white"
                        //@ts-ignore
                        onClick={() =>
                            props.dispatchUsername({ ...props.authState.authResponse, ChallengeName: 'FORGOT_PASSWORD' }, user)
                        }
                    >
                        Esqueceu a senha?
                    </button>
                </div>
            </div>
        </form>
    );
};

export const LoginFormControl: React.FC<ILoginFormControlProps> = ({ onDone, initialAuthResponse }) => {
    const [authState, dispatch] = React.useReducer(AuthResponseReducer, {
        authResponse: initialAuthResponse ? { ...initialAuthResponse } : { ...new AuthResponse() },
        username: '',
    });

    const props: IAuthFormsProps = {
        dispatchResponse: (response: AuthResponse) => dispatch(SetAuthResponse(response)),
        dispatchUsername: (authResponse: AuthResponse, username: string) =>
            dispatch(SetAuthState({ authResponse, username })),
        doneAuth: () => dispatch(DoneAuth()),
        goBack: () => setForm(newStepForm(LoginForm, props)),
        authState,
    };
    const [Form, setForm] = React.useState<JSX.Element>(newStepForm(LoginForm, props));

    React.useEffect(() => {
        // everytime authstate changes, check for ChallengeName and renders proper form
        if (authState.authResponse.done) {
            onDone();
        }
        if (authState.authResponse) {
            switch (authState.authResponse.ChallengeName) {
                case 'LOGIN':
                    setForm(newStepForm(LoginForm, props));
                    break;
                case 'NEW_PASSWORD_REQUIRED':
                    setForm(newStepForm(ChangePasswordForm, props));
                    break;
                case 'FORGOT_PASSWORD':
                    setForm(newStepForm(ForgotPasswordForm, props));
                    break;
                case 'CONFIRM_PASSWORD':
                    setForm(newStepForm(ConfirmPasswordForm, props));
                    break;
                case 'FORCE_CHANGE_PASSWORD':
                    setForm(newStepForm(ForceChangePassword, props));
                    break;
            }
        }
    }, [authState, onDone]);

    return <>{Form}</>;
};
