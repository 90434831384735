import { createStyles, makeStyles, Theme } from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            display: 'flex',
            alignItems: 'center',
            height: '100%',
            textAlign: 'center',
            minHeight: 'calc(100vh - 75px)',
        },
    })
);