import { defaultContactInfo, defaultPersonalInfo } from '../../../components/planningForm/customerInfo/types';
import { IPlanningRedux } from '../../types/formPlanning';

let regex = /"/gi;
const userIDStorage:string = String(localStorage.getItem('userID')).replace(regex, "");

export const initialState: IPlanningRedux = {
    //toolbar da página da tabela

    toolbar: {
        title: 'PORTAL DO ASSESSOR',
        color: 'white',
        current: 0,
        goBack: () => {},
        total: 0,
    },
    customerUserInfo: { email: '', nickname: '', userID: userIDStorage || '', user: '' },
    customerPageIndex: 0,
    customerFormValues: {
        financing: {
            financing: [],
            consortia: [],
            userID: userIDStorage || '',
        },
        customerInfo: {
            personalInfo: defaultPersonalInfo,
            contactInfo: defaultContactInfo,
        },
        fixedCost: {
            costChange: [],
            habitational: {
                condoFee: 0,
                rent: 0,
                iptu: 0,
                domesticWorkers: 0,
                eletricityBill: 0,
                communicationBill: 0,
                gasBill: 0,
                waterBill: 0,
                houseMaintenance: 0,
                houseInsurance: 0,
                other: 0,
            },
            education: {
                school: 0,
                books: 0,
                other: 0,
                sports: 0,
            },
            transport: {
                fuel: 0,
                maintenance: 0,
                sharedTransport: 0,
                ipva: 0,
                insurance: 0,
                other: 0,
                uber: 0,
            },
            feeding: {
                superMarket: 0,
                lunch: 0,
                supplement: 0,
                breadWine: 0,
            },
            care: {
                clinical: 0,
                perfume: 0,
                gym: 0,
                other: 0,
            },
            recreation: {
                diversion: 0,
                streaming: 0,
                hobbies: 0,
                club: 0,
                other: 0,
            },
            health: { insurancePlan: 0, medicalExpenses: 0, medicament: 0, other: 0 },
            other: {
                tithe: 0,
                alimony: 0,
                classCouncil: 0,
                pet: 0,
                other: 0,
            },
        },
        goal: {
            goals: [],
            financialIndependence: {
                desiredIncoming: 5000,
                age: 65,
                lifeExpectation: 85,
            },
        },
        suggestions: {
            suggestedConsortia: [],
            suggestedPension: [],
            suggestedInsurance: [],
            suggestedInvestments: [],
        },
        incoming: {
            personalIncome: {
                salary: 1000,
                lastSalaryBonus: 0,
                vacationBonus: 0,
                prl: 0,
                bonus: 0,
                alimony: 0,
                rent: 0,
                other: 0,
                dividend: 0,
                perspective: [{ value: 0, date: new Date() }],
            },
            partnerIncome: {
                salary: 0,
                lastSalaryBonus: 0,
                vacationBonus: 0,
                prl: 0,
                alimony: 0,
                rent: 0,
                other: 0,
                bonus: 0,
                dividend: 0,
                perspective: [{ value: 0, date: new Date() }],
            },
            ready: false,
        },
        patrimony: {
            house: {
                forSell: false,
                value: 0,
                debit: 0,
            },
            properties: [],
            financialAssets: [],
            pensions: [],
        },

        riskManagement: {
            savings: {
                mensalIncoming: 0,
                months: 0,
                successionValue: 0,
                successionDuration: 0,
            },
            successionCosts: {
                current: {
                    itcmd: 0,
                    oab: 0,
                    notary: 0,
                },
                future: {
                    itcmd: 0,
                    oab: 0,
                    notary: 0,
                },
            },
            insurance: [],
        },
    },
    gainInfo: {
        officeData: {
            percentTED: 0,
            percentInsurance: 0,
            percentPension: 0,
            percentConsortia: 0,
        },
        plannerData: {
            percentTED: 0,
            percentInsurance: 0,
            percentPension: 0,
            percentConsortia: 0,
        },
        percentPlanner: 0,
    },
    // steps
    userCreationStep: false,
    userInfoStep: false,
    objectivesStep: false,
    financingStep: false,
    goalStep: false,
    fixedCostStep: false,
    patrimonyStep: false,
    riskmanagementStep: false,
    suggestionsStep: false,
    incomingStep: false,
    insuranceStep: false,
    fetch: false,

    logout: true,
};
