import { createStyles, makeStyles, Theme } from '@material-ui/core';
export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        subHeading: {
            color: '#2C2F31',
            fontWeight: 600,
        },
        cardHeader: {
            fontWeight: 600,
            fontSize: 16,
            color: '#020202',
        },
        cardLine: {
            display: 'flex',
            justifyContent: 'space-between',
        },
        grow: {
            flexGrow: 1,
        },
        spacing: {
            padding: theme.spacing(1),
        },
        lastCard: {
            maxWidth: '30vw',
        },
        successTextColor: {
            color: theme.palette.success.main,
        },
        flex: {
            display: 'flex',
            alignItems: 'center',
            marginTop: theme.spacing(2),
            justifyContent: 'flex-end',
        },
        button: {
            width: '20px',
        },
    })
);
