import { createStyles, makeStyles, Theme } from '@material-ui/core';
import { grey } from '@material-ui/core/colors';

export const useStyle = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            display: 'flex',
            flexDirection: 'column',
            height: '100%',
        },
        paper: {
            boxShadow: 'none',
            flex: '1 0',
        },
        cardHeader: {
            '@media (min-width: 768px)': {
                fontWeight: 600,
                fontSize: 16,
                color: '#020202',
                padding: `${theme.spacing(2)}px 0`,
            },
            '@media (max-width: 767px)': {
                fontWeight: 600,
                fontSize: 16,
                color: '#020202',
                padding: `${theme.spacing(2)}px 0`,
                textAlign: 'center',
            },
        },
        objective: {
            '@media (min-width: 768px)': {
                marginBottom: theme.spacing(2.5),
            },
            '@media (max-width: 767px)': {
                display: 'flex',
                flexDirection: 'column',
            },
        },
        legendMobile: {
            '@media (min-width: 768px)': {

            },
            '@media (max-width: 767px)': {
                display: 'flex',
                flexDirection: 'row',
                marginTop: '15px',
                alignItems: 'center',
            },
        },
        objectiveLine: {
            paddingTop: theme.spacing(2),
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'baseline',
            paddingRight: theme.spacing(0.5),
        },
        grow: {
            flexGrow: 1,
        },
        firstOuterLabel: {
            fontSize: 10,
            color: 'black',
            flexShrink: 0,
            alignSelf: 'flex-start',
            flexBasis: '25%',
        },
        middleOuterLabel: {
            fontSize: 10,
            color: grey[700],
            flexBasis: '25%',
        },
        lastOuterLabel: {
            marginLeft: theme.spacing(3),
            color: theme.palette.primary.main,
            fontSize: 10,
        },
        firstInnerLabel: {
            color: 'black',
            alignSelf: 'flex-start',
            flexBasis: '25%',
        },
        frequency: {
            color: '#2C2F31',
        },
        middleInnerLabel: {
            color: grey[700],
            flexBasis: '25%',
        },
        lastInnerLabel: {
            color: theme.palette.primary.main,
        },
        spacing: {
            padding: theme.spacing(0.3),
        },
        cardTotal: {
            background: '#F8F8F8',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            padding: theme.spacing(2),
        },
        valueText: {
            fontSize: 18,
        },
    })
);