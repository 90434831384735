import { createStyles, makeStyles, Theme } from '@material-ui/core';
import { grey, red } from '@material-ui/core/colors';

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        tableHeader: {
            fontWeight: 600,
        },
        deleteHidden: {
            backgroundColor: theme.palette.error.main,
            height: '50px',
            borderTopRightRadius: '5px',
            borderBottomRightRadius: '5px',
            float: 'right',
            position: 'absolute',
        },
    })
);