import { createStyles, makeStyles, Theme } from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) =>
createStyles({
    root: {
        '& > :not(:first-child)': {
            marginTop: theme.spacing(2),
        },
    },
    inputLabel: {
        marginBottom: '4px',
    },
    chooseButtons: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-start',
        alignItems: 'right',
    },
    fingerIcon: {
        marginLeft: theme.spacing(1),
    },
})
);