import { Button, Grid, IconButton, Typography } from '@material-ui/core';
import { Delete } from '@material-ui/icons';
import React from 'react';
import unmaskCurrency from '../../../../utils/unmaskCurrency';
import MoneyAdornment from '../../../global/adornments/MoneyAdornment';
import { GreyedDateInput, GreyedTextInput } from '../../../global/inputs/GreyedInput';
import CurrencyInput from '../../../global/masks/currencyMaskedInput';
import { useStyles } from './style';

interface IPerspectiveLineProps {
    value: string;
    date: Date;
    setPerspective: (item: { value: number; date: Date }) => void;
    removeSelf: () => void;
    isLast: boolean;
    autoFocus: boolean;
    addLine?: () => void;
}

const PerspectiveLine: React.FC<IPerspectiveLineProps> = ({
    value,
    date,
    setPerspective,
    autoFocus,
    isLast,
    removeSelf,
    addLine,
}) => {
    const classes = useStyles();

    const [raise, setRaise] = React.useState(!value.includes('-'));

    const unmask = (v: string) => Math.abs(unmaskCurrency(v)) * (raise ? 1 : -1);
    const setDate = (dateEv: Date) => {
        const valueUnmasked = unmask(value);
        setPerspective({ value: valueUnmasked, date: dateEv });
    };

    const setValue = (v: string) => {
        const valueUnmasked = unmask(v);
        setPerspective({ value: valueUnmasked, date });
    };

    React.useEffect(() => {
        const unmaskI = (v: string) => Math.abs(unmaskCurrency(v)) * (raise ? 1 : -1);

        setPerspective({ value: unmaskI(value), date });
    }, [raise, setRaise, value]);

    return (
        <div className="row">
            <div className="col-md-4">
                <div className="mb-2 d-flex flex-column gap-2">
                    <Typography variant="body2">Data</Typography>
                    <GreyedDateInput
                        defaultValue={new Date()}
                        variant="inline"
                        format="dd/MM/yyyy"
                        margin="normal"
                        value={date}
                        onChange={dateEvt => setDate(dateEvt as Date)}
                        KeyboardButtonProps={{
                            'aria-label': 'change-date',
                        }}
                        inputVariant="outlined"
                        size="small"
                        style={{ minWidth: '50%' }}
                    />
                </div>
            </div>

            <div className="col-md-4">
                <div className="mb-2 d-flex flex-column gap-2">
                    <Typography variant="body2">Valor</Typography>
                    <GreyedTextInput
                        autoFocus={isLast && autoFocus}
                        color="primary"
                        variant="outlined"
                        size="small"
                        type="numeric"
                        placeholder="0.00"
                        InputProps={{
                            inputComponent: CurrencyInput as any,
                            startAdornment: <MoneyAdornment />,
                        }}
                        InputLabelProps={{
                            shrink: true,
                        }}
                        value={value !== '0' ? value.replace('-', '') : ''}
                        onChange={event => setValue(event.target.value as string)}
                        onFocus={e => {
                            const val = e.target.value;
                            e.target.value = '';
                            e.target.value = val;
                        }}
                    />
                </div>
            </div>

            <div className="col-md-4 d-flex gap-2 align-items-center mt-3">
                        {/* @ts-ignore */}
                <Button
                    classes={{ root: raise ? classes.raised : classes.unraised }}
                    focusRipple={false}
                    onClick={() => setRaise(true)}
                >
                    Aumento
                </Button>

                {/* @ts-ignore */}
                <Button
                    className={!raise ? classes.reduction : classes.unraised}
                    focusRipple={false}
                    onClick={() => setRaise(false)}
                >
                    Redução
                </Button>
                {/* @ts-ignore */}
                <IconButton className={classes.removeButton} onClick={removeSelf}>
                    <Delete />
                </IconButton>
                {isLast ? (
                    //@ts-ignore
                    < Button
                        size="small"
                variant="contained"
                disableElevation
                color="primary"
                style={{ color: 'white' }}
                onClick={addLine}
                className={classes.mw100}
                    >
                Adicionar
            </Button>
                ) : null}
        </div>
        </div >


    );
};
export default PerspectiveLine;
