import { Paper, Typography } from '@material-ui/core';
import React from 'react';
import formatter from '../../../global/formater';
import { IInventoryFreeAssets } from '../types';
import { useStyles } from './style'

const InventoryFreeAssets: React.FC<IInventoryFreeAssets> = ({
    privatePension,
    temporaryLifeInsurance,
    permanentLifeInsurance,
}) => {
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <Typography variant="h5">Ativos financeiros livres de inventário</Typography>
            <Paper className={classes.content}>
                <div className={classes.child}>
                    <Typography variant="h6">Seguro de vida vitalício</Typography>
                    <Typography variant="h5" color="secondary">
                        {formatter.format(permanentLifeInsurance)}
                    </Typography>
                </div>
                <div className={classes.divider} />
                <div className={classes.child}>
                    <Typography variant="h6">Seguro de vida temporário</Typography>
                    <Typography variant="h5" color="secondary">
                        {formatter.format(temporaryLifeInsurance)}
                    </Typography>
                </div>
                <div className={classes.divider} />
                <div className={classes.childWide}>
                    <Typography variant="h6">Previdência privada (VGBL + PGBL)</Typography>
                    <Typography variant="h5" color="secondary">
                        {formatter.format(privatePension)}
                    </Typography>
                </div>
                <div className={classes.grow} />
                <Paper className={classes.free} elevation={0}>
                    <div className={classes.freeContent}>
                        <div style={{ margin: 'auto', marginLeft: 16 }}>
                            <Typography align="left" className={classes.whiteLabel}>
                                Ativos livres
                            </Typography>
                            <Typography className={classes.freeLabel} align="left" variant="h4">
                                {formatter.format(temporaryLifeInsurance + permanentLifeInsurance + privatePension)}
                            </Typography>
                        </div>
                    </div>
                </Paper>
            </Paper>
        </div>
    );
};
export default InventoryFreeAssets;
