import { createStyles, makeStyles, Theme } from '@material-ui/core';
import { grey, red } from '@material-ui/core/colors';


export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        selectButton: {
            display: 'flex',
            flexFlow: 'row wrap',
            justifyContent: 'flex-start',
            alignItems: 'flex-end',
            margin: '-5px',
        },
        buttonItem: {
            margin: '5px',
        },
        paper: {
            padding: theme.spacing(2),
        },
        inputGroup: {
            margin: `${theme.spacing(1)}px 0`,
        },
        monthsInput: {
            display: 'flex',
            justifyContent: 'flex-start',
            alignItems: 'flex-end',
        },
        discountDiv: {
            display: 'flex',
            justifyContent: 'start',
        },
    })
);
