import { createStyles, makeStyles, Theme } from '@material-ui/core';
import { grey, red } from '@material-ui/core/colors';


export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        inputLabel: {
            padding: '1em 0 0.5em',
        },
        mw100: {
            maxWidth: '100px',
            maxHeight: '50px'
        },
        perspectiveLine: {
            display: 'flex',
            justifyContent: 'flex-start',
            alignItems: 'flex-end',
        },
        line: {
            display: 'flex',
            justifyContent: 'flex-start',
            alignItems: 'flex-end',
        },
        removeButton: {
            backgroundColor: red[400],
            color: '#ffffff',
            '&:hover': { backgroundColor: red[800] },
            maxWidth: '50px',
            maxHeight: '50px',

        },
        raised: {
            backgroundColor: '#A1D830',
            color: '#ffffff',
            '&:hover': { color: '#000000' },
            maxWidth: '100px',
            maxHeight: '50px',
        },
        reduction: {
            backgroundColor: red[400],
            color: '#ffffff',
            maxHeight: '50px',
            '&:hover': { color: '#000000' },
        },
        unraised: {
            backgroundColor: '#E1E1E1',
            maxWidth: '100px',
            maxHeight: '50px',
        },
    })
);

export const selectedStyle = {
    raised: {
        backgroundColor: '#A1D830',
        color: '#ffffff',
    },
    reduction: {
        backgroundColor: red[400],
        color: '#ffffff',
    },
    unraised: {
        backgroundColor: '#E1E1E1',
    },
};