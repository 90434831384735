import {
    Button,
    createStyles,
    InputAdornment,
    makeStyles,
    Theme,
    Typography,
    Select,
    MenuItem,
    TextField,
} from '@material-ui/core';
import { Fingerprint } from '@material-ui/icons';
import moment from 'moment';
import React, { useEffect } from 'react';
import { GreyedDateInput, GreyedTextInput } from '../../../global/inputs/GreyedInput';
import MoneyAdornment from '../../../global/adornments/MoneyAdornment';
import CurrencyInput from '../../../global/masks/currencyMaskedInput';
import unmaskCurrency from '../../../../utils/unmaskCurrency';
import CPFMaskedInput from '../../../global/masks/cpfMaskedInput';
import { Gender, IPersonalData, MaritalStatus } from '../types';
import paises from '../../../../utils/ddis.json';
import { useStyles } from './style';

interface IPersonalDataProps {
    personalInfo: IPersonalData;
    setPersonalInfo: (data: IPersonalData) => void;
    cpfErr: boolean;
    married?: boolean;
    disabledCpf?: boolean;
    isPartnerData?: boolean;
}

const marriageRegimeTypes = [
    'Separação Total de bens',
    'Separação parcial de bens',
    'Comunhão Universal',
    'Comunhão Parcial de bens',
];

const PersonalData: React.FC<IPersonalDataProps> = ({
    personalInfo,
    setPersonalInfo,
    cpfErr,
    married,
    disabledCpf = false,
    isPartnerData = false,
}) => {
    const classes = useStyles();
    const date = personalInfo.dateOfBirth ? moment(new Date(personalInfo.dateOfBirth), 'DD/MM/YYYY').toDate() : new Date();
    console.log(date)
    const [birthDate, setBirthDate] = React.useState(date);
    const savedGender = personalInfo?.gender;
    const [selectedGender, selectGender] = React.useState([savedGender === 'masculine', savedGender === 'feminine']);

    const marital = personalInfo?.maritalStatus;
    const [selectedMarital, selectMarital] = React.useState([
        marital === 'single',
        marital === 'married',
        marital === 'divorced',
        marital === 'widowed',
    ]);

    useEffect(() => {
        if (personalInfo.nationality == null || personalInfo.nationality?.trim() == '') {
            setPersonalInfo({ ...personalInfo, nationality: 'Brasil' });
        }
    }, []);

    const onMaritalClick = (maritalStatus: MaritalStatus, index: number) => {
        setPersonalInfo({ ...personalInfo!, maritalStatus });
        selectMarital(selectedMarital.map((_, idx) => (idx === index ? true : false)));
    };

    const onChangeCPF = (cpf: string) => {
        setPersonalInfo({ ...personalInfo, cpf });
    };

    const onGenderClick = (gender: Gender, index: number) => {
        setPersonalInfo({ ...personalInfo!, gender });
        selectGender(selectedGender.map((_, idx) => (idx === index ? true : false)));
    };

    const onDateOfBirthChange = (date: Date) => {
        setPersonalInfo({ ...personalInfo, dateOfBirth: date });
        setBirthDate(date);
    };

    const onFullNameChange = (fullName: string) => {
        setPersonalInfo({ ...personalInfo, fullName });
    };

    const onChangeMarriageRegime = (marriageRegimeOption: string) => {
        setPersonalInfo({ ...personalInfo, marriageRegime: marriageRegimeOption });
    };

    const onChangeNationality = (nationality: string) => {
        setPersonalInfo({ ...personalInfo, nationality });
    };

    const onChangeRNE = (rne: string) => {
        setPersonalInfo({ ...personalInfo, rne });
    };

    const onChangeAnnualGrossIncome = (value: string) => {
        let numericValue = unmaskCurrency(value);
        setPersonalInfo({ ...personalInfo, annualGrossIncome: numericValue });
    };

    return (
        <div className={`${classes.root} container`}>
            <div className="row d-flex justify-content-between">
                <div className="col-md-6 d-flex justify-content-center flex-column">
                    <Typography className={classes.inputLabel} variant="h6">
                        Nome Completo
                    </Typography>
                    <GreyedTextInput
                        required
                        variant="outlined"
                        size="small"
                        placeholder={personalInfo.fullName}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment style={{ marginTop: 0 }} position="start">
                                    <Fingerprint className={classes.fingerIcon} />
                                </InputAdornment>
                            ),
                        }}
                        onChange={event => onFullNameChange(event.target.value)}
                        value={personalInfo.fullName}
                        fullWidth
                    />
                </div>
                <div className="col-md-6 d-flex justify-content-center flex-column">
                    <Typography className={classes.inputLabel} variant="h6">
                        Data de Nascimento
                    </Typography>
                    <GreyedDateInput
                        size="small"
                        format="dd/MM/yyyy"
                        required
                        margin="normal"
                        variant="inline"
                        inputVariant="outlined"
                        id="date-picker-inline"
                        value={birthDate}
                        onChange={d => d && onDateOfBirthChange(d as Date)}
                        KeyboardButtonProps={{
                            'aria-label': 'change date',
                        }}
                    />
                </div>
            </div>
            <div className="row d-flex justify-content-between">
                <div className="col-md-12 d-flex justify-content-start flex-column">
                    {married ? null : (
                        <div>
                            <Typography className={classes.inputLabel} variant="h6">
                                Estado Civil
                            </Typography>
                            <div className={classes.chooseButtons}>
                                {/* @ts-ignore */}
                                <Button
                                    variant="outlined"
                                    size="large"
                                    color={selectedMarital[0] ? 'primary' : 'default'}
                                    disableElevation
                                    onClick={() => onMaritalClick('single', 0)}
                                >
                                    Solteiro
                                </Button>
                                {/* @ts-ignore */}
                                <Button
                                    style={{ margin: '0 20px' }}
                                    variant="outlined"
                                    size="large"
                                    color={selectedMarital[1] ? 'primary' : 'default'}
                                    disableElevation
                                    onClick={() => onMaritalClick('married', 1)}
                                >
                                    Casado
                                </Button>
                                {/* @ts-ignore */}
                                <Button
                                    variant="outlined"
                                    size="large"
                                    color={selectedMarital[2] ? 'primary' : 'default'}
                                    disableElevation
                                    onClick={() => onMaritalClick('divorced', 2)}
                                >
                                    Divorciado
                                </Button>
                                {/* @ts-ignore */}
                                <Button
                                    style={{ margin: '0 20px' }}
                                    variant="outlined"
                                    size="large"
                                    color={selectedMarital[3] ? 'primary' : 'default'}
                                    disableElevation
                                    onClick={() => onMaritalClick('widowed', 3)}
                                >
                                    Viúvo
                                </Button>
                            </div>
                        </div>
                    )}
                </div>
                <div className="col-md-12 d-flex justify-content-start flex-column mt-4">

                    {(married || selectedMarital[1]) && !isPartnerData && (
                        <>
                            <Typography className={classes.inputLabel} variant="h6">
                                Regime de casamento
                            </Typography>
                            <div className={classes.chooseButtons}>
                                {marriageRegimeTypes.map(item => (
                                    //@ts-ignore
                                    <Button
                                        style={{ marginRight: '20px' }}
                                        variant="outlined"
                                        size="large"
                                        color={personalInfo.marriageRegime == item ? 'primary' : 'default'}
                                        disableElevation
                                        onClick={() => onChangeMarriageRegime(item)}
                                    >
                                        {item}
                                    </Button>
                                ))}
                            </div>
                            <Typography style={{ marginTop: '8px' }} className={classes.inputLabel} variant="subtitle1">
                                {selectedMarital[1] ? 'Preencheremos suas informações na próxima página' : ''}
                            </Typography>
                        </>
                    )}
                </div>

            </div>

            <div className="row d-flex justify-content-between">
                <div className="col-md-12 d-flex justify-content-start flex-column">
                    <Typography className={classes.inputLabel} variant="h6">
                        Sexo
                    </Typography>
                    <div className={classes.chooseButtons}>
                        {/* @ts-ignore */}
                        <Button
                            variant="outlined"
                            size="large"
                            color={selectedGender[0] ? 'primary' : 'default'}
                            disableElevation
                            onClick={() => onGenderClick('masculine', 0)}
                        >
                            Masculino
                        </Button>
                        {/* @ts-ignore */}
                        <Button
                            style={{ margin: '0 20px' }}
                            variant="outlined"
                            size="large"
                            color={selectedGender[1] ? 'primary' : 'default'}
                            disableElevation
                            onClick={() => onGenderClick('feminine', 1)}
                        >
                            Feminino
                        </Button>
                    </div>
                </div>
            </div>
            <div className="row d-flex justify-content-between">
                <div className="col-md-6 d-flex justify-content-center flex-column">
                    <Typography variant="h6" className={classes.inputLabel}>
                        Nacionalidade
                    </Typography>
                    <Select
                        labelId="nacionalidade"
                        required={true}
                        id="nacionalidade"
                        value={personalInfo.nationality}
                        name="select-nacionlidade"
                        style={{ minWidth: 250, height: '45px' }}
                        defaultValue="Brasil"
                        fullWidth
                        variant="outlined"
                        onChange={(event: React.ChangeEvent<{ value: unknown }>) => {
                            onChangeNationality(event.target.value as string);
                        }}
                    >
                        {paises.map(data => (
                            <MenuItem value={data.country}>{data.country}</MenuItem>
                        ))}
                    </Select>
                </div>
                <div className="col-md-6 d-flex justify-content-center flex-column">
                    {personalInfo.nationality === 'Brasil' && (
                        <>
                            <Typography variant="h6" className={classes.inputLabel}>
                                CPF
                            </Typography>
                            <GreyedTextInput
                                error={cpfErr}
                                helperText={cpfErr ? 'Favor inserir um cpf válido' : ''}
                                variant="outlined"
                                size="small"
                                InputProps={{
                                    inputComponent: CPFMaskedInput as any,
                                }}
                                disabled={disabledCpf}
                                value={personalInfo.cpf}
                                onChange={event => onChangeCPF(event.target.value)}
                            />
                        </>
                    )}
                    {personalInfo.nationality !== 'Brasil' && (
                        <>
                            <Typography variant="h6" className={classes.inputLabel}>
                                RNE
                            </Typography>
                            <GreyedTextInput
                                variant="outlined"
                                size="small"
                                value={personalInfo?.rne}
                                onChange={event => onChangeRNE(event.target.value)}
                                disabled={disabledCpf}
                            />
                        </>
                    )}
                </div>
            </div>

            <div>
                <Typography variant="h6" className={classes.inputLabel}>
                    Renda bruta anual declarada
                </Typography>
                <TextField
                    required
                    color="primary"
                    placeholder="0.00"
                    inputMode="numeric"
                    variant="outlined"
                    InputProps={{
                        inputComponent: CurrencyInput as any,
                        startAdornment: <MoneyAdornment />,
                    }}
                    InputLabelProps={{ shrink: true }}
                    onChange={event => onChangeAnnualGrossIncome(event.target.value)}
                    value={personalInfo?.annualGrossIncome}
                />
            </div>
        </div>
    );
};
export default PersonalData;
