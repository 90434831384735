import { CircularProgress } from '@material-ui/core';
import { useStyles } from './style'
import React from 'react';

interface LoadingPageProps {
    loading: boolean;
    children?: React.ReactNode;
}

const LoadingPage: React.FC<LoadingPageProps> = ({ loading, children }) => {
    const classes = useStyles();

    return loading ? (
        <div className={classes.root}>
            <CircularProgress />
        </div>
    ) : (
        <React.Fragment>{children}</React.Fragment>
    );
};
export default LoadingPage;