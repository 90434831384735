import { createStyles, makeStyles, Theme } from '@material-ui/core';
export const useStyles = makeStyles((theme: Theme) =>
createStyles({
    content: {
        padding: theme.spacing(2),
        minHeight: '362px',
    },
    valueInput: {
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center',
    },
    duration: {
        display: 'flex',
        alignItems: 'center',
    },
    grow: {
        flexGrow: 1,
    },
    input: {
        marginRight: '5%',
    },
})
);