export interface IProfileGain {
    default: number;
    conservative: number;
    moderate: number;
    bold: number;
    agressive: number;
}

export const defaultProfileGain: IProfileGain = {
    default: 0.04,
    conservative: 0.02,
    moderate: 0.04,
    bold: 0.06,
    agressive: 0.08,
};

export interface IFinancialInfo {
    cdi: number;
    ipca: number;
    profileGain: IProfileGain;
    lastRefresh: Date;
    realGain: number;
}
