import {
    Button,
    Checkbox,
    Chip,
    createStyles,
    Divider,
    FormControlLabel,
    makeStyles,
    MenuItem,
    Paper,
    Select,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TextField,
    Theme,
    Typography,
} from '@material-ui/core';
import { grey } from '@material-ui/core/colors';
import { Add, Delete } from '@material-ui/icons';
import { KeyboardDatePicker } from '@material-ui/pickers';
import { default as React } from 'react';
import unmaskCurrency, { maskCurrency } from '../../../../utils/unmaskCurrency';
import MoneyAdornment from '../../../global/adornments/MoneyAdornment';
import CurrencyInput from '../../../global/masks/currencyMaskedInput';
import { IGoalValues, ITimeUnit } from '../types';
import { useStyles } from './style';

const timeUnit = [
    { value: 'year', label: 'anos' },
    { value: 'month', label: 'meses' },
];

interface ITableRow {
    label: string;
    periodicity: {
        period: number;
        timeUnit: ITimeUnit;
        unique: boolean;
    };
    firstYear: number;
    value: string;
}

interface ITableGoalProps {
    defaultGoalValues: IGoalValues[];
    setGoalValues: React.Dispatch<React.SetStateAction<IGoalValues[]>>;
    nextStep: () => void;
    previousStep: () => void;
}

const TableGoal: React.FC<ITableGoalProps> = ({ defaultGoalValues, setGoalValues, nextStep, previousStep }) => {
    const classes = useStyles();
    const [addedLine, setAddedLine] = React.useState(false);
    const [ready, setReady] = React.useState(false);
    const currentYear = new Date(`${new Date().getFullYear()} `);

    const [tableRows, setTableRows] = React.useState<ITableRow[]>(
        defaultGoalValues.length
            ? defaultGoalValues.map(item => ({
                  ...item,
                  value: maskCurrency(item.value),
              }))
            : [
                  {
                      label: '',
                      periodicity: { period: 0, timeUnit: 'year', unique: false },
                      firstYear: currentYear.getFullYear(),
                      value: '',
                  },
              ]
    );
    const addLine = () => {
        setAddedLine(true);
        setTableRows([
            ...tableRows,
            {
                label: '',
                periodicity: { period: 0, timeUnit: 'year', unique: false },
                firstYear: currentYear.getFullYear(),
                value: '',
            },
        ]);
    };

    const handleLabelChange = (value: string, index: number) => {
        setTableRows(rows => rows.map((item, i) => (i === index ? { ...item, label: value } : item)));
    };

    const handlePeriodChange = (value: string, index: number) => {
        const parsedVal = parseInt(value, 10);
        if (!Number.isNaN(parsedVal)) {
            setTableRows(
                tableRows.map((item, i) =>
                    i === index ? { ...item, periodicity: { ...item.periodicity, period: parsedVal } } : item
                )
            );
        }
    };

    const handleTimeUnitChange = (value: string, index: number) => {
        setTableRows(
            tableRows.map((row, i) =>
                i === index ? { ...row, periodicity: { ...row.periodicity, timeUnit: value as ITimeUnit } } : row
            )
        );
    };

    const toggleUnique = (index: number, checked: boolean) => {
        setTableRows(
            tableRows.map((row, i) =>
                i === index
                    ? { ...row, periodicity: { period: 0, timeUnit: checked ? 'once' : 'year', unique: checked } }
                    : row
            )
        );
    };

    const negativeFactor = (row: ITableRow) => {
        if (row.label === 'Aporte de Patrimônio') {
            return -1;
        } else return 1;
    };

    const handleYearChange = (value: Date, index: number) => {
        const parsedVal = value.getFullYear();
        setTableRows(rows => rows.map((row, i) => (i === index ? { ...row, firstYear: parsedVal } : row)));
    };

    const setValueInArr = (value: string, index: number) => {
        setTableRows(rows =>
            rows.map((row, i) => {
                return i === index ? { ...row, value } : row;
            })
        );
    };
    const [btnDisabled, setBtnDisabled] = React.useState(false);

    const onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        setGoalValues(tableRows.map(row => ({ ...row, value: unmaskCurrency(row.value) * negativeFactor(row) })));
        nextStep();
        setBtnDisabled(true);
    };

    const changeMouseHover = (hover: boolean, index: number) => {
        setMouseHover(rows => rows.map((_, i) => (index === i ? hover : false)));
    };

    const removeRow = (index: number) => {
        // create a new array without index entry
        setTableRows(rows => [...rows.slice(0, index), ...rows.slice(index + 1)]);
    };

    const [mouseHover, setMouseHover] = React.useState(tableRows.map(row => false));
    React.useEffect(() => {
        if (addedLine) {
            setMouseHover(tableRows.map(row => false));
        }
    }, [tableRows]);

    React.useEffect(() => {
        if (addedLine) {
            setReady(true);
        }
    }, [mouseHover]);
    React.useEffect(() => {
        showRowDel(tableRows.length - 1);
    }, [ready]);

    const showRowDel = async (row: number) => {
        setMouseHover(rows => rows.map((_, i) => row === i));
        setReady(false);
        setAddedLine(false);
        await new Promise(r => setTimeout(r, 4000));
        setMouseHover(rows => rows.map(item => false));
    };

    const objectivesTypes = [
        'Automóvel',
        'Automóveis presente 18 anos',
        'Aniversários / Presentes',
        'Vestuário',
        'Reformas',
        'Mobília',
        'Auxílio Terceiros',
        'Casa na Praia',
        'Casamento',
        'Congressos',
        'Educação',
        'Eletrônicos',
        'Festa 15 anos',
        'Imóvel Adicional',
        'Procedimentos estéticos',
        'Intercâmbio',
        'Jóias / Relógios / Acessórios',
        'Lancha',
        'Moradia',
        'Negócio Próprio',
        'Reforma da Casa',
        'Viagens',
        'Nascimento do filho',
        'Aporte de Patrimônio',
    ]
        .sort()
        .concat('Outros');

    return (
        <div className={classes.root}>
            <div className={classes.head}>
                <Typography variant="h4">Faça dos seus sonhos, os seus objetivos</Typography>
                <div className={classes.subHeader}>
                    <Typography align="center" variant="body1" className='mb-3'>
                        Não se limite, tenha a vida que sempre sonhou
                    </Typography>
                    <form onSubmit={onSubmit}>
                        <TableContainer className={classes.table} component={Paper}>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell align="left">Objetivos</TableCell>
                                        <TableCell align="left">Periodicidade</TableCell>
                                        <TableCell align="right">Ano do primeiro objetivo</TableCell>
                                        <TableCell align="right">Valor</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {tableRows.map((row, i) => (
                                        <TableRow key={i.toString()} style={{ overflow: 'hidden' }}>
                                            <TableCell>
                                                <Select
                                                    labelId="-select-label"
                                                    id="demo-simple-select"
                                                    value={row.label}
                                                    onChange={event =>
                                                        handleLabelChange(event.target.value as string, i)
                                                    }
                                                    fullWidth
                                                    style={{ maxWidth: '220px', minWidth: '220px' }}
                                                >
                                                    {/* removes last element and map */}
                                                    {objectivesTypes.slice(0, -1).map(label => (
                                                        <MenuItem key={label} value={label}>
                                                            {label}
                                                        </MenuItem>
                                                    ))}
                                                    <Divider />
                                                    <MenuItem
                                                        key={objectivesTypes[objectivesTypes.length - 1]}
                                                        value={objectivesTypes[objectivesTypes.length - 1]}
                                                    >
                                                        {objectivesTypes[objectivesTypes.length - 1]}
                                                    </MenuItem>
                                                </Select>
                                            </TableCell>
                                            <TableCell align="left">
                                                <div className={classes.periodicity}>
                                                    <Typography variant="body2">a cada</Typography>
                                                    <TextField
                                                        type="number"
                                                        value={row.periodicity.period || ''}
                                                        onChange={event => handlePeriodChange(event.target.value, i)}
                                                        disabled={row.periodicity.timeUnit === 'once'}
                                                        style={{
                                                            flexBasis: '12%',
                                                            padding: '0 0.5em',
                                                            minWidth: '50px',
                                                        }}
                                                        onInput={e => {
                                                            const val = (e.target as HTMLInputElement).value as string;
                                                            (e.target as HTMLInputElement).value = val
                                                                ? Math.max(
                                                                      0,
                                                                      parseInt((e.target as HTMLInputElement).value, 10)
                                                                  )
                                                                      .toString()
                                                                      .slice(0, 2)
                                                                : '0';
                                                        }}
                                                        InputProps={{ inputProps: { min: 0, max: 99 } }}
                                                    />
                                                    <TextField
                                                        select
                                                        value={
                                                            row.periodicity.timeUnit !== 'once'
                                                                ? row.periodicity.timeUnit
                                                                : ''
                                                        }
                                                        onChange={event => handleTimeUnitChange(event.target.value, i)}
                                                        disabled={row.periodicity.unique}
                                                        style={{ minWidth: '60px' }}
                                                    >
                                                        {timeUnit.map(option => (
                                                            <MenuItem key={option.value} value={option.value}>
                                                                {option.label}
                                                            </MenuItem>
                                                        ))}
                                                    </TextField>
                                                    <FormControlLabel
                                                        value="end"
                                                        style={{ marginLeft: '3px' }}
                                                        control={
                                                            <Checkbox
                                                                checked={row.periodicity.unique}
                                                                disableTouchRipple={true}
                                                                onChange={event =>
                                                                    toggleUnique(i, event.target.checked)
                                                                }
                                                                style={{ padding: 0 }}
                                                            />
                                                        }
                                                        label="Único"
                                                        labelPlacement="end"
                                                    />
                                                </div>
                                            </TableCell>
                                            <TableCell style={{ width: '20%' }} align="right">
                                                <KeyboardDatePicker
                                                    className={classes.yearSelector}
                                                    variant="inline"
                                                    format="yyyy"
                                                    margin="normal"
                                                    id="date-picker-inline"
                                                    views={['year']}
                                                    openTo="year"
                                                    value={new Date(row.firstYear, 0)}
                                                    onChange={date => date && handleYearChange(date as Date, i)}
                                                />
                                            </TableCell>
                                            <TableCell align="right">
                                                <TextField
                                                    color="primary"
                                                    placeholder="0.00"
                                                    inputMode="numeric"
                                                    InputProps={{
                                                        inputComponent: CurrencyInput as any,
                                                        startAdornment: (
                                                            <MoneyAdornment negative={negativeFactor(row) === -1} />
                                                        ),
                                                    }}
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                    classes={{
                                                        root: classes.input,
                                                    }}
                                                    inputProps={{ allowNegative: true }}
                                                    value={row.value}
                                                    style={{ maxWidth: '75%', flexShrink: 1 }}
                                                    onChange={event => {
                                                        setValueInArr(event.target.value, i);
                                                    }}
                                                />
                                            </TableCell>
                                            <div
                                                className={classes.deleteHidden}
                                                onMouseEnter={() => changeMouseHover(true, i)}
                                                onMouseLeave={() => changeMouseHover(false, i)}
                                                //@ts-ignore
                                                onClick={() => removeRow(i)}
                                                style={{ borderLeft: !mouseHover[i] ? '9px solid #f44336' : '' }}
                                            >
                                                {mouseHover[i] ? (
                                                    <Delete
                                                        style={{ marginTop: '100%', marginRight: '5%' }}
                                                        color="action"
                                                    />
                                                ) : null}
                                            </div>
                                        </TableRow>
                                    ))}
                                    <TableRow>
                                        <TableCell>
                        {/* @ts-ignore */}
                                            <Chip
                                                variant="outlined"
                                                color="secondary"
                                                size="medium"
                                                label="Adicionar nova linha"
                                                clickable
                                                onClick={addLine}
                                                onDelete={addLine}
                                                deleteIcon={<Add />}
                                            />
                                        </TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <div className={classes.formControllers}>
                        {/* @ts-ignore */}
                            <Button
                                variant="contained"
                                className={classes.outButton}
                                size="medium"
                                color="default"
                                disableElevation
                                onClick={previousStep}
                            >
                                Sair
                            </Button>
                            <button type="submit" className={classes.chipButton} disabled={btnDisabled}>
                                <Chip
                                    color="primary"
                                    className={classes.chip}
                                    size="medium"
                                    label="Próximo"
                                    clickable
                                />
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
};
export default TableGoal;
