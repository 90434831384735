import {
    createStyles,
    makeStyles,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Theme,Button,Modal, TextField, Typography,
} from '@material-ui/core';
import { Delete, Edit } from '@material-ui/icons';
import React, {useState} from 'react';
import formatter from '../../../global/formater';
import { IFinancingRow } from '../types';
import { useStyles } from './style';
import { format } from 'date-fns';
import {ModalEditFinancing} from './ModalEditFinancing'


interface IFinancingTableProps {
    financingRows: IFinancingRow[];
    remove: (index: number) => void;
}

const FinancingTable: React.FC<IFinancingTableProps> = ({ financingRows, remove }) => {
    const classes = useStyles();
    const [mouseHover, setMouseHover] = React.useState(financingRows.map(row => false));
    const [ready, setReady] = React.useState(false);

    const [openEditFinancing, setOpenEditFinancing] = useState(false);
    const [financingToEdit, setFinancingToEdit] = useState(null);
    

    React.useEffect(() => {
        setMouseHover(financingRows.map(row => false));
    }, [financingRows]);
    React.useEffect(() => {
        setReady(true);
    }, [mouseHover]);

    const showRowDel = async (row: number) => {
        if (mouseHover.length > 0) {
            setMouseHover(rows => rows.map((_, i) => row === i));
            setReady(false);

            await new Promise(r => setTimeout(r, 4000));
            setMouseHover(rows => rows.map(item => false));
        }
    };

    React.useEffect(() => {
        showRowDel(financingRows.length - 1);
    }, [ready]);
    const changeMouseHover = (hover: boolean, index: number) => {
        setMouseHover(rows => rows.map((_, i) => (index === i ? hover : false)));
    };

    const getCategory = (cat: 'sac' | 'price' | 'other') => {
        switch (cat) {
            case 'other':
                return 'OUTROS';
            default:
                return cat.toUpperCase();
        }
    };

    const editFinancing = (financing, index) => {
        setOpenEditFinancing(true);
        setFinancingToEdit({financing, index})
    }

    
    const handleCloseEditFinancing = () => {
        setOpenEditFinancing(false);
    };

    return (
        <>
        <Paper elevation={5}>
            <TableContainer component={Paper}>
                <Table className={classes.table}>
                    <TableHead>
                        <TableRow>
                            <TableCell className={classes.tableHeader} align="center">
                                Editar
                            </TableCell>
                            <TableCell className={classes.tableHeader} align="left">
                                Financiamentos
                            </TableCell>
                            <TableCell className={classes.tableHeader} align="right">
                                Juros
                            </TableCell>
                            <TableCell className={classes.tableHeader} align="right">
                                Categoria
                            </TableCell>
                            <TableCell className={classes.tableHeader} align="right">
                                Prestações
                            </TableCell>
                            <TableCell className={classes.tableHeader} align="right">
                                Saldo devedor
                            </TableCell>
                            <TableCell className={classes.tableHeader} align="right">
                                Valor da Prestação
                            </TableCell>
                            <TableCell className={classes.tableHeader} align="right">
                                Data de Início
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {financingRows.map((row, i) => (
                            <TableRow key={`row-${row.interestRate + row.installment}`}>
                                <TableCell align="left">
                                    {/* @ts-ignore */}
                                    <Edit className={classes.editFin}  onClick={() => editFinancing(row, i)}/>
                                    </TableCell>
                                <TableCell align="left">{row.name.toUpperCase()}</TableCell>
                                <TableCell align="right">
                                    {row.interestRate ? row.interestRate.toString() + '%' : '-'}
                                </TableCell>
                                <TableCell align="right">{getCategory(row.type)}</TableCell>
                                <TableCell align="right">{row.monthAmount}</TableCell>
                                <TableCell align="right">{formatter.format(row.debit)}</TableCell>
                                <TableCell align="right">{formatter.format(row.installment)}</TableCell>
                                <TableCell align="right">{format(new Date(row.startDate), 'MM/yyyy')}</TableCell>
                                <div
                                    className={classes.deleteHidden}
                                    onMouseEnter={() => changeMouseHover(true, i)}
                                    onMouseLeave={() => changeMouseHover(false, i)}
                                    //@ts-ignore
                                    onClick={() => remove(i)}
                                    // we dont need border when mouse is hovering this div
                                    style={{ borderLeft: !mouseHover[i] ? '9px solid #f44336' : '' }}
                                >
                                    {mouseHover[i] ? (
                                        <Delete style={{ marginTop: '75%', marginRight: '5%' }} color="action" />
                                    ) : null}
                                </div>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </Paper>

          {/* EDITAR FINANCIAMENTO */}
          <ModalEditFinancing handleCloseEditFinancing={handleCloseEditFinancing} openEditFinancing={openEditFinancing} financingToEdit={financingToEdit}/>
      </>
    );
};
export default FinancingTable;
