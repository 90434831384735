export interface IFinancingValue {
    type: 'sac' | 'price' | 'other';
    name: string;
    installment: number;
    interestRate: number;
    debit: number;
    monthsLeft: number;
    startDate: Date;
}
export interface IFinancingRow {
    name: string;
    type: 'sac' | 'price' | 'other';
    interestRate: number;
    installment: number;
    monthAmount: number;
    debit: number;
    startDate: Date;
}

export interface IFinancingRequest {
    userID: string;
    financing: IFinancingValue[];
    consortia: IConsortium[];
    ready?: boolean;
}

export const consortiumCompanies = ['ademilar', 'bancorbras', 'mapfre', 'porto seguro', 'rodobens', 'outros'] as const;
export type Companies = typeof consortiumCompanies[number];

export interface IConsortium {
    ConsortiumID?: string;
    name: string;
    company: Companies;
    annualPayment: number;
    receivedValue: number;
    term: number;
    contemplationYear: number;
    startDate: Date;
}
