import { Typography, useTheme } from '@material-ui/core';
import React from 'react';
import LoadingPage from '../../../global/loadingPage/desktop/loadingPage';
import { useStyles } from './style'

interface IDurationProps { className?: string; loading: boolean; elevation: number; duration: number; }

const Duration: React.FC<IDurationProps> = ({ loading, className, duration, elevation }) => {
    const classes = useStyles();
    const theme = useTheme();

    return (
        <LoadingPage loading={loading}>
            <div className={className || classes.flex} style={{ padding: theme.spacing(2) }}>
                <Typography className={classes.whiteLabel}>Duração</Typography>
                <Typography className={classes.durationLabel} variant="h4">{duration !== -1 ? duration : 'Vitalício'}</Typography>
                {duration !== -1 && <Typography className={classes.whiteLabel}>{'Anos'} </Typography>}
            </div>
        </LoadingPage>
    );
};
export default Duration;
