import { createStyles, makeStyles, Theme } from '@material-ui/core' 
import { blueGrey } from '@material-ui/core/colors';

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        table: {
            padding: theme.spacing(2),
        },
        header: {
            color: blueGrey[900],
            marginBottom: theme.spacing(2),
        },
    })
);