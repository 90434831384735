import { ICustomerPageProps } from '../../components/customerPages/types';
import { IFinancingRequest } from '../../components/planningForm/financing/types';
import { IFixedCost } from '../../components/planningForm/fixedCost/types';
import { IGoal } from '../../components/planningForm/goals/types';
import { IIncome } from '../../components/planningForm/incoming/types';
import { IFinancialAssets, IPatrimony } from '../../components/planningForm/patrimony/types';
import { IRiskManagement } from '../../components/planningForm/riskManagement/types';
import { ISuggestions } from '../../components/planningForm/suggestions/types';
import { ITotalYearUpdate } from '../types/customer';
import { ICustomerFormValues, ICustomerInfo, ICustomerUserInfo, IGainInfo } from '../types/formPlanning';
import { ReduxAction } from './reduxAction';

type CustomerPage = React.FC<ICustomerPageProps>;

export const setUserInfo = 'UPDATE_CUSTOMER_USER_INFO';
export const ChangeCustomerUserInfo = (customerInfo: ICustomerUserInfo): ReduxAction<ICustomerUserInfo> => ({
    type: setUserInfo,
    payload: customerInfo,
});

export const setGainInfo = 'UPDATE_GAIN_INFO';
export const ChangeGainInfo = (gainInfo: IGainInfo): ReduxAction<IGainInfo> => ({
    type: setGainInfo,
    payload: gainInfo,
});

export const resetUserInfo = 'RESET_USER_INFO';
export const ResetUserInfo = (): ReduxAction<undefined> => ({
    type: resetUserInfo,
});

export const setClientInfo = 'UPDATE_CUSTOMER_INFO';
export const ChangeCustomerInfo = (customerInfo: ICustomerInfo): ReduxAction<ICustomerInfo> => ({
    type: setClientInfo,
    payload: customerInfo,
});

export const selectPage = 'UPDATE_CURRENT_PAGE';
export const SelectCurrentPage = (menuIndex: number): ReduxAction<{ menuIndex: number }> => ({
    type: selectPage,
    payload: { menuIndex },
});

export const setClientFinancing = 'UPDATE_CUSTOMER_FINANCING';
export const ChangeCustomerFinancing = (financing: IFinancingRequest): ReduxAction<IFinancingRequest> => ({
    type: setClientFinancing,
    payload: financing,
});

export const setClientFixedCost = 'UPDATE_CUSTOMER_FIXED_COST';
export const ChangeCustomerFixedCost = (fixedCost: IFixedCost): ReduxAction<IFixedCost> => ({
    type: setClientFixedCost,
    payload: fixedCost,
});

export const setClientGoal = 'UPDATE_CUSTOMER_GOAL';
export const ChangeCustomerGoal = (fixedCost: IGoal): ReduxAction<IGoal> => ({
    type: setClientGoal,
    payload: fixedCost,
});

export const setClientIncoming = 'UPDATE_CUSTOMER_INCOMING';
export const ChangeCustomerIncoming = (fixedCost: IIncome): ReduxAction<IIncome> => ({
    type: setClientIncoming,
    payload: fixedCost,
});

export const setClientPatrimony = 'UPDATE_CUSTOMER_PATRIMONY';
export const ChangeCustomerPatrimony = (fixedCost: IPatrimony): ReduxAction<IPatrimony> => ({
    type: setClientPatrimony,
    payload: fixedCost,
});

export const setClientSuggestions = 'UPDATE_CUSTOMER_SUGGESTIONS';
export const ChangeCustomerSuggestions = (fixedCost: ISuggestions): ReduxAction<ISuggestions> => ({
    type: setClientSuggestions,
    payload: fixedCost,
});

export const setClientRiskManagement = 'UPDATE_CUSTOMER_RISK_MANAGEMENT';
export const ChangeCustomerRiskManagement = (fixedCost: IRiskManagement): ReduxAction<IRiskManagement> => ({
    type: setClientRiskManagement,
    payload: fixedCost,
});

export const setFormValues = 'UPDATE_FORM_VALUES';
export const ChangeFormValues = (formValues: ICustomerFormValues): ReduxAction<ICustomerFormValues> => ({
    type: setFormValues,
    payload: formValues,
});

export const setExit = 'UPDATE_EXIT';
export const SetExit = (): ReduxAction<never> => ({
    type: setExit,
});

export const setLogout = 'UPDATE_LOGOUT';
export const SetLogout = (): ReduxAction<never> => ({
    type: setLogout,
});

export const changeCurrentStep = 'UPDATE_CURRENT_STEP';
export const ChangeCurrentStep = (current: number): ReduxAction<number> => ({
    type: changeCurrentStep,
    payload: current,
});

export const setToolbarTitle = 'UPDATE_TOOLBAR_TITLE';
export const SetToolbarParams = (
    title: string,
    color: string,
    navigation: () => void,
    current: number,
    total: number
): ReduxAction<{ title: string; color: string; navigation: () => void; current: number; total: number }> => ({
    type: setToolbarTitle,
    payload: { title, color, navigation, current, total },
});

export const updateTotalSaved = 'UPDATE_TOTAL_SAVED';
export const UpdateTotalSaved = (totalUpdate: ITotalYearUpdate): ReduxAction<ITotalYearUpdate> => ({
    type: updateTotalSaved,
    payload: totalUpdate,
});

export const copyInvestiments = 'COPY_INVESTIMENTS';
export const CopyInvestiments = (): ReduxAction<never> => ({
    type: copyInvestiments,
});

export const changeSuggestedAssets = 'CHANGE_SUGG_ASSETS';
export const ChangeSuggestedAssets = (assets: IFinancialAssets[]): ReduxAction<IFinancialAssets[]> => ({
    type: changeSuggestedAssets,
    payload: assets,
});
