import { makeStyles, Theme, createMuiTheme } from '@material-ui/core';

export const toolbarTheme = createMuiTheme({
    palette: {
        primary: {
            main: '#00878B',
        },
        secondary: {
            main: '#2CA0A0',
        },
    },
});

export const useStyles = makeStyles((theme: Theme) => {
    return {
        grow: {
            flexGrow: 1,
        },
        appBar: (props: { drawerWidth: string }) => ({
            '@media (min-width: 768px)': {
                width: '100%',
                paddingLeft: '15%',
                backgroundColor: 'white',
            },
            '@media (max-width: 767px)': {
                width: '100%',
                backgroundColor: 'white',
                paddingLeft: '15px',
                height: '7%',
            },
            zIndex: 1000,
        }),
        toolbar: {
            marginLeft: theme.spacing(1),
            minWidth: '80px',
        },
        legendRow: {
            display: 'flex',
            flexDirection: 'row',
        },
        whiteInfos: {
            color: '#AAAAAA',
            marginRight: theme.spacing(1),
        },
        line: {
            '@media (min-width: 768px)': {
                display: 'flex',
            },
            '@media (max-width: 767px)': {
                display: 'none',
            },
        },
        clientName: {
            '@media (min-width: 768px)': {
                marginTop: theme.spacing(1),
            },
            '@media (max-width: 767px)': {
                paddingLeft: '25px',
            },
        },
    };
});

