import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import api from '../../../../apis/dreamrichapi';
import { KeyIcon } from '../../../../assets/icons';
import { ChangeFinancialIndependence } from '../../../../redux/actions/customerActions';
import { SelectCurrentPage } from '../../../../redux/actions/planningActions';
import { IStore } from '../../../../redux/types/store';
import { lineSpacing } from '../../../../theme/spacingBetweenComponents';
import PageHeader from '../../../global/displays/pageHeader/pageHeader';
import LoadingPage from '../../../global/loadingPage/desktop/loadingPage';
import { ICustomerPageProps } from '../../types';
import GrowthFamily from '../growthFamily/growthFamily';
import Projection from '../projection/projection';
import PatrimonyTotal from '../totalPatrimony/totalPatrimony';
import { IFinancialIndependence } from '../types';
import { useStyles } from './style';

const FinancialIndependence: React.FC<ICustomerPageProps> = ({ userID, pageNumber }) => {
    const classes = useStyles();
    const elevation = 4;

    const dispatch = useDispatch();

    const { rentability, financialIndependence, flow } = useSelector((store: IStore) => ({
        rentability: store.customer.rentability,
        financialIndependence: store.customer.financialIndependence,
        flow: store.customer.flow,
    }));
    const [defautlWithdraw, setDefautlWithdraw] = React.useState(0);
    const [err, setErr] = React.useState('');
    const [loading, setLoading] = React.useState(true);
    const [stateRentability, setStateRentability] = React.useState(rentability);

    let regex = /"/gi;
    const userIDCompare = String(localStorage.getItem("userID")).replace(regex, "");

    React.useEffect(() => {
        const fetchUserRentability = async () => {
            const params = new URLSearchParams();
            if (userID) {
                params.append('userID', userIDCompare|| userID);
            }
            const url = `/customer/rentability?` + params.toString();
            try {
                const { rentability: userRentability } = (await api.get<{ rentability: number }>(url)).data;
                if (!rentability) {
                    setStateRentability(userRentability);
                }
            } catch {
                setStateRentability(0.04);
            }
        };
        fetchUserRentability();
        dispatch(SelectCurrentPage(pageNumber));
    }, []);

    React.useEffect(() => {

        const loadLocalStorage = async () => {

            if (localStorage.getItem("financialIndependence" + userIDCompare) != undefined || localStorage.getItem("financialIndependence" + userIDCompare) != null) {

                dispatch(ChangeFinancialIndependence(JSON.parse(String(localStorage.getItem("financialIndependence" + userIDCompare)))));
                setDefautlWithdraw((JSON.parse(String(localStorage.getItem("financialIndependence" + userIDCompare)))).withdraw);
                setErr('');
                setLoading(false);

                const url = `/financialindependence?`;
                const params = new URLSearchParams();

                if (userIDCompare || userID) {
                    params.append('userID', userIDCompare ||userID);
                }
                if (flow.chart.patrimonyAtIndependence) {
                    params.append('patrimony', flow.chart.patrimonyAtIndependence.toString());
                }
                if (stateRentability) {
                    params.append('rentability', stateRentability.toString());
                }

                const response = (await api.get<IFinancialIndependence>(url + params.toString())).data;
                
                if (String(localStorage.getItem("financialIndependence" + userIDCompare)) !== JSON.stringify(response)){
                    localStorage.setItem("financialIndependence" + userIDCompare, JSON.stringify(response));
                    setDefautlWithdraw(response.withdraw);
                    dispatch(ChangeFinancialIndependence(response));
                    setErr('');
                }

            } else {

                const url = `/financialindependence?`;
                const params = new URLSearchParams();

                if (userID|| userIDCompare) {
                    params.append('userID', userIDCompare|| userID);
                }
                if (flow.chart.patrimonyAtIndependence) {
                    params.append('patrimony', flow.chart.patrimonyAtIndependence.toString());
                }
                if (stateRentability) {
                    params.append('rentability', stateRentability.toString());
                }

                const response = (await api.get<IFinancialIndependence>(url + params.toString())).data;
                localStorage.setItem("financialIndependence" + userIDCompare, JSON.stringify(response));

                setDefautlWithdraw(response.withdraw);
                dispatch(ChangeFinancialIndependence(response));
                setErr('');

            }

            setLoading(false);
        };

        loadLocalStorage();

    }, [stateRentability]);

    return (
        <LoadingPage loading={loading}>
            <div className={classes.root}>
                <PageHeader Icon={KeyIcon} label="Independência financeira" />
                <div className={classes.independenceInfo}>
                    <PatrimonyTotal
                        className={classes.incomingDisplay}
                        elevation={elevation}
                        flowRentability={stateRentability}
                        independenceYear={financialIndependence.independenceYear}
                        patrimonyAtIndependence={financialIndependence.patrimonyAtIndependence}
                        longlifeWithdraw={financialIndependence.longlifeWithdraw}
                        defaultWithdraw={defautlWithdraw}
                        plannedWithdraw={defautlWithdraw}
                        defaultDuration={financialIndependence.duration}
                        userID={userIDCompare || userID}
                    />
                    {lineSpacing()}
                    <Projection
                        className={classes.grow}
                        elevation={elevation}
                        projection={financialIndependence.projection}
                        independenceYear={financialIndependence.independenceYear}
                        withDraw={financialIndependence.withdraw}
                    />
                    <GrowthFamily
                        className={classes.grow}
                        elevation={elevation}
                        family={financialIndependence.family}
                        projection={financialIndependence.projection}
                        independenceYear={financialIndependence.independenceYear}
                    />
                </div>
            </div>
        </LoadingPage>
    );
};
export default FinancialIndependence;
