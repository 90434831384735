import {   Paper, Table, TableBody, TableCell, TableHead, TableRow, TextField, Theme, } from '@material-ui/core';
import { useStyles } from './style'
import React from 'react';
import unmaskCurrency, { maskCurrency } from '../../../../utils/unmaskCurrency';
import formatter from '../../formater';

export interface ICost {
    label: string;
    value: number;
}

export type ICostValue<T> = {
    [K in keyof T]: number;
};

export type ITableEntry<T extends ICostValue<T>> = {
    [K in keyof T]: ICost;
};

export function BackToValue<T extends ICostValue<T>>(table: ITableEntry<T>): T {
    const a: any = {};
    Object.keys(table).map(k => {
        const key = k as keyof T;
        a[key] = table[key]?.value || 0;
    });
    return a;
}

interface ITableProps {
    header: string;
    rows: [string, number][];
    tableValues: (values: Map<string, number>) => void;
    required?: boolean;
    className?: string;
    showTotal?: boolean;
    percentage?: boolean;
    inputComponent: any;
    risk: string;
}

const TableCost: React.FC<ITableProps> = ({
    className,
    header,
    rows,
    tableValues,
    required,
    showTotal,
    inputComponent,
    percentage,
    risk
}) => {
    const classes = useStyles();
    const [rowStated, _] = React.useState([...rows].map((item): string => item[0]));
    const [values, setValues] = React.useState(rows.map(item => maskCurrency(item[1])));
    const [total, setTotal] = React.useState(0);

    const sum = React.useCallback(() => {
        setTotal(values.map(item => unmaskCurrency(item) || 0).reduce((item, acc) => item + acc));
    }, [setTotal, values]);

    React.useEffect(() => {
        sum();
    }, [sum, values]);

    const setValueInArr = (val: string, index: number) => {
        const newValues = values.map((item, idx) => (idx === index ? val : item));
        setValues(newValues);
    };

    const memoTableValues = React.useMemo(
        () =>
            new Map<string, number>(rowStated.map((row, idx): [string, number] => [row, unmaskCurrency(values[idx])])),
        [values]
    );

    React.useEffect(() => {
        tableValues(memoTableValues);
    }, [memoTableValues]);

    const inputProps = percentage
        ? { endAdornment: <div style={{ marginLeft: '4px' }}>%</div> }
        : { startAdornment: <div style={{ marginRight: '4px' }}>R$</div> };

    return (
        <div className={className}>
            <Paper elevation={3} className={classes.table}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell className={classes.tableHead} style={{ width: '50%' }}>
                                {header}
                            </TableCell>
                            <TableCell align="right" className={classes.tableHead}>
                                Valor {percentage ? '(%)' : '(R$)'}
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {rowStated.map((row, i) => (
                            <TableRow key={row}>
                                <TableCell scope="row">{row}</TableCell>
                                <TableCell align="right">
                                    <TextField
                                        required={required}
                                        color="primary"
                                        variant="outlined"
                                        size="small"
                                        placeholder={risk == 'risk' ? row == 'ITCMD' ? '6' : row == 'Advogado' ? '6' : '2' : '0'}
                                        inputMode="numeric"
                                        InputProps={{ inputComponent, ...inputProps }}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        style={percentage ? { maxWidth: '40%' } : {}}
                                        value={values[i]}
                                        onChange={event => setValueInArr(event.target.value, i)}
                                        onBlur={sum}
                                    />
                                </TableCell>
                            </TableRow>
                        ))}
                        {showTotal ? (
                            <TableRow>
                                <TableCell scope="row">TOTAL</TableCell>
                                <TableCell align="right">{formatter.format(total)}</TableCell>
                            </TableRow>
                        ) : null}
                    </TableBody>
                </Table>
            </Paper>
        </div>
    );
};
export default TableCost;