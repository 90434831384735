import { createStyles, makeStyles, Theme } from '@material-ui/core';
import { grey, red } from '@material-ui/core/colors';

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            paddingTop: theme.spacing(2),
        },
        table: {
            minWidth: '50vw',
        },
        selectButton: {
            backgroundColor: '#FFFFFF',  
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-start',
            alignItems: 'flex-end',
            '& > :first-child': {
                marginRight: theme.spacing(1),
            },
        },
        inputWidth: {
            flexBasis: '5%',
        },
        nextButton: {
            paddingTop: theme.spacing(2),
            float: 'right',
        },
        deleteHidden: {
            backgroundColor: theme.palette.error.main,
            height: '63px',
            borderTopRightRadius: '5px',
            borderBottomRightRadius: '5px',
            float: 'right',
            position: 'absolute',
        },
        outButton: {
            backgroundColor: 'white',
            color: grey[400],
        },
        header: {
            fontWeight: 600,
        },
        head: {
            padding: theme.spacing(2),
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            alignItems: 'center',
        },
        formControllers: {
            width: '100%',
            paddingTop: theme.spacing(2),
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            alignContent: 'flex-end',
        },
        chipButton: {
            backgroundColor: 'transparent',
            border: 'none',
        },
        chip: {
            color: grey[50],
        },
        subheader: {
            margin: theme.spacing(2, 0),
        },
    })
);
