import {
    Card,
    CardContent,
    Chip,
    CircularProgress,
    createStyles,
    makeStyles,
    Theme,
    Typography,
} from '@material-ui/core';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import api from '../../../apis/dreamrichapi';
import { ChangeGainInfo } from '../../../redux/actions/planningActions';
import { IStore } from '../../../redux/types/store';
import unmaskCurrency from '../../../utils/unmaskCurrency';
import { GreyedTextInput } from '../../global/inputs/GreyedInput';
import { IGainInfo } from './types';
import { IPlannerPageProps } from '../types';
import PercentageAdornmentGainInfo from '../../global/adornments/PercentageAdornmentGainInfo';
import {useStyles} from './style'


const GainInfo: React.FC<IPlannerPageProps> = ({ changeTitle }) => {
    const classes = useStyles();

    const { gainInfo } = useSelector((store: IStore) => ({
        gainInfo: store.planning.gainInfo,
    }));

    const history = useHistory();
    const dispatch = useDispatch();

    const [gainInfoData, setGainInfoData] = React.useState<IGainInfo>({
        ...gainInfo,
    });

    React.useEffect(() => {
        changeTitle('Cadastro de percentuais');
    }, []);

    const [percentTEDOffice, setPercentTEDOffice] = React.useState(
        gainInfo.officeData.percentTED ? gainInfo.officeData.percentTED.toString() : ''
    );
    const [percentInsuranceOffice, setPercentInsuranceOffice] = React.useState(
        gainInfo.officeData.percentInsurance ? gainInfo.officeData.percentInsurance.toString() : ''
    );
    const [percentPensionOffice, setPercentPensionOffice] = React.useState(
        gainInfo.officeData.percentPension ? gainInfo.officeData.percentPension.toString() : ''
    );
    const [percentConsortiaOffice, setPercentConsortiaOffice] = React.useState(
        gainInfo.officeData.percentConsortia ? gainInfo.officeData.percentConsortia.toString() : ''
    );
    const [percentPlanner, setPercentPlanner] = React.useState(
        gainInfo.percentPlanner ? gainInfo.percentPlanner.toString() : ''
    );
    const [loading, setLoading] = React.useState(false);
    const [postErr, setPostErr] = React.useState('');

    React.useEffect(() => {
        setPercentTEDOffice(gainInfo.officeData.percentTED ? gainInfo.officeData.percentTED.toString() : '');
        setPercentInsuranceOffice(
            gainInfo.officeData.percentInsurance ? gainInfo.officeData.percentInsurance.toString() : ''
        );
        setPercentPensionOffice(
            gainInfo.officeData.percentPension ? gainInfo.officeData.percentPension.toString() : ''
        );
        setPercentConsortiaOffice(
            gainInfo.officeData.percentConsortia ? gainInfo.officeData.percentConsortia.toString() : ''
        );
        setPercentPlanner(gainInfo.percentPlanner ? gainInfo.percentPlanner.toString() : '');
    }, [gainInfo]);

    const onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        setLoading(true);

        localStorage.setItem('percentTEDOffice', percentTEDOffice);
        localStorage.setItem('percentInsuranceOffice', percentInsuranceOffice);
        localStorage.setItem('percentPensionOffice', percentPensionOffice);
        localStorage.setItem('percentConsortiaOffice', percentConsortiaOffice);
        localStorage.setItem('percentPlanner', percentPlanner);

        const gainBody: IGainInfo = {
            officeData: {
                percentTED: unmaskCurrency(percentTEDOffice) / 100,
                percentInsurance: unmaskCurrency(percentInsuranceOffice) / 100,
                percentPension: unmaskCurrency(percentPensionOffice) / 100,
                percentConsortia: unmaskCurrency(percentConsortiaOffice) / 100,
            },
            plannerData: {
                percentTED: ((unmaskCurrency(percentTEDOffice) / 100) * unmaskCurrency(percentPlanner)) / 100,
                percentInsurance:
                    ((unmaskCurrency(percentInsuranceOffice) / 100) * unmaskCurrency(percentPlanner)) / 100,
                percentPension: ((unmaskCurrency(percentPensionOffice) / 100) * unmaskCurrency(percentPlanner)) / 100,
                percentConsortia:
                    ((unmaskCurrency(percentConsortiaOffice) / 100) * unmaskCurrency(percentPlanner)) / 100,
            },
            percentPlanner: unmaskCurrency(percentPlanner) / 100,
        };
        setGainInfoData(gainBody);
        api.post<IGainInfo, string>('/gaininfo', gainBody)
            .then(res => {
                dispatch(ChangeGainInfo(gainBody));
                setLoading(false);
                history.push('/planner');
            })
            .catch(err => {
                setLoading(false);
                if (err.response) {
                    setPostErr(err);
                }
            });
    };

    return (
        <div className={classes.root}>
            <div className={classes.topInfo}>
                <Typography className={classes.header} align="center">
                    Quais são os percentuais de repasse anual?
                </Typography>
            </div>
            <form onSubmit={onSubmit}>
                <div className={classes.form}>
                    <div className={classes.makeRow}>
                        <Card className={classes.cardItemShrink} elevation={2} style={{ marginRight: '25px' }}>
                            <CardContent>
                                <Typography className={classes.inputLabel} variant="body2">
                                    Escritório
                                </Typography>
                                <Typography className={classes.titleInput}>TED</Typography>
                                <div>
                                    <GreyedTextInput
                                        autoFocus
                                        color="primary"
                                        variant="outlined"
                                        size="small"
                                        InputProps={{
                                            endAdornment: <PercentageAdornmentGainInfo />,
                                        }}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        placeholder={String(localStorage.getItem('percentTEDOffice'))}
                                        value={percentTEDOffice}
                                        className={classes.input}
                                        onChange={event => setPercentTEDOffice(event.target.value)}
                                        fullWidth
                                    />
                                </div>
                                <Typography className={classes.titleInput}>Seguros</Typography>
                                <div>
                                    <GreyedTextInput
                                        color="primary"
                                        variant="outlined"
                                        size="small"
                                        InputProps={{
                                            endAdornment: <PercentageAdornmentGainInfo />,
                                        }}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        placeholder={String(localStorage.getItem('percentInsuranceOffice'))}
                                        value={percentInsuranceOffice}
                                        className={classes.input}
                                        onChange={event => setPercentInsuranceOffice(event.target.value)}
                                        fullWidth
                                    />
                                </div>
                                <Typography className={classes.titleInput}>Previdência</Typography>
                                <div>
                                    <GreyedTextInput
                                        color="primary"
                                        variant="outlined"
                                        size="small"
                                        InputProps={{
                                            endAdornment: <PercentageAdornmentGainInfo />,
                                        }}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        placeholder={String(localStorage.getItem('percentPensionOffice'))}
                                        value={percentPensionOffice}
                                        className={classes.input}
                                        onChange={event => setPercentPensionOffice(event.target.value)}
                                        fullWidth
                                    />
                                </div>
                                <Typography className={classes.titleInput}>Consórcio</Typography>
                                <div>
                                    <GreyedTextInput
                                        color="primary"
                                        variant="outlined"
                                        size="small"
                                        InputProps={{
                                            endAdornment: <PercentageAdornmentGainInfo />,
                                        }}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        placeholder={String(localStorage.getItem('percentConsortiaOffice'))}
                                        value={percentConsortiaOffice}
                                        className={classes.input}
                                        onChange={event => setPercentConsortiaOffice(event.target.value)}
                                        fullWidth
                                    />
                                </div>
                            </CardContent>
                        </Card>
                        <div>
                            <Card style={{ borderRadius: '8px' }} elevation={2}>
                                <CardContent>
                                    <Typography className={classes.inputLabel} variant="body2">
                                        Percentual do Assessor
                                    </Typography>
                                    <div>
                                        <GreyedTextInput
                                            color="primary"
                                            variant="outlined"
                                            size="small"
                                            InputProps={{
                                                endAdornment: <PercentageAdornmentGainInfo />,
                                            }}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            placeholder={String(localStorage.getItem('percentPlanner'))}
                                            value={percentPlanner}
                                            className={classes.input}
                                            onChange={event => setPercentPlanner(event.target.value)}
                                            fullWidth
                                        />
                                    </div>
                                </CardContent>
                            </Card>
                            <div className={classes.divSubmit}>
                                <Typography color="error" align="center">
                                    {postErr}
                                </Typography>
                                <div className={classes.sendControl}>
                                    {loading ? <CircularProgress size={20} /> : null}
                                    <button type="submit" className={classes.submitButton} style={{ float: 'right' }}>
                                        <Chip size="medium" label={'Finalizar cadastro'} />
                                    </button>
                                </div>
                            </div>
                        </div>
                        {/*<div>
                            <Card className={classes.cardItemShrink} elevation={2} style={{ marginLeft: '25px' }}>
                                <CardContent>
                                    <Typography className={classes.inputLabel} variant="body2">
                                        Assessor
                                    </Typography>
                                    <Typography className={classes.titleInput}>TED</Typography>
                                    <div>
                                        <GreyedTextInput
                                            color="primary"
                                            variant="outlined"
                                            size="small"
                                            placeholder="0,00
"
                                            InputProps={{
                                                endAdornment: <PercentageAdornmentGainInfo />,
                                            }}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            value={percentTEDPlanner}
                                            className={classes.input}
                                            onChange={event => setPercentTEDPlanner(event.target.value)}
                                            fullWidth
                                        />
                                    </div>
                                    <Typography className={classes.titleInput}>Seguro</Typography>
                                    <div>
                                        <GreyedTextInput
                                            color="primary"
                                            variant="outlined"
                                            size="small"
                                            placeholder="0,00
"
                                            InputProps={{
                                                endAdornment: <PercentageAdornmentGainInfo />,
                                            }}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            value={percentInsurancePlanner}
                                            className={classes.input}
                                            onChange={event => setPercentInsurancePlanner(event.target.value)}
                                            fullWidth
                                        />
                                    </div>
                                    <Typography className={classes.titleInput}>Previdência</Typography>
                                    <div>
                                        <GreyedTextInput
                                            color="primary"
                                            variant="outlined"
                                            size="small"
                                            placeholder="0,00
"
                                            InputProps={{
                                                endAdornment: <PercentageAdornmentGainInfo />,
                                            }}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            value={percentPensionPlanner}
                                            className={classes.input}
                                            onChange={event => setPercentPensionPlanner(event.target.value)}
                                            fullWidth
                                        />
                                    </div>
                                    <Typography className={classes.titleInput}>Consórcio</Typography>
                                    <div>
                                        <GreyedTextInput
                                            color="primary"
                                            variant="outlined"
                                            size="small"
                                            placeholder="0,00
"
                                            InputProps={{
                                                endAdornment: <PercentageAdornmentGainInfo />,
                                            }}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            value={percentConsortiaPlanner}
                                            className={classes.input}
                                            onChange={event => setPercentConsortiaPlanner(event.target.value)}
                                            fullWidth
                                        />
                                    </div>
                                </CardContent>
                            </Card>
                        </div>*/}
                    </div>
                </div>
            </form>
        </div>
    );
};
export default GainInfo;
