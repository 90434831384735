import { useStyles } from './style';
import React from 'react';
import { IUserEntry } from '../../clientEntry';
import MaterialTable, { MTableToolbar } from 'material-table';
import { tableIcons } from '../../../../global/table/tableIcons';
import { TablePagination, Tooltip } from '@material-ui/core';
import moment from 'moment';
import api from '../../../../../apis/dreamrichapi';
import formatter from '../../../../global/formater';
import { Edit } from '@material-ui/icons';
import { Add, Delete } from '@material-ui/icons';


interface ICustomerTableProps {
    customers: IUserEntry[];
    loading: boolean;
    openModalChangePlanner: (user: IUserEntry) => void;
    selectUser: (user: IUserEntry) => void;
    createUser: () => void;
    createPlanner: () => void;
    editPercent: () => void;
    openModalDeletePlanner: any;
    openDeleteClient: any;
    Total: string;
    Partial: string;
    Rejected: string;
    NoSuggestion: string;
}

export const Table: React.FC<ICustomerTableProps> = ({

    customers,
    openModalChangePlanner,
    openModalDeletePlanner,
    openDeleteClient,
    selectUser,
    createUser,
    createPlanner,
    editPercent,
    loading,
    Total,
    Partial,
    Rejected,
    NoSuggestion,
}) => {
    const classes = useStyles();

    const today = moment(new Date());

    const handleClick = (event?: React.MouseEvent | undefined, rowData?: IUserEntry | undefined) => {
        if (rowData) selectUser(rowData);
    };

    const submitDelete = async () => {
        try {
            const params = new URLSearchParams();
            params.append('plannerID', "58dc91e8-3afe-563a-5130-6b98ec7c73b3" || '');

            await api.delete('/deleteplanner?' + params.toString());
        } catch (e) {
            console.log(e?.response?.data);
        }
    };

    const Aberto = 'https://cakeinvest-frontend.s3.amazonaws.com/open.svg';

    return (
        <div className={classes.root}>
            <MaterialTable
                title=""
                isLoading={loading}
                icons={tableIcons}
                style={{ background: "#034663", overflow: 'hidden !important' }}
                columns={[
                    {
                        title: 'Nome',
                        field: 'fullName',
                        cellStyle: {
                            backgroundColor: '#F2F3F3',
                            textAlign: 'start',
                        },
                        headerStyle: {
                            background: 'rgba(255,255,255, 0.3)',
                            color: '#fff',
                            paddingLeft: '15px !important',
                            marginLeft: '10px !important',
                            textAlign: 'start',
                        },
                        render: rowData => (
                            <Tooltip
                                title={
                                    <div>
                                        Email: {rowData.email}
                                        <br />
                                        Profissão: {rowData.jobTitle}
                                        <br />
                                        Idade: {today.diff(moment(rowData.onlyDate), 'years')}
                                        <br />
                                        Celular: {rowData.cellphone.replace('55=', '')}
                                        <br />
                                        Renda: {formatter.format(rowData.salary)}
                                    </div>
                                }
                            >
                                <div
                                    style={{
                                        whiteSpace: 'nowrap',
                                        width: 140,
                                        overflow: 'hidden',
                                        textOverflow: 'ellipsis',
                                    }}
                                >
                                    <p className='mt-0 mb-0 text-capitalize'>
                                        {rowData.fullName.toLowerCase()}
                                    </p>
                                </div>
                            </Tooltip>
                        ),
                    },
                    {
                        title: 'Criação',
                        field: 'planningDate',

                        cellStyle: {
                            backgroundColor: '#F2F3F3',
                        },
                        headerStyle: {
                            background: 'rgba(255,255,255, 0.3)',
                            color: '#fff',
                        },
                        render: rowData => moment(rowData.planningDate).format('DD/MM/YYYY'),
                        editable: 'never',
                    },
                    {
                        title: 'Assessor',
                        field: 'plannerUser',

                        cellStyle: {
                            backgroundColor: '#F2F3F3',
                        },
                        headerStyle: {
                            background: 'rgba(255,255,255, 0.3)',
                            color: '#fff',
                        },
                        render: rowData =>
                            rowData.plannerUser === '' || rowData.plannerUser === null
                                ? 'Sem assessor'
                                : rowData.plannerUser,
                        editable: 'never',
                    },
                    {
                        title: 'Foco em Investimentos',
                        field: 'totalInvestmentValue',

                        cellStyle: {
                            backgroundColor: '#F2F3F3',
                            textAlign: 'start',
                        },
                        headerStyle: {
                            background: 'rgba(255,255,255, 0.3)',
                            color: '#fff',
                            textAlign: 'start',
                        },
                        render: rowData => formatter.format(rowData.totalInvestmentValue),
                        editable: 'never',
                    },
                    {
                        title: 'Investimentos Líquidos',
                        field: 'sumInvestments',

                        cellStyle: {
                            backgroundColor: '#F2F3F3',
                        },
                        headerStyle: {
                            background: 'rgba(255,255,255, 0.3)',
                            color: '#fff',
                        },
                        render: rowData =>
                            rowData.sumInvestments !== 0 ? (
                                rowData.filtroInvestments === 'Verde' ? (
                                    <div className={classes.tooltipStyle}>
                                        <Tooltip
                                            title={
                                                <div>
                                                    Valor total: {formatter.format(rowData.sumInvestments)}
                                                    <br />
                                                    Repasse escritório:{' '}
                                                    {formatter.format(
                                                        rowData.sumInvestments * rowData.percentTEDOffice
                                                    )}
                                                    <br />
                                                    Repasse assessor:{' '}
                                                    {formatter.format(
                                                        rowData.sumInvestments * rowData.percentTEDPlanner
                                                    )}
                                                </div>
                                            }
                                        >
                                            <img src={Total} alt="Total" width="20px" height="20px"></img>
                                        </Tooltip>
                                    </div>
                                ) : rowData.filtroInvestments === 'Amarelo' ? (
                                    <div className={classes.tooltipStyle}>
                                        <Tooltip
                                            title={
                                                <div>
                                                    Valor total: {formatter.format(rowData.sumInvestments)}
                                                    <br />
                                                    Repasse escritório:{' '}
                                                    {formatter.format(
                                                        rowData.sumInvestments * rowData.percentTEDOffice
                                                    )}
                                                    <br />
                                                    Repasse assessor:{' '}
                                                    {formatter.format(
                                                        rowData.sumInvestments * rowData.percentTEDPlanner
                                                    )}
                                                </div>
                                            }
                                        >
                                            <img src={Partial} alt="Total" width="20px" height="20px"></img>
                                        </Tooltip>
                                    </div>
                                ) : (
                                    <div className={classes.tooltipStyle}>
                                        <Tooltip
                                            title={
                                                <div>
                                                    Valor total: {formatter.format(rowData.sumInvestments)}
                                                    <br />
                                                    Repasse escritório:{' '}
                                                    {formatter.format(
                                                        rowData.sumInvestments * rowData.percentTEDOffice
                                                    )}
                                                    <br />
                                                    Repasse assessor:{' '}
                                                    {formatter.format(
                                                        rowData.sumInvestments * rowData.percentTEDPlanner
                                                    )}
                                                </div>
                                            }
                                        >
                                            <img src={Rejected} alt="Total" width="20px" height="20px"></img>
                                        </Tooltip>
                                    </div>
                                )
                            ) : (
                                <div className={classes.tooltipStyle}>
                                    <Tooltip
                                        title={
                                            <div>
                                                Valor total: {formatter.format(rowData.sumInvestments)}
                                                <br />
                                                Repasse escritório:{' '}
                                                {formatter.format(rowData.sumInvestments * rowData.percentTEDOffice)}
                                                <br />
                                                Repasse assessor:{' '}
                                                {formatter.format(rowData.sumInvestments * rowData.percentTEDPlanner)}
                                            </div>
                                        }
                                    >
                                        <img src={NoSuggestion} alt="Total" width="20px" height="20px"></img>
                                    </Tooltip>
                                </div>
                            ),
                        editable: 'never',
                    },
                    {
                        title: 'Previdência',
                        field: 'sumPension',

                        cellStyle: {
                            backgroundColor: '#F2F3F3',
                        },
                        headerStyle: {
                            background: 'rgba(255,255,255, 0.3)',
                            color: '#fff',
                        },
                        render: rowData =>
                            rowData.sumPension !== 0 ? (
                                rowData.filtroPension === 'Verde' ? (
                                    <div className={classes.tooltipStyle}>
                                        <Tooltip
                                            title={
                                                <div>
                                                    Aporte mensal: {formatter.format(rowData.sumPension)}
                                                    <br />
                                                    Repasse escritório:{' '}
                                                    {formatter.format(
                                                        rowData.sumPension * rowData.percentPensionOffice
                                                    )}
                                                    <br />
                                                    Repasse assessor:{' '}
                                                    {formatter.format(
                                                        rowData.sumPension * rowData.percentPensionPlanner
                                                    )}
                                                </div>
                                            }
                                        >
                                            <img src={Total} alt="Total" width="20px" height="20px"></img>
                                        </Tooltip>
                                    </div>
                                ) : rowData.filtroPension === 'Amarelo' ? (
                                    <div className={classes.tooltipStyle}>
                                        <Tooltip
                                            title={
                                                <div>
                                                    Aporte mensal: {formatter.format(rowData.sumPension)}
                                                    <br />
                                                    Repasse escritório:{' '}
                                                    {formatter.format(
                                                        rowData.sumPension * rowData.percentPensionOffice
                                                    )}
                                                    <br />
                                                    Repasse assessor:{' '}
                                                    {formatter.format(
                                                        rowData.sumPension * rowData.percentPensionPlanner
                                                    )}
                                                </div>
                                            }
                                        >
                                            <img src={Partial} alt="Total" width="20px" height="20px"></img>
                                        </Tooltip>
                                    </div>
                                ) : (
                                    <div className={classes.tooltipStyle}>
                                        <Tooltip
                                            title={
                                                <div>
                                                    Aporte mensal: {formatter.format(rowData.sumPension)}
                                                    <br />
                                                    Repasse escritório:{' '}
                                                    {formatter.format(
                                                        rowData.sumPension * rowData.percentPensionOffice
                                                    )}
                                                    <br />
                                                    Repasse assessor:{' '}
                                                    {formatter.format(
                                                        rowData.sumPension * rowData.percentPensionPlanner
                                                    )}
                                                </div>
                                            }
                                        >
                                            <img src={Rejected} alt="Total" width="20px" height="20px"></img>
                                        </Tooltip>
                                    </div>
                                )
                            ) : (
                                <div className={classes.tooltipStyle}>
                                    <Tooltip
                                        title={
                                            <div>
                                                Aporte mensal: {formatter.format(rowData.sumPension)}
                                                <br />
                                                Repasse escritório:{' '}
                                                {formatter.format(rowData.sumPension * rowData.percentPensionOffice)}
                                                <br />
                                                Repasse assessor:{' '}
                                                {formatter.format(rowData.sumPension * rowData.percentPensionPlanner)}
                                            </div>
                                        }
                                    >
                                        <img src={NoSuggestion} alt="Total" width="20px" height="20px"></img>
                                    </Tooltip>
                                </div>
                            ),
                        editable: 'never',
                    },
                    {
                        title: 'Seguro',
                        field: 'sumInsurance',

                        cellStyle: {
                            backgroundColor: '#F2F3F3',
                        },
                        headerStyle: {
                            background: 'rgba(255,255,255, 0.3)',
                            color: '#fff',
                        },
                        render: rowData =>
                            rowData.sumInsurance !== 0 ? (
                                rowData.filtroInsurance === 'Verde' ? (
                                    <div className={classes.tooltipStyle}>
                                        <Tooltip
                                            title={
                                                <div>
                                                    Valor do prêmio anual: {formatter.format(rowData.sumInsurance)}
                                                    <br />
                                                    Repasse escritório:{' '}
                                                    {formatter.format(
                                                        rowData.sumInsurance * rowData.percentInsuranceOffice
                                                    )}
                                                    <br />
                                                    Repasse assessor:{' '}
                                                    {formatter.format(
                                                        rowData.sumInsurance * rowData.percentInsurancePlanner
                                                    )}
                                                </div>
                                            }
                                        >
                                            <img src={Total} alt="Total" width="20px" height="20px"></img>
                                        </Tooltip>
                                    </div>
                                ) : rowData.filtroInsurance === 'Amarelo' ? (
                                    <div className={classes.tooltipStyle}>
                                        <Tooltip
                                            title={
                                                <div>
                                                    Valor do prêmio anual: {formatter.format(rowData.sumInsurance)}
                                                    <br />
                                                    Repasse escritório:{' '}
                                                    {formatter.format(
                                                        rowData.sumInsurance * rowData.percentInsuranceOffice
                                                    )}
                                                    <br />
                                                    Repasse assessor:{' '}
                                                    {formatter.format(
                                                        rowData.sumInsurance * rowData.percentInsurancePlanner
                                                    )}
                                                </div>
                                            }
                                        >
                                            <img src={Partial} alt="Total" width="20px" height="20px"></img>
                                        </Tooltip>
                                    </div>
                                ) : (
                                    <div className={classes.tooltipStyle}>
                                        <Tooltip
                                            title={
                                                <div>
                                                    Valor do prêmio anual: {formatter.format(rowData.sumInsurance)}
                                                    <br />
                                                    Repasse escritório:{' '}
                                                    {formatter.format(
                                                        rowData.sumInsurance * rowData.percentInsuranceOffice
                                                    )}
                                                    <br />
                                                    Repasse assessor:{' '}
                                                    {formatter.format(
                                                        rowData.sumInsurance * rowData.percentInsurancePlanner
                                                    )}
                                                </div>
                                            }
                                        >
                                            <img src={Rejected} alt="Total" width="20px" height="20px"></img>
                                        </Tooltip>
                                    </div>
                                )
                            ) : (
                                <div className={classes.tooltipStyle}>
                                    <Tooltip
                                        title={
                                            <div>
                                                Valor do prêmio anual: {formatter.format(rowData.sumInsurance)}
                                                <br />
                                                Repasse escritório:{' '}
                                                {formatter.format(
                                                    rowData.sumInsurance * rowData.percentInsuranceOffice
                                                )}
                                                <br />
                                                Repasse assessor:{' '}
                                                {formatter.format(
                                                    rowData.sumInsurance * rowData.percentInsurancePlanner
                                                )}
                                            </div>
                                        }
                                    >
                                        <img src={NoSuggestion} alt="Total" width="20px" height="20px"></img>
                                    </Tooltip>
                                </div>
                            ),
                        editable: 'never',
                    },
                    {
                        title: 'Consórcio',
                        field: 'sumConsortia',

                        cellStyle: {
                            backgroundColor: '#F2F3F3',
                        },
                        headerStyle: {
                            background: 'rgba(255,255,255, 0.3)',
                            color: '#fff',
                        },
                        render: rowData =>
                            rowData.sumConsortia !== 0 ? (
                                rowData.filtroConsortium === 'Verde' ? (
                                    <div className={classes.tooltipStyle}>
                                        <Tooltip
                                            title={
                                                <div>
                                                    Valor da carta: {formatter.format(rowData.sumConsortia)}
                                                    <br />
                                                    Repasse escritório:{' '}
                                                    {formatter.format(
                                                        rowData.sumConsortia * rowData.percentConsortiaOffice
                                                    )}
                                                    <br />
                                                    Repasse assessor:{' '}
                                                    {formatter.format(
                                                        rowData.sumConsortia * rowData.percentConsortiaPlanner
                                                    )}
                                                </div>
                                            }
                                        >
                                            <img src={Total} alt="Total" width="20px" height="20px"></img>
                                        </Tooltip>
                                    </div>
                                ) : rowData.filtroConsortium === 'Amarelo' ? (
                                    <div className={classes.tooltipStyle}>
                                        <Tooltip
                                            title={
                                                <div>
                                                    Valor da carta: {formatter.format(rowData.sumConsortia)}
                                                    <br />
                                                    Repasse escritório:{' '}
                                                    {formatter.format(
                                                        rowData.sumConsortia * rowData.percentConsortiaOffice
                                                    )}
                                                    <br />
                                                    Repasse assessor:{' '}
                                                    {formatter.format(
                                                        rowData.sumConsortia * rowData.percentConsortiaPlanner
                                                    )}
                                                </div>
                                            }
                                        >
                                            <img src={Partial} alt="Total" width="20px" height="20px"></img>
                                        </Tooltip>
                                    </div>
                                ) : (
                                    <div className={classes.tooltipStyle}>
                                        <Tooltip
                                            title={
                                                <div>
                                                    Valor da carta: {formatter.format(rowData.sumConsortia)}
                                                    <br />
                                                    Repasse escritório:{' '}
                                                    {formatter.format(
                                                        rowData.sumConsortia * rowData.percentConsortiaOffice
                                                    )}
                                                    <br />
                                                    Repasse assessor:{' '}
                                                    {formatter.format(
                                                        rowData.sumConsortia * rowData.percentConsortiaPlanner
                                                    )}
                                                </div>
                                            }
                                        >
                                            <img src={Rejected} alt="Total" width="20px" height="20px"></img>
                                        </Tooltip>
                                    </div>
                                )
                            ) : (
                                <div className={classes.tooltipStyle}>
                                    <Tooltip
                                        title={
                                            <div>
                                                Valor da carta: {formatter.format(rowData.sumConsortia)}
                                                <br />
                                                Repasse escritório:{' '}
                                                {formatter.format(
                                                    rowData.sumConsortia * rowData.percentConsortiaOffice
                                                )}
                                                <br />
                                                Repasse assessor:{' '}
                                                {formatter.format(
                                                    rowData.sumConsortia * rowData.percentConsortiaPlanner
                                                )}
                                            </div>
                                        }
                                    >
                                        <img src={NoSuggestion} alt="Sem sugestão" width="20px" height="20px"></img>
                                    </Tooltip>
                                </div>
                            ),
                        editable: 'never',
                    },
                    {
                        title: 'Receita Potencial',
                        field: 'sumInvestments',

                        cellStyle: {
                            backgroundColor: '#F2F3F3',

                        },
                        headerStyle: {
                            background: 'rgba(255,255,255, 0.3)',
                            color: '#fff',
                        },
                        render: rowData => (
                            <div>
                                {formatter.format(
                                    rowData.sumInvestments * rowData.percentTEDOffice +
                                    rowData.sumPension * rowData.percentPensionOffice +
                                    rowData.sumInsurance * rowData.percentInsuranceOffice +
                                    rowData.sumConsortia * rowData.percentConsortiaOffice
                                )}
                            </div>
                        ),
                        editable: 'never',
                    },
                    {
                        title: 'Receita Realizada',
                        field: 'sumInvestments',
                        cellStyle: {

                            backgroundColor: '#F2F3F3',
                        },
                        headerStyle: {
                            background: 'rgba(255,255,255, 0.3)',
                            color: '#fff',
                        },

                        render: rowData =>
                            rowData.sumInvestments * rowData.percentTEDOffice +
                                rowData.sumPension * rowData.percentPensionOffice +
                                rowData.sumInsurance * rowData.percentInsuranceOffice +
                                rowData.sumConsortia * rowData.percentConsortiaOffice >
                                0 ? (
                                <div>
                                    {formatter.format(
                                        rowData.investmentsImplemented * rowData.percentTEDOffice +
                                        rowData.pensionImplemented * rowData.percentPensionOffice +
                                        rowData.insuranceImplemented * rowData.percentInsuranceOffice +
                                        rowData.consortiumImplemented * rowData.percentConsortiaOffice
                                    )}
                                </div>
                            ) : (
                                <div>{formatter.format(0)}</div>
                            ),
                        editable: 'never',
                    },
                ]}
                onRowClick={handleClick}
                data={customers}
                components={{
                    Toolbar: props => (
                        <div className={`${classes.panelHeading} d-flex align-items-center justify-content-between`}>
                            <div className="d-flex">
                                <MTableToolbar {...props} />
                            </div>
                            <div className="d-flex">
                                {/* @ts-ignore */}
                                <button className={classes.btnHeader} onClick={editPercent}>
                                    Editar Percentuais
                                </button>
                                {/* @ts-ignore */}
                                <button className={classes.btnHeader} onClick={createUser}>
                                    Novo Cliente
                                </button>
                                {/* @ts-ignore */}
                                <button className={classes.btnHeader} onClick={createPlanner}>
                                    Novo Assessor
                                </button>
                                {/* @ts-ignore */}
                                <button className={classes.btnHeader} onClick={openModalDeletePlanner}>
                                    Deletar Assessor
                                </button>

                            </div>
                        </div>
                    ),
                    Pagination: props => (
                        <div className="row mt-3 text-white m-0">
                            <div className="col col-sm-6 col-xs-6 pe-0 pt-0 pb-0 ps-2 d-flex align-items-center border-bottom">
                                <div className="d-flex justify-content-center gap-3 ">
                                    <div>Contratação:</div>
                                    <div>
                                        <img src={Rejected} alt="Rejeitado" width="15px" height="15px"></img>
                                    </div>
                                    <div style={{ marginLeft: '-12px', marginTop: '1px', marginRight: "10px" }}>Rejeitado</div>
                                    <div>
                                        <img src={Partial} alt="Parcial" width="15px" height="15px"></img>
                                    </div>
                                    <div style={{ marginLeft: '-13px', marginTop: '2px', marginRight: "10px" }}>Parcial</div>
                                    <div>
                                        <img src={Total} alt="Total" width="15px" height="15px"></img>
                                    </div>
                                    <div style={{ marginLeft: '-13px', marginTop: '1px', marginRight: "10px" }}>Total</div>
                                    <div>
                                        <img src={Aberto} alt="Em aberto" width="15px" height="15px"></img>
                                    </div>
                                    <div style={{ marginLeft: '-11px', marginTop: '1.5px', marginRight: "10px" }}>Em aberto</div>
                                    <div>
                                        <img src={NoSuggestion} alt="Sem sugestão" width="15px" height="15px"></img>
                                    </div>
                                    <div style={{ marginLeft: '-11px', marginTop: '2px' }}>Sem sugestão</div>
                                </div>
                            </div>
                            <div className="col col-sm-6 col-xs-6 d-flex ps-0 pt-0 pb-0 pe-2">
                                <TablePagination {...props} />
                            </div>
                        </div>
                    ),
                }}
                options={{
                    paging: true,
                    pageSize: 10,
                    emptyRowsWhenPaging: true,
                    pageSizeOptions: [10, 25, 50, 100],
                    headerStyle: {
                        backgroundColor: "#034663",
                        color: '#FFFFFF',
                        fontSize: 12,
                    },
                    rowStyle: {
                        backgroundColor: '#F2F3F3',
                        fontSize: 12,
                    },
                }}
                localization={{
                    pagination: {
                        labelDisplayedRows: '{from}-{to} de {count}',
                        lastTooltip: 'Última página',
                        lastAriaLabel: 'Última página',
                        nextTooltip: 'Próxima página',
                        nextAriaLabel: 'Próxima página',
                        previousTooltip: 'Página anterior',
                        previousAriaLabel: 'Página anterior',
                        firstTooltip: 'Primeira página',
                        firstAriaLabel: 'Primeira página',
                        labelRowsSelect: 'linhas',
                        labelRowsPerPage: 'Linhas por página',
                    },
                    toolbar: {
                        nRowsSelected: '{0} linhas selecionadas',
                        searchPlaceholder: 'Procurar',
                        searchTooltip: 'Buscar',
                    },
                    header: {
                        actions: 'Ações',
                    },
                    body: {
                        emptyDataSourceMessage: 'Carregando',
                        filterRow: {
                            filterTooltip: 'Filtrar',
                        },
                    },
                }}
                actions={[
                    {
                        icon: () => <Delete style={{ color: 'red' }} />,
                        tooltip: 'Deletar Cliente',
                        onClick: (event, rowData) => {
                            if (rowData && typeof rowData === 'object') {
                                openDeleteClient(rowData as IUserEntry);
                            }
                        },
                    },
                    {
                        icon: () => <Edit style={{ color: '#000000' }} />,
                        tooltip: 'Trocar Assessor',
                        onClick: (event, rowData) => {
                            if (rowData && typeof rowData === 'object') {
                                openModalChangePlanner(rowData as IUserEntry);
                            }
                        },
                    }
                ]}
            />
        </div>
    );
};
