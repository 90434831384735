import { makeStyles, Theme, createStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            padding: theme.spacing(2),
            paddingBottom: theme.spacing(4),
        },
        gridContainer: {
            height: '90%',
        },
    })
);