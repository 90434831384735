import {  makeStyles, withStyles } from '@material-ui/core';
import { TextField } from '@material-ui/core';

export const styles = makeStyles({
    root: {
        display: 'flex',
        flexDirection: 'row',

        '& > .MuiSelect-select, .MuiInputBase-input': {
            minHeight: '1rem',
        },

        '& .MuiOutlinedInput-root': {
            height: '100%',
        },
    },
    item: {
        '& .MuiSelect-select, .MuiInputBase-input .MuiInputBase-root .MuiOutlinedInput-root': {
            minHeight: '1rem',
        },
    },
});

export const CustomizedTextInput = withStyles({
    root: {
        '& .MuiFilledInput-input': {
            padding: '11px 12px 10px',
        },
        '& .MuiInputBase-root': {
            padding: '3px',
        },
    },
})(TextField);