import React, { useState } from 'react';

export const ConfigPage = () => {
    const [subdomain, setSubdomain] = useState('');
    const [name, setName] = useState('');
    const [url, setUrl] = useState('');
    const [client, setClient] = useState('');
    const [pool, setPool] = useState('');
    const [primaryColor, setPrimaryColor] = useState('');
    const [secondaryColor, setSecondaryColor] = useState('');

    async function createOffice() {
        alert('asda');
    }

    return (
        <section className="container d-flex flex-column justify-content-center align-items-center mt-3 pt-5 pb-5">
            <img src="https://cakeinvest-front-dev.s3.amazonaws.com/Logos/cake-invest-PNG+1.svg" alt="cake-logo.png" />

            <form onSubmit={createOffice} className="mt-5">
                <div className="row">
                    <div className="col-md-4 mt-4">
                        <h3 className="fw-bold">Estilização</h3>

                        <div className="mb-3 mt-3">
                            <label className="form-label">Nome do escritório</label>
                            <input
                                required
                                onChange={e => setName(e.target.value)}
                                className="form-control"
                                type="text"
                            />
                        </div>
                        <div className="mb-3">
                            <label className="form-label">Imagem</label>
                            <input required className="form-control" type="file" />
                        </div>
                        <div className="mb-3 d-flex gap-5 justify-content-between">
                            <div className="w-50">
                                <label className="form-label">Cor primária</label>
                                <input
                                    onChange={e => setPrimaryColor(e.target.value)}
                                    required
                                    className="form-control"
                                    type="color"
                                />
                            </div>

                            <div className="w-50">
                                <label className="form-label">Cor secundária</label>
                                <input
                                    onChange={e => setSecondaryColor(e.target.value)}
                                    required
                                    className="form-control"
                                    type="color"
                                />
                            </div>
                        </div>
                    </div>

                    <div className="col-md-4 mt-4">
                        <h3 className="fw-bold">MONGO</h3>
                        <div className="mb-3 mt-3">
                            <label className="form-label">Subdomínio</label>
                            <input
                                required
                                className="form-control"
                                onChange={e => setSubdomain(e.target.value)}
                                type="text"
                            />
                        </div>
                        <div className="mb-3">
                            <label className="form-label">URL Mongo</label>
                            <input
                                required
                                onChange={e => setUrl(e.target.value)}
                                className="form-control"
                                type="text"
                            />
                        </div>
                    </div>

                    <div className="col-md-4 mt-4">
                        <h3 className="fw-bold">COGNITO</h3>
                        <div className="mb-3 mt-3">
                            <label className="form-label">Subdomínio</label>
                            <input required className="form-control" value={subdomain} disabled type="text" />
                        </div>
                        <div className="mb-3">
                            <label className="form-label">Client ID</label>
                            <input
                                required
                                onChange={e => setClient(e.target.value)}
                                className="form-control"
                                type="text"
                            />
                        </div>
                        <div className="mb-3">
                            <label className="form-label">Pool ID</label>
                            <input
                                required
                                onChange={e => setPool(e.target.value)}
                                className="form-control"
                                type="text"
                            />
                        </div>
                    </div>

                    <div className="mt-3">
                        <button className="btn btn-outline-primary w-100" type="submit">
                            CRIAR ESCRITÓRIO
                        </button>
                    </div>
                </div>
            </form>
        </section>
    );
};
