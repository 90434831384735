import { createStyles, makeStyles, Theme } from '@material-ui/core';
import { grey } from '@material-ui/core/colors';

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            display: 'flex',
            justifyContent: 'center',
        },
        content: {},
        table: {
            flexGrow: 1,
            minWidth: '60%',
        },
        nextButton: {
            paddingTop: theme.spacing(2),
            alignSelf: 'flex-end',
            marginRight: '20%',
        },
        outButton: {
            backgroundColor: 'white',
            color: grey[400],
        },
        formControllers: {
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            alignContent: 'flex-end',
        },
        total: {
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'center',
            alignContent: 'flex-end',
            margin: theme.spacing(1, 0),
        },
        chipButton: {
            backgroundColor: 'transparent',
            border: 'none',
        },
        chip: {
            color: grey[50],
        },
    })
);