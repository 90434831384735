import { createStyles, makeStyles, Theme } from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        cardHeader: {
            color: 'rgba(19, 16, 16, 0.87)',
        },
        cardsLine: {
            '@media (min-width: 768px)': {
                display: 'flex',
                flexWrap: 'wrap',
                justifyContent: 'flex-start',
                alignContent: 'space-around',
                alignItems: 'flex-start',
                margin: '-4px',
                marginBottom: theme.spacing(2),
            },
            '@media (max-width: 767px)': {
                display: 'flex',
                flexDirection: 'column',
                flexWrap: 'wrap',
                justifyContent: 'center',
                alignContent: 'center',
                alignItems: 'center',
                margin: '-4px',
                marginBottom: theme.spacing(2),
            },
            
        },
        cardsRow: {
            display: 'flex',
            flexDirection: 'row',
            width: '100%',
        },
        cardItemGrow: {
            height: '100%',
            margin: 4,
            flexGrow: 1,
            borderRadius: '8px',
        },
        cardItemShrink: {
            height: '100%',
            margin: 4,
            borderRadius: '8px',
        },
        subHeader: {
            fontSize: '14px',
        },
        total: {
            display: 'flex',
            alignItems: 'end',
            justifyContent: 'flex-end',
            paddingTop: theme.spacing(2),
        },
        totalLabel: {
            '@media (min-width: 768px)': {
                display: 'flex',
                justifyContent: 'flex-end',
                padding: theme.spacing(1),
                alignItems: 'center',
            },
            '@media (max-width: 767px)': {
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center',
                alignItems: 'center',
                alignContent: 'center',
                padding: theme.spacing(1),
            },
        },
        totalValue: {
            margin: `0 ${theme.spacing(1)}px`,
            color: theme.palette.secondary.dark,
        },
        valueText: {
            color: theme.palette.secondary.light,
        },
    })
);