import { createStyles, makeStyles, Theme } from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            flexGrow: 1,
        },
        appBar: {},
        menuButton: {
            marginRight: theme.spacing(2),
        },
        title: {
            flexGrow: 1,
        },
        selectLine: {
            display: 'flex',
            color: 'white',
            flexGrow: 1,
            alignItems: 'center',
        },
        select: {
            borderRadius: '10%',
        },
        selectroot: {
            paddingLeft: '5.5px',
        },
        MuiInputBase: {
            padding: 0,
        },
    })
);