import { createStyles, makeStyles, Theme } from '@material-ui/core';
import { grey } from '@material-ui/core/colors';

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            marginTop: theme.spacing(2),
        },
        header: {
            fontWeight: 600,
            fontSize: 26,
            marginTop: theme.spacing(6),
            marginBottom: theme.spacing(2),
        },
        inputLabel: {
            marginBottom: theme.spacing(2),
            textAlign: 'center',
            fontSize: '18px',
            fontWeight: 600,
        },
        titleInput: {
            marginBottom: 2,
        },
        topInfo: {
            padding: '0 5vw',
            marginBottom: theme.spacing(4),
            textAlign: 'center',
        },
        input: {
            marginBottom: theme.spacing(2),
        },
        form: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-around',
            minWidth: '50em',
            padding: theme.spacing(0, 6, 2),
        },
        makeRow: {
            display: 'flex',
            flexDirection: 'row',
            marginLeft: 'auto',
            marginRight: 'auto',
        },
        divSubmit: {
            display: 'flex',
            flexDirection: 'column',
        },
        cardItemShrink: {
            // this can shrink
            height: '100%',
            borderRadius: '8px',
        },
        sendControl: {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',
            alignContent: 'center',
        },
        submitButton: {
            color: 'transparent',
            borderColor: 'transparent',
            backgroundColor: 'transparent',
            padding: 0,
            marginTop: theme.spacing(4),
        },
        chip: {
            backgroundColor: '#022536',
            color: 'white',
        },
        chipDisabled: {
            backgroundColor: '#022536',
            color: 'white',
        },
    })
);