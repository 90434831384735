import { Paper, Typography } from '@material-ui/core';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import api from '../../../../apis/dreamrichapi';
import { IncomingDestinationIcon } from '../../../../assets/icons';
import { ChangeIncomingDestination } from '../../../../redux/actions/customerActions';
import { SelectCurrentPage } from '../../../../redux/actions/planningActions';
import { IStore } from '../../../../redux/types/store';
import LoadingPage from '../../../global/loadingPage/desktop/loadingPage';
import { ICustomerPageProps } from '../../types';
import ExpensesChart from '../expensesChart';
import ExpensesTable from '../expensesTable/expensesTable';
import TotalIncoming from '../totalIncoming/totalIncoming';
import { IIncomingDestination } from '../types';
import { useStyles } from './style';

const IncomingDestination: React.FC<ICustomerPageProps> = ({ userID, pageNumber }) => {
    const classes = useStyles();

    const dispatch = useDispatch();
    const [err, setErr] = React.useState('');

    const incomingDestination = useSelector((store: IStore) => store.customer.incomingDestination);
    const [loading, setLoading] = React.useState(true);
    
    const incomingDestinationStore = JSON.parse(localStorage.getItem('incomingDestination' + '"' + userID + '"'))

    const userIDCompare = String(localStorage.getItem("userID"));;

    React.useEffect(() => {
        dispatch(SelectCurrentPage(pageNumber));
    }, []);

    React.useEffect(() => {

        const loadLocalStorage = async () => {
            if (localStorage.getItem("incomingDestination" + userIDCompare) != undefined || localStorage.getItem("IncomingDestination" + userIDCompare) != null) {

                dispatch(ChangeIncomingDestination(JSON.parse(String(localStorage.getItem("incomingDestination" + userIDCompare)))));
                setLoading(false);

                let url = '/incomingdestination';

                if (userID) {
                    url += `?userID=${userID}`;
                }

                const response = (await api.get<IIncomingDestination>(url)).data;


                if (String(localStorage.getItem("incomingDestination" + userIDCompare)) !== JSON.stringify(response)) {
                    localStorage.setItem("incomingDestination" + userIDCompare, JSON.stringify(response));
                    dispatch(ChangeIncomingDestination(response));
                }

            } else {

                let url = '/incomingdestination';

                if (userID) {
                    url += `?userID=${userID}`;
                }

                const response = (await api.get<IIncomingDestination>(url)).data;
                localStorage.setItem("incomingDestination" + userIDCompare, JSON.stringify(response));
                dispatch(ChangeIncomingDestination(response));

            }

            setLoading(false);
        };

        loadLocalStorage();

    }, []);

    return (
        <LoadingPage loading={loading}>
            <div className={classes.root}>
                <div className={classes.loaded}>
                    <div className={classes.header}>
                        <IncomingDestinationIcon color="#16191d" />
                        <div className={classes.adjustLabel}>
                            <Typography
                                style={{ marginBottom: '2px' }}
                                variant="h6"
                                className={classes.heading}
                                align="left"
                            >
                                Destino da renda
                            </Typography>
                        </div>
                    </div>
                    <div className={classes.firstLevel}>
                        <TotalIncoming incoming={incomingDestinationStore||incomingDestination} />
                        <div className={classes.rootContent}>
                            <div>
                                <Typography className={classes.heading} variant="h6" align="left">
                                    Distribuição de renda total até a Independência Financeira
                                </Typography>
                                <Paper className={classes.paperContent}>
                                    <ExpensesChart expenses={incomingDestinationStore||incomingDestination} />
                                </Paper>
                            </div>
                            <ExpensesTable expenses={incomingDestinationStore||incomingDestination} />
                        </div>
                    </div>
                </div>
            </div>
        </LoadingPage>
    );
};
export default IncomingDestination;
