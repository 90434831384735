import { CssBaseline } from '@material-ui/core';
import { default as React, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect, Route, Switch, useHistory, useLocation } from 'react-router';
import InvalidPlanning from '../components/customerPages/InvalidPlanning/invalidPlanning';
import MenuAppBar from '../components/planningForm/appBar/appBar';
import CreateUser from '../components/planningForm/createUser/createUser';
import CreatePlanner from '../components/planningForm/createPlanner/createPlanner';
import GainInfo from '../components/planningForm/gainInfo/gainInfo';
import CustomerInfo from '../components/planningForm/customerInfo/customerInfo/customerInfo';
import FinancingPage from '../components/planningForm/financing/entryPage/entryPage';
import FixedCost from '../components/planningForm/fixedCost/fixedCost/fixedCost';
import Goals from '../components/planningForm/goals/goals/goals';
import Income from '../components/planningForm/incoming/provisory/provisory';
import Patrimony from '../components/planningForm/patrimony/patrimony/patrimony';
import PlanningForm from '../components/planningForm/planningForm';
import PlanningResult from '../components/planningForm/planningResult/planningResult';
import RiskManagement from '../components/planningForm/riskManagement/riskManagement/riskManagement';
import SelectClient from '../components/planningForm/selectCustomer/selectCustomer';
import Suggestions from '../components/planningForm/suggestions/suggestions/suggestions';
import { ChangeCustomerUserInfo, SetToolbarParams } from '../redux/actions/planningActions';
import { IStore } from '../redux/types/store';
import { useStyles } from './style';
import { ICustomerUserInfo } from '../redux/types/formPlanning'

interface IPlannerPageProps {
    customcustomers: boolean;
    onSignOut: () => void;
    profileTitle: string;
}

const PlannerPage: React.FC<IPlannerPageProps> = ({ onSignOut, profileTitle, customcustomers }) => {
    const classes = useStyles();

    const dispatch = useDispatch();
    const history = useHistory();
    const location = useLocation();

    const [selectedUser, setSelectedUser] = React.useState('');

    let customerName:string = '';

    const { customerUserInfo, toolbar } = useSelector((store: IStore) => store.planning);

    const { customerNameRedux } = useSelector((store: IStore) => ({
        customerNameRedux: store.planning.customerFormValues.customerInfo.personalInfo.personalInfo.fullName,
    }));

    const userStorage = JSON.parse(localStorage.getItem('userSelectedRedux'));

    useEffect(() => {
        if (localStorage.getItem('user' + localStorage.getItem("userSigned"))) {
            dispatch(ChangeCustomerUserInfo(JSON.parse(localStorage.getItem('user' + localStorage.getItem("userSigned"))) as ICustomerUserInfo));
        }
        if (setSelectedUser) {
            setSelectedUser('alexandrin');
        } else {
            history.push('/planner');
        }
}, [])

    if ((!customerUserInfo.userID || !customerUserInfo) && process.env.NODE_ENV === 'development') {
        dispatch(
            ChangeCustomerUserInfo({
                userID: 'c7fdb52b-ae1a-48b0-a6b9-d1a102bef856',
                nickname: 'test user',
                email: 'test@test.com',
                user: 'maurochuairijr',
            })
        );
    }

        const { pathname } = location;

        if (pathname === '/planner/select' || (!customerNameRedux && !userStorage)) {
            customerName = '';
        } else if (customerNameRedux && !userStorage.fullName) {
            customerName = customerNameRedux;
        } else {
            customerName = userStorage.fullName;
        }


    const changeBar = (title_: string, color_: string, navigation: () => void, current?: number, total?: number) => {
        dispatch(SetToolbarParams(title_, color_, navigation, current || 0, total || 0));
    };

    const goToSelection = () => history.push('/planner/select');

    const goToPlanner = () => history.push('/planner');

    return (
        <div className={classes.root}>
            <MenuAppBar userInfo={customerUserInfo} menu={false} onSignOut={onSignOut} customerName={customerName} customCustomers={customcustomers} />
            <>

                <CssBaseline />
                {
                    customcustomers ?
                        <Switch>
                            <Route path="/planner" exact>
                                <PlanningForm
                                    changeTitle={(title_: string) => changeBar(title_, '#F2F3F3', goToSelection)} customcustomers={customcustomers}
                                    profileTitle={profileTitle} userStorage={userStorage}
                                />
                            </Route>
                            <Route path="/planner/createuser">
                                <CreateUser changeTitle={(title_: string) => changeBar(title_, '#F2F3F3', goToPlanner)} />
                            </Route>
                            <Route path="/planner/createplanner">
                                <CreatePlanner changeTitle={(title_: string) => changeBar(title_, '#F2F3F3', goToPlanner)} />
                            </Route>
                            <Route path="/planner/gaininfo">
                                <GainInfo changeTitle={(title_: string) => changeBar(title_, '#F2F3F3', goToPlanner)} />
                            </Route>
                            <Route path="/planner/clientInfo">
                                <CustomerInfo
                                    changeTitle={(title_: string, curr?: number, total?: number) =>
                                        changeBar(title_, '#F2F3F3', goToPlanner, curr, total)
                                    }
                                />
                            </Route>
                            <Route path="/planner/fixedCost">
                                <FixedCost
                                    changeTitle={(title_: string, curr?: number, total?: number) =>
                                        changeBar(title_, '#F2F3F3', goToPlanner, curr, total)
                                    }
                                />
                            </Route>
                            <Route path="/planner/goals">
                                <Goals
                                    changeTitle={(title_: string, curr?: number, total?: number) =>
                                        changeBar(title_, '#F2F3F3', goToPlanner, curr, total)
                                    }
                                />
                            </Route>
                            <Route path="/planner/patrimony">
                                <Patrimony
                                    changeTitle={(title_: string, curr?: number, total?: number) =>
                                        changeBar(title_, '#F2F3F3', goToPlanner, curr, total)
                                    }
                                />
                            </Route>
                            <Route path="/planner/risk">
                                <RiskManagement
                                    changeTitle={(title_: string, curr?: number, total?: number) =>
                                        changeBar(title_, '#F2F3F3', goToPlanner, curr, total)
                                    }
                                />
                            </Route>
                            <Route path="/planner/incoming">
                                <Income
                                    changeTitle={(title_: string, curr?: number, total?: number) =>
                                        changeBar(title_, '#F2F3F3', goToPlanner, curr, total)
                                    }
                                />
                            </Route>
                            <Route path="/planner/financing">
                                <FinancingPage
                                    changeTitle={(title_: string, curr?: number, total?: number) =>
                                        changeBar(title_, '#F2F3F3', goToPlanner, curr, total)
                                    }
                                />
                            </Route>
                            <Route path="/planner/suggestions">
                                <Suggestions
                                    changeTitle={(title_: string, curr?: number, total?: number) =>
                                        changeBar(title_, '#F2F3F3', goToPlanner, curr, total)
                                    }
                                />
                            </Route>
                            <Route path="/planner/planningResult">
                                <PlanningResult customerName={customerName} />
                            </Route>
                            <Route path="/invalid_planning" exact>
                                <InvalidPlanning
                                    setupBar={() => {
                                        changeBar(toolbar.title, '#F2F3F3', goToPlanner);
                                    }}
                                />
                            </Route>
                            <Redirect from="/" to="/planner" />
                        </Switch>
                        :
                        <Switch>
                            <Route path="/planner" exact>
                                <PlanningForm
                                    changeTitle={(title_: string) => changeBar(title_, '#F2F3F3', goToSelection)} customcustomers={customcustomers}
                                    profileTitle={profileTitle} userStorage={userStorage}
                                />
                            </Route>
                            <Route path="/planner/createuser">
                                <CreateUser changeTitle={(title_: string) => changeBar(title_, '#F2F3F3', goToPlanner)} />
                            </Route>
                            <Route path="/planner/createplanner">
                                <CreatePlanner changeTitle={(title_: string) => changeBar(title_, '#F2F3F3', goToPlanner)} />
                            </Route>
                            <Route path="/planner/gaininfo">
                                <GainInfo changeTitle={(title_: string) => changeBar(title_, '#F2F3F3', goToPlanner)} />
                            </Route>
                            <Route path="/planner/clientInfo">
                                <CustomerInfo
                                    changeTitle={(title_: string, curr?: number, total?: number) =>
                                        changeBar(title_, '#F2F3F3', goToPlanner, curr, total)
                                    }
                                />
                            </Route>
                            <Route path="/planner/fixedCost">
                                <FixedCost
                                    changeTitle={(title_: string, curr?: number, total?: number) =>
                                        changeBar(title_, '#F2F3F3', goToPlanner, curr, total)
                                    }
                                />
                            </Route>
                            <Route path="/planner/goals">
                                <Goals
                                    changeTitle={(title_: string, curr?: number, total?: number) =>
                                        changeBar(title_, '#F2F3F3', goToPlanner, curr, total)
                                    }
                                />
                            </Route>
                            <Route path="/planner/patrimony">
                                <Patrimony
                                    changeTitle={(title_: string, curr?: number, total?: number) =>
                                        changeBar(title_, '#F2F3F3', goToPlanner, curr, total)
                                    }
                                />
                            </Route>
                            <Route path="/planner/risk">
                                <RiskManagement
                                    changeTitle={(title_: string, curr?: number, total?: number) =>
                                        changeBar(title_, '#F2F3F3', goToPlanner, curr, total)
                                    }
                                />
                            </Route>
                            <Route path="/planner/incoming">
                                <Income
                                    changeTitle={(title_: string, curr?: number, total?: number) =>
                                        changeBar(title_, '#F2F3F3', goToPlanner, curr, total)
                                    }
                                />
                            </Route>
                            <Route path="/planner/financing">
                                <FinancingPage
                                    changeTitle={(title_: string, curr?: number, total?: number) =>
                                        changeBar(title_, '#F2F3F3', goToPlanner, curr, total)
                                    }
                                />
                            </Route>
                            <Route path="/planner/suggestions">
                                <Suggestions
                                    changeTitle={(title_: string, curr?: number, total?: number) =>
                                        changeBar(title_, '#F2F3F3', goToPlanner, curr, total)
                                    }
                                />
                            </Route>
                            <Route path="/planner/select">
                                <SelectClient changeTitle={(title_: string) => changeBar(title_, 'white', () => { })} customcustomers={customcustomers} profileTitle={profileTitle} />
                            </Route>
                            <Route path="/planner/planningResult">
                                <PlanningResult customerName={customerName} />
                            </Route>
                            <Route path="/invalid_planning" exact>
                                <InvalidPlanning
                                    setupBar={() => {
                                        changeBar(toolbar.title, '#F2F3F3', goToPlanner);
                                    }}
                                />
                            </Route>
                            <Route path="/">
                                <Redirect from="/" to="/planner/select" />
                            </Route>
                        </Switch>
                }
            </>
        </div >
    );
};
export default PlannerPage;