import { classExpression } from '@babel/types';
import { Button, Chip, createStyles, makeStyles, Theme } from '@material-ui/core';
import { grey } from '@material-ui/core/colors';
import React from 'react';
import TableCost, { BackToValue, ICostValue, ITableEntry } from '../../../global/inputs/tableCost/tableCost';
import CurrencyInput from '../../../global/masks/currencyMaskedInput';
import { IHealthValues, IRecreationValues, IWellbeingValues, NewHealthCost, NewRecreationCost } from '../types';
import { useStyles } from './style';

interface IWellbeingCostProps {
    defaultWellbeingValues?: IWellbeingValues;
    setWellbeingValues: React.Dispatch<React.SetStateAction<IWellbeingValues>>;
    previousStep: () => void;
    nextStep: () => void;
}

const WellbeingCost: React.FC<IWellbeingCostProps> = ({
    defaultWellbeingValues,
    setWellbeingValues,
    nextStep,
    previousStep,
}) => {
    const classes = useStyles();

    const [recreationCosts, setRecreationCosts] = React.useState(NewRecreationCost(defaultWellbeingValues?.recreation));
    const recreationCostsLabel: [string, number][] = Object.values(recreationCosts).map((cost): [string, number] => [
        cost.label,
        cost.value,
    ]);

    const [healthCosts, setHealthCosts] = React.useState(NewHealthCost(defaultWellbeingValues?.health));
    const healthCostsLabel: [string, number][] = Object.values(healthCosts).map((cost): [string, number] => [
        cost.label,
        cost.value,
    ]);

    // this function intends to modify the argument object
    function changeCostByType<T extends ICostValue<T>>(
        key: string,
        value: number,
        cost: ITableEntry<IHealthValues | IRecreationValues>
    ) {
        const keyEntry = key as keyof T;
        ((cost as unknown) as ITableEntry<T>)[keyEntry] = {
            ...((cost as unknown) as ITableEntry<T>)[keyEntry],
            value,
        };
    }

    const setTableValues = (tableName: 'recreation' | 'health', values: Map<string, number>) => {
        const costs = tableName === 'recreation' ? { ...recreationCosts } : { ...healthCosts };
        values.forEach((val, key) => {
            // forEach value find it in habitationalCost by its label
            const objectKeyForLabel = Object.entries(costs).filter(([_, costVal]) => costVal.label === key)![0]![0];
            // if there's a key for that label, then modify its value
            if (objectKeyForLabel) {
                switch (tableName) {
                    case 'health':
                        {
                            changeCostByType<IHealthValues>(objectKeyForLabel, val, costs);
                        }
                        break;
                    case 'recreation':
                        {
                            changeCostByType<IRecreationValues>(objectKeyForLabel, val, costs);
                        }
                        break;
                }
            }
        });

        if (tableName === 'recreation') {
            setRecreationCosts(costs as ITableEntry<IRecreationValues>);
        } else {
            setHealthCosts(costs as ITableEntry<IHealthValues>);
        }
    };
    const [btnDisabled, setBtnDisabled] = React.useState(false);

    const onSumbit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        const personalValues: IWellbeingValues = {
            recreation: BackToValue(recreationCosts),
            health: BackToValue(healthCosts),
        };
        setWellbeingValues(personalValues);
        nextStep();
        setBtnDisabled(true);
    };

    return (
        <div className={classes.root}>
            <form onSubmit={onSumbit}>
                <div className={classes.content}>
                    <TableCost
                        risk=''
                        className={classes.table}
                        header="Custos com recreação e cultura"
                        rows={recreationCostsLabel}
                        tableValues={values => setTableValues('recreation', values)}
                        showTotal
                        inputComponent={CurrencyInput as any}
                    />
                    <TableCost
                        risk=''
                        className={classes.table}
                        header="Custos com Saúde"
                        rows={healthCostsLabel}
                        tableValues={values => setTableValues('health', values)}
                        showTotal
                        inputComponent={CurrencyInput as any}
                    />
                    <div className={classes.formControllers}>
                        {/* @ts-ignore */}
                        <Button
                            variant="contained"
                            className={classes.outButton}
                            size="medium"
                            color="default"
                            disableElevation
                            onClick={previousStep}
                        >
                            Voltar
                        </Button>
                        <button type="submit" className={classes.chipButton} disabled={btnDisabled}>
                            <Chip className={classes.chip} color="primary" size="medium" label="Próximo" clickable />
                        </button>
                    </div>
                </div>
            </form>
        </div>
    );
};
export default WellbeingCost;
