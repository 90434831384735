import axios from 'axios';

const cepaxios = axios.create({
    baseURL: 'https://viacep.com.br/ws/',
});

interface ICEPResponse {
    cep: string;
    logradouro: string;
    complemento: string;
    bairro: string;
    localidade: string;
    uf: string;
    unidade: string;
    ibge: number;
    gia: number;
    erro?: boolean;
}

export const CEPapi = (cep: string) => {
    const unmaskedCEP = cep.replace(/-/g, '');
    const queryURL = unmaskedCEP + '/json';

    return new Promise<ICEPResponse>(async (resolve, reject) => {
        cepaxios
            .get(queryURL)
            .then(response => {
                if (response.data.erro) {
                    reject('CEP Inválido');
                }
                resolve(response.data as ICEPResponse);
            })
            .catch(err => {
                if (err.status && err.status === 400) {
                    reject('CEP Inválido');
                } else {
                    reject('Something went wrong');
                }
            });
    });
};
