import { useTheme } from '@material-ui/core';
import React from 'react';
import formatter from '../../../global/formater';
import FIGCard from '../../homework/FIGCard/FIGCard';
import { Summary } from '../types';
import { useStyles } from './style'

interface IPortfolioSummaryProps extends Summary {}

const PortfolioSummary: React.FC<IPortfolioSummaryProps> = ({ current, suggested }) => {
    const classes = useStyles();
    const theme = useTheme();

    return (
        <div className={classes.cardLine}>
            <FIGCard
                header="Carteira Atual na IF"
                value={formatter.format(current.applied)}
                subtitle="PATRIMÔNIO TOTAL"
                rentability={current.realGain.toFixed(2).toString() + '%'}
                customColor={theme.palette.secondary.light}
            />
            <div className={classes.spacing} />
            <FIGCard
                header="Carteira Sugerida na IF"
                rentability={suggested.realGain.toFixed(2).toString() + '%'}
                value={formatter.format(suggested.applied)}
                subtitle="PATRIMÔNIO TOTAL"
                customColor={theme.palette.success.dark}
            />
            <div className={classes.spacing} />
            <FIGCard
                header="Diferença de Patrimônio"
                value={formatter.format(suggested.applied - current.applied)}
                subtitle="ENTRE PATRIMÔNIOS"
                negative
                customColor={suggested.applied - current.applied > 0 ? '#41c300' : '#ee6002'}
            />
        </div>
    );
};
export default PortfolioSummary;
