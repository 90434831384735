export interface IProperty {
    label: string;
    investimentFocus: boolean;
    marketValue: number;
    outstandingBalance : number;
}

export interface IInvestimentClass {
    fixedIncoming: string;
    multiMarket: string;
    propertyFunds: string;
    exchange: string;
    international: string;
    saving: string;
    pension: string;
    currentAccount: string;
    prefixedFixedIncoming: string;
    gold: string;
    inflationFixedIncoming: string;
    variableIncoming: string;
}

export const investimentLabel: IInvestimentClass = {
    fixedIncoming: 'Renda Fixa Pós',
    multiMarket: 'Fundos Multimercados',
    propertyFunds: 'Fundos Imobiliários',
    exchange: 'Câmbio',
    international: 'Internacional',
    saving: 'Poupança',
    pension: 'Previdência',
    currentAccount: 'Conta corrente',
    variableIncoming: 'Renda Variável',
    prefixedFixedIncoming: 'Renda Fixa Pré',
    inflationFixedIncoming: 'Renda Fixa Inflação',
    gold: 'Ouro',
};

export interface IFinancialAssets {
    label: string;
    investimentClass: keyof IInvestimentClass;
    appliedValue: number;
    rentability: number;
    xp?: boolean;
}

export interface IPension {
    name: string;
    cnpj: string;
    type: 'vgbl' | 'pgbl';
    taxType: 'progressive' | 'regressive';
    balance: number;
    monthlyContribution: number;
}

export interface IHouse {
    value: number;
    forSell: boolean;
    debit: number;
    date?: Date;
}

export interface IPatrimony {
    userID?: string;
    properties: IProperty[];
    house: IHouse;
    financialAssets: IFinancialAssets[];
    pensions: IPension[];
    ready?: boolean;
    total?: number;
}

export type TaxType = 'progressive' | 'regressive';
