import { createStyles, makeStyles, Theme } from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        subHeading: {
            color: '#2C2F31',
            fontWeight: 600,
        },
        root: {
            padding: theme.spacing(2),
        },
        group: {
            width: '100%',
            borderRadius: '5px',
            marginBottom: theme.spacing(1),
            paddingTop: theme.spacing(3),
        },
        innerPadding: {
            width: '100%',
            padding: '0.6em',
        },
        summary: {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            paddingLeft: theme.spacing(2),
        },
        summaryHeader: {
            fontWeight: 600,
        },
        summaryValue: {
            fontSize: 26,
        },
        grow: {
            flexBasis: '20%',
        },
        textField: {
            width: 150,
        },
        form: {
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'baseline',
        },
        divider: {
            margin: `0 ${theme.spacing(2)}px`,
        },
    })
);