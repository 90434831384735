import React, { useEffect, useState } from 'react';
import { Table, TableContainer, TableHead, TableRow, TableCell, TableBody, Paper, } from '@material-ui/core';
import { IIncomingDestination } from '../types';
import formatter from '../../../global/formater';
import { useStyles } from './style'

interface IExpensesTableProps {
    expenses: IIncomingDestination;
}


const ExpensesTable: React.FC<IExpensesTableProps> = ({ expenses }) => {
    const classes = useStyles();

    const [total, setTotal] = useState(0);

    const [aux, setAux] = useState(0);

    const [percentages, setPercentages] = useState<IIncomingDestination>({
        expenses: 0,
        financing: 0,
        protection: 0,
        financialIndependence: 0,
        pension: 0,
        goals: 0,
        cars: 0,
        trips: 0,
        incomingGenerated: 0,
        initialPatrimony: 0,
    });

    const [orderedPercentages, setOrderedPercentages] = useState([])

    const setTableCell = (cell: string) => {
        switch (cell) {
            case 'expenses':
                return { title: 'Custo Fixo', percentage: '40% - 60%', category: cell }
            case 'financing':
                return { title: 'Financiamentos', percentage: '0% - 2%', category: cell }
            case 'protection':
                return { title: 'Proteção', percentage: '3% - 6%', category: cell }
            case 'financialIndependence':
                return { title: 'Independência Financeira', percentage: '15% - 30%', category: cell }
            case 'trips':
                return { title: 'Viagens', percentage: '5% - 8%', category: cell }
            case 'cars':
                return { title: 'Automóveis', percentage: '4% - 5%', category: cell }
            case 'goals':
                return { title: 'Compras Pessoais', percentage: '6% - 8%', category: cell }
            default:
                break;
        }
    }

    useEffect(() => {
        const expensesSum = Object.entries(expenses).reduce((prev, [key, val]) => {
            if (key !== 'incomingGenerated' && key !== 'initialPatrimony') {
                return prev + val;
            }
            return prev;
        }, 0);

        setTotal(expensesSum);
        setPercentages({
            expenses: Math.round((100.0 * expenses.expenses) / expensesSum) || 0,
            financing: Math.round((100.0 * expenses.financing) / expensesSum) || 0,
            protection: Math.round((100.0 * expenses.protection) / expensesSum) || 0,
            financialIndependence: Math.round((100.0 * expenses.financialIndependence) / expensesSum) || 0,
            pension: Math.round((100.0 * expenses.pension) / expensesSum) || 0,
            goals: Math.round((100.0 * expenses.goals) / expensesSum) || 0,
            cars: Math.round((100.0 * expenses.cars) / expensesSum) || 0,
            trips: Math.round((100.0 * expenses.trips) / expensesSum) || 0,
            incomingGenerated: Math.round((100.0 * expenses.incomingGenerated) / expensesSum) || 0,
            initialPatrimony: Math.round((100.0 * expenses.initialPatrimony) / expensesSum) || 0,
        });

        setAux(1)

    }, [setTotal, setPercentages, expenses]);

    useEffect(() => { 
        let keys = Object.entries(percentages).map(percentage => percentage)


        let ordered = keys.sort((a, b) => a[1] - b[1])

        setOrderedPercentages(ordered.reverse())
     }, [aux])

    return (
        <div className={classes.root}>
            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Categoria</TableCell>
                            <TableCell align="left">Valor destinado</TableCell>
                            <TableCell align="center">% Destinado</TableCell>
                            <TableCell align="center">Benchmarking</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            orderedPercentages.map((element, index) => {
                                if (setTableCell(element[0])) {
                                    return (
                                        <TableRow>
                                            <TableCell>{setTableCell(element[0]).title || ''}</TableCell>
                                            <TableCell>{formatter.format(expenses[setTableCell(element[0]).category] || 0)}</TableCell>
                                            <TableCell align="center">{percentages[setTableCell(element[0]).category] || 0} %</TableCell>
                                            <TableCell align="center">{setTableCell(element[0]).percentage || ''}</TableCell>
                                        </TableRow>
                                    )

                                }
                            })
                        }


                        {/* <TableRow>
                            <TableCell>Custo Fixo</TableCell>
                            <TableCell align="right">{formatter.format(expenses.expenses || 0)}</TableCell>
                            <TableCell align="right">{percentages.expenses || 0} %</TableCell>
                            <TableCell align="right">40% - 60%</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>Independência Financeira</TableCell>
                            <TableCell align="right">{formatter.format((expenses.financialIndependence) || 0)}</TableCell>
                            <TableCell align="right">{percentages.financialIndependence || 0} %</TableCell>
                            <TableCell align="right">15% - 30%</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>Proteção</TableCell>
                            <TableCell align="right">{formatter.format(expenses.protection || 0)}</TableCell>
                            <TableCell align="right">{percentages.protection || 0} %</TableCell>
                            <TableCell align="right">3% - 6%</TableCell>
                        </TableRow> */}

                        {/* <TableRow>
                            <TableCell>Moradia</TableCell>
                            <TableCell align="right">0</TableCell>
                            <TableCell align="right"> %</TableCell>
                            <TableCell align="right">10% - 15%</TableCell>
                        </TableRow> */}
                        {/* <TableRow>
                            <TableCell>Compras Pessoais</TableCell>
                            <TableCell align="right">{formatter.format(expenses.goals || 0)}</TableCell>
                            <TableCell align="right">{percentages.goals || 0} %</TableCell>
                            <TableCell align="right">6% - 8%</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>Automóveis</TableCell>
                            <TableCell align="right">{formatter.format(expenses.cars || 0)}</TableCell>
                            <TableCell align="right">{percentages.cars || 0} %</TableCell>
                            <TableCell align="right">4% - 5%</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>Viagens</TableCell>
                            <TableCell align="right">{formatter.format(expenses.trips || 0)}</TableCell>
                            <TableCell align="right">{percentages.trips || 0} %</TableCell>
                            <TableCell align="right">5% - 8%</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>Financiamentos</TableCell>
                            <TableCell align="right">{formatter.format(expenses.financing || 0)}</TableCell>
                            <TableCell align="right">{percentages.financing || 0} %</TableCell>
                            <TableCell align="right">0% - 2%</TableCell>
                        </TableRow> */}
                    </TableBody>
                </Table>
            </TableContainer>
        </div>
    );
};
export default ExpensesTable;
