import {
    createStyles,
    makeStyles,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Theme,
} from '@material-ui/core';
import { Delete } from '@material-ui/icons';
import React from 'react';
import formatter from '../../../global/formater';
import { insurance_categories } from '../insurance/insurance';
import { IInsurance } from '../types';
import {useStyles} from './style';

interface IInsuranceTableProps {
    insuranceRows: IInsurance[];
    remove: (index: number) => void;
}

const InsuranceTable: React.FC<IInsuranceTableProps> = ({ insuranceRows, remove }) => {
    const classes = useStyles();

    const [mouseHover, setMouseHover] = React.useState(insuranceRows.map(row => false));
    React.useEffect(() => {
        setMouseHover(insuranceRows.map(row => false));
    }, [insuranceRows]);
    const changeMouseHover = (hover: boolean, index: number) => {
        setMouseHover(rows => rows.map((_, i) => (index === i ? hover : false)));
    };
    return (
        <Paper elevation={5}>
            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell className={classes.tableHeader} align="left">
                                Categoria
                            </TableCell>
                            <TableCell className={classes.tableHeader} align="right">
                                Seguradora
                            </TableCell>
                            <TableCell className={classes.tableHeader} align="right">
                                Benefício
                            </TableCell>
                            <TableCell className={classes.tableHeader} align="right">
                                Prêmio mensal
                            </TableCell>
                            <TableCell className={classes.tableHeader} align="right">
                                Fonte
                            </TableCell>
                            <TableCell className={classes.tableHeader} align="right">
                                Prazo
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {insuranceRows.map((row, i) => (
                            <TableRow>
                                <TableCell align="left">{insurance_categories.get(row.category)}</TableCell>
                                <TableCell align="right">{row.insuranceCompany}</TableCell>
                                <TableCell align="right">{formatter.format(row.benefit)}</TableCell>
                                <TableCell align="right">{formatter.format(row.deductible)}</TableCell>
                                <TableCell align="right">
                                    {row.sourceDeduction == 'patrimony' ? 'Patrimônio' : 'Sobras'}
                                </TableCell>
                                <TableCell align="right">{row.timeInYears} anos</TableCell>
                                <div
                                    className={classes.deleteHidden}
                                    onMouseEnter={() => changeMouseHover(true, i)}
                                    onMouseLeave={() => changeMouseHover(false, i)}
                                    //@ts-ignore
                                    onClick={() => remove(i)}
                                    // we dont need border when mouse is hovering this div
                                    style={{ borderLeft: !mouseHover[i] ? '9px solid #f44336' : '' }}
                                >
                                    {mouseHover[i] ? (
                                        <Delete style={{ marginTop: '75%', marginRight: '5%' }} color="action" />
                                    ) : null}
                                </div>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </Paper>
    );
};
export default InsuranceTable;
