import { Typography } from '@material-ui/core';
import React, { FC, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import api from '../../../../apis/dreamrichapi';
import { HomeworkIcon } from '../../../../assets/icons';
import { ChangeHomework, UpdateHomeworkYear } from '../../../../redux/actions/customerActions';
import { SelectCurrentPage } from '../../../../redux/actions/planningActions';
import { IStore } from '../../../../redux/types/store';
import LoadingPage from '../../../global/loadingPage/desktop/loadingPage';
import { getGroup } from '../../../login/types';
import { IFinancialInfo } from '../../../toolbarInfo/types';
import { ICustomerPageProps } from '../../types';
import Considerations from '../considerations/considerations';
import FinancialIndependenceGoals from '../financialIndependenceGoal/financialIndependenceGoal';
import MonthlyGoals from '../monthlyGoals/monthlyGoals';
import { IHomework } from '../types';
import YearlyGoals from '../yearlyGoal/yearlyGoal';
import { useStyles } from './style';

const Homework: FC<ICustomerPageProps> = ({ userID, pageNumber }) => {
    const { homework, rentability, loggedUser } = useSelector((store: IStore) => ({
        homework: store.customer.homework,
        rentability: store.customer.rentability,
        loggedUser: store.loggedUser.currentUser,
    }));

    const classes = useStyles();

    const dispatch = useDispatch();

    const [, isplanner] = getGroup(loggedUser);
    const currentYear = new Date().getFullYear();
    const userIDCompare: string  = String(localStorage.getItem("userID"));

    const homeworkStorage = JSON.parse(localStorage.getItem("homework" + userIDCompare));

    const [loading, setLoading] = useState(true);
    const [err, setErr] = useState('');
    const [selectedYear, setSelectedYear] = useState<number>(0);


    const considerationsUpdate = async (considerations: string) => {
        try {
            await api.post('/updateconsiderations', { userID, considerations });
            return true;
        } catch (e) {
            console.debug(e);
            return false;
        }
    };

    const yearControl = (forward: boolean, year?: number) => {
        if (year) {
            setSelectedYear(year - homework.planningYear);
        } else {
            setSelectedYear(oldYear => {
                if (forward && oldYear < homework.yearlyGoals.length - 1) {
                    return ++oldYear;
                }
                if (!forward && oldYear > 0) {
                    return --oldYear;
                }
                return oldYear;
            });
        }
    };

    const getDefaultRentability = async () => {
        try {
            const response = await api.get<IFinancialInfo>('/financialinfo');
            return response.data.profileGain.default;
        } catch (e) {
            console.error(e);
            return 0;
        }
    };

    const fetchYear = async () => {
        const localStorageHomework = localStorage.getItem("homework" + userIDCompare)

        if (localStorageHomework != undefined || localStorageHomework != null) {

            dispatch(ChangeHomework(JSON.parse(String(localStorage.getItem("homework" + userIDCompare)))));
            setErr('');
            setLoading(false);

            const params = new URLSearchParams();
            const url = `/homework?`;

            if (userID) {
                params.append('userID', userID);
            }
            if (rentability) {
                params.append('rentability', rentability.toString());
            } else {
                const rentabilityValue = await getDefaultRentability();
                params.append('rentability', rentabilityValue.toString());
            }

            const response = (await api.get<IHomework>(url + params.toString())).data;

            if (String(localStorage.getItem("omework" + userIDCompare)) !== JSON.stringify(response)) {
                localStorage.setItem("homework" + userIDCompare, JSON.stringify(response));
                dispatch(ChangeHomework(response));
                setErr('');
            }
        } else {
            const params = new URLSearchParams();
            const url = `/homework?`;

            if (userID) params.append('userID', userID)

            if (rentability) {
                params.append('rentability', rentability.toString());
            } else {
                const rentabilityValue = await getDefaultRentability();
                params.append('rentability', rentabilityValue.toString());
            }

            const response = (await api.get<IHomework>(url + params.toString())).data;
            localStorage.setItem("homework" + userIDCompare, JSON.stringify(response));
            dispatch(ChangeHomework(response));
        }

        setLoading(false);
    };

    useEffect(() => {
        dispatch(SelectCurrentPage(pageNumber));
        fetchYear();
    }, []);

    useEffect(() => {
        setSelectedYear(currentYear - homework.planningYear || 0);
    }, [homework.planningYear, setSelectedYear]);

    useEffect(() => {
        dispatch(UpdateHomeworkYear(selectedYear));
    }, [selectedYear]);

    return (
        <LoadingPage loading={loading}>
            <div className={classes.root}>
                <div className={classes.header}>
                    <HomeworkIcon color="rgb(116, 116, 116)" />
                    <Typography className={classes.heading} align="left">
                        Dever de casa
                    </Typography>
                    <div className={classes.grow} />
                </div>
                <FinancialIndependenceGoals FIGoals={homeworkStorage?.financialIndependenceGoals || homework.financialIndependenceGoals} />
                <div>
                    <YearlyGoals
                        YGoals={homeworkStorage?.yearlyGoals || homework.yearlyGoals}
                        selectedYear={selectedYear}
                        yearControl={yearControl}
                        planningYear={homeworkStorage?.planningYear || homework.planningYear}
                        lastYear={homeworkStorage?.lastYear || homework.lastYear}
                    />
                    <MonthlyGoals
                        implementations={homeworkStorage?.yearlyGoals[selectedYear]?.implementations || homework.yearlyGoals[selectedYear]?.implementations || []}
                        yearIndex={selectedYear}
                        MGoals={homeworkStorage?.yearlyGoals[selectedYear]?.monthlyGoals || homework.yearlyGoals[selectedYear]?.monthlyGoals || []}
                        year={homeworkStorage?.yearlyGoals[selectedYear]?.year || homework.yearlyGoals[selectedYear]?.year || currentYear}
                        userID={userID}
                        yearControl={yearControl}
                    />
                </div>
                <div>
                    <Considerations
                        onUpdate={considerationsUpdate}
                        customer={!isplanner}
                        defaultText={homeworkStorage?.considerationsText || homework.considerationsText}
                    />
                </div>
                {err && <Typography>{err}</Typography>}
            </div>
        </LoadingPage>
    );
};
export default Homework;
