export const sumData = (data: any) => {
    const negative = (label: string): number => {
        switch (label) {
            case 'objetivo':
            case 'independência':
                return -1;
            default:
                return 1;
        }
    };
    return Object.entries(data).reduce<number>(
        (prev, [key, value]) => (typeof value === 'number' ? prev + value * negative(key) : prev),
        0
    );
};
